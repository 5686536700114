import { useState } from 'react'
import { CardProps } from '../cardsTypes'
import { Icon } from '@/ui'
import clsx from 'clsx'
import { useFieldContext } from '@/contexts'
import { Table2DType } from '@common/interfaces/fields/table-field.interface.ts'
import { IconName } from '@/ui/Icon'
import { CardDocType } from '@/schema/cards/components/CardDocType.tsx'

export const CardWithImageVertical = ({ doc, docSchema }: CardProps) => {
  const [hovered, setHovered] = useState(false)

  const { field } = useFieldContext<Table2DType>()
  const backgroundColor = doc.fields.color ?? ''
  const styles = field.rows?.styles
  const icon = doc.fields.icon ?? 'gantt-chart'
  const iconColor = doc.fields.color || '#000'

  return (
    <div
      className={clsx(
        'w-full h-full rounded-md p-2 flex items-center justify-between gap-4',
        !backgroundColor && 'bg-primary',
      )}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={
        hovered
          ? {
              scale: '1.01',
              transition: 'all 0.3s ease',
              backgroundColor: backgroundColor as string,
            }
          : {
              scale: '1',
              transition: 'all 0.3s ease',
              backgroundColor: backgroundColor as string,
              ...styles,
            }
      }
    >
      {icon && (
        <div className="bg-white p-1 rounded shrink-0">
          <Icon name={icon as IconName} size={16} color={iconColor as string} />
        </div>
      )}

      <div className="grow">
        <CardDocType
          hide={docSchema?.hideType}
          type={docSchema?.niceName}
          className="mb-1.5 text-white"
        />
        <p className="font-bold text-left text-white">{doc.title}</p>
      </div>
    </div>
  )
}
