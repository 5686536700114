import { db, Relation, RelationType } from '@/db'
import { io } from './socket'

export type CreateRelationPL = {
  fromId: string
  toId: string
  relationType: RelationType
  fieldName: string
  reverseFieldName: string
  _meta?: Relation['_meta']
  fromDocType: string
  toDocType: string
}

export async function createRelationApi(payload: CreateRelationPL) {
  const res = io.emit<Relation>('createDocumentRelation', {
    _workspaceId: db.activeWorkspace._id,
    relation: {
      _partId: db.activeWorkspace._id,
      ...payload,
    },
  })
  if (!res) throw new Error('Failed to create relation')

  return res
}

export async function deleteRelationApi(id: string) {
  const res = io.emit<boolean>('deleteDocumentRelation', {
    _workspaceId: db.activeWorkspace._id,
    _id: id,
  })
  if (!res) throw new Error('Failed to delete relation')

  return res
}

export async function updateRelationMetaApi(payload: { id: string; keyName: string; value: any }) {
  const res = await io.emit<boolean>('updateDocumentRelationMeta', {
    workspaceId: db.activeWorkspace._id,
    ...payload,
  })

  if (!res) throw new Error('Failed to update relation meta')

  return res
}
