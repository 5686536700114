import { SomeField } from '../fields/field.interface';
import { InteractionType } from '../documents/interaction.interface';
import { ReadAccess } from '../documents/document.interface';

export const WORKSPACE_DOCUMENT_TYPE_NAME = 'WORKSPACE';

export interface DocumentType {
  name: string;
  niceName: string;
  singleEntry?: boolean;
  showInExt?: boolean;
  menuGroup?: string;
  subMenuGroup?: string;
  sortOrderInMenu?: number;
  hideSubsections?: boolean;
  hideInMenu?: boolean;
  label?: string;
  placeholder?: string;
  helpPanelColor?: string;
  hideType?: boolean;
  icon?: string;
  aiContext?: string;
  stealth?: boolean;
  readAccess?: 'parent' | ReadAccess;
  layout: {
    name: 'home' | 'section' | 'single';
    theme?: 'rounded';
    actionFields?: ActionField[];
    bgColors?: {
      header?: string;
      leftMenu?: string;
      main?: string;
      content?: string;
      rightBar?: string;
    }
  };
  interactions?: { [key in InteractionType]?: boolean };
  interactionsArea?: 'main' | 'rightBar';
  commentStyles?: { [key: string]: string };
  fields: SomeField[];
  query?: {
    filterFields?: string[] | undefined
  },
  defaultFieldValues?: {
    [key: string]: string
  }
  addNew?: string;
  addGlobalTypes?: globalTypes[];
  optionsData?: {
    icon: string,
    text: string,
    textColor: string,
    color: string,
    bgColor: string,
    value: string,
  }[]
  iconsData?: {
    icon: string,
    value: string,
  }[]
  expScope?: Record<string, boolean>;
  labelForActivity?: {
    byFieldName?: string;
    expressionByUserEmail?: string;
    byUserRole?: string
  }
}

export type globalTypes = {
  type: string,
  parent: string
}

export type ActionField = 'like' | 'comment';

export interface Schema {
  _clientId: string;
  _workspaceId?: string;
  schema: DocumentType[];
}
