import { Link } from 'react-router-dom'
import { usePath } from '@/hooks'
import { Button } from '@/ui'
import { CardProps } from '../cardsTypes'

export const SelectCard = ({ doc, isEditMode, onDelete, headerElements }: CardProps) => {
  const { getDocPath } = usePath()

  return (
    <Link
      aria-label={`link to ${doc.title}`}
      to={getDocPath(doc)}
      className="rounded-md bg-indigo100 flex items-center relative"
    >
      {headerElements}
      <p className="text-sm px-2 max-w-[252px] py-1 truncate">{doc.title}</p>
      {isEditMode && onDelete && (
        <Button
          icon="x"
          id={'delete ' + doc.title}
          tooltip={'delete ' + doc.title}
          onClick={(e) => onDelete(e, doc)}
        />
      )}
    </Link>
  )
}
