import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { MultiValue } from 'react-select'
import clsx from 'clsx'
import { FilterFieldType } from '@common/interfaces/fields/filter-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { useQueryEntry } from '@/hooks'
import { clearFilters, updateFilter } from '@/store/filters/slice'
import { Select } from '@/ui'
import { SelectOption } from '@/ui/Select'
import { useSort } from '@/schema/hooks'

export const FilterField = () => {
  const { doc } = useDocContext()
  const { field } = useFieldContext<FilterFieldType>()
  const { placeholder, singleSelect, defaultValue, disableClear, query } = field
  const selectedDefaultValue = useRef<string | null>(null)
  const dispatch = useDispatch()
  const { docs: optionDocs } = useQueryEntry(query)
  const sortedDocs = useSort(optionDocs, field.sort)

  const allOptions = useMemo(
    () =>
      sortedDocs.map<SelectOption>((item) => ({
        label: item.title,
        value: item._id,
      })),
    [sortedDocs],
  )

  useEffect(
    () => () => {
      dispatch(clearFilters())
    },
    [],
  )

  const handleMultiChange = (newOptions: MultiValue<SelectOption>) => {
    if (!newOptions.length && disableClear) return

    dispatch(updateFilter({ name: field.name, value: newOptions.map((v) => v.value) }))
  }

  const handleSingleChange = (option: SelectOption, actionMeta) => {
    const { action } = actionMeta
    if (action === 'clear' && !disableClear) {
      dispatch(updateFilter({ name: field.name, value: [] }))
      return
    }

    dispatch(updateFilter({ name: field.name, value: [option.value] }))
  }

  const selectDefaultOption = useCallback(
    (options: SelectOption[]) => {
      if (!defaultValue || !options.length) return

      if (typeof defaultValue === 'boolean') {
        if (!selectedDefaultValue.current) {
          dispatch(updateFilter({ name: field.name, value: [options[0].value] }))
        }
        selectedDefaultValue.current = options[0].value
        return options[0]
      }

      if (defaultValue === 'last') {
        if (!selectedDefaultValue.current) {
          dispatch(updateFilter({ name: field.name, value: [options[options.length - 1].value] }))
        }
        selectedDefaultValue.current = options[options.length - 1].value
        return options[options.length - 1]
      }

      let defaultOption: SelectOption | undefined

      const fieldValue = doc.fields[defaultValue] as string[]
      if (fieldValue?.length) {
        defaultOption = options.find((op) => op.value === fieldValue[0])
      } else {
        defaultOption = options.find((op) => op.label === defaultValue)
      }

      if (defaultOption) {
        if (!selectedDefaultValue.current) {
          dispatch(updateFilter({ name: field.name, value: [defaultOption.value] }))
        }
        selectedDefaultValue.current = defaultOption.value
        return defaultOption
      }
    },
    [defaultValue],
  )

  if (!optionDocs.length) return null

  return (
    <div className={clsx('grid grid-flow-col gap-1', field.sticky && 'sticky top-12 z-20')}>
      <Select
        placeholder={placeholder}
        isClearable={!disableClear}
        isMulti={!singleSelect}
        className="min-w-[160px]"
        options={allOptions}
        defaultValue={selectDefaultOption(allOptions)}
        onChange={(value, actionMeta) =>
          singleSelect
            ? handleSingleChange(value as SelectOption, actionMeta)
            : handleMultiChange(value as MultiValue<SelectOption>)
        }
      />
    </div>
  )
}
