import { FC, MouseEvent, useMemo, useState } from 'react'
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react'
import { Button } from '@/ui'
import clsx from 'clsx'
import { inviteUser } from '@/db/users/sync'
import { useToggle } from '@/hooks'
import { validateEmail } from '@/utils/regexp'
import { MultipleEmailInputField } from './components/MultipleEmailInput'

export const InviteButton: FC<{ btnText?: string; className?: string }> = ({
  btnText,
  className,
}) => {
  const [email, setEmail] = useState<string>('')
  const [emails, setEmails] = useState<string[]>([])
  const [isOpened, { on, off }] = useToggle()

  const isValidEmails = useMemo(() => {
    if (!emails.length) {
      return false
    }

    return emails.every((item) => validateEmail(item))
  }, [emails])

  const onInvite = (event: MouseEvent) => {
    if (validateEmail(email) && !emails.length) {
      inviteUser([email], true)
      setEmail('')
    }

    if (emails.length) {
      const validatedEmails = emails.filter((entry) => validateEmail(entry))

      inviteUser(validatedEmails, true)

      setEmails(() => [])
    }

    off(event)
  }

  const text = btnText ?? 'Invite'

  return (
    <>
      <Button className={clsx('max-sm:hidden', className)} onClick={on} color="primary" icon="plus">
        {text}
      </Button>

      <Modal isOpen={isOpened} onOpenChange={off}>
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1"></ModalHeader>
          <ModalBody className="pb-4">
            <MultipleEmailInputField
              email={email}
              setEmail={setEmail}
              emails={emails}
              setEmails={setEmails}
              isValidEmails={isValidEmails}
            />
          </ModalBody>

          <ModalFooter>
            <Button disabled={!validateEmail(email) && !emails.length} onClick={onInvite}>
              Invite
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
