import { GroupBase, StylesConfig } from 'react-select'

export const setCustomStyles = <
  T,
  IsMulti extends boolean,
  Group extends GroupBase<T>,
>(): StylesConfig<T, IsMulti, Group> => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#F8F8F8' : '#F5F5F5',
    minWidth: '220px',
    padding: '6px 8px',
    border: state.isFocused ? '2px solid #B5DEFF' : '2px solid #F5F5F5',
    borderRadius: '4px',
    boxShadow: 'none',

    '&:hover': {},
  }),
  container: (provided, state) => ({
    ...provided,
    pointerEvents: state.isDisabled ? 'auto' : provided.pointerEvents,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#1E1E1E',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#FFFFFF',
    border: '1px solid #EFEFEF',
    boxShadow: '0 2px 12px rgba(169, 169, 169, 0.14)',
    borderRadius: '6px',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '12px',
    paddingBottom: '12px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#38cdbe' : '#FFF',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#1E1E1E',

    '&:hover:not(.dropdown__option--is-selected)': {
      backgroundColor: '#ececec',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#E5E5E5',
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 500,
    color: '#1E1E1E',
    borderRadius: '6px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 500,
    color: '#1E1E1E',
    padding: '2px 6px',
  }),
})
