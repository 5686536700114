import { useEffect, useRef, useState } from 'react'
import Hammer from 'hammerjs'
import { BoardPoint } from '../whiteboardTypes'
import { boardState, isSameTarget } from '../whiteboardUtils'

export type DragStart = (point: BoardPoint, e: HammerInput) => void
export type Dragging = (point: BoardPoint, e: HammerInput) => void
export type DragEnd = (
  point: BoardPoint,
  elements: Element | undefined,
  e: HammerInput,
) => boolean | void

export function useDragging<R extends SVGForeignObjectElement>(
  initialPos = { x: 0, y: 0 },
  options?: {
    disable?: boolean
    start?: DragStart
    drag?: Dragging
    end?: DragEnd
  },
) {
  const ref = useRef<R>(null)
  const [pos, setPos] = useState(initialPos)
  const [focus, setFocus] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    const el = ref.current as R
    const content = el?.children[0] ?? el
    if (el == null) return () => undefined

    el.setAttribute('transform', `translate(${pos.x}, ${pos.y})`)

    if (options?.disable) return () => undefined

    const mc = new Hammer.Manager(el)
    mc.add(new Hammer.Pan())

    let startPosX = 0
    let startPosY = 0
    let dragPosX = 0
    let dragPosY = 0
    let panned = false

    function updatePos(x: number, y: number) {
      dragPosX = x
      dragPosY = y
      setPos({ x, y })

      el.setAttribute('transform', `translate(${x}, ${y})`)
    }

    function panstart(e: HammerInput) {
      panned = isSameTarget(content, e.target)
      setIsDragging(true)
      if (el.parentElement === boardState.element) {
        const bound = content.getBoundingClientRect()
        const [x, y] = boardState.getLocalPoint(bound.x, bound.y)
        startPosX = x
        startPosY = y
      } else {
        startPosX = pos.x
        startPosY = pos.y
      }
      options?.start?.({ x: startPosX, y: startPosY }, e)
    }

    function panmove(e: HammerInput) {
      if (!panned) return

      const x = e.deltaX * boardState.ratio + startPosX
      const y = e.deltaY * boardState.ratio + startPosY

      updatePos(x, y)
      options?.drag?.({ x, y }, e)
    }

    function panend(e: HammerInput) {
      panned = false
      setIsDragging(false)

      const bound = content.getBoundingClientRect()
      const elements = document.elementsFromPoint(bound.x, bound.y)
      const clusterEl = elements.find((item) => item.hasAttribute('data-cluster'))
      const shouldReturnPos = options?.end?.({ x: dragPosX, y: dragPosY }, clusterEl, e)
      if (shouldReturnPos) {
        updatePos(startPosX, startPosY)
      }
    }

    mc.on('panstart', panstart)
    mc.on('panmove', panmove)
    mc.on('panend', panend)

    function handleFocus() {
      setFocus(true)
    }

    function handleBlur() {
      setTimeout(() => {
        setFocus(false)
      }, 500)
    }

    content.addEventListener('focus', handleFocus)
    content.addEventListener('blur', handleBlur)

    return () => {
      mc.destroy()
      content.removeEventListener('focus', handleFocus)
      content.removeEventListener('blur', handleBlur)
    }
  }, [])

  return [ref, { pos, focus, isDragging }] as const
}
