import {
  KeyValueData,
  KeyValueDataValue,
  KeyValueFieldType,
} from '@common/interfaces/fields/keyvalue-field.interface'
import { useFieldContext } from '@/contexts'
import { FieldComponent } from '../fieldType'
import { KeyValueForm } from './components/KeyValueForm'

export const KeyValueField: FieldComponent = () => {
  const { isEditable, field, docValue = {} } = useFieldContext<KeyValueFieldType, KeyValueData>()

  const { fields = [] } = field

  function isNotImageField(value: KeyValueDataValue): value is string {
    return (
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'undefined' ||
      typeof value === 'boolean'
    )
  }

  if (isEditable) {
    return <KeyValueForm />
  }

  return (
    <div className="mb-5">
      <div className="pb-4 grid grid-cols-2">
        {fields.map((field) => {
          if (isNotImageField(docValue[field.name])) {
            return (
              <div key={field.name}>
                {field.name}: {docValue[field.name]?.toString()}
              </div>
            )
          }
          return undefined
        })}
      </div>
    </div>
  )
}
