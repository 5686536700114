import { MouseEvent, useEffect } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { useDocContext, useFieldContext } from '@/contexts'
import { queryLikes, queryUserInteraction } from '@/db/interactions/queries'
import { createInteraction, deleteInteraction, fetchInteractions } from '@/db/interactions/sync'
import { CardElementsProps } from '@/schema/cards'
import { useSlr } from '@/store'
import { selectAccount, selectClientWorkspace } from '@/store/account'
import { Button } from '@/ui'
import { FieldComponent } from '../fieldType'
import { singlePromise } from '@/utils/promises'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface'

export const LikeField: FieldComponent<CardElementsProps> = ({ docItem }) => {
  const { doc } = useDocContext()
  const { field } = useFieldContext<ChildrenFieldType>()
  const { workspace } = useSlr(selectClientWorkspace)
  const currentDoc = docItem ?? doc
  const fontColor = field.layout?.card?.fontColor ?? '#1E1E1E'
  const likes = useLiveQuery(
    async () => queryLikes(currentDoc._id),
    [currentDoc._userId, currentDoc._id],
  )

  const account = useSlr(selectAccount)
  const interactionForCurrentDoc = useLiveQuery(
    () => queryUserInteraction(currentDoc._id, account._id, 'like'),
    [],
  )

  useEffect(() => {
    singlePromise(`likes/${doc._id}`, () => fetchInteractions(currentDoc._id, 'like'), {
      once: true,
    })
  }, [])

  const likeHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    if (interactionForCurrentDoc) {
      deleteInteraction(interactionForCurrentDoc)
    } else {
      createInteraction(currentDoc._id, { type: 'like', value: true })
    }
  }
  const color = interactionForCurrentDoc ? workspace.color : fontColor
  return (
    <div className="flex items-center">
      <Button variant="ghost" icon="heart" iconSize={18} onClick={likeHandler} iconColor={color} />
      <span style={{ color: fontColor }}>{likes}</span>
    </div>
  )
}
