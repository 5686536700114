import { GroupBase, SelectComponentsConfig } from 'react-select'
import { Control } from './Control'
import { MenuList } from './MenuList'
import { Option } from './Option'
import { SingleValue } from './SingleValue'

export function selectComponents<
  T,
  IsMulti extends boolean,
  Group extends GroupBase<T>,
>(): SelectComponentsConfig<T, IsMulti, Group> {
  return {
    MenuList,
    SingleValue,
    Option,
    Control,
    IndicatorSeparator: () => null,
  }
}
