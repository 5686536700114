import { useEffect, useMemo, useState } from 'react'
import { Button } from '@/ui'
import { useLiveQuery } from 'dexie-react-hooks'
import { CollaborateFieldType } from '@common/interfaces/fields/collaborate-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { Doc } from '@/db'
import { queryDocsByType } from '@/db/docs/queries'
import { queryPermissionGroupByName } from '@/db/permissions/queries'
import {
  createPermissionGroup,
  fetchDocumentPermissions,
  updateDocumentReadAccess,
  updateUserPermissionGroup,
} from '@/db/permissions/sync'
import { useFetchQuery, usePath } from '@/hooks'
import { FieldContainer } from '@/schema'
import { useSlr } from '@/store'
import { selectAccount } from '@/store/account'
import { CollaborateUser } from './components/CollaborateUser'
import { CollaborateUserSelect } from './components/CollaborateUserSelect'
import { sendInviteEmail } from './collaborateUtilts'
import { getUserApi } from '@/api/users'
import { changeArr } from '@/utils/docsSort'

export const CollaborateField = () => {
  const { doc } = useDocContext()
  const account = useSlr(selectAccount)
  const docPermission = useFetchQuery(() => fetchDocumentPermissions(doc._id), [doc._id])
  const {
    value = [],
    saveValue,
    field,
    isEditable,
  } = useFieldContext<CollaborateFieldType, string[]>()
  const allUsers = useLiveQuery(() => queryDocsByType('User'), [])
  const [isPublic, setIsPublic] = useState(doc._read_access === 'everyone')
  const { tooltipPrivate, tooltipEveryone } = field
  const { getDocPath } = usePath()

  useEffect(() => {
    setIsPublic(doc._read_access === 'everyone')
  }, [doc._read_access])

  const users = useMemo(() => {
    const shared: Doc[] = []
    const others: Doc[] = []

    allUsers?.forEach((user) => {
      if (value.includes(user._userId) || user.userId === doc._userId) {
        shared.push(user)
      } else {
        others.push(user)
      }
    })

    return { shared, others }
  }, [allUsers, value])

  const currentDocPermision = useMemo(
    () => docPermission?.permissions.find((el) => el.includes(doc._id) && el.includes('editor')),
    [docPermission, doc._id],
  )

  const removeUser = async (userId?: string) => {
    const group = await queryPermissionGroupByName(doc._id)
    if (!group || !currentDocPermision || !userId) return

    const status = await updateUserPermissionGroup(userId, group._id, 'remove')
    if (status) {
      saveValue(value.filter((el) => el !== userId))
    }
  }

  const inviteUser = async (userId?: string) => {
    if (!currentDocPermision || !userId) return

    let group = await queryPermissionGroupByName(doc._id)
    if (!group) {
      group = await createPermissionGroup(doc._id, [currentDocPermision])
    }
    if (!group) return

    try {
      const { user, email } = await getUserApi(userId)

      sendInviteEmail({
        docTitle: doc.title,
        docUrl: getDocPath(doc),
        userName: user.name,
        senderName: account.name,
        email: email.email,
        subject: `You have been invited to collaborate on ${doc.title}`,
      })
    } catch (error) {
      window.Rollbar.error(error as Error)
    }

    const status = await updateUserPermissionGroup(userId, group._id)
    if (status) {
      saveValue(changeArr(value, 'add', [userId]))
    }
  }

  const changeReadAccess = () => {
    setIsPublic(!isPublic)
    const readAcess = !isPublic ? 'everyone' : 'private'
    updateDocumentReadAccess(doc._id, readAcess)
  }

  return (
    <FieldContainer className="grid gap-3 mt-2">
      {isEditable && (
        <Button
          variant={isPublic ? 'outline' : 'default'}
          tooltip={isPublic ? tooltipPrivate : tooltipEveryone}
          onClick={changeReadAccess}
        >
          {isPublic ? `Make  private` : `Make  public`}
        </Button>
      )}
      <div className="flex flex-wrap gap-1.5">
        {users.shared.map((user) => (
          <CollaborateUser
            isAnotherUser={user.userId !== account._id}
            key={user._id}
            user={user}
            removeUser={removeUser}
          />
        ))}
      </div>
      {isEditable && <CollaborateUserSelect users={users.others} inviteUser={inviteUser} />}
    </FieldContainer>
  )
}
