import { useState } from 'react'
import { Autocomplete, AutocompleteItem } from '@nextui-org/react'
import { Key } from '@react-types/shared'
import { updateUserPermissionGroup } from '@/db/permissions/sync'

type Option = { label: string; value: string; permissionGroups: Set<string> }

interface AddPermissionUserProps {
  groupId: string
  options: Option[]
}

export const AddPermissionUser = ({ groupId, options }: AddPermissionUserProps) => {
  const [value, setValue] = useState<Key>('')

  const handleChange = async (userId: Key) => {
    setValue(userId)

    const user = options.find((option) => option.value === userId)
    if (!user) return

    await updateUserPermissionGroup(user.value, groupId)
    setValue('')
  }

  return (
    <Autocomplete
      size="sm"
      selectedKey={value}
      placeholder="Add a user"
      onChange={(e) => setValue(e.target.value)}
      onSelectionChange={handleChange}
    >
      {options.map((option) => (
        <AutocompleteItem key={option.value} value={option.value}>
          {option.label}
        </AutocompleteItem>
      ))}
    </Autocomplete>
  )
}
