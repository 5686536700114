import { CardElementsProps } from '@/schema/cards'
import { Icon, Tooltip } from '@/ui'
import { useEffect, useState } from 'react'
import { db, ImageFieldData } from '@/db'
import { io } from '@/api'
import { Modal, ModalBody, ModalContent, useDisclosure } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'
import { usePath } from '@/hooks'

export const FileButtons = ({ docItem, name }: CardElementsProps) => {
  const [url, setUrl] = useState('')
  const { isOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const { getDocPath } = usePath()

  const file = docItem?.fields?.download as ImageFieldData

  useEffect(() => {
    if (file?.fileName && docItem) {
      const payload = {
        _clientId: db.activeClient._id,
        _documentId: docItem._id,
        fileName: file.fileName,
        hours: 1,
      }

      io.emit<string>('getSignedUrl', payload)
        .then((res) => {
          setUrl(res)
        })
        .catch((error) => {
          window.Rollbar.error(error as Error)
        })
    }
  }, [])

  if (!docItem || !file || !url) return null

  const handleDownload = async (cdnUrl, filename, e) => {
    e.preventDefault()
    try {
      const response = await fetch(cdnUrl, {
        method: 'GET',
        headers: {
          'Content-Type': file.type || 'application/octet-stream',
        },
      })

      if (!response.ok) {
        throw new Error('Network response was not ok.')
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error)
    }
  }

  if (name === 'fileViewButton') {
    return (
      <>
        <div className="flex items-center justify-center">
          <Tooltip content="View file">
            <div className="bg-white flex rounded-full p-1 w-10 h-10 items-center justify-center">
              <Icon
                name="eye"
                size={18}
                color="#1E1E1E"
                onClick={(e) => {
                  e.preventDefault()
                  navigate({ pathname: getDocPath(docItem) })
                }}
              />
            </div>
          </Tooltip>
        </div>

        <Modal size="full" radius="none" isOpen={isOpen} onClose={onClose}>
          <ModalContent>
            <ModalBody className="px-0 py-0">
              <iframe src={url} width="100%" height="100%" loading="lazy" title="PDF-file" />
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  }

  return (
    <div className="flex items-center justify-center p-1 w-10 h-10 bg-white rounded-full">
      <Tooltip content="Download file">
        <Icon
          name="download"
          size={18}
          color="#1E1E1E"
          onClick={(e) => handleDownload(url, file.fileName, e)}
        />
      </Tooltip>
    </div>
  )
}
