import { useState } from 'react'
import { createDocumentCacheEntry } from '@/api/docs'
import { queryAllDocs } from '@/db/docs/queries'
import { Button } from '@/ui'
import { sequentiallyChunked } from '@/utils'

export const GenerateDocsCache = () => {
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    setLoading(true)
    const docs = await queryAllDocs()
    if (docs) {
      await sequentiallyChunked(docs, async (doc) => {
        try {
          await createDocumentCacheEntry(doc._id)
        } catch (error) {
          console.error(error)
        }
      })
    }
    setLoading(false)
  }

  return (
    <Button variant="ghost" className="max-[400px]:w-full" loading={loading} onClick={onClick}>
      Generate docs cache
    </Button>
  )
}
