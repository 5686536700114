import { useLiveQuery } from 'dexie-react-hooks'
import { QueryFieldType } from '@common/interfaces/fields/query-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { Doc } from '@/db'
import { queryDocChildren } from '@/db/docs/queries'
import { useGlobalFilter } from '@/hooks/useGlobalFilter'
import { FieldContainer } from '@/schema'
import { DocsList } from '@/schema/cards'
import { FieldComponent } from '../fieldType'

export const ChildrenField: FieldComponent = () => {
  const { field } = useFieldContext<QueryFieldType, Doc[]>()
  const { doc, isEditMode } = useDocContext()
  const docs =
    useLiveQuery(
      () => queryDocChildren(doc._id, { allowed: field?.allowed }),
      [doc._id, field.name],
    ) ?? []

  const filteredDocs = useGlobalFilter(docs, field.query, field.enableGlobalFilter)

  if (!isEditMode && !docs.length) {
    return null
  }

  return (
    <FieldContainer className="grid gap-7">
      <DocsList docs={filteredDocs} parentDoc={doc} />
    </FieldContainer>
  )
}
