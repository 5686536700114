import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react'
import * as Yup from 'yup'
import { User } from '@/db'
import { updateOtherUserPassword } from '@/db/users/sync.ts'
import { useSlr } from '@/store'
import { selectClientWorkspace } from '@/store/account'
import { Button } from '../Button'

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(4, 'Password length should be at least 4 characters')
    .max(12, 'Password cannot exceed more than 12 characters'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .min(4, 'Password length should be at least 4 characters')
    .max(12, 'Password cannot exceed more than 12 characters')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
})

export const UpdateOtherUserPasswordModal = ({
  user,
  onClose,
  isOpened,
}: {
  user: User
  onClose: () => void
  isOpened: boolean
}) => {
  const formOptions = { resolver: yupResolver(validationSchema) }
  const { register, handleSubmit, formState, clearErrors } = useForm(formOptions)
  const { errors, isSubmitting } = formState
  const { workspace } = useSlr(selectClientWorkspace)

  const submit = handleSubmit(async (data) => {
    await updateOtherUserPassword({
      password: data.password,
      confirmPassword: data.confirmPassword,
      userId: user._id,
      workspaceId: workspace._id,
    })

    onClose()
  })

  const closeModal = () => {
    clearErrors()
    onClose()
  }

  return (
    <Modal isOpen={isOpened} onOpenChange={closeModal}>
      <ModalContent className="w-[600px] max-w-2xl px-4">
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 px-0 h-12">
              Update user password
            </ModalHeader>
            <ModalBody className="p-0 max-h-[600px] overflow-y-auto overflow-x-hidden">
              <form>
                <div className="form-control w-full">
                  <Input
                    type="password"
                    className="py-2"
                    isInvalid={!!errors.password}
                    label="New password"
                    placeholder="Enter your password"
                    {...register('password')}
                  />

                  <label className="label">
                    <span className="label-text-alt">{errors.password?.message}</span>
                  </label>
                </div>

                <div className="form-control w-full">
                  <Input
                    type="password"
                    className="py-2"
                    isInvalid={!!errors.confirmPassword}
                    label="Confirm password"
                    placeholder="Enter your password"
                    {...register('confirmPassword')}
                  />
                  <label className="label">
                    <span className="label-text-alt">{errors.confirmPassword?.message}</span>
                  </label>
                </div>
              </form>
            </ModalBody>
            <ModalFooter className="border-t-1 p-1 border-neutral200">
              <Button color="default" variant="outline" onClick={onClose}>
                Close
              </Button>
              <Button type="submit" onClick={submit} disabled={isSubmitting}>
                Update
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
