import { CardProps } from '@/schema/cards/cardsTypes.ts'
import { Preview } from '@/ui'

export const CarouselCard = ({ doc, children }: CardProps) => (
  <div className="relative h-[200px] border border-neutral300 rounded-md">
    <Preview
      className="absolute left-0 top-0 w-full h-full rounded-md object-top [&>svg]:hidden"
      image={doc.fields.image}
    />
    <div className="absolute left-0 top-0 w-full h-full bg-neutral900 bg-opacity-60 rounded-md" />

    <div className="absolute left-0 top-0 flex items-center justify-center w-full h-full p-6">
      <h2 className="text-title text-white line-clamp-3 max-h-[140px]">{doc.title}</h2>
    </div>

    {children}
  </div>
)
