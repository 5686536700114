import { useState } from 'react'
import { SketchPicker } from 'react-color'
import clsx from 'clsx'
import { ChevronDown } from 'lucide-react'
import { Button, Dropdown } from '@/ui'
import { pickerColors } from '@/ui/theme'
import styles from './ColorPicker.module.css'

interface ColorPickerProps {
  value?: string
  onChange?: (value) => void
  className?: string
  showIcon?: boolean
}

export const ColorPicker = ({ className, value, onChange, showIcon }: ColorPickerProps) => {
  const [color, setColor] = useState<string | undefined>(value)

  const handleOnChange = (newValue) => {
    setColor(newValue.hex)
    onChange?.(newValue.hex)
  }

  return (
    <div className={clsx('flex', className)}>
      <Dropdown>
        <Dropdown.Toggle>
          {showIcon ? (
            <Button
              variant="ghost"
              aria-label="text color picker"
              icon="palette"
              className={clsx('toolbar-item spaced')}
            />
          ) : (
            <div className="px-[11px] py-[6px] grid gap-2 grid-cols-[20px_80px_20px] items-center justify-center border border-neutral200 rounded-md bg-white z-0">
              <button
                aria-label="select color"
                type="button"
                className={styles.colorBtn}
                style={{ backgroundColor: color }}
              />
              <span>{color ? color : 'Set color'}</span>
              <ChevronDown color="#E5E5E5" />
            </div>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="mt-1 z-10 border-none"
          onClick={(e) => {
            e?.stopPropagation()
          }}
        >
          <SketchPicker
            color={color}
            width={250}
            onChange={handleOnChange}
            presetColors={pickerColors}
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
