import { ImageFieldType } from '@common/interfaces/fields/image-field.interface'
import { useFieldContext } from '@/contexts'
import { ImageFieldData } from '@/db'
import { Preview } from '@/ui'
import { ImageFieldProps } from '../types'

export const PersonaImage = ({ onClick }: ImageFieldProps) => {
  const { isEditable, value } = useFieldContext<ImageFieldType, ImageFieldData>()

  if (isEditable) {
    return (
      <button
        onClick={onClick}
        className="relative bg-neutral200 w-32 h-32 mb-7 rounded-full cursor-pointer overflow-hidden"
      >
        <Preview image={value} className="absolute top-0 left-0 w-full h-full" />
      </button>
    )
  }

  return (
    <div className="relative bg-neutral200 w-32 h-32 mb-7 rounded-full overflow-hidden">
      <Preview image={value} className="absolute top-0 left-0 w-full h-full" />
    </div>
  )
}
