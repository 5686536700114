import { useEffect, useRef } from 'react'

export function useAutoSizeTextArea(value = '') {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    const textArea = textAreaRef.current
    if (!textArea) return

    textArea.style.height = '0px'
    const { scrollHeight } = textArea
    textArea.style.height = `${scrollHeight}px`
  }, [value])

  return textAreaRef
}
