import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Selection,
} from '@nextui-org/react'
import { ChevronDown, Plus } from 'lucide-react'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface'
import { useDocContext } from '@/contexts'
import { queryDoc, queryDocByType, queryUserDoc } from '@/db/docs/queries'
import { createDoc, CreateDocPL } from '@/db/docs/sync'
import { usePath, useSelectState } from '@/hooks'
import { useSlr } from '@/store'
import { selectAccount } from '@/store/account'
import { useLiveQuery } from 'dexie-react-hooks'
import { scm, selectDocSchema, useSchemaState } from '@/contexts/schema'
import { WORKSPACE_DOCUMENT_TYPE_NAME } from '@common/interfaces/clients/document-type.interface'

export const AddNewButton = () => {
  const { doc, roles, schema } = useDocContext()
  const workspaceSchema = useSchemaState((state) =>
    selectDocSchema(state, WORKSPACE_DOCUMENT_TYPE_NAME),
  )
  const { getDocPath } = usePath()
  const account = useSlr(selectAccount)
  const userDoc = useLiveQuery(() => queryUserDoc(account._id), [account._id])

  const options = useMemo(() => {
    const types: { label: string; value: string; caption?: string }[] = []
    const copies = new Set<string>()

    function addType(type: string) {
      const schema = scm.getDocSchema(type)
      if (schema && !copies.has(type) && !schema.singleEntry) {
        copies.add(type)
        types.push({ label: schema.niceName, value: type })
      }
    }

    if (workspaceSchema) {
      workspaceSchema.addGlobalTypes?.forEach((type) => addType(type.type))
    }

    if (!schema.singleEntry && roles.editor) {
      copies.add(schema.name)
      types.push({ label: schema.niceName, value: schema.name, caption: 'Another one' })
    }

    if (roles.contributor) {
      schema.fields
        .filter((field): field is ChildrenFieldType => field.type === 'children')
        .forEach((field) => field.allowed?.forEach(addType))
    }

    return types
  }, [schema, workspaceSchema, roles])

  const { selectedKeys, setSelectedKeys, selected, optionsMap } = useSelectState(
    options,
    options.length ? [options[0].value] : [],
    (item) => item.value,
  )

  const navigate = useNavigate()

  if (!workspaceSchema?.addGlobalTypes && (!roles.contributor || !roles.editor)) {
    return null
  }

  if (!options.length) return null

  const openNewDoc = async (newSelected?: (typeof options)[0]) => {
    const selectedItem = newSelected ?? selected
    if (!selectedItem) return
    let parentDoc = await queryDoc({ _id: doc._parentId })

    if (doc._type !== selectedItem.value) {
      parentDoc = doc
    }

    const globalType = workspaceSchema?.addGlobalTypes?.find(
      (type) => type.type === selectedItem.value,
    )

    if (globalType) {
      parentDoc = await queryDocByType(globalType.parent)
    }

    if (selectedItem.value === 'Note') {
      parentDoc = userDoc
    }

    if (!parentDoc) return

    const docSchema = scm.getDocSchema(selectedItem.value)

    const payload: CreateDocPL = {
      title: `New ${selectedItem.label}`,
      type: selectedItem.value,
      parentDoc,
      readAccess: docSchema?.readAccess,
    }

    if (docSchema?.defaultFieldValues) {
      payload.fields = docSchema.defaultFieldValues
    }

    if (docSchema?.stealth) {
      payload.stealth = true
    }

    const newDoc = await createDoc(payload)

    if (newDoc) {
      navigate({
        pathname: getDocPath(newDoc),
        search: `?edit`,
      })
    }
  }

  const handleSelect = (keys: Selection) => {
    const firstKey = Array.from(keys)[0] ?? ''
    const newSelected = optionsMap.get(firstKey)
    if (newSelected) {
      openNewDoc(newSelected)
    }
    setSelectedKeys(keys)
  }

  return (
    <ButtonGroup variant="flat" className="fixed right-6 z-50 top-20 shadow-large rounded-[1.2rem]">
      <Button
        isDisabled={!selected}
        size="sm"
        startContent={<Plus size={24} />}
        onClick={() => openNewDoc()}
        radius="full"
        className="bg-white h-10"
      >
        Add {selected?.label}
      </Button>
      <Dropdown placement="bottom-end">
        <DropdownTrigger>
          <Button radius="full" isIconOnly size="sm" className="bg-white h-10">
            <ChevronDown size={18} />
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          disallowEmptySelection
          aria-label="Merge options"
          selectedKeys={selectedKeys}
          selectionMode="single"
          onSelectionChange={handleSelect}
          className="max-w-[300px]"
        >
          {options.map((item) => (
            <DropdownItem key={item.value} description={item.caption}>
              {item.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </ButtonGroup>
  )
}
