import React, { useMemo } from 'react'
import { Skeleton } from '@/ui/Skeleton'
import { generateArray } from '@/utils/docsSort'

export const ImageSkeletons: React.FC = () => {
  const skeletons = useMemo(() => generateArray(11), [])

  return (
    <>
      {skeletons.map((key) => (
        <div key={key}>
          <Skeleton width={160} height={100} className="mb-2 rounded-none" />

          <Skeleton width={62} height={16} className="rounded-none" />
        </div>
      ))}
    </>
  )
}
