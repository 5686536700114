import Cookies from 'js-cookie'
import get from 'lodash.get'
import { Socket, io as socketIO } from 'socket.io-client'
import { singlePromise } from '@/utils/promises'
import { IOEvents } from './events'

const apiUrl = get(window, 'env.BackendUrl') || import.meta.env.VITE_API_URL

export class SocketManager {
  socket: Socket

  async getSocket() {
    if (this.socket) {
      return this.socket
    } else {
      return this.connect()
    }
  }

  private connectCb() {
    return new Promise<Socket>((resolve) => {
      try {
        if (this.socket) {
          this.socket.on('connect', () => resolve(this.socket))
          return
        }

        const token = Cookies.get('token') ?? ''
        this.socket = socketIO(apiUrl, {
          extraHeaders: { Authorization: `bearer ${token}` },
        })

        resolve(this.socket)
      } catch (error) {
        window.Rollbar.error('Failed to connect socket.io')
      }
    })
  }

  connect = (() => singlePromise('ws', () => this.connectCb())).bind(this)

  emit<R, T = any>(eventName: IOEvents, payload?: T) {
    return new Promise<R>((resolve) => {
      this.getSocket().then((socket) => {
        socket.emit(eventName, payload, (responseData: R) => {
          resolve(responseData)
        })
      })
    })
  }

  async on<T = any>(eventName: IOEvents, cb: (payload: T) => void) {
    const socket = await this.getSocket()
    socket.on(eventName, cb)
  }

  async off<T = any>(eventName: IOEvents, cb?: (payload: T) => void) {
    const socket = await this.getSocket()
    socket.off(eventName, cb)
  }
}

export const io = new SocketManager()
