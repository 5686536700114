import { useMemo } from 'react'
import { Button, ButtonProps } from '@/ui'
import { ActionType } from '@common/interfaces/documents/action.interface'
import { useDocContext } from '@/contexts'
import { usePath } from '@/hooks'
import { useAppDispatch, useSlr } from '@/store'
import { fieldsSelectors, setIsPanelOpen, updateFieldState } from '@/store/docs'
import { convertDocTo, createDoc, CreateDocPL } from '@/db/docs/sync'
import { useNavigate } from 'react-router-dom'
import { IconName } from '@/ui/Icon'

interface ActionElementProps {
  schema: ActionType & { style?: React.CSSProperties }
}

export const ActionElement = ({ schema }: ActionElementProps) => {
  const dispatch = useAppDispatch()
  const fieldEntities = useSlr(fieldsSelectors.selectEntities)
  const { doc } = useDocContext()
  const { label, icon, iconSize, style, iconColor } = schema
  const navigate = useNavigate()
  const { getDocPath } = usePath()

  const actionCb = useMemo(() => {
    switch (schema.action) {
      case 'open':
        return () => {
          if (!schema.fieldName) return

          dispatch(
            updateFieldState({
              name: schema.fieldName,
              isVisible: !fieldEntities[schema.fieldName]?.isVisible,
            }),
          )
          setTimeout(() => {
            const el = document.getElementById(`field_${schema.fieldName}`)
            el?.scrollIntoView()
          }, 50)
        }
      case 'copy':
        return async () => {
          if (!schema.toDocType || !doc) return
          const newDoc = await convertDocTo(doc, schema.toDocType)
          if (newDoc) {
            navigate({ pathname: getDocPath(newDoc), search: `?edit` })
            dispatch(setIsPanelOpen(false))
          }
        }
      case 'create':
        return async () => {
          const dSchema = schema.type

          if (dSchema) {
            const payload: CreateDocPL = {
              title: `New ${schema.label}`,
              type: dSchema,
              parentDoc: doc,
            }

            const newDoc = await createDoc(payload)

            if (newDoc) {
              navigate({
                pathname: getDocPath(newDoc),
                search: `?edit`,
              })
            }
          }
        }
    }
  }, [schema.action, fieldEntities, doc])

  const props = useMemo<ButtonProps>(() => {
    const buttonProps = {
      onClick: actionCb,
      icon: icon as IconName,
      iconSize,
      iconColor,
    }

    return buttonProps
  }, [actionCb, icon, label])

  return (
    <Button {...props} className="h-8 p-1 whitespace-normal" style={style}>
      {label ?? schema.action}
    </Button>
  )
}
