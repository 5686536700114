import { createContext, Dispatch, MouseEvent } from 'react'
import { Doc, FieldValueType } from '@/db'

export interface ModalImageState {
  type: 'image'
  image: FieldValueType | string
}

export interface ModalCopyState {
  type: 'copy'
  copyType: string
  doc: Doc
}

export interface ModalDeleteState {
  type: 'delete'
  onDelete?: (docEntry: Doc, event: MouseEvent<HTMLButtonElement>) => void
  doc: Doc
}

export type ModalType = ModalImageState | ModalCopyState | ModalDeleteState

export type ModalState<T extends ModalType> = T & {
  close: () => void
}

export interface PathContextValue {
  workspacePath: string
  getDocPath: (doc: Doc) => string
  openModalDoc: (doc: Doc, slug: string) => void
  modal: ModalType | null
  setModal: Dispatch<ModalType | null>
}

export const PathContext = createContext<PathContextValue | object>({})
