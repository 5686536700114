import { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalContent, useDisclosure } from '@nextui-org/react'
import { Button } from '@/ui'
import clsx from 'clsx'
import { io } from '@/api'
import { db, ImageFieldData } from '@/db'
import { HtmlParser } from '@/features/rte'
import { showUntitledText } from '@/utils/parseString'
import css from '../card.module.css'
import { CardProps } from '../cardsTypes'

export const AttachmentCard = ({ doc, children, headerElements }: CardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [url, setUrl] = useState('')
  const value = doc.fields?.pdfAttachment as ImageFieldData

  useEffect(() => {
    if (value?.fileName) {
      const payload = {
        _clientId: db.activeClient._id,
        _documentId: doc._id,
        fileName: value.fileName,
        hours: 1,
      }

      io.emit<string>('getSignedUrl', payload)
        .then((res) => {
          setUrl(res)
        })
        .catch((error) => {
          window.Rollbar.error(error as Error)
        })
    }
  }, [])

  return (
    <div className="w-full h-full border border-neutral300 rounded-lg flex flex-col">
      <div className="relative px-3.5 pt-11 pb-2.5">
        <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
        <h4 title={doc.title} className={clsx('text-neutral800 leading-5', css.clampThreeRows)}>
          {showUntitledText(doc.title)}
        </h4>
        <div className={clsx('text-regular pt-2.5 text-neutral800 leading-5 break-words')}>
          <HtmlParser className="leading-[19px]">{doc.fields.description}</HtmlParser>
        </div>

        {url && (
          <>
            <div className="flex justify-end">
              <Button
                icon="expand"
                aria-label="Expand"
                className="mb-3"
                onClick={(event) => {
                  event.preventDefault()
                  onOpen()
                }}
              />
            </div>

            <div>
              <iframe src={url} width="100%" height="600px" loading="lazy" title="PDF-file" />
            </div>

            <Modal size="full" radius="none" isOpen={isOpen} onClose={onClose}>
              <ModalContent>
                <ModalBody className="px-0 py-0">
                  <iframe src={url} width="100%" height="100%" loading="lazy" title="PDF-file" />
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
        )}
      </div>
      {children}
    </div>
  )
}
