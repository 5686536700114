import { useLayoutEffect, useState } from 'react'

interface IRectShape {
  width: number
  height: number
}

const getViewportWidth = () =>
  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
const getViewportHeight = () =>
  Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

export function useResize() {
  const [size, setSize] = useState<IRectShape>({
    width: getViewportWidth(),
    height: getViewportHeight(),
  })

  useLayoutEffect(() => {
    const resize = () => {
      setSize({
        width: getViewportWidth(),
        height: getViewportHeight(),
      })
    }
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  return size
}
