import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from '@/ui'
import { useLiveQuery } from 'dexie-react-hooks'
import { JSONEditorOptions } from 'jsoneditor'
import { db } from '@/db'
import { queryAllDocs } from '@/db/docs/queries'
import { querySchema } from '@/db/workspaces/queries'
import { updateSchema } from '@/db/workspaces/sync'
import { useDidUpdateEffect } from '@/hooks'
import { useAppDispatch, useSlr } from '@/store'
import { updateWorkspaceVars } from '@/store/account'
import { selectInitStatus } from '@/store/account/selectors'
import { JsonEditor, Toggle } from '@/ui'
import { getDocsWithAIContexts } from '@/utils'
import { arrToObject } from '@/utils/docsSort'
import { reload } from '@/utils/localStorage'

export const SchemaSettings = () => {
  const init = useSlr(selectInitStatus)
  const initialSchema = useLiveQuery(async () => querySchema(), []) ?? []
  const dispatch = useAppDispatch()

  const normalizedSchema = useMemo(
    () =>
      arrToObject(initialSchema, 'name', (item) => ({
        ...item,
        fields: arrToObject(item.fields, 'name'),
      })),
    [initialSchema],
  )

  useDidUpdateEffect(() => {
    if (normalizedSchema) {
      setSchema(normalizedSchema)
    }
  }, [normalizedSchema])

  const [schema, setSchema] = useState<typeof normalizedSchema>(normalizedSchema)

  const [enableAI, setEnableAI] = useState(db.flags.enableAI ?? false)

  const onSave = async () => {
    const newSchema = Object.values(schema).map((docType) => ({
      ...docType,
      fields: Object.values(docType.fields),
    }))

    const toastId = toast.loading('Schema validation')
    try {
      await updateSchema(newSchema)
      toast.update(toastId, {
        render: 'Schema updated',
        type: 'success',
        isLoading: false,
        closeButton: true,
        autoClose: 2000,
      })
      reload()
    } catch (e: any) {
      toast.update(toastId, {
        render: e.message,
        type: 'error',
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
      })
    }
  }

  const updateObjectsAIContext = async () => {
    const docs = await queryAllDocs()
    if (docs) {
      const newDocsWithContext = await getDocsWithAIContexts(docs)
      await db.ws.docs.bulkPut(newDocsWithContext)
    }
  }

  const enableAIHandler = useCallback(
    (checked: boolean) => {
      setEnableAI(checked)
      dispatch(updateWorkspaceVars({ enableAI: checked }))
    },
    [enableAI],
  )

  const schemaOptions = useMemo(
    () => ({ mode: init === 'fulfilled' ? 'tree' : 'view' }) as JSONEditorOptions,
    [init],
  )

  return (
    <div>
      <div className="pb-5">
        <Toggle label="Enable AI" on={enableAI} onChange={enableAIHandler} />
      </div>

      {normalizedSchema && (
        <JsonEditor options={schemaOptions} value={normalizedSchema} onChange={setSchema} />
      )}
      <div className="mt-7 flex gap-2">
        <Button
          className="max-[400px]:w-full"
          onClick={onSave}
          disabled={normalizedSchema === schema}
        >
          Update
        </Button>
        <Button
          variant="outline"
          className="max-[400px]:w-full max-[400px]:ml-0"
          onClick={updateObjectsAIContext}
        >
          Update all objects aiContext
        </Button>
        <Button
          variant="outline"
          color="default"
          className="max-[400px]:w-full"
          onClick={window.clearSession}
        >
          Reload data
        </Button>
      </div>
    </div>
  )
}
