import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import Editor from '@monaco-editor/react'
import { FieldTemplateProperty } from '@/features/schemaEditor/types'

type FieldCodeEditorProps = {
  propertyName: string
  templateProperty: FieldTemplateProperty
  nestedStructure: string
}

export const FieldCodeEditor: FC<FieldCodeEditorProps> = ({ propertyName, nestedStructure }) => {
  const { setValue, getValues } = useFormContext()

  const value = getValues(nestedStructure)

  const editorChangeHandler = (value) => {
    setValue(nestedStructure, value)
  }

  return (
    <div className="form-control w-full">
      <label className="cursor-pointer label justify-start flex-col items-start p-0">
        <span className="label-text capitalize font-bold px-1 py-2">{propertyName}</span>
      </label>

      <Editor
        width="100%"
        height="100px"
        defaultLanguage="javascript"
        theme="vs-dark"
        options={{ minimap: { enabled: false } }}
        value={value}
        onChange={editorChangeHandler}
      />
    </div>
  )
}
