import { io } from './socket'

type Role = 'system' | 'user' | 'assistant'

export interface AIChatMessage {
  role: Role
  content: string
}

export interface AIChatPayload {
  messages: AIChatMessage[]
}

export interface AITextEdit {
  input: string
  instruction: string
}

// EXAMPLE USAGE: const aiRes = await getChatGptResponse({ messages: [{role: 'system', content: 'I am a indian citizen'}, {role: 'user', content: 'population of india'}] });
export async function getChatGptResponse(
  request: string,
  context: string,
  history = '',
  role: Role = 'user',
): Promise<string> {
  const res = await io.emit<any, AIChatPayload>('getChatGptResponse', {
    messages: [
      {
        role: 'system',
        content:
          'You are an AI helping me write a document. Do not add any pretext or posttext to your response',
      },
      { role: 'system', content: context },
      { role: 'assistant', content: history },
      { role, content: request },
    ],
  })
  return res.choices.map((choice: any) => choice.message.content)[0]
}

// EXAMPLE USAGE: const aiRes = await getTextEditGptResponse({"input": "What day of the wek is it?", "instruction": "Fix the spelling mistakes"});
export async function getTextEditGptResponse(req: AITextEdit): Promise<string[]> {
  const res = await io.emit<any>('getTextEditGptResponse', req)
  return res.choices.map((choice: any) => choice.text)
}

export async function getOpenAIEmbeddingApi(workspaceId: string, documentId: string, text: string) {
  const res = await io.emit<boolean>('getOpenaiEmbedding', {
    workspaceId,
    documentId,
    text,
  })
  if (!res) throw new Error('Failed to get embedding')

  return res
}

export async function getOpenAIRecommendationApi(
  workspaceId: string,
  documentId: string,
  count = 5,
) {
  const res = await io.emit<string[]>('getOpenaiRecommendation', {
    workspaceId,
    documentId,
    count,
  })
  if (!res) throw new Error('Failed to get recommendation')

  return res
}
