import clsx from 'clsx'
import { UserSettings } from '../components/UserSettings'
import { SchemaLayout } from './SchemaLayout'

export const User = () => (
  <main className={clsx('pt-8 max-lg:px-5')}>
    <SchemaLayout className="grid gap-8 max-sm:gap-7" />
    <UserSettings />
  </main>
)
