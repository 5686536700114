import { useState } from 'react'
import { Input } from '@nextui-org/react'
import { Button } from '@/ui'
import { getCachedDocument, getDocument } from '@/api/docs'

export const FetchDocument = () => {
  const [value, setValue] = useState('')

  const fetchDocument = async () => {
    getDocument(value)
  }

  const fetchCache = async () => {
    getCachedDocument(value)
  }

  return (
    <div className="flex gap-2">
      <Input
        size="sm"
        type="text"
        className="input input-bordered w-1/2"
        onChange={(e) => setValue(e.target.value)}
      />
      <Button onClick={fetchDocument}>Fetch the doc</Button>
      <Button onClick={fetchCache}>Fetch the cache</Button>
    </div>
  )
}
