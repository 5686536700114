import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { rootReducer as reducer } from './rootReducer'

export type RootState = ReturnType<typeof reducer>
export type AsyncDispatch = ThunkDispatch<RootState, any, Action>
export const useAppDispatch = () => useDispatch<AsyncDispatch>()
export const useSlr: TypedUseSelectorHook<RootState> = useSelector

const persistConfig = {
  key: 'store',
  storage,
  whitelist: ['session', 'docs'],
}

const persistedReducer = persistReducer(persistConfig, reducer)

export function createStore() {
  const store = configureStore({
    reducer: persistedReducer,
    devTools: {
      trace: true,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: ['persist/REGISTER', 'persist/PERSIST', 'persist/REHYDRATE'],
        },
      }),
  })

  const persistor = persistStore(store)

  return { store, persistor }
}

export const { store, persistor } = createStore()
