import React, { useEffect, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { fetchUsers } from '@/db/users/sync'
import { ImportDataSettings } from '@/features/importer'
import { PermissionsSettings } from '@/features/permissions'
import { usePath } from '@/hooks'
import { AuditLogs } from '@/routes/WorkspaceSetttings/AuditLogs.tsx'
import { GeneralSettings } from '@/routes/WorkspaceSetttings/GeneralSettings'
import { useSlr } from '@/store'
import { selectAccount } from '@/store/account'
import { Tabs } from '@/ui'
import { Users } from '@/ui/Users'
import { isAdmin } from '@/utils/database'
import { AdvancedSettings } from './AdvancedSettings'
import { ExportDataSettings } from './ExportDataSettings'
import { SchemaSettings } from './SchemaSettings'
import { ActivityReport } from './ActivityReport.tsx'

export const WorkspaceSettings: React.FC = () => {
  const { workspacePath } = usePath()
  const account = useSlr(selectAccount)

  const [searchParams, setSearchParams] = useSearchParams()

  const tabs = [
    { value: 'general', label: 'General' },
    { value: 'users', label: 'Users' },
    { value: 'schema', label: 'Schema' },
    { value: 'import', label: 'Import' },
    { value: 'export', label: 'Export' },
    { value: 'permissions', label: 'Permissions' },
    { value: 'logs', label: 'Logs' },
    { value: 'advanced', label: 'Advanced' },
    { value: 'activity-report', label: 'Activity Report' },
  ]

  const [activeTab, setActiveTab] = useState(searchParams.get('tab') ?? tabs[0].value)

  useEffect(() => {
    fetchUsers()
  }, [])

  if (!isAdmin(account)) {
    return <Navigate replace to={workspacePath} />
  }

  const tabChange = (id: string) => {
    setSearchParams({ tab: id })
    setActiveTab(id)
  }

  function tabContent() {
    switch (activeTab) {
      case 'general':
        return <GeneralSettings />
      case 'users':
        return <Users />
      case 'schema':
        return <SchemaSettings />
      case 'import':
        return <ImportDataSettings />
      case 'export':
        return <ExportDataSettings />
      case 'permissions':
        return <PermissionsSettings />
      case 'logs':
        return <AuditLogs />
      case 'advanced':
        return <AdvancedSettings />
      case 'activity-report':
        return <ActivityReport />
      default:
        return null
    }
  }

  return (
    <div className="h-screen overflow-y-scroll">
      <main className="nodoc-page max-lg:px-10 max-sm:px-5 max-sm:py-14">
        <h1 className="text-title mb-8 max-sm:mb-5 max-sm:text-heading">Workspace Settings</h1>
        <div className="pb-8 max-sm:pb-5">
          <Tabs<string> type="button" tabs={tabs} activeTabId={activeTab} onChange={tabChange} />
        </div>
        {tabContent()}
      </main>
    </div>
  )
}
