import { memo } from 'react'
import { Avatar } from '@nextui-org/react'
import { useDebounce, useDidUpdateEffect } from '@/hooks'
import type { Collaborator } from '@/store/docs'
import { Tooltip } from '@/ui'

interface CollaboratorItemProps {
  collaborator: Collaborator
  removeCollaborator: (email: string) => void
}

export const CollaboratorItem = memo(
  ({ collaborator, removeCollaborator }: CollaboratorItemProps) => {
    const { email, name } = collaborator
    const lastTime = useDebounce(collaborator.lastTime, 5000)

    useDidUpdateEffect(() => {
      removeCollaborator(email)
    }, [lastTime])

    return (
      <Tooltip
        content={
          <>
            <p>{name}</p>
            <span className="text-neutral500">{email}</span>
          </>
        }
      >
        <Avatar className="w-6 h-6 uppercase" name={name?.[0]} />
      </Tooltip>
    )
  },
)

CollaboratorItem.displayName = 'CollaboratorItem'
