import { useEffect, useRef, useState } from 'react'
import Masonry from 'react-layout-masonry'
import { Input } from '@nextui-org/react'
import { searchUnsplashImages, UnsplashEntity } from '@/api/files'
import { usePath } from '@/hooks'
import { Button, Icon } from '@/ui'
import { ImageSkeletons } from './ImageSkeletons'

type UnsplashProps = {
  onSelect: (image: UnsplashEntity) => Promise<void>
}

export const Unsplash = ({ onSelect }: UnsplashProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [search, setSarch] = useState('')
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState<UnsplashEntity[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const loaded = useRef(false)
  const { setModal } = usePath()

  const searchImages = async (newPage = 1) => {
    setLoading(true)
    const res = await searchUnsplashImages(search, { page: newPage })
    setImages((prevImages) => prevImages.concat(res.data.results))
    setHasMore(res.data.total_pages > page)
    setPage(newPage)
    setLoading(false)
    loaded.current = false
  }

  useEffect(() => {
    const content = ref.current

    if (!content) return () => undefined

    const handleScroll = () => {
      if (loading || !hasMore) return

      const { scrollHeight, scrollTop, clientHeight } = content

      if (!loaded.current && scrollHeight - scrollTop - clientHeight < 50) {
        loaded.current = true
        searchImages(page + 1)
      }
    }

    content.addEventListener('scroll', handleScroll)

    return () => content.removeEventListener('scroll', handleScroll)
  }, [loading, page, hasMore])

  return (
    <>
      <div className="flex items-center gap-3">
        <Input
          type="text"
          className="py-2"
          size="sm"
          value={search}
          placeholder="Search for a photo"
          onChange={(e) => setSarch(e.target.value)}
        />
        <Button
          variant="outline"
          onClick={() => searchImages()}
          disabled={!search}
          loading={loading}
        >
          Search
        </Button>
      </div>
      <div className="overflow-y-auto max-h-96" ref={ref}>
        <Masonry columns={3} gap={12}>
          {images?.map((image) => (
            <div key={image.id} className="relative">
              <figure>
                <img
                  className="w-full h-full object-cover rounded mb-1"
                  src={image.urls.small}
                  alt={image.alt_description}
                />
                <figcaption>
                  {image.user.first_name} {image.user.last_name}
                </figcaption>
              </figure>
              <div className="absolute top-2 right-2 flex items-center gap-2">
                <Button
                  icon="expand"
                  variant="outline"
                  onClick={() =>
                    setModal({
                      type: 'image',
                      image: image.urls.regular,
                    })
                  }
                />
                <Button icon="circle-check" variant="outline" onClick={() => onSelect(image)} />
              </div>
            </div>
          ))}
        </Masonry>
        {loading && (
          <div className="grid grid-cols-3 gap-6 max-sm:grid-cols-2 max-[360px]:grid-cols-1">
            <ImageSkeletons />
          </div>
        )}
        {!images.length && !loading && (
          <div className="w-full h-full flex items-center justify-center flex-col pt-4">
            <Icon className="text-neutral500 mb-4" name="images" size={40} />
            <p className="text-neutral500 text-sm m-0 pb-1.5">Please update your search.</p>
          </div>
        )}
      </div>
    </>
  )
}
