import { useFieldContext } from '@/contexts'
import { DataListParams } from '@/db'
import { HtmlParser } from '@/features/rte'
import { Preview } from '@/ui'
import { showUntitledText } from '@/utils/parseString'
import { CardProps } from '../cardsTypes'

export const KeyFactCard = ({ doc, children, onDelete, headerElements }: CardProps) => {
  const { field } = useFieldContext<DataListParams>()
  if (!doc || !onDelete) return null

  const imageFit = field.layout?.card?.imageFit
  const hideTitle = field.layout?.card?.hideTitle
  const styles = field?.layout?.card?.styles

  return (
    <>
      <div
        style={styles}
        className="relative w-full rounded-lg bg-white flex flex-col  border-b-neutral400"
      >
        <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
        <div className="relative h-min">
          <Preview
            fit={imageFit ? 'contain' : 'cover'}
            className="w-full h-min rounded-lg"
            image={doc.fields.image}
            iconSize={16}
          />
          {!hideTitle && (
            <div className="px-4 pt-4 pb-1">
              <h1 title={doc.title} className="mb-3 text-[30px] font-bold leading-[32px]">
                {showUntitledText(doc.title)}
              </h1>
            </div>
          )}
        </div>
        {doc.fields.description && (
          <div className="px-4 pt-4 pb-2.5">
            <HtmlParser className="text-regular text-neutral900 [&>ul]:list-disc break-words">
              {doc.fields.description}
            </HtmlParser>
          </div>
        )}
        {children}
      </div>
    </>
  )
}
