import { KeyValueData } from '@common/interfaces/fields/keyvalue-field.interface'
import { Doc } from '@/db'
import { useImage } from '@/features/files'
import { hexToRgb } from '@/utils/colors'

export const useBackground = (doc: Doc) => {
  const style: {
    backgroundColor?: string
    opacity?: number
    backgroundImage?: string
    backgroundRepeat?: string
    backgroundSize?: string
  } = {}

  const backgroundSettings = doc.fields.backgroundSettings as KeyValueData

  let color: string | undefined
  let opacity: number | undefined
  let backgroundImage: string | undefined
  const img = useImage(backgroundSettings?.image)
  if (backgroundSettings) {
    color = backgroundSettings.color as string
    opacity = backgroundSettings.opacity as number
    if (!opacity) {
      opacity = 0.5
    }
    if (!color) {
      color = '#fff'
    }
    const rgb = hexToRgb(color) ?? { r: 255, g: 255, b: 255 }
    backgroundImage = `linear-gradient(rgba(${rgb.r},${rgb.g},${rgb.b},${opacity}), rgba(${rgb.r},${rgb.g},${rgb.b},${opacity}))`
    if (img?.url) {
      backgroundImage += `, url("${img.url}")`
    }
  }
  if (backgroundImage) {
    style.backgroundImage = backgroundImage
    style.backgroundRepeat = 'no-repeat'
    style.backgroundSize = 'cover'
  }

  return style
}
