import clsx from 'clsx'
import { AreaFieldType } from '@common/interfaces/fields/area-fields.interace'
import { useFieldContext } from '@/contexts'
import { Icon } from '@/ui'
import { IconName } from '@/ui/Icon'
import { AreaItem } from './AreaItem'
import { hexToRgb } from '@/utils/colors'
import { selectDocSchema, useSchemaState } from '@/contexts/schema'
import { WORKSPACE_DOCUMENT_TYPE_NAME } from '@common/interfaces/clients/document-type.interface'

export const BlocksView = () => {
  const { field, isEditable } = useFieldContext<AreaFieldType>()
  const workspaceSchema = useSchemaState((state) =>
    selectDocSchema(state, WORKSPACE_DOCUMENT_TYPE_NAME),
  )
  const workspaceColor = workspaceSchema?.helpPanelColor || '#EFEFEF'
  const styles = field.styles ?? {}
  const { icon, iconColor, iconSize = 16 } = field.layout ?? {}
  const shouldShowHelpPanel = isEditable && field.helpPanel && field.showHelpPanel

  const rgb = hexToRgb(workspaceColor as string) ?? { r: 255, g: 255, b: 255 }

  const helpPanelElement = () => (
    <div
      className="flex mb-2 p-2 rounded-lg"
      style={{ background: `rgba(${rgb.r},${rgb.g},${rgb.b},${0.8})` as string }}
    >
      {field.helpPanelIcon && (
        <div className="p-1 bg-white rounded-md h-fit w-fit">
          <Icon size={16} name={field.helpPanelIcon as IconName} />
        </div>
      )}
      <p className="pl-2 pt-1">{field.helpPanel}</p>
    </div>
  )

  return (
    <div
      className={clsx('rounded-lg', field.sticky && 'sticky top-12 z-20', {
        'p-3': !field.withoutPadding,
      })}
      style={styles}
      id={`field_${field.name}`}
    >
      {!field.hideName && (
        <div className="flex gap-2 mb-2 items-center">
          {icon && <Icon name={icon as IconName} color={iconColor} size={iconSize} />}
          <div className="grow">
            <h2 className="text-subtitle" style={field.helpPanelStyles}>
              {field.niceName}
            </h2>
          </div>
        </div>
      )}
      {shouldShowHelpPanel && helpPanelElement()}
      <AreaItem
        value={field.name}
        cols={field.cols}
        areas={field.areas}
        styles={field.secondaryStyles}
      />
    </div>
  )
}
