import { io } from '@/api'
import { getUserInfoApi } from '@/api/users'
import { BaseWorkspace, fetchClientsApi, fetchWorkspacesApi } from '@/api/workspaces'
import { Client, db, User } from '@/db'
import { store } from '@/store'
import { setAccount, setInit } from '@/store/account/slice'
import { closeSession, switchWorkspace } from '@/store/session/slice'
import { LS } from '@/utils'
import { fetchDocs, fetchUpdatedDocs, listenDocs } from '../docs/sync'
import { listenInteractions } from '../interactions/sync'
import { fetchPermissionGroups, listenPermissions } from '../permissions/sync'
import { listenRelations } from '../relations/sync'
import { listenUsers } from '../users/sync'
import { listenWorkspaces } from '../workspaces/sync'
import { useSchemaState } from '@/contexts/schema'

export async function initAppState() {
  const expiredSessionTimer = setTimeout(() => {
    window.Rollbar.error('Session expired')
    closeSession()
  }, 1000 * 60)
  await fetchAccountData()
  const permissions = fetchPermissionGroups()
  await fetchDocs(permissions)
  setDisconnectTime()
  clearTimeout(expiredSessionTimer)
}

async function fetchAccountData() {
  try {
    const { clientIds, workspaceIds: baseWorkspaces, user } = await getUserInfoApi()
    const [allClients, workspaces] = await Promise.all([
      fetchClientsApi(clientIds),
      fetchWorkspacesApi(baseWorkspaces),
    ])

    if (!user) {
      throw Error("The account doesn't exist")
    }
    const clients = allClients.filter(Boolean)
    const { clientId } = store.getState().session
    const client = clients.find((cl: Client) => cl._id === clientId) ?? clients[0]
    const workspace = workspaces.find((ws) => ws._partId === client._id)

    if (!workspace || !client) {
      throw Error("Can't find client or workpace of the current account")
    }

    if (window.gtag) {
      window.gtag('event', 'signIn', {
        username: user.name,
        status: 'success',
      })
    }

    const baseWs = baseWorkspaces.find((ws: any) => ws._id === workspace._id)
    if (!baseWs) throw Error("Can't find the workspace")

    baseWs._permissionGroups.push('GROUP#EVERYONE')

    const account: User = {
      ...user,
      _isVerified: true,
      _relationType: baseWs._relationType,
      _allowedOperation: baseWs._allowedOperation,
      permissionGroups: baseWs._permissionGroups,
    }

    const wsDb = db.setWorkspaceDb(workspace, client, account)
    if (!wsDb) throw Error("Can't create workspace db")

    const { schema } = workspace
    if (schema) {
      useSchemaState.getState().setSchema(schema)

      await db.ws.transaction('rw', db.ws.users, async () => {
        await db.ws.users.put(account)
      })
    }

    await db.transaction('rw', db.workspaces, db.clients, () => {
      db.workspaces.bulkPut(workspaces)
      db.clients.bulkPut(clients)
    })

    const workspaceIds = baseWorkspaces.map((w: BaseWorkspace) => w._id)

    const simpleWorkspace = { ...workspace, schema: [] }

    store.dispatch(switchWorkspace(simpleWorkspace))
    store.dispatch(
      setAccount({
        data: account,
        init: 'preloaded',
        clientIds,
        workspaceIds,
        client,
        workspace: simpleWorkspace,
      }),
    )
    await listenAccountConnection()
  } catch (error) {
    window.Rollbar.error(error as Error)
    window.Rollbar.error('Error fetching account data', { error })
    store.dispatch(closeSession())
  }
}

let disconnectTimeInterval: NodeJS.Timeout

function setDisconnectTime() {
  if (disconnectTimeInterval) {
    clearInterval(disconnectTimeInterval)
  }

  LS.set('disconnectTime', new Date().toISOString())
  disconnectTimeInterval = setInterval(async () => {
    LS.set('disconnectTime', new Date().toISOString())
  }, 5000 * 60)

  store.dispatch(setInit('fulfilled'))
}

export function saveUrlCache(name: string, url: string) {
  return db.ws.transaction('rw', db.ws.urls, async () => {
    await db.ws.urls.put({ name, url })
  })
}

async function listenAccountConnection() {
  const socket = await io.getSocket()
  socket.removeAllListeners()
  let lostConnect = false

  socket.on('disconnect', () => {
    clearInterval(disconnectTimeInterval)
    lostConnect = true
  })

  socket.on('connect', async () => {
    if (lostConnect) {
      store.dispatch(setInit('fulfilled'))
      await fetchUpdatedDocs()
      setDisconnectTime()
      lostConnect = false
    }
  })

  listenDocs()
  listenRelations()
  listenWorkspaces()
  listenInteractions()
  listenPermissions()
  listenUsers()
}
