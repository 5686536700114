import { queryDocsByType } from '@/db/docs/queries.ts'
import { queryFromDocRelations } from '@/db/relations/queries.ts'
import { createOneRelation } from '@/db/relations/sync.ts'
import { Button } from '@/ui'
import { sequentially } from '@/utils'
import { useState } from 'react'

const FIELD_NAMES = ['metric', 'Period', 'State']
const DOC_TYPES = {
  metric: 'Metric',
  Period: 'QTR',
  State: 'Country',
}

export const FixMetricsDataRelation = () => {
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    const docsByType = await queryDocsByType('MetricData')

    if (!docsByType?.length) return

    setLoading(true)

    await sequentially(docsByType, async (doc) =>
      Promise.all(
        FIELD_NAMES.map(async (f) => {
          const docFieldName = doc.fields?.[f] as string[]

          if (!docFieldName) {
            return Promise.resolve()
          }

          if (!docFieldName.length) {
            return Promise.resolve()
          }

          const relations = await queryFromDocRelations(doc._id, f)

          if (!relations?.length) {
            return createOneRelation({
              fromId: doc._id,
              toId: docFieldName[0],
              relationType: 'singlelink',
              fieldName: f,
              reverseFieldName: 'metricData',
              fromDocType: doc._type,
              toDocType: DOC_TYPES[f],
            })
          }

          return Promise.resolve()
        }),
      ),
    )

    setLoading(false)
  }

  return (
    <Button
      variant="outline"
      className="max-[400px]:w-full mt-3"
      loading={loading}
      onClick={onSubmit}
    >
      Fix Metrics Data Relations
    </Button>
  )
}
