import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { HtmlFieldType } from '@common/interfaces/fields/html-field.interface'
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { useFieldContext } from '@/contexts'
import debounce from 'lodash.debounce'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { $insertNodes } from 'lexical'

type FieldChangePluginProps = {
  isInitializeState?: boolean
}

export const FieldChangePlugin = ({ isInitializeState }: FieldChangePluginProps) => {
  const [editor] = useLexicalComposerContext()
  const { value, saveValue } = useFieldContext<HtmlFieldType, string>()
  const isSent = useRef(true)

  const getNewValue = () =>
    new Promise<string>((resolve) => {
      editor.update(() => {
        const newValue = $generateHtmlFromNodes(editor, null)
        resolve(newValue)
      })
    })

  const saveHtml = useCallback(() => {
    if (!isSent.current) {
      isSent.current = true
      getNewValue().then(saveValue)
    }
  }, [])

  const saveHtmlDebounced = useMemo(() => debounce(saveHtml, 1000), [])

  const onChange = useCallback(() => {
    isSent.current = false
    saveHtmlDebounced()
  }, [])

  useEffect(() => {
    if (!isInitializeState || !value) return

    editor.update(() => {
      const parser = new DOMParser()
      const dom = parser.parseFromString(value, 'text/html')
      const nodes = $generateNodesFromDOM(editor, dom)
      $insertNodes(nodes)
    })

    return () => {
      saveHtmlDebounced.cancel()
      saveHtml()
    }
  }, [])

  return <OnChangePlugin onChange={onChange} ignoreSelectionChange ignoreHistoryMergeTagChange />
}
