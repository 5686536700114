import { Collection } from 'dexie'
import { QueryEntry } from '@common/interfaces/fields/query-field.interface'
import { asyncFilter } from '@/utils/docsSort'
import { createEvaluator } from '@/utils/evaluator'
import { db } from '../db'
import { Doc, User } from '../dbTypes'
import { getRelationIdsByFieldName } from './queries'
import { compareAsc, sub } from 'date-fns'
import { scm } from '@/contexts/schema'

class QueryManager {
  doc: Doc
  user: User
  query: QueryEntry

  async queryData(doc: Doc, user: User, query?: QueryEntry) {
    if (!query) return undefined

    this.doc = doc
    this.user = user
    this.query = query

    const { types, scope } = this.query
    try {
      if (types === '*') {
        return await this.queryAllData()
      }

      if (scope === 'global') {
        return await this.queryGlobalData(types)
      }

      if (scope === 'children') {
        return await this.queryChildrenData()
      }

      return await this.queryContextData()
    } catch (e) {
      window.Rollbar.error(e as Error)
      return undefined
    }
  }

  async queryAllData() {
    const { scope } = this.query

    const collection =
      scope === 'global'
        ? db.ws.docs.toCollection()
        : db.ws.docs.where('_ancestors').equals(this.doc._id)

    return this.getResult(collection)
  }

  async queryGlobalData(types: string[]) {
    const { relationFilter } = this.query
    const { scope, fieldName, type, docTypes = [], scopeLevel } = relationFilter ?? {}
    if (type === 'reverselink' && fieldName) {
      const rels = db.ws.relations
        .where('[fromDocType+reverseFieldName]')
        .anyOf(types.map((t) => [t, fieldName]))

      const fromIdSet = new Set<string>()
      const descendantSet = new Set<string>()

      if (scope === 'context') {
        const contextId = scopeLevel ? this.doc._ancestors[scopeLevel] : this.doc._id
        await db.ws.docs
          .where('_type')
          .anyOf(docTypes)
          .and((doc) => doc._ancestors.includes(contextId))
          .each((doc) => descendantSet.add(doc._id))

        rels.and((rel) => descendantSet.has(rel.toId))
      }

      await rels.each((rel) => {
        fromIdSet.add(rel.fromId)
      })

      const docs = db.ws.docs.where('_id').anyOf(Array.from(fromIdSet))
      return this.getResult(docs)
    }

    const docs = db.ws.docs.where('_type').anyOf(this.query.types)

    return this.getResult(docs)
  }

  async queryContextData() {
    const { types, relationFilter, scopeLevel, scopeType = 'global' } = this.query
    const typeSet = new Set(types)
    let contextId = this.doc._id

    if (scopeLevel) {
      if (scopeType === 'local') {
        const ancestors = [...this.doc._ancestors]
        ancestors.reverse()
        contextId = ancestors[scopeLevel - 1]
      } else {
        this.doc._ancestors[scopeLevel]
      }
    }

    const isLink = relationFilter?.type === 'multilink' || relationFilter?.type === 'singlelink'
    const isReverse = relationFilter?.type === 'reverselink'

    let rels
    if (isLink) {
      const toIds = await getRelationIdsByFieldName(
        relationFilter?.fieldName,
        this.doc._id,
        'fromId',
      )
      rels = this.getDocsWithTypes(toIds, typeSet)
    } else if (isReverse) {
      const fromIds = await getRelationIdsByFieldName(
        relationFilter?.fieldName,
        this.doc._id,
        'toId',
      )
      rels = this.getDocsWithTypes(fromIds, typeSet)
    } else {
      rels = db.ws.docs
        .where('_ancestors')
        .equals(contextId)
        .and((item) => typeSet.has(item._type))
    }

    return this.getResult(rels)
  }

  async queryChildrenData() {
    const { types } = this.query
    const typeSet = new Set(types)
    const children = db.ws.docs
      .where('_parentId')
      .equals(this.doc._id)
      .and((item) => typeSet.has(item._type))

    return this.getResult(children)
  }

  async getResult(coll: Collection<Doc>) {
    const {
      fieldFilter,
      limit,
      resultType,
      gt = 0,
      lt,
      amount = 10,
      fieldName = 'likes',
    } = this.query
    let data = await coll.toArray()

    if (fieldFilter) {
      data = await asyncFilter(data, async (item) => {
        const evaluator = await createEvaluator(item, this.user, {
          contextDoc: this.doc,
          asyncScope: scm.getDocSchema(item._type)?.expScope,
        })
        return evaluator(fieldFilter)
      })
    }

    if (limit) {
      data = data.slice(0, limit)
    }

    if (resultType === 'count') {
      return data.length
    }

    if (resultType === 'boolean') {
      const count = data.length

      return lt ? count < lt : count > gt
    }

    if (resultType === 'top') {
      if (data.length === 0) {
        const entries = await coll.toArray()

        data = entries.slice(0, amount)
      }

      if (data.length > amount) {
        data = data
          .sort((a, b) => (b.fields?.[fieldName] as number) - (a.fields?.[fieldName] as number))
          .slice(0, amount)
      }
    }

    if (resultType === 'activity' && data.length) {
      const date = sub(new Date(), {
        days: 30,
      })

      const entries = data.reduce((arr: Array<{ id: string; amount: number }>, entry) => {
        const activities = entry.fields.ACTIVITY as any[]

        const activitiesAmountByDoc = activities.reduce((sum, activity) => {
          if (compareAsc(activity.timestamp, date)) {
            Object.values(activity).forEach((v) => {
              if (typeof v !== 'string') {
                sum += v
              }
            })
          }

          return sum
        }, 0)

        if (activitiesAmountByDoc !== 0) {
          arr.push({ id: entry._id, amount: activitiesAmountByDoc })
        }

        return arr
      }, [])

      const sortedActivities = entries.sort((a, b) => b.amount - a.amount).slice(0, amount)

      const docsIdsWithMostActivities = sortedActivities.map((entry) => entry.id)

      data = data.filter((entry) => docsIdsWithMostActivities.includes(entry._id))
    }

    return data
  }

  getDocsWithTypes(ids: string[], typeSet: Set<string>) {
    return db.ws.docs
      .where('_ancestors')
      .anyOf(ids)
      .or('_id')
      .anyOf(ids)
      .and((item) => typeSet.has(item._type))
  }
}

export const queryManager = new QueryManager()
