import { DashboardFieldType } from '@common/interfaces/fields/dashboard-field.interface'
import { useFieldContext } from '@/contexts'
import { FieldContainer } from '@/schema'
import { FieldComponent } from '../fieldType'
import { Metric } from './components/Metric'

export const DashboardField: FieldComponent = () => {
  const { field } = useFieldContext<DashboardFieldType>()
  const { metrics } = field

  return (
    <FieldContainer className="grid grid-cols-4 gap-x-6 max-md:grid-cols-2 max-md:gap-4 max-sm:grid-cols-1">
      {metrics.map((metric, i) => (
        <Metric key={i} metric={metric} />
      ))}
    </FieldContainer>
  )
}
