import { toast } from 'react-toastify'
import { saveFieldApi, updateAncestorsApi } from '@/api/docs'
import { createRelationApi, deleteRelationApi } from '@/api/relations'
import { createTelemetryApi } from '@/api/workspaces'
import { store } from '@/store'
import { updateStatus } from '@/store/docs'
import { keyHash, sequentiallyChunked } from '@/utils'
import { getDocAIContext } from '@/utils/docAIContext'
import { db } from '../../db'
import { Doc, FieldValue, Relation, SomeField } from '../../dbTypes'
import { queryRelationBetweenDocs, queryRelationWithParent } from '../../relations/queries'
import { queryAncestors, queryDoc, queryDocChildren, queryHome } from '../queries'
import { getArrDiff } from '@/utils/docsSort'
import { scm } from '@/contexts/schema'
import { parallel, sleep } from '@/utils/promises'

export async function saveFieldLocal(doc: Doc, field: FieldValue) {
  const aiContext = await getDocAIContext(doc)
  const changes: Partial<Doc> = {
    aiContext,
    _modifiedOn: new Date().toISOString(),
  }

  if (field.name === 'secondaryId') {
    changes.secondaryId = field.value as string
  }

  if (field.name === 'title') {
    changes.title = field.value as string
  } else {
    changes[`fields.${field.name}`] = field.value
  }

  await db.ws.transaction('rw', db.ws.docs, async () => {
    await db.ws.docs.update(doc._id, changes)
  })
}

export async function fieldMiddleware(currentDoc: Doc, field: FieldValue, fieldSchema: SomeField) {
  const currentField = currentDoc?.fields[field.name]

  if (fieldSchema.type === 'singlelink' || fieldSchema.type === 'multilink') {
    const { added, removed } = getArrDiff(field.value as string[], currentField as string[])
    const removedRelIds = removed.map((toId) => {
      const { id } = keyHash.getDocumentRelation(field.name, currentDoc._id, toId)
      return id
    })

    const newRelations: Relation[] = []
    await Promise.all([
      parallel(added, async (docId) => {
        const toDoc = await queryDoc({ _id: docId })
        if (!toDoc) return

        try {
          const newRel = await createRelationApi({
            fromId: currentDoc._id,
            fromDocType: currentDoc._type,
            toId: toDoc._id,
            toDocType: toDoc._type,
            relationType: fieldSchema.type,
            fieldName: field.name,
            reverseFieldName: fieldSchema.reverseName ?? '',
          })

          newRelations.push(newRel)
        } catch (error) {
          window.Rollbar.error(error as Error)
        }
      }),
      parallel(removedRelIds, deleteRelationApi),
    ])

    await db.ws.transaction('rw', db.ws.relations, () => {
      db.ws.relations.bulkAdd(newRelations)
      db.ws.relations.bulkDelete(removedRelIds)
    })

    return true
  } else {
    const status = await saveFieldApi(currentDoc._id, field.name, field.value)
    if (status) {
      return true
    }
    await saveFieldLocal(currentDoc, { name: field.name, value: currentField })
    return false
  }
}

export async function saveField(
  docId: string,
  field: FieldValue,
  options: { saveLog?: boolean; delay?: number } = {},
) {
  const { saveLog = true, delay } = options
  const currentDoc = await queryDoc({ _id: docId })
  if (!currentDoc) return

  const fieldSchema = scm.getFieldSchema(currentDoc._type, field.name)
  if (!fieldSchema) return

  store.dispatch(updateStatus('pending'))

  await saveFieldLocal(currentDoc, field)
  if (delay) {
    await sleep(delay)
  }
  const status = await fieldMiddleware(currentDoc, field, fieldSchema)

  if (!status) {
    store.dispatch(updateStatus('failed'))
    return false
  }

  const { activityLabel } = store.getState()

  if (saveLog) {
    createTelemetryApi({
      documentId: docId,
      documentType: currentDoc._type,
      documentTitle: currentDoc.title,
      action: 'update',
      propertyName: field.name,
      propertyType: fieldSchema.type,
      value: field.value,
      label: activityLabel?.label,
    })
  }

  store.dispatch(updateStatus('success'))

  return true
}

export type ChangeParentFormData = {
  parentSlug: string
  isHome: boolean
}

export async function changeParent({
  doc,
  parentSlug,
  isHome,
}: {
  doc: Doc
} & ChangeParentFormData) {
  const toastId = toast.loading('Changing parent...')

  try {
    const newParent = await (isHome ? queryHome() : queryDoc({ _slug: parentSlug }))
    if (!newParent) {
      throw new Error('Parent not found by slug')
    }

    if (newParent._id === doc._id) {
      throw new Error('Document can not be parent of itself')
    }

    let currentRelation = await queryRelationBetweenDocs(doc._parentId ?? '', doc._id)
    if (!currentRelation) {
      currentRelation = await queryRelationWithParent(doc._id)
    }

    if (currentRelation) {
      await deleteRelationApi(currentRelation._id)
    }

    await updateDocTree(doc, newParent)

    toast.update(toastId, {
      render: 'Parent is successfully changed',
      type: 'success',
      isLoading: false,
      autoClose: 2000,
    })
  } catch (error: any) {
    toast.update(toastId, {
      render: error.message as string,
      type: 'error',
      isLoading: false,
      autoClose: 2000,
    })
  }
}

async function updateDocTree(doc: Doc, newParent: Doc) {
  try {
    const oldAncestors = await queryAncestors(doc)
    const ancestors = await queryAncestors(newParent, true)
    doc._parentId = newParent._id
    doc._ancestors = ancestors.map((ancestor) => ancestor.documentId)

    await updateAncestorsApi({
      _id: doc._id,
      _parentId: newParent._id,
      oldAncestors,
      ancestors,
    })
    await db.ws.docs.update(doc._id, { _parentId: doc._parentId, _ancestors: doc._ancestors })

    const children = await queryDocChildren(doc._id)
    if (!children) return

    await sequentiallyChunked(children, (child) => updateDocTree(child, doc), 5)

    return true
  } catch (error) {
    window.Rollbar.error(error as Error)
    return false
  }
}
