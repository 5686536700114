import { useMemo } from 'react'
import clsx from 'clsx'
import { useLiveQuery } from 'dexie-react-hooks'
import { WhiteboardFieldType } from '@common/interfaces/fields/whiteboard-field.interface'
import { useFieldContext } from '@/contexts'
import { queryToDocsByFieldList } from '@/db/docs/queries'
import { deleteDoc } from '@/db/docs/sync'
import { updateRelationMeta } from '@/db/relations/sync'
import { useDragging } from '../hooks'
import css from '../whiteboard.module.css'
import { BoardPoint, DocEntry } from '../whiteboardTypes'
import { BoardObject } from './BoardObject'
import { Document } from './Document'

type ClusterProps = DocEntry

export const Cluster = ({ doc, rel }: ClusterProps) => {
  const { field, saveValue } = useFieldContext<WhiteboardFieldType>()
  const pos: BoardPoint = rel._meta?.[field.name]

  const linkFieldNames = useMemo(() => {
    const { clusterObjectFields = [] } = field
    const clusterField = clusterObjectFields.find((item) => item.objectType === doc._type)

    if (!clusterField?.linkFieldNames) return []
    return clusterField.linkFieldNames ?? []
  }, [])
  const linkDocs = useLiveQuery(() => queryToDocsByFieldList(doc._id, linkFieldNames), [doc._id])

  const handleEdit = (value: string) => {
    doc.title = value
    saveValue(value)
  }

  const updatePosition = (point: BoardPoint) => {
    updateRelationMeta(rel._id, field.name, point)
  }

  const deleteNote = () => {
    deleteDoc(doc)
  }

  const [resizeRef, { pos: size }] = useDragging<SVGRectElement>({ x: 456, y: 500 })

  return (
    <BoardObject
      data={doc}
      pos={pos}
      width={size.x}
      height={size.y}
      dragEnd={updatePosition}
      onEdit={handleEdit}
      onDelete={deleteNote}
      data-cluster
      docs={linkDocs?.map((item) => (
        <Document key={item.rel._id} doc={item.doc} rel={item.rel} inCluster />
      ))}
      corner={<rect ref={resizeRef} className={clsx('drag-object', css.clusterCorner)} />}
    >
      <div className="px-3 py-5">
        <p className="font-bold">{doc.title}</p>
        <p className="text-sm uppercase text-neutral500 mb-2">{doc._type}</p>
      </div>
    </BoardObject>
  )
}
