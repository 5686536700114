export const colors = {
  primary: '#01E4C2',
  blue: '#C6E7FA',
  lavande: '#CFC6FA',
  white: '#FFFFFF',
  success: '#4FC67F',
  info: '#67BAF5',
  warning: '#FEA757',
  error: '#F27474',
  'light-green': '#D6E9CD',
  'light-blue': '#B5DEFF',
  'light-orange': '#FDE7D1',
  'light-red': '#FCE9E9',
  'primary-hv': '#52C1B1',
  // neutral
  neutral50: '#F8F8F8',
  neutral100: '#F5F5F5',
  neutral200: '#EFEFEF',
  neutral300: '#E5E5E5',
  neutral400: '#D2D2D2',
  neutral500: '#9E9E9E',
  neutral600: '#808080',
  neutral700: '#5F6160',
  neutral800: '#2F2F2F',
  neutral900: '#1E1E1E',
  // blue
  blue50: '#E6F4FF',
  blue100: '#D7EDFF',
  blue200: '#B5DEFF',
  blue300: '#96D0FF',
  blue400: '#7EC5FE',
  blue500: '#55B1FB',
  blue600: '#2490E7',
  // green
  green50: '#DEF9E7',
  green100: '#C0EBCF',
  green200: '#ABDBBD',
  green300: '#89C49F',
  green400: '#64A97E',
  green500: '#448E60',
  green600: '#205B36',
  // indigo
  indigo50: '#E9E9FC',
  indigo100: '#C9CBFF',
  indigo200: '#7D81E8',
  indigo300: '#4044BF',
  indigo400: '#040995',
  indigo500: '#000466',
  indigo600: '#00034D',
  // yellow
  yellow50: '#FFF3C8',
  yellow100: '#FFE899',
  yellow200: '#FFE380',
  yellow300: '#F5D153',
  yellow400: '#EEC228',
  yellow500: '#DDB118',
  yellow600: '#9E7B00',
  // orange
  orange50: '#FCE3D6',
  orange100: '#FAD0BA',
  orange200: '#F7B390',
  orange300: '#F39A69',
  orange400: '#DC773F',
  orange500: '#D25610',
  orange600: '#762800',
  // pink
  pink50: '#FEE6EE',
  pink100: '#FBB6CD',
  pink200: '#FBB6CD',
  pink300: '#BF406A',
  pink400: '#950434',
  pink500: '#660022',
  pink600: '#4D001A',
  // purple
  purple50: '#FAF4FB',
  purple100: '#E7CBEC',
  purple200: '#D4A2DD',
  purple300: '#C279CE',
  purple400: '#AF50BF',
  purple500: '#9C27B0',
  purple600: '#852196',
}

export const pickerColors = [
  '#D2D2D2',
  '#FDBAAA',
  '#FBBE9D',
  '#fcd95d',
  '#fae28e',
  '#07E4C2',
  '#9BD1FD',
  '#C4C5FD',
  '#FEB9D0',
  '#FB98B9',
]

export const userColors = [
  colors.blue200,
  colors.green200,
  colors.indigo200,
  colors.yellow200,
  colors.orange200,
  colors.pink200,
  colors.purple200,
]
