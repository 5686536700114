import { PinIcon } from 'lucide-react'
import { CardElementsProps } from '../..'
import { Tooltip } from '@/ui'

export const PinBadge = ({ docItem }: CardElementsProps) => {
  if (!docItem) return
  return (
    <div className="flex gap-1 items-center">
      {docItem.fields.pinned && (
        <div className="rounded bg-neutral100 h-6 aspect-square grid place-items-center">
          <Tooltip content="Pinned">
            <PinIcon size={14} />
          </Tooltip>
        </div>
      )}
    </div>
  )
}
