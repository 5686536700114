import { useCallback } from 'react'
import { usePath } from '@/hooks'
import { DeleteDocModal } from '.'
import { CopyDocModal } from './components/CopyDocModal'
import { ImageModal } from '@/schema/cards/ImageModal'

export const ModalWrapper = () => {
  const { modal, setModal } = usePath()

  const close = useCallback(() => {
    setModal(null)
  }, [])

  if (!modal) return null

  switch (modal.type) {
    case 'copy': {
      return <CopyDocModal {...modal} close={close} />
    }
    case 'delete': {
      return <DeleteDocModal {...modal} close={close} />
    }
    case 'image': {
      return <ImageModal {...modal} close={close} />
    }

    default:
      null
  }
}
