import { useCallback, useEffect } from 'react'
import { useDrop } from 'react-dnd'
import { useLiveQuery } from 'dexie-react-hooks'
import { LinkFieldType } from '@common/interfaces/fields/link-field.interface'
import { WhiteboardFieldType } from '@common/interfaces/fields/whiteboard-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { Doc } from '@/db'
import { queryToDocsByFieldList } from '@/db/docs/queries'
import { queryNotes } from '@/db/interactions/queries'
import { fetchInteractions } from '@/db/interactions/sync'
import { createOneRelation } from '@/db/relations/sync'
import { useBoardZoom } from '../hooks'
import { boardState, isLinkField } from '../whiteboardUtils'
import { Cluster } from './Cluster'
import { Document } from './Document'
import { Note } from './Note'

export const Board = () => {
  const [svgRef] = useBoardZoom()
  const { doc, schema } = useDocContext()
  const { field } = useFieldContext<WhiteboardFieldType>()
  const { placeableObjectFields = [], clusterObjectFields = [] } = field

  const docs = useLiveQuery(() => queryToDocsByFieldList(doc._id, placeableObjectFields), [doc._id])
  const clusters = useLiveQuery(
    () =>
      queryToDocsByFieldList(
        doc._id,
        clusterObjectFields.map((c) => c.fieldName),
      ),
    [doc._id],
  )
  const notes = useLiveQuery(() => queryNotes(doc._id, field.name), [doc._id])

  const getDocField = useCallback(
    (type: string) => {
      const foundField = schema.fields.find(
        (f) =>
          placeableObjectFields.includes(f.name) && isLinkField(f) && f.allowed?.includes(type),
      )
      return foundField as LinkFieldType
    },
    [schema],
  )

  const [, drop] = useDrop(() => ({
    accept: 'doc',
    drop: (item: Doc) => {
      const itemField = getDocField(item._type)

      if (isLinkField(itemField)) {
        createOneRelation({
          fromId: doc._id,
          fromDocType: doc._type,
          toId: item._id,
          toDocType: item._type,
          fieldName: itemField.name,
          relationType: 'multilink',
          reverseFieldName: itemField.reverseName ?? '',
          _meta: {
            [field.name]: boardState.getCenterPoint(214),
          },
        })
      }
    },
  }))

  useEffect(() => {
    fetchInteractions(doc._id, 'note')
  }, [])

  return (
    <svg
      ref={(el) => {
        drop(el)
        svgRef.current = el
      }}
    >
      {clusters?.map((item) => (
        <Cluster key={item.rel._id} doc={item.doc} rel={item.rel} />
      ))}
      {docs?.map((item) => (
        <Document key={item.rel._id} doc={item.doc} rel={item.rel} />
      ))}
      {notes?.map((note) => (
        <Note key={note._id} note={note} />
      ))}
    </svg>
  )
}
