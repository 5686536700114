import { FC, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Trash } from 'lucide-react'
import { FieldTemplateProperty } from '../types'
import { FieldProperty } from './FieldProperty'

type FieldAreaProps = {
  propertyName: string
  nestedStructure: string
  templateProperty: FieldTemplateProperty
}

const convertData = (data) =>
  Object.entries(data).reduce((acc, [key, value]: any) => {
    if (!acc[key] && value.type !== 'object') {
      acc[key] = value.value
    }

    if (value.type === 'object') {
      acc[key] = convertData(value.value)
    }

    return acc
  }, {})

export const FieldArray: FC<FieldAreaProps> = ({
  propertyName,
  nestedStructure,
  templateProperty,
}) => {
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray<any, any>({
    control,
    name: propertyName,
  })

  const properties = useMemo(
    () => convertData({ ...(templateProperty.value as any) }),
    [templateProperty.value],
  )

  return (
    <div className="w-full [&>div]:visible">
      <div className="collapse collapse-arrow bg-neutral200">
        <input type="checkbox" />
        <div className="collapse-title text-xl font-medium">
          <span className="label-text capitalize font-bold">{propertyName}</span>
        </div>
        <div className="collapse-content">
          <div>
            {fields.map((field, index) => (
              <div key={field.id} className="pb-5">
                <div className="flex flex-wrap gap-5 pb-5">
                  {Object.keys(field).map((key) => {
                    const structureInSchema = `${nestedStructure}.${index}`

                    if (key === 'id') {
                      return null
                    }

                    return (
                      <FieldProperty
                        key={key}
                        propertyName={key}
                        templateProperty={templateProperty.value[key]}
                        nestedStructure={structureInSchema}
                      />
                    )
                  })}
                </div>

                <button
                  className="btn btn-error w-full max-w-[300px]"
                  type="button"
                  onClick={() => remove(index)}
                >
                  <Trash />
                </button>
              </div>
            ))}

            <button
              type="button"
              className="btn btn-primary max-w-xs"
              onClick={() => append([properties])}
            >
              Add more
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
