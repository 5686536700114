import { FormEvent, MouseEvent, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { useLiveQuery } from 'dexie-react-hooks'
import Fuse from 'fuse.js'
import { Search } from 'lucide-react'
import { db, Doc } from '@/db'
import { usePath } from '@/hooks'
import { useToggleOutside } from '@/hooks'
import { useDebounce } from '@/hooks'
import { pathes } from '@/routes'
import { SmallRowCard } from '@/schema/cards'
import { FUSE_SEARCH_OPTIONS } from './globalSearchConsts'

export const GlobalSearch = () => {
  const { getDocPath, workspacePath } = usePath()
  const [ref, isFocused, { toggle: toggleSearchDropDown, off }] = useToggleOutside()
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState('')
  const docs = useLiveQuery(() => db.ws.docs.toArray().catch(console.error), [])

  const fuse = new Fuse(docs ?? [], FUSE_SEARCH_OPTIONS)
  const searchTextWithDebounce = useDebounce(searchText, 700)

  const results = useMemo(() => {
    if (!searchTextWithDebounce) return []
    return fuse.search(searchTextWithDebounce).map((result) => result.item)
  }, [searchTextWithDebounce])

  const openDoc = (doc: Doc, event: MouseEvent) => {
    setSearchText('')
    off(event)
    navigate(getDocPath(doc))
  }

  const onPressEnter = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    navigate(`${workspacePath}/${pathes.SEARCH}?query=${searchText}`)
    setSearchText('')
  }

  return (
    <div className="relative" ref={ref}>
      <form onSubmit={onPressEnter} className="bg-neutral100 h-8 rounded-lg">
        <label htmlFor="search" className="flex items-center h-full px-3 py-0.5">
          <Search size={16} />
          <input
            autoComplete="off"
            onFocus={toggleSearchDropDown}
            type="text"
            id="search"
            placeholder="Search…"
            className={clsx(
              'bg-neutral50 outline-none h-full transition-[width] ease-linear duration-200 delay-0',
              isFocused ? 'w-[160px] pl-3 max-sm:w-[80px]' : 'w-0',
            )}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </label>
      </form>
      {searchText && isFocused && (
        <div
          className="absolute right-0 top-12 w-[550px] bg-white p-4 drop-shadow-xl rounded border border-neutral200 z-[100] overflow-auto max-[720px]:w-[310px] max-[380px]:w-[270px] max-[380px]:right-[-10px] max-sm:p-3"
          style={{ maxHeight: '80vh' }}
        >
          <div className="bg-neutral50 rounded py-2 px-4 grid grid-flow-col gap-3 justify-start items-center mt-4">
            <Search size={18} />
            <p>“{searchText}” - Press enter to view all results</p>
          </div>
          <section className="grid gap-1.5 mt-4">
            <h4 className="text-sm uppercase text-neutral700 px-4 py-2">Results</h4>
            <div className="grid gap-1.5">
              {results.map((doc) => (
                <button
                  className="text-left"
                  key={doc._id}
                  onClick={(event) => openDoc(doc, event)}
                >
                  <SmallRowCard doc={doc} />
                </button>
              ))}
            </div>
          </section>
        </div>
      )}
    </div>
  )
}
