import { useCallback } from 'react'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import { pathes } from '@/routes'

export const useWhiteboardMode = () => {
  const isWhiteboardMode = useMatch(`${pathes.WORKSPACE}${pathes.WHITEBOARD}`)
  const location = useLocation()
  const navigate = useNavigate()

  const toggleWhiteboardMode = useCallback(
    (whiteboardName?: string) => {
      if (whiteboardName) {
        const pathname = location.pathname.endsWith('/')
          ? location.pathname.slice(0, -1)
          : location.pathname
        navigate(`${pathname}/wb/${whiteboardName}`)
      } else {
        const [docPath] = location.pathname.split('wb')
        navigate(docPath, { replace: true })
      }
    },
    [location],
  )

  return {
    isWhiteboardMode,
    toggleWhiteboardMode,
  }
}
