import { createSelector } from 'reselect'
import type { RootState } from '@/store'

export const selectAccount = (state: RootState) => state.account.data
export const selectWorkspaceIds = (state: RootState) => state.account.workspaceIds
export const selectInitStatus = (state: RootState) => state.account.init
export const selectProgress = (state: RootState) => state.account.progress

export const selectClientWorkspace = createSelector(
  (state: RootState) => state.account,
  ({ client, workspace }) => ({ client, workspace }),
)
