import { useState } from 'react'
import { queryDocsByType, queryUserDoc } from '@/db/docs/queries'
import { createDoc } from '@/db/docs/sync'
import { updateDocumentReadAccess } from '@/db/permissions/sync'
import { fetchUsers } from '@/db/users/sync'
import { Button } from '@/ui'
import { sequentially } from '@/utils'

export const SyncUsers = () => {
  const [loading, setLoading] = useState(false)

  const syncUsers = async () => {
    setLoading(true)
    const users = await fetchUsers()
    if (!users) return setLoading(false)

    const userType = localStorage.getItem('devUserType') ?? 'User'
    const usersParentType = localStorage.getItem('devUsersParentType') ?? 'Users'

    const docs = await queryDocsByType(usersParentType)
    const parent = docs[0]
    if (!parent) return setLoading(false)

    await sequentially(users, async (user) => {
      if (!user.email) return

      const doc = await queryUserDoc(user._id)
      if (doc) return

      const newDoc = await createDoc({
        type: userType,
        parentDoc: parent,
        title: user.name || user.email.substring(0, user.email.indexOf('@')),
        userId: user._id,
        fields: { userId: user._id },
      })

      if (newDoc) {
        await updateDocumentReadAccess(newDoc._id, 'everyone')
      }
    })

    setLoading(false)
  }

  return (
    <>
      <Button variant="ghost" onClick={syncUsers} loading={loading}>
        Sync users
      </Button>
    </>
  )
}
