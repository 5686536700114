import { toast } from 'react-toastify'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { io } from '@/api'
import { updateUserApi } from '@/api/users'
import { db, WorkspaceFlags } from '@/db'
import { RootState } from '@/store'
import { updateOneWorkspace } from '@/store/account/slice'
import { selectAccount } from './selectors'
import { ProfilePayload, UpdateAccountPL, UpdateClientPL, UpdateWorkspacePL } from './types'

export const updateProfile = createAsyncThunk(
  'account/updateProfile',
  async (payload: ProfilePayload, { getState, rejectWithValue }) => {
    const state = getState() as RootState
    const account = selectAccount(state)
    const id = toast.loading('Updating profile')

    try {
      const { _id, name, email, color, profileImage } = payload
      const body: UpdateAccountPL = {
        _id: _id ?? account._id,
        name,
        email,
        color,
      }

      if (color) {
        body.color = color
      }

      if (profileImage) {
        body.profileImage = profileImage
      }

      await updateUserApi(body)
      toast.update(id, {
        render: 'Profile successfully updated',
        type: 'success',
        isLoading: false,
        autoClose: 2000,
      })

      return body
    } catch (e) {
      toast.update(id, {
        render: 'Email is already exist',
        type: 'error',
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
      })
      window.Rollbar.error(e as Error)
      return rejectWithValue(false)
    }
  },
)

export const updateClient = createAsyncThunk(
  'account/updateClient',
  async (payload: UpdateClientPL) => {
    const status = await toast.promise(
      io.emit<boolean>('updateClient', {
        _id: db.activeClient._id,
        ...payload,
      }),
      {
        pending: 'Updating organization',
        success: 'Organization updated',
        error: 'Cannot update organization',
      },
    )

    const newClient = { ...db.activeClient, ...payload }
    if (status) {
      await db.clients.update(db.activeClient._id, payload)
      db.activeClient = newClient
    }

    return newClient
  },
)

export const updateWorkspace = createAsyncThunk(
  'account/updateWorkspace',
  async (payload: UpdateWorkspacePL, { dispatch }) => {
    const status = await toast.promise(io.emit<boolean>('updateWorkspace', payload), {
      pending: 'Updating workspace',
      success: 'Workspace updated',
      error: 'Cannot update workspace',
    })

    dispatch(updateOneWorkspace(payload))

    const updates: Omit<UpdateWorkspacePL, '_id' | '_partId'> = {
      name: payload.name,
    }

    if (payload.color) {
      updates.color = payload.color
    }

    if (payload.image) {
      updates.image = payload.image
    }

    const newWorkspace = { ...db.activeWorkspace, ...payload }
    if (status) {
      await db.workspaces.update(payload._id, payload)
      db.activeWorkspace = newWorkspace
    }

    return newWorkspace
  },
)

export const updateWorkspaceVars = createAsyncThunk(
  'account/updateWorkspaceVars',
  async (payload: WorkspaceFlags, { dispatch }) => {
    const newVars = { ...db.activeWorkspace._envVars, ...payload }
    const status = await toast.promise(
      io.emit<boolean>('updateWorkspace', {
        _id: db.activeWorkspace._id,
        _partId: db.activeWorkspace._partId,
        _envVars: newVars,
      }),
      {
        pending: 'Updating workspace variables',
        success: 'The variables updated',
      },
    )

    if (status) {
      const newWorkspace = { ...db.activeWorkspace, _envVars: newVars }
      dispatch(updateOneWorkspace(newWorkspace))
      db.activeWorkspace = newWorkspace
      db.flags = newVars
      await db.workspaces.update(db.activeWorkspace._id, { _envVars: newVars })
    }

    return status
  },
)
