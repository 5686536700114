import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import { NumberFieldType } from '@common/interfaces/fields/number-field.interface'
import { useFieldContext } from '@/contexts'
import { FieldContainer } from '@/schema'
import { Tooltip } from '@/ui'
import { formatNumber } from '@/utils/numberFormatter'
import { FieldComponent } from '../fieldType'

export const NumberField: FieldComponent = () => {
  const {
    value = '',
    saveValue,
    field,
    isEditable,
  } = useFieldContext<NumberFieldType, number | ''>()
  const [num, setNum] = useState<number | ''>(value)

  const sendChangeDebounced = useMemo(() => debounce((val: number | '') => saveValue(val), 500), [])

  const titleChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const valueInt = parseFloat(event.target.value)

    if (!isNaN(valueInt)) {
      setNum(valueInt)
      sendChangeDebounced(valueInt)
    } else {
      setNum('')
      sendChangeDebounced('')
    }
  }, [])

  if (isEditable) {
    return (
      <FieldContainer>
        <input
          type="number"
          pattern="-?[0-9]+"
          placeholder={field.placeholder ?? 'Add number'}
          className={clsx('outline-none w-full resize-none bg-neutral50 p-2 rounded-md')}
          value={num}
          onChange={titleChangeHandler}
        />
      </FieldContainer>
    )
  }

  return (
    <FieldContainer>
      <Tooltip content={`${value || 0}`}>
        <p className="text-break">{formatNumber(+value)}</p>
      </Tooltip>
    </FieldContainer>
  )
}
