import { ImageFieldData } from "./interfaces/fields/image-field.interface";

export function isImg(field: any): field is ImageFieldData {
  return (
    field && "fileName" in field && "_clientId" in field && "source" in field
  );
}

export function tryImg(field: any) {
  return isImg(field) ? field : undefined;
}
