import { ChangeEvent, useState } from 'react'
import axios from 'axios'
import { getUnixTime } from 'date-fns'
import { Paperclip } from 'lucide-react'
import { FileAttachmentFieldType } from '@common/interfaces/fields/file-attachment-field.interface.ts'
import { io } from '@/api'
import { useDocContext, useFieldContext } from '@/contexts'
import { db, ImageFieldData } from '@/db'
import { FileAttachmentView } from '@/fields/file-attachment/FileAttachmentView.tsx'
import { Button } from '@/ui'

export const FileAttachmentField = () => {
  const { doc } = useDocContext()
  const { isEditable, value, field, saveValue } = useFieldContext<
    FileAttachmentFieldType,
    ImageFieldData
  >()
  const fileType = field?.fileType ?? 'application/pdf'

  const [progress, setProgress] = useState(0)

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      const fileName = `${getUnixTime(new Date())}-${file.name}`
      const type = file.type

      const payload = {
        _clientId: db.activeClient._id,
        _documentId: doc._id,
        fileName,
        type,
        hours: 1,
      }

      const signedUrl: string = await io.emit('getSignedUrl', payload)

      if (signedUrl) {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)

        const config = {
          headers: { 'Content-Type': fileType },
          onUploadProgress: (event) => {
            const percent = Math.round((event.loaded / event.total) * 100)
            setProgress(percent)
            if (percent === 100) {
              setTimeout(() => setProgress(0), 1000)
            }
          },
        }

        reader.onload = async () => {
          try {
            const res = await axios.put(signedUrl, reader.result, config)
            if (res) {
              saveValue({
                source: 'cdn',
                fileName,
                _clientId: db.activeClient._id,
                type,
                _documentId: doc._id,
              })
            }
          } catch (error) {
            window.Rollbar.error(error as Error)
          }
        }
      }
    }
  }

  if (!isEditable) {
    return <FileAttachmentView />
  }

  return (
    <div className="pt-10">
      <label className="bg-neutral300 inline-flex items-center p-3 mb-3 rounded-md cursor-pointer">
        <input type="file" accept={fileType} className="sr-only" onChange={handleUpload} />

        <Paperclip color="#808080" size={16} />
        <span className="text-regular text-neutral700 pl-2">Attach file</span>
      </label>

      {progress > 0 ? (
        <progress className="progress progress-primary w-full" value={progress} max="100" />
      ) : null}

      {value?.fileName && (
        <div className="bg-neutral300 flex items-center justify-between p-2 rounded-md">
          <p>{value.fileName}</p>

          <Button
            icon="trash"
            onClick={() => {
              saveValue({
                source: 'unsplash',
                _clientId: db.activeClient._id,
                fileName: '',
                _documentId: '',
              })
            }}
          />
        </div>
      )}
    </div>
  )
}
