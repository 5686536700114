import { useCallback, useEffect, useState } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { useFieldContext } from '@/contexts'
import { CardSchema, DataListParams, Doc } from '@/db'
import { queryDoc, queryDocChildren, queryDocList, queryDocsByType } from '@/db/docs/queries'
import { scm, useSchemaState } from '@/contexts/schema'
import { useSlr } from '@/store'
import { selectFilters } from '@/store/filters/selectors'
import { groupArr, sumArr } from '@/utils/docsSort'
import { singlePromise } from '@/utils/promises'
import { FormatType, formatNumber } from '@/utils/numberFormatter'

export type SparklinesDatum = { x: string | number; y: number }
export type AnnotationDatum = { title: string; value: number }
export interface SparklinesProps {
  currentDoc: Doc
  docs?: Doc[]
  sparkline: CardSchema['sparkline']
}

export function useSparklinesDatum({ currentDoc, docs, sparkline }: SparklinesProps) {
  const [value, setValue] = useState<string | number>('')
  const filters = useSlr(selectFilters)
  const { field } = useFieldContext<DataListParams>()
  const [data, setData] = useState<SparklinesDatum[]>([])
  const [annotation, setAnnotation] = useState<AnnotationDatum>()
  const fieldsSchema = useSchemaState((state) => state.fields)
  const yFilter = filters[sparkline?.filterName ?? '']
  const filterDoc = useLiveQuery(() => queryDoc({ _id: yFilter?.[0] ?? '' }), [yFilter])
  const targetDocs = useLiveQuery(() => queryDocChildren(currentDoc._id), [currentDoc._id])

  const addSymbol = (amount: number) => formatNumber(amount, currentDoc.fields?.type as FormatType)

  const getSum = useCallback(
    (countData: Doc[]): number => {
      const { query } = field?.layout?.card ?? {}
      const { fieldName } = query ?? {}
      const aggregateFunction = currentDoc.fields?.aggregateFunction

      if (!countData.length || !fieldName) {
        return 0
      }

      if (aggregateFunction === 'last') {
        const last = countData[countData.length - 1]
        const result = last.fields[fieldName] as number
        return result ?? 0
      }

      if (aggregateFunction === 'sum' || aggregateFunction === 'avg') {
        const sum = sumArr(countData, (docItem) => +(docItem.fields[fieldName] ?? 0))
        if (sum <= 0) return sum

        return aggregateFunction === 'sum' ? sum : sum / countData.length
      }

      if (aggregateFunction === 'max') {
        const fieldValues = countData.map((entry) => {
          if (entry.fields?.[fieldName]) {
            return entry.fields?.[fieldName] as number
          }

          return 0
        })

        const max = Math.max(...(fieldValues as number[]))

        return max
      }

      return 0
    },
    [field],
  )

  useEffect(() => {
    async function sortDatum() {
      if (!docs || !sparkline?.groupBy) {
        return
      }

      if (!filterDoc) {
        setValue(0)
        return setData([])
      }

      const fieldName = sparkline[sparkline.groupBy]
      const quarters = await singlePromise('quarters', () => queryDocsByType('QTR'))
      const fiscalYears = await singlePromise('fiscalYears', () => queryDocsByType('FiscalYear'))

      const preparedData: Doc[] = []

      await Promise.all(
        docs.map<Promise<void>>(async (docItem) => {
          const field = docItem.fields?.[fieldName]
          const fieldSchema = scm.getFieldSchema(docItem._type, fieldName)

          if (
            (Array.isArray(field) && fieldSchema?.type === 'singlelink') ||
            fieldSchema?.type === 'multilink'
          ) {
            const linkedDocs = await queryDocList(field as string[])
            const qrtDoc = linkedDocs[0]
            if (!qrtDoc) return Promise.resolve()
            if (filterDoc._type !== qrtDoc._type) return Promise.resolve()

            const preparedDoc = {
              ...docItem,
              fields: {
                ...docItem.fields,
                [fieldName]: qrtDoc.title,
              },
            }

            const dateDocs = filterDoc?._type === 'QTR' ? quarters : fiscalYears
            // get docs before or equal to filterDoc
            const filterDocIndex = dateDocs?.findIndex((doc) => doc._id === filterDoc?._id) ?? 0
            const currentDocIndex = dateDocs?.findIndex((doc) => doc._id === qrtDoc?._id) ?? 0
            if (filterDocIndex >= currentDocIndex) {
              preparedData.push(preparedDoc)
            }
          }

          return Promise.resolve()
        }),
      )

      preparedData.sort((a, b) =>
        String(a.fields[fieldName]).localeCompare(String(b.fields[fieldName]), undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      )

      const groups = groupArr(preparedData, (docItem) => {
        const key = docItem.fields[fieldName]
        if (typeof key === 'string') return key
        return '-'
      })

      const data = Object.entries(groups).map<SparklinesDatum>(([key, group]) => ({
        x: key,
        y: getSum(group),
      }))

      const maxTicks = 5
      const filterDocIndex = data.findIndex((item) => item.x === filterDoc.title)
      if (filterDocIndex > -1) {
        const start = filterDocIndex - maxTicks
        const lastData = data.splice(start > 0 ? start : 0, filterDocIndex + 1)
        setData(lastData)
        setValue(addSymbol(lastData[lastData.length - 1].y))
      } else {
        setData([])
        setValue(0)
      }
    }

    sortDatum()
  }, [docs, sparkline, yFilter, filterDoc, fieldsSchema])

  useEffect(() => {
    if (!filterDoc || !targetDocs?.length) {
      if (annotation) setAnnotation(undefined)
      return
    }

    const targetDoc = targetDocs?.find((doc) => {
      const dateField = doc.fields.Period?.[0] as string | undefined
      if (dateField) {
        return filterDoc._id === dateField || filterDoc._parentId === dateField
      }
    })
    if (targetDoc) {
      const targetValue = +targetDoc.fields[sparkline?.y ?? ''] || 0
      const result = addSymbol(targetValue)
      setAnnotation({
        value: targetValue,
        title: `${targetDoc.title}: ${result}`,
      })
    }
  }, [yFilter, filterDoc, targetDocs, currentDoc])

  return { data, annotation, value }
}
