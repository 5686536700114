import clsx from 'clsx'
import css from './toggle.module.css'

type ToggleProps = {
  label: string
  on: boolean
  disabled?: boolean
  onChange: (isOn: boolean) => void
}

export const Toggle = ({ on, onChange, label, disabled }: ToggleProps) => (
  <div className="flex items-center gap-2 rounded-xl">
    <label htmlFor="editableToggle" className="grid">
      <input
        id="editableToggle"
        aria-label="editableToggle"
        type="checkbox"
        disabled={disabled}
        checked={on}
        className={css.toggleInput}
        onChange={(e) => onChange(e.target.checked)}
      />
      <span className={clsx(css.toggleBtn, on && css.toggleBtnOn)} />
    </label>
    <span className="font-bold">{label}</span>
  </div>
)
