import { DataListParams, Doc } from '@/db'
import { sortDocs } from '@/utils/docsSort'
import { useMemo } from 'react'

export function useSort(data?: Doc[], fieldSort?: DataListParams['sort']) {
  return useMemo(() => {
    if (!data) return []

    if (!fieldSort?.length) return sortDocs(data)

    const dataCopy = [...data]

    fieldSort.forEach((sort) => {
      dataCopy.sort((a, b) => {
        const sortBy = sort.name as keyof Doc
        let type = typeof a[sortBy]

        if (type === 'undefined') {
          type = typeof a.fields[sortBy]
        }

        switch (type) {
          case 'string': {
            const aValue = (a[sortBy] ?? a.fields[sortBy]) as string
            const bValue = (b[sortBy] ?? b.fields[sortBy]) as string

            if (sort.isDesc) {
              return bValue.localeCompare(aValue, undefined, {
                numeric: true,
                sensitivity: 'base',
              })
            }

            return aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base' })
          }
          case 'number': {
            const aValue = (a[sortBy] ?? a.fields[sortBy]) as number
            const bValue = (b[sortBy] ?? b.fields[sortBy]) as number

            if (sort.isDesc) {
              return bValue - aValue
            }
            return aValue - bValue
          }
          case 'boolean': {
            const aValue = +(a[sortBy] ?? false)
            const bValue = +(b[sortBy] ?? false)

            if (sort.isDesc) {
              return aValue - bValue
            }
            return bValue - aValue
          }
          default:
            return 0
        }
      })
    })

    return dataCopy
  }, [data, fieldSort])
}
