import { useMemo } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { useDocContext } from '@/contexts'
import { queryDocList } from '@/db/docs/queries'
import { getDocAIContext } from '@/utils'

export function useAIContext() {
  const { doc, schema } = useDocContext()

  const multilinks = useMemo(() => {
    if (!doc.aiContext) return []

    return Object.entries(doc.fields).filter(([fieldName]) =>
      schema.fields.some((field) => field.name === fieldName && field.type === 'multilink'),
    )
  }, [doc.fields])

  const multilinkContexts = useLiveQuery(async () => {
    doc.aiContext = await getDocAIContext(doc)

    const contexts = await Promise.all(
      multilinks.map(async ([fieldName, value]) => {
        const docs = await queryDocList(value as string[])
        const field = schema.fields.find((f) => f.name === fieldName)
        const name = field?.niceName

        if (docs?.length) {
          return `${name}:\n`.concat(
            docs
              .filter((item) => item.aiContext)
              .map((item) => `- ${item.aiContext}`)
              .join('\n'),
          )
        }

        return null
      }),
    )

    return contexts.filter(Boolean).join('\n')
  }, [multilinks])

  return useMemo(() => {
    if (!doc.aiContext) return ''

    if (!multilinkContexts) return doc.aiContext

    return doc.aiContext.concat('\n').concat(multilinkContexts)
  }, [multilinkContexts])
}
