import { FunctionComponent, MouseEvent } from 'react'
import { CardElement, Doc, DocumentType } from '@/db'
import { LikeField } from '@/fields/like'
import { PublicBadge } from '@/schema/cards/components/PublicBadge.tsx'
import { UsernameWithAvatar } from '../AvatarUsername'
import { CardDocStatus } from '../CardDocStatus/CardDocStatus'
import { CommentsCount } from '../CommentsCount'
import { DateBadge } from '../DateBadge/DateBadge'
import { DeleteButton } from '../DeleteButton/DeleteButton'
import { PinBadge } from '../PinBadge/PinBadge'
import { FileButtons } from '../FileButtons.tsx'
import { Collaborators } from '../Collaborators'
import { Bookmark } from '../Bookmark.tsx'
import { TypeIcon } from '../TypeIcon.tsx'

export interface CardElementsProps {
  docItem?: Doc
  date?: boolean
  isEditMode?: boolean
  onDelete?: (event: MouseEvent<HTMLButtonElement>, doc: Doc) => void
  docSchema?: DocumentType
  name?: string
  styles?: { [key: string]: string }
}

export const cardElements: Record<CardElement, FunctionComponent<CardElementsProps>> = {
  avatar: UsernameWithAvatar,
  status: CardDocStatus,
  likes: LikeField,
  date: DateBadge,
  comments: CommentsCount,
  pin: PinBadge,
  public: PublicBadge,
  deleteBtn: DeleteButton,
  downloadButton: FileButtons,
  fileViewButton: FileButtons,
  collaborators: Collaborators,
  bookmark: Bookmark,
  typeIcon: TypeIcon,
}
