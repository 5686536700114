import { FieldValue } from '@/db'
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface FieldState {
  name: string
  isVisible?: boolean
}

export type DocStatus = 'idle' | 'pending' | 'success' | 'failed'

export const fieldsAdpater = createEntityAdapter<FieldState, string>({
  selectId: (field) => field.name,
})

const initialNewDoc = {
  _ancestors: [],
  fields: {},
  _parentId: '',
  parentDoc: {},
  _partId: '',
  _id: 'DOCUMENT#NEW-DOC',
  _slug: '',
  _titleSlug: '',
  _userId: '',
  _addedOn: '',
  _modifiedOn: '',
  _type: '',
  title: 'Title',
  updatedFields: {},
  isNew: false,
}

export const docsSlice = createSlice({
  name: 'docs',
  initialState: {
    fields: fieldsAdpater.getInitialState(),
    isEditMode: false,
    activeDocId: '',
    status: 'idle',
    newDoc: initialNewDoc,
    isPanelOpen: false,
  },
  reducers: {
    toggleEditMode(state, action: PayloadAction<boolean>) {
      state.isEditMode = action.payload
      fieldsAdpater.removeAll(state.fields)
    },
    setActiveDocId(state, action) {
      const queryParams = new URLSearchParams(window.location.search)
      state.activeDocId = action.payload
      state.isEditMode = queryParams.has('edit')
      fieldsAdpater.removeAll(state.fields)
    },
    updateFieldState(state, action: PayloadAction<FieldState>) {
      fieldsAdpater.upsertOne(state.fields, action.payload)
    },
    setStatus(state, action: PayloadAction<DocStatus>) {
      state.status = action.payload
    },
    setNewDoc(state, action) {
      state.newDoc = { ...state.newDoc, ...action.payload }
    },
    setIsPanelOpen(state, action) {
      state.isPanelOpen = action.payload
    },
    setNewDocFields(state, action: PayloadAction<FieldValue>) {
      const field = action.payload
      state.newDoc.updatedFields[field.name] = field.value

      if (field.name === 'title') {
        state.newDoc.title = field.value as string
      } else {
        state.newDoc.fields[field.name] = field.value
      }
    },
    resetNewDoc(state) {
      state.newDoc = initialNewDoc
    },
  },
  extraReducers(builder) {
    builder.addCase('persist/REHYDRATE', (state, action: any) => ({
      ...state,
      ...action.payload,
    }))
  },
})
