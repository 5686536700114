import clsx from 'clsx'
import { HtmlParser } from '@/features/rte'
import { showUntitledText } from '@/utils/parseString'
import css from '../card.module.css'
import { CardProps } from '../cardsTypes'

export const ColorfulCard = ({ doc, headerElements, children }: CardProps) => {
  const backgroundColor = (doc?.fields?.color as string) ?? '#efefef'
  return (
    <div
      className="relative w-full h-full border rounded-lg flex flex-col"
      style={{ backgroundColor }}
    >
      <div className="absolute top-3 left-0 w-full z-20">{headerElements}</div>
      <div className="px-3.5 pt-11 pb-2.5 h-[200px]">
        <h4 title={doc.title} className={clsx('text-neutral800 leading-5', css.clampThreeRows)}>
          {showUntitledText(doc.title)}
        </h4>
        <div
          className={clsx(
            'text-regular pt-2.5 text-neutral800 leading-5 break-words',
            css.clampThreeRows,
          )}
        >
          <HtmlParser className="leading-[19px]">{doc.fields.description}</HtmlParser>
        </div>
      </div>
      {children}
    </div>
  )
}
