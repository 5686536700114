import { useMemo } from 'react'
import clsx from 'clsx'
import css from '@/schema/cards/card.module.css'
import { CardProps } from '@/schema/cards/cardsTypes.ts'
import { CardDocType } from '@/schema/cards/components/CardDocType.tsx'
import { showUntitledText } from '@/utils/parseString.ts'
import { FormatType, formatNumber } from '@/utils/numberFormatter'

export const GoalCard = ({ doc, docSchema, parentDoc, children, headerElements }: CardProps) => {
  const valueField = doc.fields?.value as number

  const value = useMemo(
    () => formatNumber(valueField, parentDoc?.fields?.type as FormatType),
    [valueField],
  )

  return (
    <div className="relative border-neutral300 w-full h-full border rounded-lg flex flex-col">
      <div className="px-3.5 py-8">
        {headerElements}
        <CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="mb-1.5" />
        <p title={doc.title} className={clsx('text-focus', css.clampThreeRows)}>
          {showUntitledText(doc.title)}
        </p>

        <p className="text-title pt-3">{value}</p>
      </div>
      {children}
    </div>
  )
}
