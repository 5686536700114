import { PropsWithChildren, useCallback, useMemo } from 'react'
import { DocContext, DocContextValue } from '@/contexts'
import { Doc, DocumentType, FieldValue } from '@/db'
import { saveField } from '@/db/docs/sync'
import { useAccountRoles } from '@/hooks'
import { useSlr } from '@/store'
import { selectAccount } from '@/store/account'
import { useAreas } from '../schema/hooks'

export type DocProviderProps = {
  doc: Doc
  schema: DocumentType
  isEditMode: boolean
  isCompound?: boolean
  isNew?: boolean
  onChange?: (value: FieldValue) => void
} & PropsWithChildren

export const DocProvider = ({
  doc,
  schema,
  isEditMode,
  isCompound,
  isNew,
  children,
  onChange,
}: DocProviderProps) => {
  const account = useSlr(selectAccount)
  const areas = useAreas(schema)
  const roles = useAccountRoles(doc)

  const save = useCallback((fieldValue: FieldValue) => saveField(doc._id, fieldValue), [doc])

  const contextValue = useMemo<DocContextValue>(
    () => ({
      doc,
      isEditMode,
      save: onChange ?? save,
      schema,
      user: account,
      areas,
      isCompound,
      isNew,
      roles,
    }),
    [doc, isEditMode, schema, roles, save, onChange],
  )

  return <DocContext.Provider value={contextValue}>{children}</DocContext.Provider>
}
