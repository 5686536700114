import { CardProps } from '@/schema/cards/cardsTypes.ts'
import { Preview } from '@/ui'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'
import { CardDocType } from '../components/CardDocType'
import clsx from 'clsx'

export const VerticalCarouselCard = ({ doc, children, setImage, docSchema }: CardProps) => {
  const { field } = useFieldContext<ChildrenFieldType>()
  const styles = field?.layout?.card?.styles
  const secondaryStyles = field?.layout?.card?.secondaryStyles

  return (
    <div className="relative h-[320px] bg-[#f1f1f1] flex items-end" style={styles}>
      <Preview
        fit="cover"
        className="w-full h-full object-center"
        image={doc.fields.image}
        onClick={(e) => setImage?.(e, doc.fields.image)}
      />

      <div
        className={clsx(
          'text-heading text-neutral900 bg-primary/60 absolute left-5 bottom-5 right-5',
          { 'p-3': field.verticalCarousel?.showTitle || !field.verticalCarousel?.hideType },
        )}
      >
        {!field.verticalCarousel?.hideType && (
          <CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="mb-1.5" />
        )}
        {field.verticalCarousel?.showTitle && <h2 style={secondaryStyles}>{doc.title}</h2>}
      </div>

      {children}
    </div>
  )
}
