import clsx from 'clsx'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'
import { useFieldContext } from '@/contexts'
import { Doc } from '@/db'
import { HtmlParser } from '@/features/rte'
import { Preview } from '@/ui'
import css from '../card.module.css'
import { CardProps } from '../cardsTypes'
import { CardDocType } from '../components/CardDocType'

export const HorizontalSmallCard = ({
  doc,
  children,
  showQuote,
  docSchema,
  headerElements,
}: CardProps) => {
  const { field } = useFieldContext<ChildrenFieldType, Doc[]>()
  const imageFit = field.layout?.card?.imageFit
  const quote = doc.fields['Persona Quote']
  return (
    <div
      className={clsx('relative flex gap-x-1 h-full border border-neutral300 rounded-md', {
        [css.carouselHorizontalCard]: field.isCarousel,
      })}
    >
      <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
      <Preview
        fit={imageFit ? 'contain' : 'cover'}
        image={doc.fields?.image}
        icon="user"
        iconSize={26}
        className="w-[4.9rem] h-[3.7rem] border-r rounded-md"
      />

      <div className="pt-1 pr-1">
        <CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="mb-1.5" />
        <p
          title={doc.title}
          className="text-focus leading-4 break-words whitespace-pre-wrap line-clamp-2"
        >
          {doc.title}
        </p>
        {showQuote && <HtmlParser className={css.personaHtml}>{quote}</HtmlParser>}
        {children}
      </div>

      {children}
    </div>
  )
}
