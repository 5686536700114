import { CardProps } from '../cardsTypes'
import { Preview } from '@/ui'

export const CardWithImage = ({ doc }: CardProps) => (
  <div className="bg-white h-full rounded-md p-6 border border-neutral300 flex items-center">
    {doc.fields?.image && (
      <Preview
        fit="cover"
        className="w-[60px] h-[60px] rounded-full shrink-0 mr-3"
        image={doc.fields.image}
        iconSize={36}
      />
    )}

    <p className="font-bold grow text-left">{doc.title}</p>
  </div>
)
