import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Accordion, AccordionItem } from '@nextui-org/react'
import { Doc } from '@/db'
import { queryDoc } from '@/db/docs/queries'
import type { SidebarGroup } from '../Sidebar'
import { SideBarSection } from './SideBarSection'

interface SidebarGroupsProps {
  groups?: SidebarGroup[]
}

export const SidebarGroups = ({ groups }: SidebarGroupsProps) => {
  const { slug } = useParams()
  const [activeSection, setActiveSection] = useState<Doc | null>(null)

  const setActiveSectionFromNavigation = async () => {
    const activeDoc = await queryDoc({ _slug: slug })
    if (activeDoc) {
      setActiveSection(activeDoc)
    }
  }

  useEffect(() => {
    if (activeSection?._slug !== slug) {
      setActiveSectionFromNavigation()
    }
  }, [slug, activeSection?._id])

  const itemClasses = {
    heading: 'px-2',
    title: 'font-bold text-sm text-neutral700 uppercase ',
    trigger: 'pt-1 pb-0 rounded-lg flex items-center',
    indicator: 'text-medium hover:bg-primary hover:bg-opacity-10 p-2 rounded-full',
    content: 'py-0',
  }

  return (
    <nav className="overflow-y-auto overflow-x-hidden border-t border-t-neutral200">
      {groups?.map((group) => (
        <Accordion
          key={group.name}
          itemClasses={itemClasses}
          defaultExpandedKeys={[group.name]}
          isCompact
        >
          <AccordionItem key={group.name} aria-label={group.name} title={group.name}>
            {group.docs
              .sort((a: any, b: any) => {
                const orderA =
                  (a.fields?.sortOrderInMenu || a.sortOrderInMenu) ?? group.docs.length + 1
                const orderB =
                  (b.fields?.sortOrderInMenu || b.sortOrderInMenu) ?? group.docs.length + 1

                if (orderA > orderB) return 1
                if (orderA < orderB) return -1
                return 0
              })
              .map((doc: any) => {
                if (doc._slug)
                  return <SideBarSection key={doc._id} doc={doc} activeSection={activeSection} />
                if (doc.docs)
                  return (
                    <Accordion
                      key={group.name}
                      itemClasses={itemClasses}
                      defaultExpandedKeys={[group.name]}
                      isCompact
                    >
                      <AccordionItem key={doc.name} aria-label={doc.name} title={doc.name}>
                        {doc.docs
                          .sort((a: any, b: any) => {
                            const orderA =
                              (a.fields?.sortOrderInMenu || a.sortOrderInMenu) ??
                              group.docs.length + 1
                            const orderB =
                              (b.fields?.sortOrderInMenu || b.sortOrderInMenu) ??
                              group.docs.length + 1

                            if (orderA > orderB) return 1
                            if (orderA < orderB) return -1
                            return 0
                          })
                          .map((doc: any) => (
                            <SideBarSection key={doc._id} doc={doc} activeSection={activeSection} />
                          ))}
                      </AccordionItem>
                    </Accordion>
                  )
              })}
          </AccordionItem>
        </Accordion>
      ))}
    </nav>
  )
}
