/* eslint-disable */
import Microlink from '@microlink/react'
import { URLFieldData, URLFieldType } from '@common/interfaces/fields/url-field.interface.ts'
import { useFieldContext } from '@/contexts'
import { FieldContainer } from '@/schema'
import { Icon } from '@/ui'
import urlIcon from './images/url-icon.png'
import { UrlEdit } from './UrlEdit'

export const UrlField = () => {
  const { value, isEditable, field } = useFieldContext<URLFieldType, URLFieldData>()

  if (isEditable) {
    return <UrlEdit />
  }

  if (!value?.url) {
    return null
  }

  return (
    <FieldContainer>
      {field.viewComponent === 'preview' ? (
        <div>
          <Microlink url={value.url} size="large" className="w-full !max-w-full" />
        </div>
      ) : (
        <a
          href={value.url}
          target="_blank"
          rel="noreferrer"
          className="flex items-center gap-x-2 text-blue600"
        >
          {field.showWebexIcon && <img src={urlIcon} width="30" alt="url icon" />}

          {value.url}

          {!field.showWebexIcon && <Icon name="external-link" />}
        </a>
      )}
    </FieldContainer>
  )
}
