import { SchemaTemplate } from '@/features/schemaEditor/types'

export const documentTemplate: SchemaTemplate = {
  name: {
    required: true,
    value: '',
    type: 'text',
  },
  niceName: {
    required: false,
    value: '',
    type: 'text',
  },
  menuGroup: {
    required: false,
    value: '',
    type: 'text',
  },
  icon: {
    required: false,
    value: '',
    type: 'icon',
  },
  aiContext: {
    required: false,
    value: '',
    type: 'text',
  },
  singleEntry: {
    required: false,
    value: false,
    type: 'boolean',
  },
  showInExt: {
    required: false,
    value: false,
    type: 'boolean',
  },
  hideSubsections: {
    required: false,
    value: false,
    type: 'boolean',
  },
  hideInMenu: {
    required: false,
    value: false,
    type: 'boolean',
  },
  layout: {
    required: true,
    value: {
      name: {
        required: true,
        value: '',
        type: 'select',
      },
      theme: {
        required: false,
        value: '',
        type: 'select',
      },
      actionFields: {
        required: false,
        value: [],
        type: 'multiselect',
      },
    },
    type: 'object',
  },
  interactions: {
    required: false,
    value: {
      comment: {
        required: false,
        value: false,
        type: 'boolean',
      },
      vote: {
        required: false,
        value: false,
        type: 'boolean',
      },
      like: {
        required: false,
        value: false,
        type: 'boolean',
      },
      dislike: {
        required: false,
        value: false,
        type: 'boolean',
      },
      note: {
        required: false,
        value: false,
        type: 'boolean',
      },
    },
    type: 'object',
  },
}
