import { io } from '@/api'
import {
  createInteractionApi,
  deleteInteractionApi,
  fetchInteractionsApi,
  updateInteractionApi,
} from '@/api/interactions'
import { db } from '../db'
import { Interaction, InteractionType, InteractionValue } from '../dbTypes'
import { queryInteractionById, queryLikes } from '../interactions/queries'
import { saveField } from '@/db/docs/mutations/updateDocsMutation.ts'

export async function fetchInteractions(docId: string, type: InteractionType) {
  const interactions = await fetchInteractionsApi({ _id: docId, type })
  db.ws.interactions.bulkPut(interactions)
}

export interface CreateInteractionPL<V> {
  type: InteractionType
  value: V
  refId?: string
}

export async function createInteraction<V = InteractionValue>(
  docId: string,
  payload: CreateInteractionPL<V>,
) {
  try {
    const interaction: Interaction = await createInteractionApi({
      _partId: docId,
      _type: payload.type,
      _refId: payload.refId,
      value: payload.value,
    })

    await db.ws.interactions.add(interaction)

    const likes = await queryLikes(docId)

    await saveField(docId, { name: 'likes', value: likes ?? 0 })

    return interaction as Interaction<V>
  } catch (error) {
    window.Rollbar.error(error as Error)
  }
}

export async function updateInteraction<V = InteractionValue>(
  docId: string,
  id: string,
  changes: Interaction<V>,
) {
  const interaction = await queryInteractionById(id)
  if (!interaction) return

  try {
    db.ws.interactions.update(id, changes)
    return await updateInteractionApi({ _documentId: docId, _id: id, value: changes.value })
  } catch (error) {
    db.ws.interactions.update(id, interaction)
    window.Rollbar.error(error as Error)
  }
}

export async function deleteInteraction(interaction: Interaction) {
  try {
    await db.ws.interactions.delete(interaction._id)
    await deleteInteractionApi({ _documentId: interaction._partId, _id: interaction._id })

    const likes = await queryLikes(interaction._partId)

    await saveField(interaction._partId, { name: 'likes', value: likes ?? 0 })

    return true
  } catch (error) {
    db.ws.interactions.put(interaction)
    window.Rollbar.error(error as Error)
    return false
  }
}

export function listenInteractions() {
  io.on('mergeCreateDocumentInteraction', async (interaction: Interaction) => {
    db.ws.interactions.add(interaction)
  })
  io.on('mergeRemoveDocumentInteraction', async (interaction: Interaction) => {
    db.ws.interactions.delete(interaction._id)
  })
}
