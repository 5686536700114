import { useMemo } from 'react'
import { AxisBottom } from '@visx/axis'
import { localPoint } from '@visx/event'
import { Group } from '@visx/group'
import { LegendOrdinal } from '@visx/legend'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { BarStack } from '@visx/shape'
import { useTooltip, useTooltipInPortal } from '@visx/tooltip'
import { addDays, eachDayOfInterval, format, max } from 'date-fns'
import { FieldContainer } from '@/schema'
import { useFieldContext } from '@/contexts'
import { StackBarChartInterface } from '@common/interfaces/fields/stack-bar-chart.interface.ts'
import { Icon } from '@/ui'

type TooltipData = {
  bar: any
  key: string
  index: number
  height: number
  width: number
  x: number
  y: number
  color: string
  operations: any
}

const LABEL_COLORS = {
  slt: '#F4538A',
  'r&d': '#74E291',
  'supply chain': '#59D5E0',
  marketing: '#FAA300',
  user: '#74E291',
  admin: '#FAA300',
  other: '#F5DD61',
}

const OPERATION_ICONS = {
  read: 'book',
  update: 'pen-line',
}

let tooltipTimeout: number

const height = 280
const margin = 20

const getDate = (d) => d.timestamp
const formatDate = (date: string) => format(new Date(date), 'MMM dd')

export const StackBarChart = ({
  activities,
}: {
  activities: Array<{ [key: string]: string | number }>
}) => {
  const { field } = useFieldContext<StackBarChartInterface>()

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useTooltip<TooltipData>()

  const { containerRef, TooltipInPortal, containerBounds } = useTooltipInPortal({
    scroll: true,
  })

  const { width } = containerBounds
  const yMax = height - margin
  const xMax = width

  const activitiesByLabel = useMemo(
    () =>
      activities.reduce(
        (arr: Array<{ [key: string]: { [key: string]: number } | string }>, activity) => {
          const obj = {}

          Object.entries(activity).forEach(([key, value]) => {
            if (key === 'timestamp') {
              obj['timestamp'] = value

              return
            }

            const labelAndOperation = key.split('_')

            const label = labelAndOperation[0]
            const operation = labelAndOperation[1]

            if (!obj[label]) {
              obj[label] = {
                [operation]: value,
              }
            } else {
              obj[label][operation] = value
            }
          })

          arr.push(obj)

          return arr
        },
        [],
      ),
    [activities],
  )

  const activitiesTotalByOperation = useMemo(
    () =>
      activities.reduce((obj: { [key: string]: number }, activity) => {
        Object.entries(activity).forEach(([key, value]) => {
          if (key === 'timestamp') {
            return
          }

          const operation = key.split('_')[1]

          if (operation === 'create' || operation === 'delete') {
            return
          }

          if (!obj[operation]) {
            obj[operation] = value as number
          } else {
            obj[operation] += value as number
          }
        })

        return obj
      }, {}),
    [activities],
  )

  const labels = useMemo(
    () =>
      activitiesByLabel.reduce((arr: string[], activity) => {
        Object.keys(activity).forEach((key) => {
          if (key !== 'timestamp' && !arr.includes(key)) {
            arr.push(key)
          }
        })

        return arr
      }, []),
    [activitiesByLabel],
  )

  const yAxisValues = useMemo(
    () =>
      activitiesByLabel.reduce((allTotals: number[], currentActivity) => {
        const totalActivityOperation = Object.entries(currentActivity).reduce(
          (dailyTotal, [key, value]) => {
            if (key !== 'timestamp') {
              Object.values(value).forEach((amount) => (dailyTotal += amount))
            }

            return dailyTotal
          },
          0,
        )

        allTotals.push(totalActivityOperation)
        return allTotals
      }, []),
    [activitiesByLabel],
  )

  const dateScaleDomain = useMemo(() => {
    if (!activitiesByLabel.length) {
      return []
    }

    if (activitiesByLabel.length >= 5) {
      return activitiesByLabel.map((a) => getDate(a))
    }

    const maxDateOfActivity = max(
      activitiesByLabel.map((activity) => new Date(activity.timestamp as string)),
    )

    const result = eachDayOfInterval({
      start: maxDateOfActivity,
      end: addDays(maxDateOfActivity, 4),
    })

    const existingDates = activitiesByLabel.map((activity) => getDate(activity))
    const newDates = result.map((r) => r.toISOString())

    return existingDates.concat(newDates)
  }, [activitiesByLabel])

  const dateScale = scaleBand({
    domain: dateScaleDomain,
    padding: 0.2,
    range: [0, xMax],
    round: true,
  })

  const activityScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [0, Math.max(...yAxisValues)],
        range: [yMax - margin, margin],
        nice: true,
      }),
    [yAxisValues, yMax, margin],
  )

  const colorRange = useMemo(() => labels.map((label) => LABEL_COLORS[label]), [labels])

  const colorScale = useMemo(
    () =>
      scaleOrdinal<string, string>({
        domain: labels,
        range: colorRange,
      }),
    [labels, colorRange],
  )

  const activitiesByCount = useMemo(
    () =>
      activitiesByLabel.reduce((arr: Array<{ [key: string]: string | number }>, activity) => {
        const obj = {}

        Object.entries(activity).forEach(([key, value]) => {
          if (key !== 'timestamp') {
            let sum = 0
            Object.values(value).forEach((v) => {
              sum += v
            })

            obj[key] = sum
          } else {
            obj['timestamp'] = value
          }
        })

        labels.forEach((l) => {
          if (!obj[l]) {
            obj[l] = 0
          }
        })

        arr.push(obj)

        return arr
      }, []),
    [activitiesByLabel, labels],
  )

  return (
    <FieldContainer>
      <div className="relative">
        <svg ref={containerRef} width="100%" height="100%" viewBox={`0 0 ${width} ${height}`}>
          <rect x={0} y={0} width="100%" height="100%" fill="#eaedff" rx={14} />

          <Group>
            <BarStack
              data={activitiesByCount}
              keys={labels}
              x={getDate}
              xScale={dateScale}
              yScale={activityScale}
              color={colorScale}
            >
              {(barStacks) =>
                barStacks.map((barStack) =>
                  barStack.bars.map((bar, index) => (
                    <rect
                      key={`bar-stack-${barStack.index}-${bar.index}`}
                      x={bar.x}
                      y={bar.y}
                      height={bar.height}
                      width={bar.width}
                      fill={bar.color}
                      onMouseLeave={() => {
                        tooltipTimeout = window.setTimeout(() => {
                          hideTooltip()
                        }, 300)
                      }}
                      onMouseMove={(event) => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout)
                        const eventSvgCoords = localPoint(event)
                        const left = bar.x + bar.width / 2
                        const operations = activitiesByLabel[index][bar.key]

                        showTooltip({
                          tooltipData: {
                            ...bar,
                            operations,
                          },
                          tooltipTop: eventSvgCoords?.y,
                          tooltipLeft: left,
                        })
                      }}
                    />
                  )),
                )
              }
            </BarStack>
          </Group>

          <AxisBottom
            top={yMax - margin}
            scale={dateScale}
            tickFormat={formatDate}
            stroke="#808080"
            hideTicks
            tickLabelProps={{
              fill: '#808080',
              fontSize: 14,
              textAnchor: 'middle',
            }}
            numTicks={2}
          />
        </svg>

        <div>
          <LegendOrdinal
            scale={colorScale}
            direction="row"
            labelMargin="0"
            shapeMargin="0 2px 0 0"
            itemMargin="0 4px"
            className="flex-wrap justify-center gap-1 py-2"
          />
        </div>

        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeft}
            className="bg-neutral900 min-w-[60px] text-white"
          >
            <div style={{ color: colorScale(tooltipData.key) }} className="pb-1">
              <strong>{tooltipData.key}</strong>
            </div>
            <div>
              {Object.entries(tooltipData.operations).map(([key, value]) => (
                <p key={key}>{`${key} ${value as number}`}</p>
              ))}
            </div>
            <small>{formatDate(getDate(tooltipData.bar.data))}</small>
          </TooltipInPortal>
        )}

        {field?.showTotal && (
          <div className="flex flex-col gap-4 pt-3">
            <h4 className="text-subtitle">Total Activities</h4>

            {Object.entries(activitiesTotalByOperation).map(([key, value]) => (
              <div key={key} className="flex justify-between items-center">
                <p className="font-semibold capitalize flex items-center gap-2">
                  <Icon name={OPERATION_ICONS[key]} />

                  {key}
                </p>
                <span className="font-semibold">{value}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </FieldContainer>
  )
}
