import { useLiveQuery } from 'dexie-react-hooks'
import { CardElementsProps } from '../CardElements/cardElements'
import { Tooltip, Avatar } from '@/ui'
import { queryDocsByType } from '@/db/docs/queries'
import { getField } from '@/utils/fields'
import { Doc } from '@/db'
import { useMemo } from 'react'

export const Collaborators = ({ docItem }: CardElementsProps) => {
  const value = getField<string[]>('collaborate', docItem?.fields)

  const allUsers = useLiveQuery(() => queryDocsByType('User'), [])

  const users = useMemo(() => {
    const shared: Doc[] = []
    allUsers?.forEach((user) => {
      if (value?.includes(user._userId) || user.userId === docItem?._userId) {
        shared.push(user)
      }
    })

    return { shared }
  }, [allUsers, value])

  if (users?.shared && users?.shared.length < 2) return null

  if (users?.shared && users?.shared.length > 7) {
    const newShared = [...users.shared]
    newShared.length = 7
    return (
      <div className="flex w-full items-center h-9">
        {newShared.map((user) => (
          <Tooltip key={user._id} content={user?.title}>
            <div className="flex gap-x-1.5 items-center">
              <Avatar
                key={user._id}
                avatarGroupStyles="-mr-2 hover:z-10"
                className="hover:h-9"
                user={user}
              />
            </div>
          </Tooltip>
        ))}
        <div className={'font-semibold text-sm capitalize -mr-2 z-30'}>
          <div
            className={
              'flex items-center justify-center text-neutral700 leading-none bg-neutral400 h-[2rem] w-[2rem] rounded-full'
            }
          >
            +{users?.shared.length}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex w-auto mr-1 items-center h-9">
      {users?.shared.map((user) => (
        <Tooltip key={user._id} content={user?.title}>
          <div className="flex gap-x-1.5 items-center -mr-2">
            <Avatar
              key={user._id}
              avatarGroupStyles="hover:z-10"
              className="hover:h-9"
              user={user}
            />
          </div>
        </Tooltip>
      ))}
    </div>
  )
}
