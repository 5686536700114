import { FormProvider, useForm } from 'react-hook-form'
import { DocumentType, SomeField } from '@/db'
import { Button } from '@/ui'
import { SchemaTemplate } from '../../types'
import { FieldProperty } from '../FieldProperty'

type SchemaFormProps = {
  template: SchemaTemplate
  schema: DocumentType | SomeField | null
  isField?: boolean
  onSubmit: (data: any) => void
  updateSchema: (data: any) => void
}

export const SchemaForm = ({ template, schema, onSubmit, updateSchema }: SchemaFormProps) => {
  const methods = useForm({
    defaultValues: {
      ...schema,
    },
  })

  const { isValid, isDirty } = methods.formState

  if (!template) {
    return null
  }

  const saveSchema = () => {
    const values = methods.getValues()

    updateSchema(values)
  }

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-wrap gap-5 pb-12">
          {Object.entries(template).map(([key, { ...property }]) => (
            <FieldProperty key={key} propertyName={key} templateProperty={property} />
          ))}
        </div>

        <Button type="submit" className="mb-4">
          Update schema locally
        </Button>
        <Button type="button" disabled={!isDirty || !isValid} onClick={saveSchema}>
          Update schema on server
        </Button>
      </form>
    </FormProvider>
  )
}
