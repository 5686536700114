import { createSelector } from 'reselect'
import { RootState } from '@/store'

export const selectSessionIds = createSelector(
  (state: RootState) => state.session.clientId,
  (state: RootState) => state.session.workspaceId,
  (state: RootState) => state.session.userId,
  (clientId, workspaceId, userId) => ({ clientId, workspaceId, userId }),
)

export const selectSessionStatus = createSelector(
  (state: RootState) => state.session.status,
  (state: RootState) => state.session.connection,
  (status, connection) => ({ status, connection }),
)
