import clsx from 'clsx'
import { HtmlParser } from '@/features/rte'
import { Icon, Preview } from '@/ui'
import { showUntitledText } from '@/utils/parseString'
import { CardProps } from '../cardsTypes'
import { CardDocType } from '../components/CardDocType'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'
import {
  cardElements,
  CardElementsProps,
} from '@/schema/cards/components/CardElements/cardElements.ts'
import { createElement } from 'react'

export const InfoCard = ({ doc, docSchema, children, headerElements, setImage }: CardProps) => {
  const { field } = useFieldContext<ChildrenFieldType>()
  const hideDescription = field.layout?.card?.hideDescription
  const extraElements = field.layout?.card?.extraElements
  const styles = field?.layout?.card?.styles
  const secondaryStyles = field?.layout?.card?.secondaryStyles
  const fontSize = field.layout?.card?.fontSize
  const fontColor = field.layout?.card?.fontColor

  const backgroundColor = field.layout?.card?.backgroundColor ?? ''

  return (
    <div
      className={clsx('relative flex flex-col h-full rounded shadow-lg overflow-hidden')}
      style={{ backgroundColor: backgroundColor ? (backgroundColor as string) : '#338DCD' }}
    >
      <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
      <div className={clsx('h-[280px]')} style={styles}>
        <Preview
          fit="cover"
          className="w-full h-full aspect-[4/3]"
          styles={secondaryStyles}
          image={doc.fields.image}
          iconSize={36}
          onClick={(e) => setImage?.(e, doc.fields.image)}
        />
      </div>

      <div className="flex flex-col grow gap-4 p-5">
        {extraElements && (
          <div className="flex items-center gap-4" style={field?.layout?.card?.extraElementsStyles}>
            {extraElements.map((componentName) => {
              const component = cardElements[componentName]

              if (!component) {
                return null
              }

              return createElement<CardElementsProps>(component, {
                key: componentName,
                docItem: doc,
                docSchema,
              })
            })}
          </div>
        )}

        <div style={{ color: fontColor ?? '#FFF' }}>
          <CardDocType hide={false} type={docSchema?.niceName} className="pb-1.5" />
          <h2
            title={doc.title}
            className={clsx('text-heading2 text-neutral900 break-words line-clamp-3')}
            style={{ fontSize, color: fontColor ?? '#FFF' }}
          >
            {showUntitledText(doc.title)}
          </h2>
        </div>

        {doc.fields?.description && !hideDescription && (
          <div className="grow">
            <HtmlParser
              styles={{ color: fontColor ?? '#FFF' }}
              className="prose text-caption text-neutral700 text-break whitespace-pre-line line-clamp-3 [&>ul]:list-disc [&>ul]:pl-3"
            >
              {doc.fields.description}
            </HtmlParser>
          </div>
        )}
      </div>
      <div className="flex items-center justify-between px-5 pb-5">
        <div className="w-full">{children}</div>
        <div>
          <div className="bg-[#FFCF02] rotate-45 flex items-center justify-center w-10 h-10 rounded-full">
            <Icon name="arrow-right" />
          </div>
        </div>
      </div>
    </div>
  )
}
