import { formatDistance, subDays } from 'date-fns'
import { CardElementsProps } from '../CardElements/cardElements'
import { useMemo } from 'react'

export const DateBadge = ({ date, docItem }: CardElementsProps) => {
  const dateFormatted = useMemo(() => {
    if (!date || !docItem) return null

    return formatDistance(subDays(new Date(docItem._addedOn), 0), new Date(), {
      addSuffix: true,
    })
  }, [date, docItem?._addedOn])

  if (!dateFormatted) return null

  return (
    <div className="flex gap-x-1.5 items-center">
      <span className="text-xs text-neutral700 line-clamp-1">{dateFormatted}</span>
    </div>
  )
}
