import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { FieldCodeEditor } from '@/features/schemaEditor/components/FieldCodeEditor'
import { FieldColor } from '@/features/schemaEditor/components/FieldColor'
import { FieldIcon } from '@/features/schemaEditor/components/FieldIcon'
import { FieldTemplateProperty, SchemaTemplate } from '../types'
import { FieldArray } from './FieldArray'
import { FieldCondition } from './FieldCondition'
import { FieldMultiSelect } from './FieldMultiSelect'
import { FieldSelect } from './FieldSelect'

type FieldPropertyProps = {
  propertyName: string
  templateProperty: FieldTemplateProperty
  nestedStructure?: string
  ancestor?: string | undefined
}

export const FieldProperty: FC<FieldPropertyProps> = ({
  propertyName,
  templateProperty,
  nestedStructure,
  ancestor,
}) => {
  const { register } = useFormContext()

  let structureInSchema = propertyName

  if (nestedStructure) {
    structureInSchema = `${nestedStructure}.${propertyName}`
  }

  switch (templateProperty.type) {
    case 'text':
    case 'number': {
      return (
        <div className="form-control w-full max-w-[300px]">
          <label className="label">
            <span className="label-text capitalize font-bold">{propertyName}</span>
          </label>
          <input
            type={templateProperty.type}
            placeholder="Type here"
            className="text-base input input-bordered w-full h-11"
            {...register(structureInSchema, { required: templateProperty.required })}
          />
        </div>
      )
    }
    case 'boolean': {
      return (
        <div className="form-control">
          <label className="cursor-pointer label justify-start flex-col p-0">
            <span className="label-text capitalize font-bold px-1 py-2">{propertyName}</span>
            <input
              type="checkbox"
              className="toggle toggle-primary"
              {...register(structureInSchema, { required: templateProperty.required })}
            />
          </label>
        </div>
      )
    }
    case 'multiselect': {
      return (
        <FieldMultiSelect
          propertyName={propertyName}
          templateProperty={templateProperty}
          nestedStructure={structureInSchema}
        />
      )
    }
    case 'select': {
      return (
        <FieldSelect
          propertyName={propertyName}
          templateProperty={templateProperty}
          nestedStructure={structureInSchema}
          ancestor={ancestor}
        />
      )
    }
    case 'condition': {
      return (
        <FieldCondition
          propertyName={propertyName}
          templateProperty={templateProperty}
          nestedStructure={structureInSchema}
        />
      )
    }
    case 'array': {
      return (
        <FieldArray
          propertyName={propertyName}
          nestedStructure={structureInSchema}
          templateProperty={templateProperty}
        />
      )
    }
    case 'code': {
      return (
        <FieldCodeEditor
          propertyName={propertyName}
          templateProperty={templateProperty}
          nestedStructure={structureInSchema}
        />
      )
    }
    case 'color': {
      return (
        <FieldColor
          propertyName={propertyName}
          templateProperty={templateProperty}
          nestedStructure={structureInSchema}
        />
      )
    }
    case 'icon': {
      return (
        <FieldIcon
          propertyName={propertyName}
          templateProperty={templateProperty}
          nestedStructure={structureInSchema}
        />
      )
    }
    case 'object': {
      const nestedProperties: SchemaTemplate = {
        ...(templateProperty.value as any),
      }

      return (
        <div className="w-full">
          <span className="label-text capitalize font-bold">{propertyName}</span>

          <div className="flex flex-wrap gap-5">
            {Object.entries(nestedProperties).map(([key, value]) => (
              <FieldProperty
                key={key}
                propertyName={key}
                templateProperty={value}
                nestedStructure={structureInSchema}
                ancestor={propertyName}
              />
            ))}
          </div>
        </div>
      )
    }
    default:
      return null
  }
}
