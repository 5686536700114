import { QueryEntry2, QueryFieldType } from '@common/interfaces/fields/query-field.interface'
import { useFieldContext } from '@/contexts'
import { useQueryEntry } from '@/hooks'
import { useGlobalFilter } from '@/hooks/useGlobalFilter'
import { DocsList } from '@/schema/cards'
import { FieldComponent } from '../../fieldType'

export const QueryDefaultView: FieldComponent = () => {
  const { field } = useFieldContext<QueryFieldType>()
  const { docs } = useQueryEntry(field.query)
  const filteredDocs = useGlobalFilter(docs, field.query as QueryEntry2, field.enableGlobalFilter)

  return <DocsList type="query" docs={filteredDocs} />
}
