import { useMemo } from 'react'
import { DocumentType, SomeField } from '@/db'
import { useSlr } from '@/store'
import { selectAccount } from '@/store/account'
import { Areas } from '../components/AreaRenderer'

const noSchema: Partial<Record<Areas, Set<string>>> = {
  heading: new Set(['title', 'image', 'subtitle', 'authorDate', 'description']),
}

export function useAreas(schema: DocumentType) {
  const account = useSlr(selectAccount)
  return useMemo(() => {
    const areaMap = new Map<Areas | string, SomeField[]>()

    function setArea(key: string, value: SomeField) {
      const area = areaMap.get(key)
      if (area) {
        area.push(value)
      } else {
        areaMap.set(key, [value])
      }
    }

    setArea('advanced', {
      name: 'secondaryId',
      niceName: 'Secondary ID',
      type: 'text',
      viewComponent: 'text',
      placeholder: 'Enter ID',
      area: 'advanced',
      condition: {
        hiddenEdit: account._relationType !== 'admin',
      },
    })

    schema.fields.forEach((field) => {
      if (field.area) {
        setArea(field.area, field)
      } else if (noSchema.heading?.has(field.name)) {
        setArea('heading', field)
      } else {
        setArea('default', field)
      }
    })

    return areaMap
  }, [schema])
}
