import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import { TextFieldType } from '@common/interfaces/fields/text-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { useAutoSizeTextArea } from '@/hooks'
import { FieldContainer } from '@/schema'

export const IFrameEdit = () => {
  const { value = '', saveValue, field } = useFieldContext<TextFieldType, string>()
  const [text, setText] = useState(value)
  const { schema } = useDocContext()
  const textAreaRef = useAutoSizeTextArea(value)

  const titleChangeHandler = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value)
    sendChangeDebounced(event.target.value)
  }, [])

  const sendChangeDebounced = useMemo(
    () =>
      debounce((val: string) => {
        saveValue(val)
      }, 1000),
    [],
  )

  return (
    <FieldContainer>
      {field.name === 'title' && <p className="doc-type">{schema.niceName}</p>}
      <textarea
        name="title"
        placeholder={field.placeholder ?? 'Enter the path to the video'}
        className={clsx(
          'outline-none w-full resize-none',
          field.name === 'title' ? 'text-title' : 'text-regular',
        )}
        ref={textAreaRef}
        value={text}
        onChange={titleChangeHandler}
      />
    </FieldContainer>
  )
}
