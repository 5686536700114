import { useMemo } from 'react'
import clsx from 'clsx'
import { BannerFieldType } from '@common/interfaces/fields/area-fields.interace'
import { useDocContext, useFieldContext } from '@/contexts'
import { HtmlParser } from '@/features/rte'
import { AreaRenderer } from '@/schema'
import { FieldComponent } from '../fieldType'
import { Cover } from '../image'
import css from './banner.module.css'

export const BannerField: FieldComponent = () => {
  const { doc, schema } = useDocContext()
  const { field, isEditable } = useFieldContext<BannerFieldType>()
  const description = schema.fields.find((item) => item.name === 'description')
  const classes = useMemo(
    () => field.layout?.classes?.map((cls) => css[cls] ?? '').join(' '),
    [field],
  )
  const { color, fontSize, lineHeight, backgroundColor, ...containerStyles } = field.styles ?? {}

  const isFull = classes?.includes('full')

  if (isEditable) {
    return (
      <>
        <AreaRenderer areaName="links" />
        <AreaRenderer areaName="contentFooter" />
      </>
    )
  }

  return (
    <div
      className={clsx('field-theme', css.info, classes)}
      style={{ ...containerStyles, backgroundColor }}
    >
      <div className="absolute right-4 top-4 flex gap-4">
        <AreaRenderer areaName="badges" />
      </div>
      <div className={css.image}>
        <Cover
          image={doc.fields.image}
          className="relative w-full rounded-lg h-full"
          classes={classes}
        >
          <div
            className={clsx('absolute left-0 top-0 w-full h-full rounded-lg', classes)}
            style={containerStyles}
          />
        </Cover>
      </div>
      <div className={clsx(isFull && css.content)}>
        {!isFull && doc._type !== 'WORKSPACE' && !schema.hideType && (
          <p className="doc-type mb-5 max-md:mb-3">{schema.niceName}</p>
        )}
        <h1 className="text-heading mb-5 max-md:mb-3" style={{ color, fontSize, lineHeight }}>
          {doc.title}
        </h1>

        <div style={field.contentStyles}>
          {doc.fields?.description && !description?.area && (
            <HtmlParser className="prose prose-sm leading-normal pb-5 [&>ul]:list-disc [&>ul]:pl-3 break-words">
              {doc.fields.description}
            </HtmlParser>
          )}

          <AreaRenderer
            areaName="links"
            className="flex gap-4 [&>div]:!p-0 [&>div]:!bg-transparent [&>div]:w-auto"
          />
        </div>

        <AreaRenderer
          areaName="contentFooter"
          className="[&>div]:!p-0 [&>div]:!bg-transparent pt-2"
          style={{ paddingLeft: field?.contentStyles?.padding }}
        />
      </div>
    </div>
  )
}
