import { Input } from '@nextui-org/react'
import { useDidUpdateEffect, useSearch } from '@/hooks'
import { useFilterState } from '@/contexts/filter'
import { Icon } from '@/ui'

export const FilterSearch = () => {
  const { value, search, handleSearch } = useSearch()
  const { updateFilter } = useFilterState()

  useDidUpdateEffect(() => {
    updateFilter('title', search)
  }, [search])

  return (
    <Input
      type="search"
      value={value}
      onChange={handleSearch}
      placeholder="Search"
      startContent={<Icon name="search" size={16} />}
      className="max-w-60"
    />
  )
}
