import { useState } from 'react'
import { CardProps } from '../cardsTypes'
import { Icon } from '@/ui'
import { IconName } from '@/ui/Icon'

export const MiniCard = ({ doc }: CardProps) => {
  const [hovered, setHovered] = useState(false)
  const icon = doc.fields.icon

  return (
    <div
      className="bg-white h-full min-h-[34px] rounded-md p-1 border border-neutral300 flex items-center gap-[6px] shadow-md"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={
        hovered
          ? {
              scale: '1.01',
              transition: 'all 0.3s ease',
              borderColor: '#d2d2d2',
            }
          : {
              borderColor: '#efefef',
              scale: '1',
              transition: 'all 0.3s ease',
            }
      }
    >
      {icon && (
        <div className="shrink-0">
          <Icon name={icon as IconName} size={16} />
        </div>
      )}

      <p className="grow text-left text-xs">{doc.title}</p>
    </div>
  )
}
