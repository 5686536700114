import clsx from 'clsx'
import { useLiveQuery } from 'dexie-react-hooks'
import { Doc } from '@/db'
import { queryDocChildren, queryHome } from '@/db/docs/queries'
import { QuickPanel } from '@/features/quickPanel'
import { usePath, useToggle } from '@/hooks'
import { scm, selectDocSchema, useSchemaState } from '@/contexts/schema'
import { useSlr } from '@/store'
import { selectAccount } from '@/store/account'
import { IconName } from '@/ui/Icon'
import { SidebarGroups } from './components/SidebarGroups'
import { SidebarLink } from './components/SideBarLink'
import css from './Sidebar.module.css'
import { WORKSPACE_DOCUMENT_TYPE_NAME } from '@common/interfaces/clients/document-type.interface'
import { sortDocs } from '@/utils/docsSort'

export type SidebarGroup = {
  name: string
  docs: Array<Doc | { name: string; docs: Doc[]; _id: string; sortOrderInMenu: number | undefined }>
}

export const Sidebar = () => {
  const { workspacePath, getDocPath } = usePath()
  const [isOpened, { off: closeSidebar }] = useToggle()
  const user = useSlr(selectAccount)
  const workspaceSchema = useSchemaState((state) =>
    selectDocSchema(state, WORKSPACE_DOCUMENT_TYPE_NAME),
  )
  const docTypes = useSchemaState((state) => state.docTypes)
  const backgroundColor = workspaceSchema?.layout?.bgColors?.leftMenu

  const query = useLiveQuery(async () => {
    const homeDoc = await queryHome()
    if (!homeDoc) return undefined

    const allSections = sortDocs(await queryDocChildren(homeDoc?._id))
    const sections: Doc[] = []
    const secondarySections: Doc[] = []

    const groups: Record<string, SidebarGroup> = {}

    scm.sortBySchema(allSections)?.forEach((entry) => {
      const docSchema = scm.getDocSchema(entry._type)
      if (!docSchema?.menuGroup) return

      if (docSchema.menuGroup === 'home') {
        sections.push(entry)
        return
      }
      if (docSchema.menuGroup === 'secondarySections') {
        secondarySections.push(entry)
        return
      }
      if (docSchema.menuGroup in groups) {
        if (docSchema.subMenuGroup) {
          const data = {
            name: docSchema.subMenuGroup,
            docs: [entry],
            _id: docSchema.subMenuGroup,
            sortOrderInMenu: docSchema.sortOrderInMenu,
          }
          const isExist = groups[docSchema.menuGroup].docs.findIndex(
            (item: any) => item.name === docSchema.subMenuGroup,
          )
          const docs = groups[docSchema.menuGroup].docs.find(
            (item: any) => item.name === docSchema.subMenuGroup,
          )

          if (isExist !== -1 && docs) {
            // @ts-expect-error: Unreachable code error
            const newData = { ...docs, docs: [...docs.docs, entry] }

            groups[docSchema.menuGroup].docs.splice(isExist, 1, newData)
          } else {
            groups[docSchema.menuGroup].docs.push(data)
          }
        } else {
          groups[docSchema.menuGroup].docs.push(entry)
        }
      } else {
        groups[docSchema.menuGroup] = {
          name: docSchema.menuGroup,
          docs: [entry],
        }
      }
    })

    return { homeDoc, sections, groups: Object.values(groups), secondarySections }
  }, [docTypes])

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div className={clsx({ [css.container]: isOpened })} onClick={closeSidebar}>
        <aside
          className={clsx(css.sidebar, { [css.open]: isOpened })}
          style={{ backgroundColor, scrollbarColor: backgroundColor }}
        >
          <nav className="pt-2 px-2 border-neutral200 py-0">
            <SidebarLink to={workspacePath} icon="home" end>
              Home
            </SidebarLink>
            <QuickPanel />
            {query?.sections
              .sort((a: any, b: any) => {
                const orderA =
                  (a.fields?.sortOrderInMenu || a.sortOrderInMenu) ?? query?.sections.length + 1
                const orderB =
                  (b.fields?.sortOrderInMenu || b.sortOrderInMenu) ?? query?.sections.length + 1

                if (orderA > orderB) return 1
                if (orderA < orderB) return -1
                return 0
              })
              .map((doc) => (
                <SidebarLink
                  key={doc._id}
                  to={getDocPath(doc)}
                  icon={(doc.fields.icon ?? scm.getDocSchema(doc._type)?.icon) as IconName}
                  color={doc.fields?.color as string}
                  doc={doc}
                  user={user}
                >
                  {doc.title}
                </SidebarLink>
              ))}
          </nav>
          <SidebarGroups groups={query?.groups} />
          <nav className="pt-2 px-2 border-t border-t-neutral200 py-0">
            {query?.secondarySections
              .sort((a: any, b: any) => {
                const orderA =
                  (a.fields?.sortOrderInMenu || a.sortOrderInMenu) ?? query?.sections.length + 1
                const orderB =
                  (b.fields?.sortOrderInMenu || b.sortOrderInMenu) ?? query?.sections.length + 1

                if (orderA > orderB) return 1
                if (orderA < orderB) return -1
                return 0
              })
              .map((doc) => (
                <SidebarLink
                  key={doc._id}
                  to={getDocPath(doc)}
                  icon={(doc.fields.icon ?? scm.getDocSchema(doc._type)?.icon) as IconName}
                  color={doc.fields?.color as string}
                  doc={doc}
                  user={user}
                >
                  {doc.title}
                </SidebarLink>
              ))}
          </nav>
        </aside>
      </div>
    </>
  )
}
