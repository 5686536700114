import debounce from 'lodash.debounce'
import { io } from '@/api'
import { IOEvents } from '@/api/socket/events'
import { Data, db } from '@/db'

export function sleep(time = 1000) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export async function parallel<R, T = any>(
  arr: T[],
  cb: (item: T, index: number) => Promise<R | undefined>,
  options?: { filtered: boolean; withResult?: boolean },
): Promise<R[]>

export async function parallel<R, T = any>(
  arr: T[],
  cb: (item: T, index: number) => Promise<R | undefined>,
  options?: { filtered: boolean; withResult: boolean },
): Promise<R[]>

export async function parallel<R, T = any>(
  arr: T[],
  cb: (item: T, index: number) => Promise<R | undefined>,
  options?: { filtered?: boolean; withResult?: boolean },
) {
  const { filtered = false, withResult = true } = options ?? {}
  const result: (R | undefined)[] = []
  await Promise.all(
    arr.map(async (el, i) => {
      const res = await cb(el, i)
      if (withResult) {
        if (filtered && res) result.push(res)
        else if (!filtered) result.push(res)
      }

      return Promise.resolve()
    }),
  )
  return withResult ? result : undefined
}

export async function sequentially<R = void, T = any>(
  arr: T[],
  cb: (item: T, index: number) => Promise<R>,
  withResult = true,
  delay?: number,
) {
  const result: R[] = []

  for (let i = 0; i < arr.length; i += 1) {
    const res = await cb(arr[i], i)
    if (withResult && res) result.push(res)
    if (delay) await sleep(delay)
  }

  return result
}

export async function sequentiallyChunked<R = void, T = any>(
  arr: T[],
  cb: (item: T, index: number) => Promise<R>,
  chunkSize = 5,
  chunkCb?: (chunk: R[], index: number) => Promise<void>,
  withResult = true,
) {
  const chunks = splitToChunk(arr, chunkSize)
  const result: R[] = []

  for (let i = 0; i < chunks.length; i += 1) {
    const res = await Promise.all<R>(chunks[i].map(cb))
    await chunkCb?.(res, i)
    if (withResult) result.push(...res)
  }

  return result
}

function splitToChunk(arr: any[], chunkSize: number) {
  const chunks: any[] = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize))
  }
  return chunks
}

export async function moreData<T, P = any>({
  initEvent,
  moreEvent,
  cb,
  payload,
}: {
  initEvent: IOEvents
  payload?: P
  moreEvent: IOEvents
  cb: (data: Data<T>) => any
}) {
  const finish = new Promise<void>((resolve) => {
    let isFinished = false
    let hasMore = false

    io.on(moreEvent, async (data: Data<T>) => {
      hasMore = true
      await cb(data)

      if (isFinished) resolve()
      else hasMore = false
    })

    io.on('moreFinish', () => {
      isFinished = true
      if (!hasMore) resolve()
    })
  })

  const data = await io.emit<Data<T>>(initEvent, {
    _workspaceId: db.activeWorkspace?._id,
    ...(payload && payload),
  })
  await cb(data)
  await finish
  io.off('moreFinish')
}

const promises: Record<string, Promise<any>> = {}
const deletes: Record<string, () => void> = {}
const fetchIds = new Set<string>()

export function singlePromise<T>(
  key: string,
  fetchFn: () => Promise<T>,
  options?: {
    onResolve?: (res: T) => void
    once?: boolean
  },
): Promise<T> {
  const { onResolve, once } = options ?? {}
  const pendingPromise = promises[key]

  if (once && fetchIds.has(key)) return pendingPromise

  if (pendingPromise) {
    pendingPromise.then((res) => {
      onResolve?.(res)
      deletes[key]?.()
    })

    return pendingPromise
  }

  deletes[key] = debounce(() => {
    delete promises[key]
    delete deletes[key]
  }, 300)

  promises[key] = new Promise((resolve) =>
    fetchFn().then((res) => {
      onResolve?.(res)
      deletes[key]?.()
      resolve(res)

      if (once) fetchIds.add(key)
    }),
  )

  return promises[key]
}
