import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AIRecommendationsInitialState {
  embeddingStatus: boolean
  recommendedDocIds: { [key: string]: string[] }
}

const initialState: AIRecommendationsInitialState = {
  embeddingStatus: false,
  recommendedDocIds: {},
}

const aiRecommendationsSlice = createSlice({
  name: 'ai-recommendations',
  initialState,
  reducers: {
    setEmbeddingStatus(state, action: PayloadAction<boolean>) {
      state.embeddingStatus = action.payload
    },
    setRecommendationDocIds(state, action) {
      const { documentId, recommendationDocIds } = action.payload

      state.recommendedDocIds[documentId] = recommendationDocIds
    },
  },
})

export const { setEmbeddingStatus, setRecommendationDocIds } = aiRecommendationsSlice.actions

export const { reducer } = aiRecommendationsSlice
