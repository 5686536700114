import { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react'
import { Button } from '@/ui'
import { useDocContext } from '@/contexts'
import { db } from '@/db'
import { PermissionsModal } from '@/features/permissions'
import { AdvancedArea } from '@/schema/areas/AdvancedArea'
import { Icon } from '@/ui'
import { usePath } from '@/hooks'

interface MoreMenuProps {
  isNotHome: boolean
  canEdit?: boolean
}

interface MenuItem {
  key: 'permissions' | 'advanced' | 'delete'
  label: string
  icon: 'lock' | 'settings-2' | 'trash'
  isAllowed: boolean
}

export const MoreMenu = ({ isNotHome, canEdit = false }: MoreMenuProps) => {
  const { doc, schema } = useDocContext()
  const [option, setOption] = useState<'advanced' | 'permissions' | null>(null)
  const { setModal } = usePath()

  const close = () => setOption(null)
  const isAdmin = db.activeAccount._relationType === 'admin'
  const showMenu = canEdit || isAdmin || (isNotHome && canEdit)

  const items: MenuItem[] = [
    {
      key: 'permissions',
      label: 'Permissions',
      icon: 'lock',
      isAllowed: canEdit,
    },
    {
      key: 'advanced',
      label: 'Advanced',
      icon: 'settings-2',
      isAllowed: isAdmin,
    },
    {
      key: 'delete',
      label: `Delete ${schema.niceName}`,
      icon: 'trash',
      isAllowed: isNotHome && canEdit,
    },
  ]

  const getItems = () => items.filter((item) => item.isAllowed)

  const dropdownItems: MenuItem[] = getItems()

  if (!showMenu) {
    return null
  }

  return (
    <>
      <Dropdown>
        <DropdownTrigger>
          <Button icon="ellipsis" variant="ghost" aria-label="open menu" />
        </DropdownTrigger>
        <DropdownMenu
          aria-label="Static Actions"
          items={dropdownItems}
          itemClasses={{
            base: ['data-[hover=true]:bg-primary', 'data-[hover=true]:bg-opacity-10'],
            title: ['text-regular', 'font-normal'],
          }}
        >
          {(item) => (
            <DropdownItem
              key={item.key}
              aria-label={`open modal for ${item.key} ${doc._type}`}
              onClick={() => {
                if (item.key === 'delete') {
                  setModal({ doc, type: 'delete' })
                } else {
                  setOption(item.key)
                }
              }}
              startContent={<Icon name={item.icon} size={16} />}
            >
              {item.label}
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
      {option === 'advanced' && <AdvancedArea onClose={close} />}
      {option === 'permissions' && <PermissionsModal onClose={close} />}
    </>
  )
}
