import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useLiveQuery } from 'dexie-react-hooks'
import { Table2DType } from '@common/interfaces/fields/table-field.interface'
import { useFieldContext } from '@/contexts'
import { queryMatchRelations } from '@/db/common/queries'
import { usePath } from '@/hooks'
import { useQueryEntry } from '@/hooks'
import { FieldContainer } from '@/schema'
import { Preview } from '@/ui'
import css from '../TableField.module.css'
import { toMap } from '@/utils/docsSort'

type Cell = { col: string; row: string; id: string; color: string; isPrimary: boolean }

function idToLine(id: string) {
  return id.replace('#', '')
}

export const TableMatch = () => {
  const { field } = useFieldContext<Table2DType>()
  const { docs: cols } = useQueryEntry(field.columns.query)
  const colMap = useMemo(() => toMap(cols, (col) => col._id), [cols])
  const { docs: rows } = useQueryEntry(field.rows?.query)
  const data = useLiveQuery(() => queryMatchRelations(cols, rows), [cols, rows])
  const { getDocPath } = usePath()

  const sortedData = useMemo(() => {
    const cells = new Map<string, Cell>()

    data?.forEach(async ({ colId, rowId, isPrimary }) => {
      const col = idToLine(colId)
      const row = idToLine(rowId)
      const cellId = `${col}_${row}`
      const color = (colMap.get(colId)?.fields.color as string) || '--p'

      cells.set(cellId, { col, row, id: cellId, color, isPrimary })
    })

    const emptyCellLen = cols.length * rows.length - cells.size

    const emptyCells = Array.from({ length: emptyCellLen }, (_, i) => (
      <div key={i} className=" bg-neutral50 rounded-md" />
    ))

    const dataCells = Array.from(cells.values())

    return { emptyCells, dataCells }
  }, [data, rows, cols, field])

  const gridStyles = useMemo(
    () => ({
      gridTemplateColumns: 'minmax(min-content, 200px) '.concat(
        cols.map((el) => `[${idToLine(el._id)}] auto`).join(' '),
      ),
      gridTemplateRows: 'auto '.concat(rows.map((el) => `[${idToLine(el._id)}] auto`).join(' ')),
    }),
    [cols, rows],
  )

  const { emptyCells, dataCells } = sortedData

  return (
    <FieldContainer className="grid gap-2 mb-10 overflow-x-auto rounded-md pt-2" style={gridStyles}>
      <div className="grid text-center p-4 place-items-center font-bold h-full rounded-md left-0 sticky z-[11] bg-white">
        {field.niceName}
      </div>
      {cols.map((item) => (
        <Link
          aria-label={`link to ${item.title}`}
          to={getDocPath(item)}
          key={item._id}
          className={css.col}
          style={{
            gridRowStart: 1,
            gridColumnStart: idToLine(item._id),
            backgroundColor: item.fields.color as string,
          }}
        >
          {item.title}
        </Link>
      ))}
      {rows.map((item) => (
        <Link
          aria-label={`link to ${item.title}`}
          to={getDocPath(item)}
          key={item._id}
          className={clsx(css.row, 'p-2')}
          style={{
            gridRowStart: idToLine(item._id),
            gridColumnStart: 1,
          }}
        >
          <div className="flex items-center w-full">
            <Preview image={item.fields.image} iconSize={30} className="h-8 w-10 rounded" />
            <div className="w-full text-center pl-0.5">{item.title}</div>
          </div>
        </Link>
      ))}
      {dataCells.map((cell) => (
        <div
          key={cell.id}
          className="rounded-md"
          style={{
            gridColumnStart: cell.col,
            gridRowStart: cell.row,
            backgroundColor: cell.color,
            opacity: cell.isPrimary ? 1 : 0.4,
          }}
        />
      ))}
      {emptyCells}
    </FieldContainer>
  )
}
