import { TableFieldType } from '@common/interfaces/fields/table-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { TableDefault } from '@/fields/table/components/TableList/TableDefault.tsx'
import { FieldComponent } from '../fieldType'
import { TableList } from './components/TableList'
import { TableMatch } from './components/TableMatch'
import { TableView } from './components/TableView'
import { Filter } from '@/features/filter'

export const TableField: FieldComponent = () => {
  const { doc } = useDocContext()
  const { field } = useFieldContext<TableFieldType>()

  const getTableComponent = () => {
    switch (field.viewComponent) {
      case 'match':
        return <TableMatch />
      case 'list':
        return <TableList />
      case 'default':
        return <TableDefault parentDoc={doc} />
      default:
        return <TableView />
    }
  }

  return <Filter>{getTableComponent()}</Filter>
}
