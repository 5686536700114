import { useFilterState } from '@/contexts/filter'
import { ChangeHandler, Select } from '@/ui'
import { FilterOptionProps } from '../Filter'
import { BooleanFieldType } from '@common/interfaces/fields/boolean-field.interface.ts'
import { useMemo } from 'react'

export const FilterBoolean = ({ filter, fieldSchema }: FilterOptionProps<BooleanFieldType>) => {
  const { updateFilter, removeFilter } = useFilterState()
  const niceName = fieldSchema.niceName
  const placeholderText = filter.placeholder ?? `Filter by ${niceName.toLowerCase()}`

  const handleChange: ChangeHandler<{ label: string; value: boolean }> = (option, actionMeta) => {
    const { action } = actionMeta
    if (action === 'clear' || option === null) {
      return removeFilter(filter.fieldName)
    }

    updateFilter(filter.fieldName, option.value)
  }

  const options = useMemo(
    () => [
      { label: niceName, value: true },
      { label: `Not ${niceName}`, value: false },
    ],
    [niceName],
  )

  return (
    <Select<{ label: string; value: boolean }>
      placeholder={placeholderText}
      isClearable
      options={options}
      isSearchable={false}
      onChange={handleChange}
    />
  )
}
