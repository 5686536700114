import { useMemo } from 'react'
import clsx from 'clsx'
import { useDocContext } from '@/contexts'
import { CommentSection } from '@/features/comments'
import { useToggle } from '@/hooks'
import { Button } from '@/ui'

import { AreaRenderer } from '../AreaRenderer'
import { RighBarActions } from './RightBarActions'
import { scm, selectDocSchema, useSchemaState } from '@/contexts/schema'
import { WORKSPACE_DOCUMENT_TYPE_NAME } from '@common/interfaces/clients/document-type.interface'

export const RightBar = () => {
  const [isOpened, { on: openRightbar, off: closeRightbar }] = useToggle()
  const { doc, schema } = useDocContext()
  const workspaceSchema = useSchemaState((state) =>
    selectDocSchema(state, WORKSPACE_DOCUMENT_TYPE_NAME),
  )
  const schemaFields = useSchemaState((state) => state.fields)
  const backgroundColor = workspaceSchema?.layout?.bgColors?.rightBar
  const showSkeleton = useMemo(
    () =>
      !Object.keys(doc.fields).some((fieldName) => {
        const fieldSchema = scm.getFieldSchema(doc._type, fieldName)
        if (fieldSchema?.area !== 'right-sidebar') {
          return false
        }

        const value = doc.fields[fieldName]
        if (Array.isArray(value) && !value.length) {
          return false
        }

        return !!value
      }),
    [workspaceSchema, schemaFields],
  )

  return (
    <>
      <Button
        variant="outline"
        icon="chevrons-left"
        className="rounded-l-md rounded-r-none hidden z-10 max-lg:flex max-lg:fixed max-lg:right-0 max-lg:top-20"
        onClick={openRightbar}
      />

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        onClick={closeRightbar}
        className={clsx({
          'max-lg:fixed max-lg:top-0 max-lg:right-0 max-lg:bottom-0 max-lg:left-0 z-10': isOpened,
        })}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          onClick={(e) => e.stopPropagation()}
          className={clsx(
            'min-h-full relative w-[280px] border-l border-neutral200 transition-[transform] ease-out duration-[400ms] max-[350px]:w-[300px] max-lg:fixed max-lg:top-0 max-lg:bottom-0 max-lg:right-[-316px] max-lg:z-10 max-lg:overflow-y-auto',
            { 'translate-x-[-316px]': isOpened },
          )}
          style={{ backgroundColor: backgroundColor ?? '#fff' }}
        >
          <RighBarActions className="[&>div]:pr-5 border-b border-neutral200 h-11 px-4 justify-end" />
          <div className="grid gap-8 px-4 py-7">
            <AreaRenderer showSkeleton={showSkeleton} areaName="right-sidebar" />

            {schema.interactions?.comment && schema.interactionsArea !== 'main' && (
              <CommentSection />
            )}
          </div>

          <Button
            variant="outline"
            icon="chevrons-right"
            className="rounded-r-md rounded-l-none hidden max-lg:flex max-lg:absolute max-lg:left-0 max-lg:bottom-5"
            onClick={closeRightbar}
          />
        </div>
      </div>
    </>
  )
}
