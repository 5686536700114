import { PropsWithChildren, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useSearchParams } from 'react-router-dom'
import clsx from 'clsx'
import { useToggle } from '@/hooks'
import { Button } from '@/ui'
import css from '../../SchemaEditor.module.css'
import { BranchProps } from '../../types'
import { AddFieldModal } from './AddFieldModal.tsx'
import { BranchTree } from './BranchTree'

export const Branch = (props: PropsWithChildren<BranchProps>) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { branch, level, moveBranch, tree, parent, index } = props
  const [isExpanded, { toggle: toggleTree }] = useToggle(true)
  const ref = useRef<HTMLDivElement>(null)

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'branch',
    item: props,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const [, drop] = useDrop<BranchProps, void>(() => ({
    accept: 'branch',
    hover(dragged: BranchProps) {
      if (!tree || !ref.current) return
      if (dragged.level !== level) return
      if (dragged.branch.name === branch.name) return

      if (dragged.index === index) return
      if (dragged.index === -1 || index === -1) return

      // const hoverBoundingRect = ref.current.getBoundingClientRect()
      // const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // const clientOffset = monitor.getClientOffset()
      // if (!clientOffset) return

      // const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // if (dragged.index < index && hoverClientY < hoverMiddleY) return
      // if (dragged.index > index && hoverClientY > hoverMiddleY) return

      moveBranch?.(dragged.index, index)
    },
  }))

  const isActivePage =
    searchParams.get('field') === branch.name && parent?.name === searchParams.get('doc')

  const openBranch = (isNew = false, fieldType?: string) => {
    const params: Record<string, string> = {
      doc: branch.name,
    }

    if (isNew) {
      if (searchParams.has('new') && isActivePage) return
      params.new = isNew.toString()
    }

    if (branch.isField && parent?.name) {
      params.doc = parent.name
      params.field = branch.name
    }

    if (isNew && fieldType) {
      params.doc = branch.name
      params.fieldType = fieldType
    }

    setSearchParams(params)
  }

  if (level !== 0) {
    drag(drop(ref))
  }

  return (
    <>
      <div className={clsx(css.branchRow, isDragging && 'opacity-0')} ref={ref}>
        {branch.children && (
          <Button
            icon={isExpanded ? 'chevron-down' : 'chevron-right'}
            disabled={!branch.children?.length}
            onClick={toggleTree}
          />
        )}

        <Button
          variant={isActivePage ? 'default' : 'ghost'}
          className={clsx(
            'w-full justify-start',
            isActivePage ? 'font-bold' : 'font-normal',
            branch.isField && 'text-sm',
          )}
          onClick={() => openBranch(false)}
        >
          {branch.name}
        </Button>

        {branch.isField && branch.children && (
          <Button icon="plus" className={css.addBtn} onClick={() => openBranch(true)} />
        )}

        {branch.children && !branch.isField && <AddFieldModal onSubmit={openBranch} />}
      </div>
      {isExpanded && !isDragging && <BranchTree branch={branch} level={level + 1} index={0} />}
    </>
  )
}
