import { useState } from 'react'
import { queryDoc, queryDocByType, queryDocsByType } from '@/db/docs/queries'
import { scm } from '@/contexts/schema'
import { Button } from '@/ui'
import { sequentially } from '@/utils'
import { changeParent } from '@/db/docs/sync'
import { createManyRelation } from '@/db/relations/sync'
import { DataLinkParams, RelationType } from '@/db'

export const MoveIdeas = () => {
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    const ideas = await queryDocsByType('Idea')
    if (!ideas?.length) {
      window.Rollbar.error('No ideas')
      return
    }

    setLoading(true)
    const schema = scm.getDocSchema(ideas[0]._type)
    const ideaFinder = await queryDocByType('IdeaFinder')
    if (!schema || !ideaFinder) {
      window.Rollbar.error('No schema or idea finder')
      return
    }

    await sequentially(ideas, async (doc) => {
      const challengeDoc = await queryDoc({ _id: doc._parentId })
      const categoryDoc = await queryDoc({ _id: doc._ancestors[1] ?? '' })
      if (challengeDoc?._id === ideaFinder._id) return

      await changeParent({ doc, parentSlug: ideaFinder._slug, isHome: false })

      if (!challengeDoc || !categoryDoc) {
        window.Rollbar.error('No challenge or category doc')
        return
      }
      const challengeLinkField = scm.getFieldSchema<DataLinkParams>(doc._type, 'challenges')
      const categoryLinkField = scm.getFieldSchema<DataLinkParams>(doc._type, 'categories')
      if (!challengeLinkField?.reverseName || !categoryLinkField?.reverseName) {
        window.Rollbar.error('No challenge or category link field')
        return
      }

      await createManyRelation([
        {
          fromId: doc._id,
          fromDocType: doc._type,
          toId: challengeDoc._id,
          toDocType: challengeDoc._type,
          fieldName: challengeLinkField.name,
          relationType: challengeLinkField.type as RelationType,
          reverseFieldName: challengeLinkField.reverseName,
        },
        {
          fromId: doc._id,
          fromDocType: doc._type,
          toId: categoryDoc._id,
          toDocType: categoryDoc._type,
          fieldName: categoryLinkField.name,
          relationType: categoryLinkField.type as RelationType,
          reverseFieldName: categoryLinkField.reverseName,
        },
      ])
    })

    setLoading(false)
  }

  return (
    <Button variant="ghost" className="max-[400px]:w-full" loading={loading} onClick={onClick}>
      Move ideas
    </Button>
  )
}
