import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useLiveQuery } from 'dexie-react-hooks'
import { queryDoc, queryHome } from '@/db/docs/queries'
import { selectDocSchema, useSchemaState } from '@/contexts/schema'
import { useAppDispatch, useSlr } from '@/store'
import { selectIsEditMode, setActiveDocId } from '@/store/docs'

export const useActiveDoc = (onlyHome = false) => {
  const { slug } = useParams()
  const dispatch = useAppDispatch()
  const isEditMode = useSlr(selectIsEditMode)

  const activeDoc = useLiveQuery(async () => {
    try {
      const doc = await (slug && !onlyHome ? queryDoc({ _slug: slug }) : queryHome())
      if (!doc) return undefined

      return doc
    } catch (e) {
      window.Rollbar.error(e as Error)
      return undefined
    }
  }, [slug, isEditMode])

  const docSchema = useSchemaState((state) => selectDocSchema(state, activeDoc?._type))

  useEffect(() => {
    if (activeDoc) {
      dispatch(setActiveDocId(activeDoc._id))
    }
  }, [activeDoc?._id])

  return {
    activeDoc,
    schema: docSchema,
    isEditMode,
  }
}
