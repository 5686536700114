import { useState } from 'react'
import {
  DiagramFieldData,
  DiagramFieldType,
} from '@common/interfaces/fields/diagram-field.interface'
import { useFieldContext } from '@/contexts'
import { useDebounce, useDidUpdateEffect } from '@/hooks'

export const DiagramSettings = () => {
  const { field, value, saveValue } = useFieldContext<DiagramFieldType, DiagramFieldData>()
  const [settingsJson, setSettingsJson] = useState(() => {
    const { axisX, axisY, diagramItemDataFieldName, backgroundColor } = field
    const defaultSettings: DiagramFieldData = {
      axisX,
      axisY,
      blocks: [],
      diagramItemDataFieldName,
      backgroundColor,
    }
    return JSON.stringify(value ?? defaultSettings, null, 4)
  })
  const preparedSettings = useDebounce(settingsJson, 1000)

  useDidUpdateEffect(() => {
    try {
      const settings = JSON.parse(preparedSettings)
      saveValue(settings)
    } catch (e) {
      window.Rollbar.error(e as Error)
    }
  }, [preparedSettings])

  return (
    <textarea
      className="textarea textarea-bordered w-full h-64 mb-4"
      value={settingsJson}
      onChange={(e) => setSettingsJson(e.target.value)}
    />
  )
}
