import { useMemo } from 'react'
import clsx from 'clsx'
import { useLiveQuery } from 'dexie-react-hooks'
import { useFieldContext } from '@/contexts'
import { DataListParams } from '@/db'
import { queryDocChildren } from '@/db/docs/queries'
import { Icon, Preview } from '@/ui'
import { IconName } from '@/ui/Icon'
import { showUntitledText } from '@/utils/parseString'
import css from '../card.module.css'
import { CardProps } from '../cardsTypes'
import { CardDocType } from '../components/CardDocType'

export const DefaultCard = ({ doc, docSchema, children, headerElements }: CardProps) => {
  const { field } = useFieldContext<DataListParams>()

  const countElementConfig = field.layout?.card?.countElement
  const imageFit = field.layout?.card?.imageFit
  const childDocs = useLiveQuery(() => queryDocChildren(doc._id))

  const countElement = useMemo(() => {
    if (!countElementConfig) {
      return null
    }

    const { icon, type } = countElementConfig

    if (!icon && !type) {
      return null
    }

    const docsByType = childDocs?.filter((entry) => entry._type === type)

    return (
      <div className="bg-white absolute left-3 top-3 opacity-90 flex items-center p-2 rounded-md z-[1]">
        <Icon name={icon as IconName} size={14} />

        <p className="pl-1.5">{docsByType ? docsByType.length : 0} ideas</p>
      </div>
    )
  }, [countElementConfig, childDocs])

  return (
    <div className="relative h-full w-full border-neutral300 border rounded-lg bg-white flex flex-col">
      <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
      {countElement}
      <div className="relative h-24">
        <Preview
          fit={imageFit ? 'contain' : 'cover'}
          className="w-full h-full rounded-t-lg object-top"
          image={doc.fields.image}
          iconSize={36}
        />
      </div>
      <div className="px-4 pt-4 pb-2.5">
        <CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="mb-1.5" />
        <p className={clsx('text-focus', css.clampThreeRows)}>{showUntitledText(doc.title)}</p>
      </div>
      {children}
    </div>
  )
}
