import { useNavigate } from 'react-router-dom'
import { pathes } from '@/routes'
import { Button } from '@/ui'

export const RendererError: React.FC = () => {
  const navigate = useNavigate()

  const toSettings = () => {
    navigate(pathes.WORKSPACE_SETTINGS)
    setTimeout(() => window.location.reload())
  }

  return (
    <div className="grid justify-items-center content-center gap-3 h-screen">
      <p>There may be an error in the scheme</p>
      <Button onClick={toSettings}>Go to workspace settings</Button>
    </div>
  )
}
