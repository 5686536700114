import { useCallback, useMemo } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { Switch } from '@nextui-org/react'
import { Button } from '@/ui'
import { useLiveQuery } from 'dexie-react-hooks'
import { useDocContext } from '@/contexts'
import { queryDocChildren, queryHome } from '@/db/docs/queries'
import { Collaborators } from '@/features/header/components/Collaborators'
import { GlobalSearch } from '@/features/header/components/GlobalSearch'
import { CurrentWorkspace } from '@/features/sidebar/components/CurrentWorkspace.tsx'
import { usePath } from '@/hooks'
import { pathes } from '@/routes'
import { selectDocSchema, useSchemaState } from '@/contexts/schema'
import { useAppDispatch, useSlr } from '@/store'
import { toggleEditMode } from '@/store/docs'
import { selectSessionStatus } from '@/store/session/selectors.ts'
import { DocumentStatus } from '../DocumentStatus'
import { SidebarProfile } from '../sidebar/components/SidebarProfile'
import { Breadcrumbs } from './components/Breadcrumbs'
import { MoreMenu } from './components/MoreMenu'
import css from './Header.module.css'
import { WORKSPACE_DOCUMENT_TYPE_NAME } from '@common/interfaces/clients/document-type.interface'

export const Header = () => {
  const { doc, roles, isEditMode } = useDocContext()
  const isWorkspace = useMatch(`${pathes.WORKSPACE}`)
  const isDoc = useMatch(`${pathes.WORKSPACE}${pathes.DOCUMENT}`)
  const dispatch = useAppDispatch()
  const { status } = useSlr(selectSessionStatus)

  const { getDocPath } = usePath()
  const navigate = useNavigate()

  const homeDoc = useLiveQuery(() => queryHome())
  const siblingDocs = useLiveQuery(() => queryDocChildren(doc._parentId), [doc._parentId])
  const docs = siblingDocs?.filter((item) => item._type === doc._type) || siblingDocs
  const workspaceSchema = useSchemaState((state) =>
    selectDocSchema(state, WORKSPACE_DOCUMENT_TYPE_NAME),
  )
  const backgroundColor = workspaceSchema?.layout?.bgColors?.header

  const currenIndex = useMemo(
    () => docs?.findIndex((item) => item._id === doc._id) ?? -1,
    [docs, doc],
  )

  const prevDoc = docs?.[currenIndex - 1]
  const nextDoc = docs?.[currenIndex + 1]

  const prevDocType = prevDoc?._type === doc._type
  const nextDocType = nextDoc?._type === doc._type

  const goTo = useCallback(
    (direction: 'prev' | 'next') => {
      const toDoc = direction === 'next' ? nextDoc : prevDoc
      if (toDoc && toDoc?._type === doc._type) {
        navigate(getDocPath(toDoc))
      }
    },
    [currenIndex],
  )

  return (
    <header className={css.header} style={{ backgroundColor }}>
      <CurrentWorkspace />
      <Breadcrumbs />

      <div className={css.tools}>
        <DocumentStatus />
        {!isWorkspace && !(doc._parentId === homeDoc?._id) && (
          <div className="max-sm:hidden flex gap-1">
            <Button
              variant="ghost"
              icon="chevron-left"
              id="previousBtn"
              tooltip={
                prevDoc && prevDocType
                  ? `Go to the previous ${prevDoc._type}`
                  : 'No previous document'
              }
              disabled={!prevDoc || !prevDocType}
              onClick={() => goTo('prev')}
            />
            <Button
              variant="ghost"
              icon="chevron-right"
              id="nextBtn"
              tooltip={
                nextDoc && nextDocType ? `Go to the next ${nextDoc._type}` : 'No next document'
              }
              disabled={!nextDoc || !nextDocType}
              onClick={() => goTo('next')}
            />
          </div>
        )}

        {(isWorkspace || isDoc) && roles.editor && (
          <div className="flex items-center gap-x-3">
            {status !== 'success' && 'Reconnecting...'}
            <Switch
              isDisabled={status !== 'success'}
              isSelected={isEditMode}
              onValueChange={(isOn) => dispatch(toggleEditMode(isOn))}
              size="sm"
            >
              Editable
            </Switch>
          </div>
        )}
        <Collaborators />
        <GlobalSearch />
        <SidebarProfile />
        <MoreMenu isNotHome={!isWorkspace} canEdit={roles.editor} />
      </div>
    </header>
  )
}
