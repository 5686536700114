import { useState } from 'react'
import clsx from 'clsx'
import { DataListParams } from '@common/interfaces/fields/field.interface.ts'
import { useFieldContext } from '@/contexts'
import { Icon, Preview } from '@/ui'
import { IconName } from '@/ui/Icon'
import { showUntitledText } from '@/utils/parseString'
import { CardProps } from '../cardsTypes'
import { CardDocType } from '@/schema/cards/components/CardDocType.tsx'

export const WithIconCard = ({ doc, docSchema, children, headerElements }: CardProps) => {
  const [hovered, setHovered] = useState(false)
  const { field } = useFieldContext<DataListParams>()
  const backgroundColor = doc.fields.color ?? ''
  const icon = doc.fields.icon
  const iconColor = doc.fields.color || '#000'
  const fontSize = field.layout?.card?.fontSize
  const fontColor = field.layout?.card?.fontColor
  const styles = field?.layout?.card?.styles
  const secondaryStyles = field?.layout?.card?.secondaryStyles

  return (
    <>
      {doc.fields?.cardImage && (
        <Preview fit="cover" className="w-full mb-3" image={doc.fields.cardImage} iconSize={36} />
      )}

      <div
        className={clsx(
          'relative w-full rounded-lg flex flex-col cursor-pointer',
          !backgroundColor && 'bg-primary',
        )}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={
          hovered
            ? {
                scale: '1.01',
                transition: 'all 0.3s ease',
                borderColor: '#d2d2d2',
                backgroundColor: backgroundColor as string,
                ...styles,
              }
            : {
                borderColor: '#efefef',
                scale: '1',
                transition: 'all 0.3s ease',
                backgroundColor: backgroundColor as string,
                ...styles,
              }
        }
      >
        <div className="p-2 place-items-center">
          {headerElements}
          <div className="flex items-center w-full justify-between gap-2">
            <div>
              <CardDocType
                hide={docSchema?.hideType}
                type={docSchema?.niceName}
                className="mb-1.5"
              />
              <p
                title={doc.title}
                className={'font-bold line-clamp-2 text-caption'}
                style={{ fontSize, color: fontColor }}
              >
                {showUntitledText(doc.title)}
              </p>
            </div>
            {icon && (
              <div className="bg-white p-1 rounded shrink-0" style={secondaryStyles}>
                <Icon name={icon as IconName} size={16} color={iconColor as string} />
              </div>
            )}
          </div>
        </div>
        {children}
      </div>
    </>
  )
}
