import { PropsWithChildren } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import clsx from 'clsx'
import { QueryEntry } from '@common/interfaces/fields/query-field.interface'
import { Doc, User } from '@/db'
import { selectDocSchema, useSchemaState } from '@/contexts/schema'
import { Icon } from '@/ui'
import { IconName } from '@/ui/Icon'
import { SidebarLinkCount } from './SidebarLinkCount'
import { SidebarLinkAdd } from './SidebarLinkAdd'

interface SidebarLinkProps extends PropsWithChildren<NavLinkProps> {
  icon?: IconName
  color?: string
  doc?: Doc
  user?: User
}

export const SidebarLink = ({
  icon,
  color,
  children,
  className,
  doc,
  user,
  ...linkProps
}: SidebarLinkProps) => {
  const docSchema = useSchemaState((state) => selectDocSchema(state, doc?._type))
  const query = docSchema?.query as QueryEntry
  return (
    <NavLink
      {...linkProps}
      onClick={(e) => e.stopPropagation()}
      className={({ isActive }) =>
        clsx(
          className,
          'flex grid-flow-col justify-start items-center gap-2 py-1.5 px-2 rounded hover:bg-primary hover:bg-opacity-10 overflow-hidden',
          isActive && 'bg-primary bg-opacity-10 text-focus',
        )
      }
    >
      {icon && <Icon name={icon} size={16} className="shrink-0 mr-1" />}
      {/*{color && <div className="w-2.5 h-2.5 rounded-sm" style={{ backgroundColor: color }} />}*/}
      <p className="flex-grow">{children}</p>
      {query && doc && user && <SidebarLinkCount query={query} doc={doc} user={user} />}
      {docSchema?.addNew && doc && <SidebarLinkAdd doc={doc} addType={docSchema?.addNew} />}
    </NavLink>
  )
}
