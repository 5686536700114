import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@/ui'
import { useLiveQuery } from 'dexie-react-hooks'
import { queryUserDoc } from '@/db/docs/queries'
import { useAppDispatch, useSlr } from '@/store'
import { selectAccount } from '@/store/account'
import { setNewDoc } from '@/store/docs'

export const QuickPanelCreate = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const account = useSlr(selectAccount)
  const parentDoc = useLiveQuery(() => queryUserDoc(account._id), [account._id])

  const onCreate = useCallback(async () => {
    if (!parentDoc) return

    navigate({
      pathname: location.pathname,
      search: `?row=newDocument`,
    })
    dispatch(
      setNewDoc({
        _type: 'Note',
        _parentId: parentDoc._id,
        _ancestors: [...parentDoc._ancestors, parentDoc._id],
        parentDoc: parentDoc,
        isNew: true,
      }),
    )
  }, [parentDoc])

  return (
    <div className="absolute top-[10px] right-[22px]">
      <Button onClick={onCreate} className="justify-self-end" icon="plus">
        Add Note
      </Button>
      <div className="mx-auto my-4 w-2/3 border-b border-neutral200" />
    </div>
  )
}
