import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/utils/cn'
import { Icon, IconName } from '../Icon'
import { TooltipContainer, TooltipContent, TooltipProvider } from '../Tooltip'
import { TooltipTrigger } from '@radix-ui/react-tooltip'
import { LoaderCircleIcon } from 'lucide-react'

export const buttonVariants = cva(
  'inline-flex items-center justify-center gap-1.5 whitespace-nowrap rounded-lg font-semibold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:opacity-50 disabled:cursor-not-allowed dark:focus-visible:ring-slate-300',
  {
    variants: {
      variant: {
        default: 'text-sm text-white bg-primary shadow hover:bg-primary/80',
        outline:
          'text-sm text-neutral900 border border-neutral900 bg-white shadow-sm hover:bg-neutral300',
        ghost: 'text-sm text-neutral900 hover:bg-primary/20',
        link: 'text-sm underline-offset-4 hover:underline',
        base: 'text-regular font-normal shadow-none',
      },
      size: {
        default: 'h-7 px-3 py-2',
        icon: 'h-7 w-7 shrink-0',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean
  icon?: IconName
  iconSize?: number
  tooltip?: React.ReactNode
  iconColor?: string
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading,
      children,
      icon,
      iconSize = 16,
      tooltip,
      iconColor,
      ...attrs
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'

    const iconElement = React.useMemo(() => {
      if (loading) return <LoaderCircleIcon size={iconSize} className="animate-spin" />
      if (icon) return <Icon color={iconColor} name={icon} size={iconSize} />
      return null
    }, [loading, icon, iconSize, iconColor])

    if (!children && !size) {
      size = 'icon'
    }

    if (typeof tooltip === 'string') {
      attrs['aria-label'] = tooltip
    }

    const btnElement = (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...attrs}
        disabled={attrs.disabled || loading}
      >
        {iconElement}
        {children}
      </Comp>
    )

    if (tooltip) {
      return (
        <TooltipProvider>
          <TooltipContainer>
            <TooltipTrigger asChild>{btnElement}</TooltipTrigger>
            {tooltip && <TooltipContent>{tooltip}</TooltipContent>}
          </TooltipContainer>
        </TooltipProvider>
      )
    }

    return btnElement
  },
)
Button.displayName = 'Button'

export { Button }
