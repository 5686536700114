import { useMemo } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { QueryEntry, QueryEntry2 } from '@common/interfaces/fields/query-field.interface'
import { useDocContext } from '@/contexts'
import { Doc, queryManager } from '@/db'
import { useSlr } from '@/store'
import { selectAIRecommendedDocIds } from '@/store/ai-recommendations'

type QueryResult = {
  count: number
  docs: Doc[]
  condition?: boolean
}

export function useQueryEntry(query?: QueryEntry | QueryEntry2): QueryResult {
  const { doc, user } = useDocContext()
  const { source } = (query as QueryEntry) ?? {}
  const recommendations = useSlr((state) => selectAIRecommendedDocIds(state, doc._id))

  let result = useLiveQuery(
    () => queryManager.queryData(doc, user, query as QueryEntry),
    [query, doc._id],
  )

  if (source === 'aiRecommended' && Array.isArray(result) && recommendations?.length) {
    result = result.filter((item) => recommendations.includes(item._id))
  }

  const docs = useMemo<Doc[]>(() => {
    if (Array.isArray(result)) {
      return result
    }

    return []
  }, [result])

  const count = useMemo<number>(() => {
    if (typeof result === 'number') {
      return result
    }

    return docs.length
  }, [result])

  const condition = useMemo(() => {
    if (typeof result === 'boolean') {
      return result
    }
  }, [result])

  return { count, condition, docs }
}
