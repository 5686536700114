import { InteractionType } from './interfaces/documents/interaction.interface';
import { ACTIONS, ROLES } from './interfaces/documents/permission.interface';

export class KeyHash {
  readonly CLIENT_PREFIX = 'CLIENT';

  readonly WORKSPACE_PREFIX = 'WORKSPACE';

  readonly USER_PREFIX = 'USER';

  readonly GROUP_PREFIX = 'GROUP';

  readonly EMAIL_PREFIX = 'EMAIL';

  readonly DOCUMENT_PREFIX = 'DOCUMENT';

  readonly DOCUMENT_TYPE_PREFIX = 'DOCUMENTTYPE';

  readonly DOCUMENT_FIELD_PREFIX = 'FIELD';

  readonly DOCUMENT_FROM_RELATION_PREFIX = 'FROMRELATION';

  readonly DOCUMENT_TO_RELATION_PREFIX = 'TORELATION';

  readonly ACTOR_ID_PREFIX = 'ACTOR';

  readonly ACTOR_ID_RANDOM_PREFIX = 'RANDOM';

  readonly INTERACTION_ID_PREFIX = 'INTERACTION';

  readonly INTERACTION_TYPE_PREFIX = 'TYPE';

  readonly TOKEN_PREFIX = 'TOKEN';

  readonly ROLE_PREFIX = 'ROLE';

  readonly PERMISSION_PREFIX = 'PERMISSION';

  readonly ACTION_PREFIX = 'ACTION';

  readonly ACL_PREFIX = 'ACL';

  readonly ACL_INHERIT_PREFIX = 'ACLINHERIT';

  readonly SOCKETIO_PREFIX = 'SOCKETIO';

  readonly SEP = '#';

  partJoin(parts: string[]): string {
    return parts.join(this.SEP);
  }

  genClient(_id: string): string {
    return `${this.CLIENT_PREFIX}${this.SEP}${_id}`;
  }

  genWorkspace(_id: string): string {
    return `${this.WORKSPACE_PREFIX}${this.SEP}${_id}`;
  }

  genEmail(email: string): string {
    return `${this.EMAIL_PREFIX}${this.SEP}${email}`;
  }

  genUser(_id: string): string {
    return `${this.USER_PREFIX}${this.SEP}${_id}`;
  }

  genGroup(id: string): string {
    return `${this.GROUP_PREFIX}${this.SEP}${id}`;
  }

  genUserWorkspaceRelation(_id: string, workspaceId: string): string {
    return `${_id}${this.SEP}${workspaceId}`;
  }

  genWorkspaceUserRelation(_id: string, workspaceId: string): string {
    return `${workspaceId}${this.SEP}${_id}`;
  }

  genDocument(_id: string): string {
    return `${this.DOCUMENT_PREFIX}${this.SEP}${_id}`;
  }

  genDocumentType(name: string): string {
    return `${this.DOCUMENT_TYPE_PREFIX}${this.SEP}${name}`;
  }

  genDocumentField(documentId: string, name: string): string {
    return `${documentId}${this.SEP}${this.DOCUMENT_FIELD_PREFIX}${this.SEP}${name}`;
  }

  getDocumentRelation(fieldName: string, fromDocId: string, toDocId: string) {
    return {
      id: `${this.DOCUMENT_FROM_RELATION_PREFIX}${this.SEP}${fromDocId}${this.SEP}${fieldName}${this.SEP}${toDocId}`,
      reverseId: `${this.DOCUMENT_TO_RELATION_PREFIX}${this.SEP}${toDocId}${this.SEP}${fieldName}${this.SEP}${fromDocId}`,
    };
  }

  parseRelation(relId: string) {
    const id = relId.replace(this.DOCUMENT_FROM_RELATION_PREFIX, '');
    const [fromId, fieldName, toId] = id.split(this.SEP);
    return { fromId, fieldName, toId };
  }

  getActorId(email: string, fieldId: string, random: string): string {
    return `${this.ACTOR_ID_PREFIX}${this.SEP}${email}${this.SEP}${fieldId}${this.SEP}${this.ACTOR_ID_RANDOM_PREFIX}${this.SEP}${random}`;
  }

  getInteractionId(_id: string, type: InteractionType): string {
    return `${this.INTERACTION_ID_PREFIX}${this.SEP}${this.INTERACTION_TYPE_PREFIX}${this.SEP}${type}${this.SEP}${_id}`;
  }
  parseFieldId(fieldId: string) {
    const [docId, fieldName] = fieldId.split(
      `${this.SEP}${this.DOCUMENT_FIELD_PREFIX}${this.SEP}`,
    );
    return { docId, fieldName };
  }

  genToken(_id: string): string {
    return `${this.TOKEN_PREFIX}${this.SEP}${_id}`;
  }

  genDocumentTypeRolePermission(
    documentTypeId: string,
    role: ROLES,
    action?: ACTIONS,
  ): string {
    const ret = [
      this.PERMISSION_PREFIX,
      documentTypeId,
      this.ROLE_PREFIX,
      role,
    ];

    if (action) {
      ret.push(this.ACTION_PREFIX, action);
    }

    return this.partJoin(ret);
  }

  genDocumentRolePermission(
    documentTypeId: string,
    documentId: string,
    role: ROLES,
    action?: ACTIONS,
  ): string {
    const ret = [
      this.PERMISSION_PREFIX,
      documentTypeId,
      documentId,
      this.ROLE_PREFIX,
      role,
    ];

    if (action) {
      ret.push(this.ACTION_PREFIX, action);
    }

    return this.partJoin(ret);
  }

  parsePermission(permission: string) {
    const [, , type, documentOrRolePrefix, idOrRole, , role] = permission.split(this.SEP);

    if(documentOrRolePrefix === this.DOCUMENT_PREFIX) {
      return { type, documentId: this.genDocument(idOrRole), role: role as ROLES };
    }

    return { type, documentId: null, role:idOrRole as ROLES };
  }

  genSocketioId(connectionId: string): string {
    return this.partJoin([this.SOCKETIO_PREFIX, connectionId]);
  }
}
