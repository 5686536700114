import { createElement } from 'react'
import { useFieldContext } from '@/contexts'
import { DataListParams } from '@/db'
import { cardElements, CardElementsProps } from './cardElements'

export const CardHeaderElements = ({
  docItem,
  date,
  isEditMode,
  onDelete,
  docSchema,
}: CardElementsProps) => {
  const { field } = useFieldContext<DataListParams>()
  let elements = ['pin', 'public', 'deleteBtn']
  const headerElements = field.layout?.card?.headerElements
  const whithoutDefaultElements = field.layout?.card?.whithoutDefaultElements

  if (headerElements) {
    if (whithoutDefaultElements) {
      elements = [...headerElements]
    } else {
      elements = [...headerElements, ...elements]
    }
  }

  return (
    <div className="z-20 flex-wrap mt-auto justify-end flex gap-x-2.5 w-full px-2 text-sm text-neutral700">
      {elements.map((componentName) => {
        const component = cardElements[componentName]

        if (!component) {
          return null
        }

        return createElement<CardElementsProps>(component, {
          key: componentName,
          docItem,
          date,
          isEditMode,
          onDelete,
          docSchema,
        })
      })}
    </div>
  )
}
