import Cookies from 'js-cookie'
import { Button } from '@/ui'

export const CoockieBanner = (props) => {
  const { setHasCoockie, context } = props

  const handleAccept = () => {
    Cookies.set('acceptCookies', 'true', { expires: 365 })
    setHasCoockie(true)
  }

  const handleDecline = () => {
    Cookies.set('acceptCookies', 'false')
    setHasCoockie(true)
  }

  return (
    <div
      className="fixed bottom-0 p-6 bg-white border border-neutral100 flex justify-around items-center shadow-md"
      style={{ zIndex: 2000, width: '-webkit-fill-available' }}
    >
      <p className="text-subtitle flex items-center">
        {context ? context : 'This website uses coockies to enhance the user experiense.'}
      </p>
      <div className="flex items-center gap-2">
        <Button variant="ghost" onClick={handleDecline}>
          Decline
        </Button>
        <Button onClick={handleAccept}>Accept</Button>
      </div>
    </div>
  )
}
