import { useEffect, useRef } from 'react'

export function useDidUpdateEffect(callback: () => void, inputs?: any[]) {
  const isMountRef = useRef(false)

  useEffect(() => {
    if (isMountRef.current) {
      callback()
    } else {
      isMountRef.current = true
    }
  }, inputs)
}
