import { useMemo } from 'react'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear } from '@visx/scale'
import { Bar } from '@visx/shape'
import { Text } from '@visx/text'
import { useDiagramWidth } from '@/fields/diagram/hooks'

const height = 280
const margin = 20

const draftData = [
  { key: 'draft1', value: 24 },
  { key: 'draft2', value: 14 },
  { key: 'draft3', value: 25 },
  { key: 'draft4', value: 18 },
  { key: 'draft5', value: 20 },
]

export const EmptyBarChart = () => {
  const { currentWidth } = useDiagramWidth()
  const xMax = currentWidth
  const yMax = height - margin * 2

  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: draftData.map((d) => d.key),
        padding: 0.4,
      }),
    [xMax],
  )

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, margin],
        round: true,
        domain: [0, 25],
      }),
    [yMax, margin],
  )

  return (
    <div>
      <svg width="100%" height={height}>
        <rect width="100%" height="100%" fill="#F8F8F8" rx={14} />
        <Group>
          {draftData.map((d) => {
            const barWidth = xScale.bandwidth()
            const barHeight = yMax - (yScale(d.value) ?? 0)
            const barX = xScale(d.key)
            const barY = yMax - barHeight
            return (
              <Bar
                key={`bar-${d.key}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill="#D2D2D2"
              />
            )
          })}
        </Group>

        <Text
          x={currentWidth / 2}
          y={yMax + margin}
          textAnchor="middle"
          fill="#D2D2D2"
          style={{ fontWeight: 600 }}
        >
          No Activities
        </Text>
      </svg>
    </div>
  )
}
