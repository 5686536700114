import { toast } from 'react-toastify'
import { WorkspaceUserRelation } from '@common/interfaces/clients/workspace.interface'
import { io } from '@/api'
import {
  addEmailToWorkspaceApi,
  deleteUserApi,
  getUserApi,
  updateOtherUserPasswordApi,
  UpdateOtherUserPasswordType,
  updateUserApi,
  updateUserEmailApi,
} from '@/api/users'
import { clearSession } from '@/store/session/slice'
import { LS, moreData, sequentiallyChunked } from '@/utils'
import { db } from '../db'
import { User } from '../dbTypes'
import { queryDocsByType, queryUserDoc } from '../docs/queries'
import { createDoc } from '../docs/sync'
import { updateDocumentReadAccess } from '../permissions/sync'
import { queryUser, queryUserByEmail } from './queries'
import { parallel } from '@/utils/promises'

export async function fetchUserById(userId: string) {
  const user = await queryUser(userId)
  if (user) return user

  try {
    const { user, email, permissionGroups = [], primaryPermissionGroup } = await getUserApi(userId)
    const userData: User = {
      ...user,
      _isVerified: !!email._isVerified,
      _relationType: 'user',
      permissionGroups,
      primaryPermissionGroup,
    }

    await db.ws.users.put(userData)
    return userData
  } catch (error) {
    window.Rollbar.error(error as Error)
  }
}

export async function fetchUsers() {
  const isoDate = LS.get('disconnectTime')
  const users = await db.ws.users.count()

  const userRelations: WorkspaceUserRelation[] = []
  await moreData<WorkspaceUserRelation>({
    initEvent: 'findWorkspaceUsers',
    payload: {
      _clientId: db.activeWorkspace._partId,
      _workspaceId: db.activeWorkspace._id,
      ...(isoDate && users > 1 && { isoDate }),
    },
    moreEvent: 'moreFindWorkspaceUsers',
    cb: (res) => userRelations.push(...res.items),
  })

  try {
    const users = await sequentiallyChunked<User>(
      userRelations,
      async (relation: WorkspaceUserRelation) => {
        const {
          user,
          email,
          permissionGroups = [],
          primaryPermissionGroup,
        } = await getUserApi(relation._userId)
        return {
          ...user,
          _isVerified: !!email._isVerified,
          _relationType: relation._relationType,
          permissionGroups,
          primaryPermissionGroup,
        }
      },
    )

    await db.ws.users.bulkPut(users)
    return db.ws.users.toArray()
  } catch (error) {
    window.Rollbar.error(error as Error)
  }
}

export async function inviteUser(emails: string[], disableEmail = false) {
  const users = await parallel<User>(
    emails,
    async (email) => {
      const id = toast.loading(`Inviting user with email ${email}`)
      try {
        const currentUser = await queryUserByEmail(email)

        if (currentUser) {
          toast.update(id, {
            render: `User with email ${email} already invited`,
            type: 'error',
            isLoading: false,
            closeButton: true,
            autoClose: 3000,
          })

          return
        }

        const { status, user } = await addEmailToWorkspaceApi({
          email,
          _disableEmail: disableEmail,
        })

        const currentUserDoc = await queryUserDoc(user._id)
        const parents = await queryDocsByType('Users')
        const parentDoc = parents[0]
        if (parentDoc && !currentUserDoc) {
          const newDoc = await createDoc({
            type: 'User',
            parentDoc,
            title: user.name || user.email.substring(0, user.email.indexOf('@')),
            userId: user._id,
            fields: { userId: user._id },
          })

          if (newDoc) {
            await updateDocumentReadAccess(newDoc._id, 'everyone')
          }
        }

        if (!status) {
          toast.update(id, {
            render: `User with email ${email} already invited`,
            type: 'error',
            isLoading: false,
            closeButton: true,
            autoClose: 3000,
          })

          return
        }

        toast.update(id, {
          render: `User with email ${email} successfully invited`,
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        })

        return { ...user, _allowedOperation: 'edit', _relationType: 'user' }
      } catch (error) {
        window.Rollbar.error(error as Error)
      }
    },
    { filtered: true },
  )
  await db.ws.users.bulkPut(users.filter((user): user is User => Boolean(user)))
}

export async function updateUserEmail(newEmail: string) {
  try {
    await updateUserEmailApi({
      _id: db.activeAccount._id,
      email: newEmail,
    })
    toast.success('Email verification mail sent')
  } catch (error) {
    window.Rollbar.error(error as Error)
    toast.error('Email update failed')
  }
}

export async function deleteUser(id: string) {
  const response = await toast.promise(deleteUserApi(id), {
    pending: 'Deleting user',
    success: 'User deleted',
    error: 'Cannot delete user',
  })

  await db.ws.users.delete(id)

  return response
}

type MakeUserAdminPayload = {
  _userId: string
  _workspaceId: string
  _clientId: string
  isAdmin: boolean
}

export async function makeUserAdmin(id: string, isAdmin: boolean) {
  const response = await toast.promise(
    io.emit<MakeUserAdminPayload>('makeUserAdmin', {
      _userId: id,
      _workspaceId: db.activeWorkspace._id,
      _clientId: db.activeClient._id,
      isAdmin,
    }),
    {
      pending: 'Changing role',
      success: 'Role changed',
      error: 'Cannot change role',
    },
  )

  await db.ws.users.update(id, { _relationType: isAdmin ? 'admin' : 'user' })

  return response
}

export async function updateUserPassword(password: string, confirmPassword: string) {
  try {
    await io.emit('updateUserPassword', {
      password,
      confirmPassword,
    })
    toast.success('Password updated')
  } catch (error) {
    window.Rollbar.error(error as Error)
    toast.error('Password update failed')
  }
}

export function updateOtherUserPassword(payload: UpdateOtherUserPasswordType) {
  return toast.promise(updateOtherUserPasswordApi(payload), {
    pending: 'Updating password',
    success: 'Password updated',
    error: 'Updating password failed',
  })
}

export async function updateOtherUsername(payload: {
  _id: string
  name: string
  _workspaceId: string
}) {
  try {
    await toast.promise(updateUserApi(payload), {
      pending: 'Updating user name',
      success: 'User name updated',
      error: 'Updating user name failed',
    })

    const { _id, name } = payload
    db.ws.transaction('rw', db.ws.users, () => {
      db.ws.users.update(_id, { name })
    })

    return true
  } catch (error) {
    window.Rollbar.error(error as Error)
    return false
  }
}

export function listenUsers() {
  io.on('mergeUpdateUser', (user: any) => {
    db.ws.users.update(user._id, { ...user })
  })

  io.on('mergeMakeUserAdmin', ({ _userId, isAdmin }: MakeUserAdminPayload) => {
    const role = isAdmin ? 'admin' : 'user'
    db.ws.users.update(_userId, { _relationType: role })
    if (db.activeAccount._id === _userId) {
      toast.success(`Your role has been changed. Reload in 5 sec...`, {
        delay: 5,
        onClose() {
          clearSession()
        },
      })
    }
  })

  io.on('mergeAddEmailToWorkspace', (user: any) => {
    db.ws.users.add(user)
  })
}
