import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import type { Doc } from '@/db'
import { pathes } from '@/routes'
import { useSlr } from '@/store'
import { selectAccount, selectClientWorkspace } from '@/store/account'
import { ModalType, PathContext, PathContextValue } from './PathContext'

export const PathProvider = ({ children }: PropsWithChildren) => {
  const { client, workspace } = useSlr(selectClientWorkspace)
  const account = useSlr(selectAccount)
  const [modal, setModal] = useState<ModalType | null>(null)
  const navigate = useNavigate()
  const location = useLocation()

  const workspacePath = useMemo(
    () =>
      generatePath(pathes.WORKSPACE, {
        client: client._titleSlug,
        workspace: workspace._titleSlug,
      }),
    [client, workspace],
  )

  const getDocPath = (doc: Doc) => {
    const docPath = generatePath(pathes.DOCUMENT, {
      titleSlug: doc._titleSlug || 'untitled',
      slug: doc._slug,
    })

    return `${workspacePath}/${docPath}`
  }

  const openModalDoc = (doc: Doc, slug: string) => {
    navigate({
      pathname: getDocPath(doc),
      search: `?row=${slug}`,
    })
  }

  const pathValue = useMemo<PathContextValue>(
    () => ({
      workspacePath,
      getDocPath,
      openModalDoc,
      modal,
      setModal,
    }),
    [workspacePath, modal],
  )

  useEffect(() => {
    if (!account.name) {
      navigate(pathes.SET_UP_NAME, { replace: true })
    } else if (
      location.pathname === pathes.HOME ||
      (account.name && location.pathname === pathes.SET_UP_NAME)
    ) {
      navigate(workspacePath, { replace: true })
    }
  }, [workspacePath, account.name])

  return <PathContext.Provider value={pathValue}>{children}</PathContext.Provider>
}
