import { HTMLAttributes } from 'react'
import clsx from 'clsx'
import { Edit } from 'lucide-react'

export const EditPicture = ({ children, className }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={clsx(
      'bg-neutral50 gap-1 bg-opacity-70 content-center justify-items-center text-center w-full h-full p-2',
      className,
    )}
  >
    <Edit size={18} />
    <span className="text-sm">{children}</span>
  </div>
)
