import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { Input } from '@nextui-org/react'
import { Button } from '@/ui'
import { getUnixTime } from 'date-fns'
import { saveNewFile } from '@/features/files'
import { FileUpload } from '@/features/files'
import { useDidUpdateEffect } from '@/hooks'
import { useAppDispatch, useSlr } from '@/store'
import { selectClientWorkspace, updateWorkspace, UpdateWorkspacePL } from '@/store/account'
import { ColorPicker, Preview } from '@/ui'
import { pickerColors } from '@/ui/theme'

export const GeneralSettings: React.FC = () => {
  const { workspace } = useSlr(selectClientWorkspace)
  const [name, setName] = useState(workspace.name ?? '')
  const [file, setFile] = useState<File | null>(null)
  const dispatch = useAppDispatch()
  const [color, setColor] = useState(workspace.color ?? pickerColors[0])

  useDidUpdateEffect(() => {
    setName(workspace.name ?? '')
    setColor(workspace.color ?? pickerColors[0])
  }, [workspace.name, workspace.color])

  const setNameHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }, [])

  const onSubmitHandler = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (workspace && name) {
        const payload: UpdateWorkspacePL = { _id: workspace._id, _partId: workspace._partId, name }

        if (color) {
          payload.color = color
        }

        if (file) {
          const fileName = `${getUnixTime(new Date())}-${file.name}`
          saveNewFile({
            fileName,
            file,
            docId: workspace._id,
          }).then((imgUrl) => {
            if (imgUrl) {
              payload.image = {
                source: 'cdn',
                _clientId: workspace._partId,
                _documentId: workspace._id,
                fileName,
              }
            }

            dispatch(updateWorkspace(payload))
          })
        } else {
          dispatch(updateWorkspace(payload))
        }
      }
    },
    [workspace, name, file, color],
  )

  if (!workspace) {
    return <p className="py-16 px-36">Loading...</p>
  }

  return (
    <div>
      <form onSubmit={onSubmitHandler}>
        <div className="form-control mb-4 flex flex-col pb-7">
          <p className="label">Logo</p>

          <FileUpload onUpload={setFile} className="w-32 h-32 mb-3 rounded-md">
            <Preview image={workspace?.image} fit="contain" className="w-full h-full" />
          </FileUpload>

          <span className="text-sm text-neutral700">Upload your workspace’s logo.</span>
        </div>

        <div className="form-control max-w-xs mb-4">
          <Input
            size="sm"
            type="text"
            label="Name"
            labelPlacement="outside"
            value={name}
            onChange={setNameHandler}
          />
        </div>

        <div className="mb-6">
          <p className="label text-tiny mb-1 font-semibold">Primary color</p>
          <ColorPicker value={color} onChange={setColor} />
        </div>

        <Button type="submit" disabled={!name}>
          Update
        </Button>
      </form>
    </div>
  )
}
