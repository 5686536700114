import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import css from './dropdown.module.css'
import { useDropdownContext } from './hooks'

export type DropdownMenuProps = {
  className?: string
  children: ReactNode
  onClick?: (e?: MouseEvent) => void
}

export const DropdownMenu: FC<DropdownMenuProps> = ({ children, className, onClick }) => {
  const { isOpen, off } = useDropdownContext()

  if (!isOpen) {
    return null
  }

  const handleKeyDown = (e) => {
    if (e.target.nodeName === 'INPUT' && e.key === 'Enter') {
      off()
    }
  }

  const handleClick = (e) => {
    if (e.target.nodeName === 'INPUT') {
      return
    } else {
      onClick ? onClick() : off()
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={clsx(css.menu, className)}
      onClick={(e) => handleClick(e)}
      onKeyDown={handleKeyDown}
    >
      {children}
    </div>
  )
}
