import { useCallback, useMemo, useState } from 'react'
import { Textarea } from '@nextui-org/react'
import debounce from 'lodash.debounce'
import { TextFieldType } from '@common/interfaces/fields/text-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { useAutoSizeTextArea } from '@/hooks'
import { FieldContainer } from '@/schema'

export const TextEdit = () => {
  const { schema, isCompound } = useDocContext()
  const { value = '', saveValue, field } = useFieldContext<TextFieldType, string>()
  const [text, setText] = useState(value)
  const textAreaRef = useAutoSizeTextArea(value)
  const styles = field?.styles

  const titleChangeHandler = useCallback((value: string) => {
    setText(value)
    if (isCompound) {
      saveValue(value)
    } else {
      sendChangeDebounced(value)
    }
  }, [])

  const sendChangeDebounced = useMemo(
    () =>
      debounce((val: string) => {
        saveValue(val)
      }, 1000),
    [],
  )

  return (
    <FieldContainer className="h-24">
      {field.name === 'title' && !schema.hideType && <p className="doc-type">{schema.niceName}</p>}
      <Textarea
        placeholder={field.placeholder ?? 'Untitled'}
        variant="bordered"
        disableAnimation
        disableAutosize
        value={text}
        style={styles}
        classNames={{
          input: [
            'resize-y max-h-[60px]',
            field.name === 'title' ? 'text-title font-bold' : 'text-regular',
          ],
          inputWrapper: 'h-auto border-none',
        }}
        ref={textAreaRef}
        onValueChange={titleChangeHandler}
      />
    </FieldContainer>
  )
}
