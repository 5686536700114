import { createContext, RefObject } from 'react'

export interface RteContextValue {
  withAI: boolean
  enableAI: boolean
  toggleAI: (withAI: boolean) => void
  containerRef: RefObject<HTMLDivElement>
}

export const RteContext = createContext<RteContextValue | object>({})
