import { useLiveQuery } from 'dexie-react-hooks'
import { QueryEntry } from '@common/interfaces/fields/query-field.interface'
import { Doc, queryManager, User } from '@/db'

interface CountProps {
  query: QueryEntry
  doc: Doc
  user: User
}

export const SidebarLinkCount = ({ query, doc, user }: CountProps) => {
  const result =
    useLiveQuery(() => queryManager.queryData(doc, user, query as QueryEntry), [query]) || []

  let count = 0
  if (typeof result === 'number') {
    count = result
  } else if (Array.isArray(result)) {
    count = result.length
  }

  if (count === 0) return null

  return <div className="text-white bg-primary rounded-full px-2 text-sm">{count}</div>
}
