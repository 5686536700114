import { useMemo } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { QueryEntry2 } from '@common/interfaces/fields/query-field.interface'
import { db, Doc } from '@/db'
import { ColumnItem } from '@/db/common/QueryManager2'
import { useSlr } from '@/store'
import { selectFilters } from '@/store/filters/selectors'

const isId = (relPoint?: string) => relPoint === 'target' || relPoint === 'parent'

const parentOrTargetId = (obj: Doc, type?: string) => (type === 'parent' ? obj._parentId : obj._id)

const queryData = async (docs: Doc[], query: QueryEntry2) => {
  if (!query) return
  const { relations } = query
  const data = docs

  return Promise.all(
    data.map(async (obj) => {
      const row: ColumnItem = {
        target: obj,
        columns: {},
        isReverse: false,
      }

      if (relations) {
        await Promise.all(
          Object.entries(relations).map(async ([dataKey, relation]) => {
            const { from, to, fieldName } = relation
            const isTargetFromId = isId(from)
            const docType = isTargetFromId ? from : to
            const index = isTargetFromId ? '[fromId+fieldName]' : '[toId+fieldName]'
            const id = parentOrTargetId(obj, docType)

            const rels = await db.ws.relations.where(index).equals([id, fieldName]).toArray()
            const columnData = rels.map((rel) => (isTargetFromId ? rel.toId : rel.fromId))
            row.columns[dataKey] = new Set(columnData)
            row.isReverse = !isTargetFromId

            return rels
          }),
        )
      }

      return row
    }),
  )
}

const getDocs = (docs: ColumnItem[]) => docs.map((row) => row.target)

const GetFilteredDocs = (docs, query) => {
  const { dynamicFilter } = query as QueryEntry2
  const filters = useSlr(selectFilters)
  const rows = useLiveQuery(() => queryData(docs, query as QueryEntry2), [query, docs])
  return useMemo(() => {
    if (!rows) return []

    let filtered = rows

    if (dynamicFilter) {
      filtered = rows.filter((row) =>
        Object.entries(dynamicFilter).every(([dataKey, filterFieldName]) => {
          const filterValue = filters[filterFieldName] as string[]
          const column = row.columns[dataKey]
          if (!column || !filterValue?.length) return true

          return filterValue.some((value) => column.has(value))
        }),
      )
    }

    return getDocs(filtered)
  }, [query, filters, rows])
}

export function useGlobalFilter(docs: Doc[], query, enableFilter = false) {
  if (!query || !enableFilter) {
    return docs
  }
  return GetFilteredDocs(docs, query)
}
