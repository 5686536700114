import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { Doc, FieldValueType } from '@/db'
import { Preview } from '@/ui'
import { colors } from '../theme'

interface AvatarProps extends PropsWithChildren {
  user?: Doc | null
  image?: FieldValueType
  letters?: string
  size?: 1 | 2 | 3 | 4 | 5
  shape?: 'circle' | 'square'
  className?: string
  fit?: 'contain' | 'cover'
  avatarGroupStyles?: string
}

export const Avatar = ({
  user,
  image,
  letters,
  size = 1,
  shape = 'circle',
  className,
  children,
  fit,
  avatarGroupStyles,
}: AvatarProps) => {
  const styles = clsx(
    'aspect-square',
    {
      'h-6': size === 1,
      'h-7': size === 2,
      'h-8': size === 3,
      'h-9': size === 4,
      'h-10': size === 5,
      'rounded-md': shape === 'square',
      'rounded-full': shape === 'circle',
    },
    className,
  )

  const initials = user?.title?.[0] ?? letters
  const img = user?.fields.image ?? image

  if (initials && !img) {
    return (
      <div
        className={clsx(
          'font-semibold text-sm capitalize',
          !avatarGroupStyles && 'relative',
          avatarGroupStyles,
        )}
      >
        <div
          style={{ backgroundColor: (user?.fields.color as string) ?? colors.primary }}
          className={clsx(styles, 'flex items-center justify-center text-white leading-none')}
        >
          {initials}
        </div>
      </div>
    )
  }

  if (img) {
    return <Preview image={img} icon="user" fit={fit} className={clsx(styles, avatarGroupStyles)} />
  }

  return children
}
