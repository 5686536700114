import { useLiveQuery } from 'dexie-react-hooks'
import { useDocContext, useFieldContext } from '@/contexts'
import { DataListParams } from '@/db'
import { Avatar } from '@/ui/Avatar'
import { CardElementsProps } from '../CardElements/cardElements'
import { Tooltip } from '@/ui'
import { queryUserDoc } from '@/db/docs/queries'

export const UsernameWithAvatar = ({ docItem }: CardElementsProps) => {
  const { doc } = useDocContext()
  const { field } = useFieldContext<DataListParams>()
  const footerElements = field.layout?.card?.footerElements || []
  const currentDoc = docItem ?? doc
  const user = useLiveQuery(() => queryUserDoc(currentDoc._userId), [currentDoc._userId])

  if (footerElements.includes('date')) {
    return (
      <Tooltip content={user?.title}>
        <div className="flex gap-x-1.5 items-center">
          <Avatar user={user ?? null} />
        </div>
      </Tooltip>
    )
  }

  return (
    <div className="flex gap-x-1.5 items-center grow">
      <Avatar user={user ?? null} />
      <div className="line-clamp-1">{user?.title}</div>
    </div>
  )
}
