import { Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react'
import { AreaRenderer } from '../components/AreaRenderer'
import { ChangeParentForm } from '../components/ChangeParentForm'

export const AdvancedArea = ({ onClose }: { onClose: () => void }) => (
  <Modal isOpen={true} onClose={onClose} className="overflow-y-visible">
    <ModalContent>
      <ModalHeader className="flex flex-col gap-1">Advanced</ModalHeader>
      <ModalBody className="pb-4">
        <AreaRenderer areaName="advanced" />
        <ChangeParentForm />
      </ModalBody>
    </ModalContent>
  </Modal>
)
