import { WORKSPACE_DOCUMENT_TYPE_NAME } from '@common/interfaces/clients/document-type.interface'
import { Ancestor } from '@common/interfaces/documents/document.interface'
import { ReverseLinkParams } from '@common/interfaces/fields/link-field.interface'
import { scm } from '@/contexts/schema'
import { db } from '../db'
import { Doc, Relation } from '../dbTypes'
import {
  queryFromDocRelations,
  queryRelantionsByFieldList,
  queryRelationsByDocId,
  queryToDocRelationsByType,
} from '../relations/queries'
import { parallel } from '@/utils/promises'

export function queryHome() {
  return db.ws.docs.get({ _type: WORKSPACE_DOCUMENT_TYPE_NAME }).catch<undefined>(console.error)
}

export function queryDocByType(type: string) {
  return db.ws.docs.get({ _type: type }).catch<undefined>(console.error)
}

export function queryDoc(keys: {
  _id?: string
  title?: string
  _userId?: string
  _slug?: string
  secondaryId?: string
}) {
  if (!Object.values(keys).some(Boolean)) return undefined

  return db.ws.docs.get(keys).catch<undefined>(console.error)
}

export async function queryUserDoc(userId: string) {
  return db.ws.docs.get({ userId }).catch<undefined>(console.error)
}

export async function queryDocWithSchema(id: string) {
  const doc = await queryDoc({ _id: id })
  if (!doc) return undefined

  const schema = scm.getDocSchema(doc._type)
  if (!schema) return undefined

  return { doc, schema }
}

export async function queryDocsByType(type?: string) {
  if (!type) return []

  const docs = await db.ws.docs
    .where('_type')
    .equals(type)
    .toArray()
    .catch<undefined>(console.error)
  return docs ?? []
}

export async function queryDocsByTypeList(types?: string[]) {
  if (!types) return []

  const docs = await db.ws.docs
    .where('_type')
    .anyOf(types)
    .toArray()
    .catch<undefined>(console.error)
  return docs ?? []
}

export async function queryDocsByUserId(userId?: string) {
  if (!userId) return []

  const docs = await db.ws.docs
    .where('_userId')
    .equals(userId)
    .toArray()
    .catch<undefined>(console.error)
  return docs
}

export async function queryDocsAnyOf(key: string, types: string[]) {
  const docs = await db.ws.docs.where(key).anyOf(types).toArray().catch<undefined>(console.error)
  return docs
}

export async function queryDocsById(id: string) {
  const docs = await db.ws.docs.where('_id').equals(id).toArray().catch<undefined>(console.error)
  return docs?.[0]
}

export async function queryDocList(keys: string[]) {
  const docs = await db.ws.docs.where('_id').anyOf(keys).toArray().catch<undefined>(console.error)
  return docs ?? []
}

export function queryDocsByRelations(rels: string[]) {
  return db.ws.docs.where('_id').anyOf(rels).toArray().catch<undefined>(console.error)
}

export async function queryToDocsByField(fromId: string, fieldName: string) {
  try {
    const rels = await queryFromDocRelations(fromId, fieldName)
    if (!rels) return undefined
    const filteredRels = rels.map((rel) => rel.toId).filter((rel) => Boolean(rel))

    const docs = await queryDocsByRelations(filteredRels)
    return { docs, rels }
  } catch (e) {
    window.Rollbar.error(e as Error)
    return undefined
  }
}

export async function queryToDocsByFieldList(fromId: string, fieldNames: string[]) {
  try {
    const rels = await queryRelantionsByFieldList(fromId, fieldNames)
    if (!rels) return undefined

    const items: { doc: Doc; rel: Relation }[] = []

    await Promise.all(
      rels.map(async (rel) => {
        const doc = await queryDoc({ _id: rel.toId })
        if (doc) {
          items.push({ doc, rel })
        }
      }),
    )

    return items
  } catch (e) {
    window.Rollbar.error(e as Error)
    return undefined
  }
}

export async function queryReverseLinkDocs(fromId: string, configs: ReverseLinkParams[]) {
  try {
    const rels = await queryToDocRelationsByType(fromId, configs)
    if (!rels) return undefined

    const docs = await db.ws.docs
      .where('_id')
      .anyOf(rels.map((rel) => rel.fromId))
      .toArray()
    return { docs, rels }
  } catch (e) {
    window.Rollbar.error(e as Error)
    return undefined
  }
}

export async function queryAllowedDocs(allowed: string[] = []) {
  const docs = await db.ws.docs
    .where('_type')
    .anyOf(allowed)
    .toArray()
    .catch<undefined>(console.error)
  return docs
}

export async function queryDocChildren(docId?: string, options?: { allowed?: string[] }) {
  if (!docId) return undefined

  const params: Partial<Doc> = { _parentId: docId }
  const docs = db.ws.docs.where(params)

  if (options?.allowed?.length && docs) {
    docs.and((item) => !!options.allowed?.includes(item._type))
  }

  return docs.toArray().catch<undefined>(console.error)
}

export function queryAllDocs() {
  return db.ws.docs.toArray().catch<undefined>(console.error)
}

export function queryDocsByTitle(mantionString: string | null) {
  if (mantionString == null) return []
  return db.ws.docs.where('title').startsWithIgnoreCase(mantionString).toArray()
}

export async function queryDocRelatedDocs(
  docId: string,
  type: string,
  fieldName = '',
  filters: string[] | [],
) {
  const relations = await queryRelationsByDocId(docId)
  if (!relations) return []

  const fromIdSet = new Set(relations.map((rel) => rel.fromId))

  const docColls = db.ws.docs
    .where('_type')
    .equals(type)
    .and((doc) => fromIdSet.has(doc._id))

  if (filters?.length) {
    docColls.and((doc) => {
      const field = doc.fields[fieldName] as string[]
      return Boolean(field?.includes(filters[0]))
    })
  }

  const docs = (await docColls.toArray().catch<undefined>(console.error)) ?? []

  return docs
}

export async function queryAncestors(doc: Doc, includeDoc = false) {
  const docs =
    (await db.ws.docs
      .where('_id')
      .anyOf(doc._ancestors ?? [])
      .toArray()
      .catch<undefined>(console.error)) ?? []

  docs.sort((a, b) => {
    const aIndex = doc._ancestors?.indexOf(a._id) ?? -1
    const bIndex = doc._ancestors?.indexOf(b._id) ?? -1
    return aIndex - bIndex
  })

  const ancestors = docs ?? []
  if (includeDoc) {
    ancestors.push(doc)
  }

  return ancestors.map<Ancestor>((doc) => ({
    documentId: doc._id,
    documentType: doc._type,
    setViewer: !!doc.setViewer,
    setContributor: !!doc.setContributor,
  }))
}

export async function queryLinkedDocField(doc: Doc, fieldPath = '') {
  const fields = fieldPath.split('.')

  const docs: Doc[] = []

  async function recursiveMultilinkDocsQuery(doc: Doc, fieldName = '') {
    const fieldValue = doc.fields[fieldName]
    if (!fieldValue) return

    if (Array.isArray(fieldValue)) {
      await parallel(fieldValue as string[], async (id) => {
        const linkedDoc = await queryDoc({ _id: id })
        if (!linkedDoc) return
        const nextFieldName = fields.shift()

        if (nextFieldName) {
          return recursiveMultilinkDocsQuery(linkedDoc, nextFieldName)
        }

        docs.push(linkedDoc)
      })
    }
  }

  await recursiveMultilinkDocsQuery(doc, fields.shift())

  return docs
}

export async function queryDocsWithPermissions(permissions: string[]) {
  const permissionsSet = new Set(permissions)
  const docsPermissions = await db.ws.permissions
    .where('permissions')
    .anyOf(permissions)
    .toArray()
    .catch<undefined>(console.error)

  if (!docsPermissions) return []

  const ids = new Set<string>()
  return parallel(
    docsPermissions,
    async (docPermission) => {
      if (ids.has(docPermission._id)) return
      ids.add(docPermission._id)
      const doc = await queryDoc({ _id: docPermission._id })
      if (doc) {
        return {
          doc,
          permissions: docPermission.permissions.filter((perm) => permissionsSet.has(perm)),
        }
      }
    },
    { filtered: true },
  )
}

export async function queryDocChildrenByType(docId?: string, type?: string) {
  if (!docId && !type) return undefined

  const params: Partial<Doc> = { _parentId: docId, _type: type }
  const docs = db.ws.docs.where(params)

  return docs.toArray().catch<undefined>(console.error)
}
