import { Group } from '@visx/group'
import { Text } from '@visx/text'
import get from 'lodash.get'
import { Doc } from '@/db'
import { colors } from '@/ui/theme'

interface DiagramBadgeProps {
  w: number
  h: number
  doc: Doc
}

export const DiagramBadge = ({ w, h, doc }: DiagramBadgeProps) => {
  const textPadding = 10
  const textWidth = w - textPadding * 2
  const textHeight = h - textPadding * 2

  const marketSize = get(doc, 'fields.market.marketSize') || ''
  const badgeWidth = marketSize.length

  if (!marketSize) {
    return null
  }

  return (
    <Group top={h - 15} left={w - 5}>
      <rect x={-(badgeWidth * 11)} y={-10} width={badgeWidth * 11} height={20} rx="6" fill="#FFF" />
      <Text
        width={textWidth}
        height={textHeight}
        x={-badgeWidth * 5.5}
        y={0}
        textAnchor={w ? 'middle' : 'start'}
        verticalAnchor={h ? 'middle' : 'start'}
        fill={colors.neutral600}
        className="bg-white"
        style={{ fontWeight: 600 }}
      >
        {marketSize}
      </Text>
    </Group>
  )
}
