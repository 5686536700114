import { useLiveQuery } from 'dexie-react-hooks'
import { TextFieldType } from '@common/interfaces/fields/text-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { FieldContainer } from '@/schema'
import { getDocAIContext } from '@/utils'
import { FieldComponent } from '../fieldType'

export const StaticField: FieldComponent = () => {
  const { isEditable, field } = useFieldContext<TextFieldType, string>()
  const { ...tagStyles } = field.styles ?? {}
  const { doc } = useDocContext()
  const context = useLiveQuery(() => getDocAIContext(doc, field.value), [])

  if (!field.value) return null

  if (isEditable) {
    return null
  }

  return (
    <FieldContainer>
      {field.isHeadline ? (
        <h3 className="text-heading2 break-words whitespace-pre-wrap" style={tagStyles}>
          {context}
        </h3>
      ) : (
        <p style={tagStyles}>{context}</p>
      )}
    </FieldContainer>
  )
}
