import {
  ChangeEvent,
  Dispatch,
  FC,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useRef,
} from 'react'
import { EmailChip } from './EmailChip'

export type MultipleEmailInputProps = {
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  emails: string[]
  setEmails: Dispatch<SetStateAction<string[]>>
  isValidEmails: boolean
}

export const MultipleEmailInputField: FC<MultipleEmailInputProps> = ({
  email,
  setEmail,
  emails,
  setEmails,
  isValidEmails,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const inputChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }, [])

  const keyPressEventHandler = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const { key } = event

      if ((key === 'Enter' || key === ' ' || key === ',') && email) {
        setEmails((prev) => [...prev, email.replace(/[, ]/g, '')])
        setEmail('')
      }

      if (key === 'Backspace' && !email) {
        setEmails((prev) => prev.slice(0, -1))
      }
    },
    [email],
  )

  const setFocusHandler = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef.current])

  const deleteEmailHandler = useCallback((chosenEmail: string) => {
    setEmails((prev) => prev.filter((item) => item !== chosenEmail))
  }, [])

  return (
    <div>
      <p className="text-sm text-neutral900 font-bold pb-2.5">Email addresses</p>

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        onClick={setFocusHandler}
        className="flex flex-wrap items-start content-start h-32 py-2 px-4 border border-neutral400 rounded overflow-y-auto hover:cursor-text"
      >
        {emails.map((test) => (
          <EmailChip key={test} email={test} onDelete={deleteEmailHandler} />
        ))}

        <input
          type="text"
          placeholder="name@company.com"
          className="w-40 focus:outline-0"
          ref={inputRef}
          value={email}
          onChange={inputChangeHandler}
          onKeyUp={keyPressEventHandler}
        />
      </div>

      {Boolean(emails.length) && !isValidEmails && (
        <p className="text-sm text-orange500 pt-2.5">Invalid email addresses</p>
      )}

      <p className="text-sm text-neutral700 pt-2.5">
        We will send an email that gives them access to your workspace.
      </p>
    </div>
  )
}
