import { Key, useCallback, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { format } from 'date-fns'
import { ws } from '@/api'
import { db } from '@/db'
import { HtmlParser } from '@/features/rte'
import { LinkedDocuments } from '@/routes/WorkspaceSetttings/LinkedDocuments.tsx'
import { Button, Preview } from '@/ui'

const columns = [
  { name: 'Action', uid: 'action' },
  { name: 'Title', uid: 'title' },
  { name: 'Type', uid: 'documentType' },
  { name: 'Property type', uid: 'propertyType' },
  { name: 'Property name', uid: 'propertyName' },
  { name: 'Value', uid: 'value' },
  { name: 'User', uid: 'userName' },
  { name: 'Date', uid: '_addedOn' },
]

export const AuditLogs = () => {
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState<any[]>([])

  const [page, setPage] = useState(1)
  const rowsPerPage = 20

  const pages = Math.ceil(items.length / rowsPerPage)

  const data = useMemo(() => {
    const start = (page - 1) * rowsPerPage
    const end = start + rowsPerPage

    return items.slice(start, end)
  }, [page, items])

  const fetchLogs = async () => {
    setLoading(true)
    await ws.emit(
      'find-telemetry',
      { partId: { workspaceId: db.activeWorkspace._id } },
      'find-telemetry-event-id',
    )
    setLoading(false)

    ws.on('find-telemetry-event-id', (res: { items: any[] }) => {
      if (res.items) {
        setItems((prevItems) => [...prevItems, ...res.items])
      }
    })
  }

  const renderCell = useCallback((item: any, columnKey: Key) => {
    const cellValue = item[columnKey as keyof any]

    switch (columnKey) {
      case 'value':
        switch (item.propertyType) {
          case 'html': {
            return cellValue ? (
              <HtmlParser className="text-break">{cellValue}</HtmlParser>
            ) : (
              <div>No description</div>
            )
          }

          case 'image': {
            return (
              <Preview
                fit="contain"
                className="w-[80px] h-[60px]"
                image={cellValue}
                iconSize={36}
              />
            )
          }

          case 'singlelink':
          case 'multilink':
          case 'children': {
            return <LinkedDocuments value={cellValue} />
          }

          case 'url': {
            return <p className="w-[200px] break-words">{cellValue.text}</p>
          }
        }

        return <p className="w-[200px] break-words">{cellValue?.toString()}</p>
      case '_addedOn':
        return <p>{format(new Date(cellValue), 'MM/dd/yyyy hh:mm a')}</p>
      default:
        return cellValue
    }
  }, [])

  return (
    <div>
      <Table
        aria-label="Example table with custom cells"
        bottomContent={
          <div className="flex w-full justify-center">
            <Pagination
              isCompact
              showControls
              showShadow
              color="secondary"
              page={page}
              total={pages}
              onChange={(page) => setPage(page)}
            />
          </div>
        }
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.uid} align={column.uid === '_addedOn' ? 'center' : 'start'}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={data} loadingState={loading ? 'loading' : 'idle'}>
          {(item) => (
            <TableRow key={item._id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="flex items-center gap-4 mt-4 rounded-md">
        <Button onClick={fetchLogs} disabled={!!items.length} loading={loading}>
          Fetch
        </Button>
        <CSVLink data={items}>Download</CSVLink>
      </div>
    </div>
  )
}
