import { useCallback, useRef } from 'react'
import { SelectOption } from '@/ui/Select'
import { useDocContext } from '@/contexts'
import { FieldValueType } from '@/db'

export function useFilterDefaultValue(
  defaultValue: FieldValueType | undefined,
  setValue: (value: string[]) => void,
) {
  const { doc } = useDocContext()
  const selectedDefaultValue = useRef<string | null>(null)

  const selectDefaultOption = useCallback(
    (options: SelectOption[]) => {
      if (!defaultValue || !options.length) return

      if (typeof defaultValue === 'boolean') {
        if (!selectedDefaultValue.current) {
          setValue([options[0].value])
        }
        selectedDefaultValue.current = options[0].value
        return options[0]
      }

      if (defaultValue === 'last') {
        if (!selectedDefaultValue.current) {
          setValue([options[options.length - 1].value])
        }
        selectedDefaultValue.current = options[options.length - 1].value
        return options[options.length - 1]
      }

      let defaultOption: SelectOption | undefined

      if (typeof defaultValue !== 'string') return

      const fieldValue = doc.fields[defaultValue] as string[]
      if (fieldValue?.length) {
        defaultOption = options.find((op) => op.value === fieldValue[0])
      } else {
        defaultOption = options.find((op) => op.label === defaultValue)
      }

      if (!defaultOption) return

      if (!selectedDefaultValue.current) {
        setValue([defaultOption.value])
      }
      selectedDefaultValue.current = defaultOption.value
      return defaultOption
    },
    [defaultValue],
  )

  return { selectDefaultOption }
}
