import { Preview } from '@/ui'
import css from '../card.module.css'
import { CardProps } from '../cardsTypes'

export const SmallRowCard = ({ doc }: CardProps) => (
  <article className={css.smallRowCard}>
    <Preview image={doc.fields.image} className={css.cardImg} />
    <h1 className="text-focus area">{doc.title}</h1>
    <p className="text-sm text-neutral700 capitalize">{doc._type}</p>
  </article>
)
