import { useCallback, useEffect, useState } from 'react'
import { Bookmark as BookmarkIcon } from 'lucide-react'
import { useDocContext } from '@/contexts'
import { Tooltip } from '@/ui'
import { useSlr } from '@/store'
import { selectAccount } from '@/store/account'

export const Bookmark = () => {
  const { doc, save } = useDocContext()
  const user = useSlr(selectAccount)
  const { fields } = doc
  const fieldName = 'bookmarked'
  const fieldValue: string[] = (fields.bookmarked as string[]) ?? []

  const [value, setValue] = useState<string[]>(fieldValue)
  const [checked, setChecked] = useState(false)

  const changeHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault()
      setValue((prev) => {
        const index = prev.findIndex((id) => id === user._id)
        if (index !== -1) {
          const data = [...prev]
          data.splice(index, 1)
          save({ name: fieldName, value: data })
          return data
        } else {
          save({ name: fieldName, value: [...prev, user._id] })
          return [...prev, user._id]
        }
      })
    },
    [doc],
  )
  useEffect(() => {
    if (value?.length) {
      const isSelected = value.includes(user._id)
      setChecked(isSelected)
    } else {
      setChecked(false)
    }
  }, [value])

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className="rounded-full bg-white aspect-square grid place-items-center items-center justify-center w-10 h-10"
      onClick={changeHandler}
    >
      <Tooltip content="Bookmarked">
        <BookmarkIcon fill={checked ? '#000' : '#fff'} color="#1E1E1E" size={18} />
      </Tooltip>
    </div>
  )
}
