import {
  createContext,
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithChildren,
  PropsWithRef,
  RefAttributes,
  useContext,
  useMemo,
} from 'react'
import AriaModal from 'react-aria-modal'
import clsx from 'clsx'
import { Button } from '../Button'
import css from './Modal.module.css'

export interface ModalProps extends PropsWithChildren {
  title: string
  position?: 'default' | 'right'
  className?: string
  onClose?: () => void
}

const ModalContext = createContext<ModalProps>({ title: '' })

type ModalFC = ForwardRefExoticComponent<
  PropsWithRef<ModalProps> & RefAttributes<HTMLDivElement>
> & {
  Actions: FC<PropsWithChildren>
}

export const Modal = forwardRef(({ children, className, ...props }, ref) => {
  const { title, onClose } = props
  const id = useMemo(() => title.replace(/\s/g, '-').toLowerCase(), [title])

  return (
    <ModalContext.Provider value={props}>
      <AriaModal onExit={onClose} focusDialog={true} titleId={id}>
        <div className="modal modal-open max-sm:px-4">
          <div className={clsx('modal-box overflow-visible', css.modalBox)}>
            <div className="modal-header pt-7 px-8 bg-transparent max-sm:px-4">
              <h3 id={id} className="text-subtitle">
                {title}
              </h3>
              <Button icon="x" iconSize={18} variant="ghost" onClick={onClose} />
            </div>
            <div
              className={clsx('modal-content p-8 max-sm:p-4 overflow-auto', className)}
              ref={ref}
            >
              {children}
            </div>
          </div>
        </div>
      </AriaModal>
    </ModalContext.Provider>
  )
}) as ModalFC

Modal.displayName = 'Modal'

const ModalActions = ({ children }: PropsWithChildren) => {
  const { onClose } = useContext(ModalContext)

  return (
    <div className="modal-action mt-7">
      <Button variant="ghost" onClick={onClose}>
        Cancel
      </Button>
      {children}
    </div>
  )
}

Modal.Actions = ModalActions
