import { PropsWithChildren, ReactNode } from 'react'
import { Progress } from '@nextui-org/react'
import { useSlr } from '@/store'
import { selectProgress } from '@/store/account/selectors.ts'
import { Spinner } from '@/ui/Spinner/Spinner.tsx'

export const LoadScreen = ({
  showProgress = false,
  children,
}: PropsWithChildren<{ showProgress?: boolean }>) => {
  const progress = useSlr(selectProgress)

  if (!showProgress) {
    return (
      <div className="modal modal-open bg-white bg-opacity-90 select-none">
        <div className="text-center">
          <Spinner />
          <p className="mt-3 pl-1 text-focus text-neutral700">{children}</p>
        </div>
      </div>
    )
  }

  const hasTotal = progress.total > 0
  const isDone = hasTotal && progress.loaded === progress.total

  let message: ReactNode = 'Initializing the application...'
  if (isDone) {
    message = 'Preparing the data...'
  } else if (hasTotal) {
    message = 'Syncing data...'
  }

  return (
    <div className="fixed h-screen w-full grid place-items-center">
      <Progress
        label={message}
        size="sm"
        isIndeterminate={!hasTotal}
        value={progress.loaded}
        maxValue={progress.total}
        aria-label="Loading..."
        className="max-w-md"
      />
    </div>
  )
}
