import { QueryEntry2 } from '@common/interfaces/fields/query-field.interface'
import { getApprovedMetricData } from '@/utils/docsSort'
import { db } from '../db'
import { Doc, User } from '../dbTypes'

export interface ColumnItem {
  target: Doc
  isReverse: boolean
  columns: {
    [key: string]: Set<string>
  }
}

class QueryManager2 {
  doc: Doc
  user: User
  query: QueryEntry2

  async queryData(doc: Doc, user: User, query: QueryEntry2) {
    this.doc = doc
    this.user = user
    this.query = query

    const { objectType, types, relations } = this.query

    const whereQuery = db.ws.docs.where('_type')
    const coll = types ? whereQuery.anyOf(types) : whereQuery.equals(objectType)

    const scopeId = this.getAncestorIdByScope()
    if (scopeId) {
      coll.and((obj) => obj._ancestors.includes(scopeId))
    }
    const data = getApprovedMetricData(await coll.toArray(), true)

    return Promise.all(
      data.map(async (obj) => {
        const row: ColumnItem = {
          target: obj,
          columns: {},
          isReverse: false,
        }

        if (relations) {
          await Promise.all(
            Object.entries(relations).map(async ([dataKey, relation]) => {
              const { from, to, fieldName } = relation
              const isTargetFromId = this.isId(from)
              const docType = isTargetFromId ? from : to
              const index = isTargetFromId ? '[fromId+fieldName]' : '[toId+fieldName]'
              const id = this.parentOrTargetId(obj, docType)

              const rels = await db.ws.relations.where(index).equals([id, fieldName]).toArray()
              const columnData = rels.map((rel) => (isTargetFromId ? rel.toId : rel.fromId))
              row.columns[dataKey] = new Set(columnData)
              row.isReverse = !isTargetFromId

              return rels
            }),
          )
        }

        return row
      }),
    )
  }

  getAncestorIdByScope(): string | null {
    const { scope } = this.query
    const { _id, _ancestors } = this.doc

    if (!scope) return null

    if (scope === 1) return _id

    return _ancestors[scope - 2]
  }

  isId(relPoint?: string) {
    return relPoint === 'target' || relPoint === 'parent'
  }

  parentOrTargetId(obj: Doc, type?: string) {
    return type === 'parent' ? obj._parentId : obj._id
  }
}

export const queryManager2 = new QueryManager2()
