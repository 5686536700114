import clsx from 'clsx'
import { useDocContext } from '@/contexts'
import { RightBar } from '../components'
import { SchemaLayout } from './SchemaLayout'

export const Single = () => {
  const { isCompound } = useDocContext()
  return (
    <div className={clsx('grid grid-cols-[1fr_auto] max-lg:grid-cols-1')}>
      <main className="pt-5 max-lg:px-5">
        <SchemaLayout className="grid gap-8 max-sm:gap-7" />
      </main>
      {!isCompound && <RightBar />}
    </div>
  )
}
