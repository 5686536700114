import { useState } from 'react'
import { Button } from '@/ui'
import { useDocContext } from '@/contexts'
import { createInteraction } from '@/db/interactions/sync'
import { Rte, useRte } from '../rte'

export const AddComment = () => {
  const { doc } = useDocContext()
  const [loading, setLoading] = useState(false)
  const [rteRef, { clearHtml, getHtml }] = useRte()

  const onSubmit = async () => {
    setLoading(true)
    const comment = await getHtml()
    await createInteraction(doc._id, { type: 'comment', value: comment })
    clearHtml()
    setLoading(false)
  }

  return (
    <div className="mt-3 relative grid gap-2">
      <Rte placeholder="Leave a comment..." ref={rteRef} />
      <Button className="justify-self-end" variant="outline" onClick={onSubmit} loading={loading}>
        Comment
      </Button>
    </div>
  )
}
