import * as React from 'react'
import { Link } from 'react-router-dom'
import { useLiveQuery } from 'dexie-react-hooks'
import { Doc } from '@/db'
import { usePath } from '@/hooks'
import { MediumCard } from '@/schema/cards'
import { queryLinkedDocField } from '@/db/docs/queries'

export const ListLinkedDocuments: React.FC<{ doc: Doc; fieldName: string }> = ({
  doc,
  fieldName,
}) => {
  const docs = useLiveQuery(() => queryLinkedDocField(doc, fieldName), [doc, fieldName]) ?? []
  const { getDocPath } = usePath()

  if (!docs.length) {
    return <div className="text-center opacity-30">-</div>
  }

  return (
    <div className="flex flex-col p-1 gap-y-[18px]">
      {docs.map((item) => {
        if (['FiscalYear', 'Metric', 'QTR', 'Country', 'State', 'Strategy'].includes(item._type)) {
          return (
            <Link aria-label={`link to ${item.title}`} key={item._id} to={getDocPath(item)}>
              <p
                key={item._id}
                className={`line-clamp-2 ${
                  ['FiscalYear', 'QTR'].includes(item._type) && 'whitespace-nowrap'
                }`}
                title={item.title}
              >
                {item.title}
              </p>
            </Link>
          )
        }

        return (
          <div key={item._id} className="bg-neutral50 rounded-md">
            <Link aria-label={`link to ${item.title}`} key={item._id} to={getDocPath(item)}>
              <MediumCard doc={item} />
            </Link>
          </div>
        )
      })}
    </div>
  )
}
