import { useMemo } from 'react'
import clsx from 'clsx'
import { Check } from 'lucide-react'
import { useDocContext } from '@/contexts'
import { db } from '@/db'
import { useSlr } from '@/store'
import { selectAIRecommendedDocIds } from '@/store/ai-recommendations'
import { Button } from '@/ui'
import type { MultiLinkChildProps } from '../MultiLinkField'

type MultiLinkRecommendedProps = MultiLinkChildProps

export const MultiLinkRecommended = ({
  relatedDocs,
  allowedDocs = [],
  addRelation,
  addRelationList,
}: MultiLinkRecommendedProps) => {
  const { doc, isEditMode } = useDocContext()

  const recommendations = useSlr((state) => selectAIRecommendedDocIds(state, doc._id))
  const recommendedDocsByType = useMemo(() => {
    if (db.flags.enableAI && allowedDocs?.length && recommendations?.length && isEditMode) {
      return allowedDocs.filter((item) => recommendations.includes(item._id))
    }

    return []
  }, [recommendations, allowedDocs])

  if (!recommendedDocsByType.length) {
    return null
  }

  return (
    <div className="bg-neutral50 rounded-md py-[22px] my-3 px-4">
      <div className="flex items-center justify-between pb-[20px]">
        <p className="flex items-center text-xs text-neutral700">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-[6px]"
          >
            <path
              d="M4 6.5L5.39535 3.5L6.86047 6.5L10 7.73529L6.86047 9.5L5.39535 12.5L4 9.5L1 7.73529L4 6.5Z"
              fill="#FF8888"
            />
            <path
              d="M10.9535 2.10438L11.9302 0L12.907 2.10438L15 2.82353L12.907 3.78484L11.9302 6L10.9535 3.78484L9 2.82353L10.9535 2.10438Z"
              fill="#FF8888"
            />
            <path
              d="M10.9535 12.1044L11.9302 10L12.907 12.1044L15 12.8235L12.907 13.7848L11.9302 16L10.9535 13.7848L9 12.8235L10.9535 12.1044Z"
              fill="#FF8888"
            />
          </svg>
          Recommended
        </p>

        <Button
          variant="outline"
          icon="plus"
          onClick={() => addRelationList(recommendedDocsByType)}
        >
          Add all
        </Button>
      </div>

      <div className="flex flex-wrap">
        {recommendedDocsByType.map((entry) => {
          const isSelected = relatedDocs.some((item) => item._id === entry._id)

          return isEditMode ? (
            <button
              className={clsx(
                'bg-white flex items-center text-sm text-neutral900 font-bold mr-[6px] mb-[8px] px-[10px] py-1 rounded-md',
                isSelected ? 'cursor-default text-neutral700' : '',
              )}
              onClick={() => addRelation(entry)}
              disabled={isSelected && isEditMode}
            >
              {isSelected && <Check width={14} height={14} color="#808080" className="mr-3" />}
              {entry.title}
            </button>
          ) : (
            <p className="bg-white flex items-center text-sm text-neutral900 font-bold mr-[6px] mb-[8px] px-[10px] py-1 rounded-md">
              {isSelected && <Check width={14} height={14} color="#808080" className="mr-3" />}
              {entry.title}
            </p>
          )
        })}
      </div>
    </div>
  )
}
