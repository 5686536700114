import { useForm } from 'react-hook-form'
import { Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react'
import { User } from '@/db'
import { queryUserDoc } from '@/db/docs/queries'
import { saveField } from '@/db/docs/sync'
import { updateOtherUsername } from '@/db/users/sync.ts'
import { useSlr } from '@/store'
import { selectClientWorkspace } from '@/store/account'
import { Button } from '../Button'

export const UpdateOtherUsernameModal = ({
  user,
  onClose,
  isOpened,
}: {
  user: User
  onClose: () => void
  isOpened: boolean
}) => {
  const { register, handleSubmit, formState } = useForm()
  const { errors, isSubmitting } = formState
  const { workspace } = useSlr(selectClientWorkspace)

  const submit = handleSubmit(async (data) => {
    const status = await updateOtherUsername({
      _id: user._id,
      _workspaceId: workspace._id,
      name: data.name,
    })
    const userDoc = await queryUserDoc(user._id)

    if (!userDoc || !status) return onClose()
    saveField(userDoc._id, { name: 'title', value: data.name }, { saveLog: false })
    onClose()
  })

  return (
    <Modal isOpen={isOpened} onOpenChange={onClose}>
      <ModalContent className="w-[600px] max-w-2xl px-4">
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 px-0 h-12">Update username</ModalHeader>
            <ModalBody className="p-0 max-h-[600px] overflow-y-auto overflow-x-hidden">
              <form>
                <div className="form-control w-full">
                  <Input
                    type="text"
                    className="py-2"
                    isInvalid={!!errors.name}
                    label="New username"
                    placeholder="Enter your username"
                    {...register('name', { required: true })}
                  />
                </div>
              </form>
            </ModalBody>
            <ModalFooter className="border-t-1 p-1 border-neutral200">
              <Button color="default" variant="outline" onClick={onClose}>
                Close
              </Button>
              <Button color="primary" type="submit" onClick={submit} disabled={isSubmitting}>
                Update
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
