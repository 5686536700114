import { useCallback, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { localPoint } from '@visx/event'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear } from '@visx/scale'
import { Circle } from '@visx/shape'
import { Text } from '@visx/text'
import { useTooltip, useTooltipInPortal } from '@visx/tooltip'
import { format } from 'date-fns'
import { GraphFieldInterface } from '@common/interfaces/fields/graph-field.interface.ts'
import { useFieldContext } from '@/contexts'
import { Doc } from '@/db'
import { usePath, useQueryEntry } from '@/hooks'
import { FieldContainer } from '@/schema'
import { useSort } from '@/schema/hooks'

const margin = 60
const defaultHeight = 300
const innerHeight = defaultHeight - margin

const formatDate = (date: string) => format(new Date(date), 'MMM dd')

let tooltipTimeout: number

export const GraphField = () => {
  const { field } = useFieldContext<GraphFieldInterface>()
  const { docs: data } = useQueryEntry(field.query)
  const docs = useSort(data, field.sort)
  const navigate = useNavigate()

  const { getDocPath } = usePath()

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useTooltip<any>()

  const { containerRef, TooltipInPortal, containerBounds } = useTooltipInPortal({
    scroll: true,
  })

  const width = containerBounds?.width

  const activitiesTotalByDocId: { [key: string]: number } = useMemo(
    () =>
      docs.reduce((obj, entry) => {
        const activities = entry.fields?.ACTIVITY as []

        if (!activities) {
          obj[entry._id] = 0

          return obj
        }

        obj[entry._id] = activities.reduce((amount, activity) => {
          Object.entries(activity).forEach(([key, value]) => {
            if (key !== 'timestamp') {
              amount += value as number
            }
          })

          return amount
        }, 0)

        return obj
      }, {}),
    [docs],
  )

  const xAxisValues = useMemo(
    () =>
      docs
        .map((d) => d._modifiedOn)
        .sort((a, b) => {
          const dateA = new Date(a).getTime()
          const dateB = new Date(b).getTime()

          return dateA - dateB
        }),
    [docs],
  )
  const yAxisValues = useMemo(() => Object.values(activitiesTotalByDocId), [activitiesTotalByDocId])

  const xScale = useMemo(
    () =>
      scaleBand({
        domain: xAxisValues,
        range: [margin, width - margin],
      }),
    [xAxisValues, width, margin],
  )

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, Math.max(...yAxisValues)],
        range: [innerHeight, margin],
      }),
    [yAxisValues, innerHeight, margin],
  )

  const x = useCallback((item: Doc) => item._modifiedOn, [])

  const y = useCallback((item: Doc) => activitiesTotalByDocId[item._id], [activitiesTotalByDocId])

  return (
    <FieldContainer>
      <div className="relative">
        <svg
          ref={containerRef}
          width="100%"
          height="100%"
          viewBox={`0 0 ${width} ${defaultHeight}`}
        >
          <rect x={0} y={0} width="100%" height="100%" fill="#eaedff" rx={14} />
          <Group>
            {docs.map((item) => {
              const number = Math.round((docs.length - 1) / 3)
              const docIndexByModifyDate = xAxisValues.findIndex(
                (date) => date === item._modifiedOn,
              )

              let fill = '#D2D2D2'

              if (docIndexByModifyDate > number) {
                fill = '#c9cbff'
              }

              if (docIndexByModifyDate > number * 2) {
                fill = '#9061b6'
              }

              return (
                <Circle
                  key={item._id}
                  r={14}
                  fill={fill}
                  fillOpacity="0.6"
                  transform="translate(14 -14)"
                  className="cursor-pointer"
                  cx={xScale(x(item))}
                  cy={yScale(y(item))}
                  onClick={() => {
                    navigate(getDocPath(item))
                  }}
                  onMouseMove={(event) => {
                    if (tooltipTimeout) clearTimeout(tooltipTimeout)
                    const eventSvgCoords = localPoint(event)
                    const left = (xScale(x(item)) as number) + 14

                    showTooltip({
                      tooltipData: { ...item, totalActivities: activitiesTotalByDocId[item._id] },
                      tooltipTop: eventSvgCoords?.y,
                      tooltipLeft: left,
                    })
                  }}
                  onMouseLeave={() => {
                    tooltipTimeout = window.setTimeout(() => {
                      hideTooltip()
                    }, 300)
                  }}
                />
              )
            })}
          </Group>

          <Group>
            <AxisLeft
              left={margin}
              scale={yScale}
              hideTicks
              label="Activity"
              numTicks={1}
              stroke="#9E9E9E"
              labelOffset={6}
              tickLabelProps={{
                fill: '#808080',
                fontSize: 14,
                textAnchor: 'middle',
              }}
              tickTransform="translate(-10 0)"
              labelProps={{
                fill: '#6c5efb',
              }}
              tickValues={yScale.domain()}
              tickComponent={(props) => {
                if (props.formattedValue === '0') {
                  return <Text {...props}>Low</Text>
                }

                return <Text {...props}>High</Text>
              }}
            />
          </Group>

          <Group>
            <AxisBottom
              top={innerHeight}
              stroke="#9E9E9E"
              hideTicks
              scale={xScale}
              numTicks={1}
              tickFormat={formatDate}
              label="Time since last activity"
              tickLabelProps={{
                fill: '#808080',
                fontSize: 14,
                textAnchor: 'middle',
              }}
              labelProps={{
                fill: '#6c5efb',
              }}
              tickComponent={(props) => {
                if (props.formattedValue === formatDate(xAxisValues[0])) {
                  return <Text {...props}>{props.formattedValue}</Text>
                }

                return <Text {...props}>Today</Text>
              }}
            />
          </Group>
        </svg>

        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeft}
            className="min-w-[60px] pointer-events-auto"
          >
            <div className="pb-1">
              <Link
                aria-label={'Go to ' + tooltipData.title}
                to={getDocPath(tooltipData)}
                className="text-neutral900"
              >
                <strong>{tooltipData.title}</strong>
              </Link>
            </div>

            <p className="text-neutral900 pb-[2px]">
              Total activities: {tooltipData.totalActivities}
            </p>

            <p className="text-neutral900">
              Last modified date: {formatDate(tooltipData._modifiedOn)}
            </p>
          </TooltipInPortal>
        )}
      </div>
    </FieldContainer>
  )
}
