import { create } from 'zustand'
import { DocumentType } from '@common/interfaces/clients/document-type.interface'
import type { SomeField } from '@common/interfaces/fields/field.interface'
import { arrToObject } from '@/utils/docsSort'
import { camelToCapitalizeWords, toSentence } from '@/utils/parseString'
import { immer } from 'zustand/middleware/immer'

export type AllSchema = DocumentType[]

export interface SchemaState {
  allSchema: AllSchema
  docTypes: Partial<Record<string, DocumentType>>
  fields: Partial<Record<string, SomeField>>
  order: Partial<Record<string, number>>
  setSchema: (schema: AllSchema) => void
  updateDocument: (docType: string, value: DocumentType) => void
  updateField: (docType: string, fieldName: string, value: SomeField) => void
  addNewDocumentToParent: (parentName: string, fieldName: string, data: any) => void
}

export const useSchemaState = create<SchemaState>()(
  immer((set) => ({
    allSchema: [],
    docTypes: {},
    fields: {},
    order: {},
    setSchema(schema: AllSchema) {
      set((state) => {
        state.allSchema = schema.map((docType, index) => {
          docType.niceName = docType.niceName || camelToCapitalizeWords(docType.name)
          state.docTypes[docType.name] = docType
          state.order[docType.name] = index

          const fieldMap = arrToObject(docType.fields ?? [], 'name', (item) => {
            item.niceName = item.niceName || toSentence(item.name)
            state.fields[`${docType.name}_${item.name}`] = item
            return item
          })

          docType.fields = Object.values(fieldMap)
          return docType
        })
      })
    },
    updateDocument(docType: string, value: DocumentType) {
      set((state) => {
        const index = state.allSchema.findIndex((doc) => doc.name === docType)

        if (index !== -1) {
          state.allSchema[index] = value as DocumentType
        } else {
          state.allSchema.push(value)
        }
      })
    },
    updateField(docType: string, fieldName: string, value: SomeField) {
      set((state) => {
        const docIndex = state.allSchema.findIndex((doc) => doc.name === docType)
        const doc = state.allSchema[docIndex]

        if (doc) {
          const index = doc.fields.findIndex((field) => field.name === fieldName)
          if (index !== -1) {
            doc.fields[index] = value as SomeField
          } else {
            doc.fields.push(value)
          }
        }

        state.allSchema[docIndex] = doc
      })
    },
    addNewDocumentToParent(parentName: string, fieldName: string, data: any) {
      set((state) => {
        const doc = state.allSchema.find((doc) => doc.name === parentName)
        const docIndex = state.allSchema.findIndex((doc) => doc.name === parentName)
        const field = doc?.fields.find((item) => item.name === fieldName)
        const fieldIndex = doc?.fields.findIndex((item) => item.name === fieldName)

        if (field && fieldIndex && field.type === 'children' && field.allowed) {
          if (!field.allowed.includes(data.name)) {
            field.allowed.push(data.name)
          }

          state.allSchema[docIndex].fields[fieldIndex] = field
        }
      })
    },
  })),
)
