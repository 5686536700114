import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate } from 'react-router-dom'
import LogoIcon from '@/assets/logo.svg?react'
import { pathes } from '@/routes'
import { useAppDispatch, useSlr } from '@/store'
import { selectAccount } from '@/store/account'
import { updateProfile } from '@/store/account/actions'
import { Button } from '@/ui'
import { userColors } from '@/ui/theme'
import { random } from '@/utils'

export const SetUpNamePage = () => {
  const [loading, setLoading] = useState(false)
  const account = useSlr(selectAccount)
  const { register, handleSubmit, getValues, watch } = useForm()
  const dispatch = useAppDispatch()

  const onSubmit = () => {
    const index = random(0, userColors.length)

    setLoading(true)
    dispatch(
      updateProfile({
        color: userColors[index],
        name: getValues('name'),
        email: account.email,
      }),
    )
  }

  if (account.name) {
    return <Navigate to={pathes.HOME} replace />
  }

  return (
    <div className="p-10">
      <header className="flex justify-between">
        <div className="flex items-center">
          <LogoIcon />
          <strong className="text-heading2 ml-3.5">Hypha</strong>
        </div>
      </header>
      <main className="grid justify-items-center text-neutral700 pt-20">
        <h1 className="text-title mb-3.5">Let’s set up your profile</h1>
        <p>Tell us a bit about yourself.</p>
        <div className="pt-8 grid w-[340px] gap-5">
          <input
            type="text"
            className="input input-bordered"
            placeholder="your name"
            {...register('name')}
          />

          <Button
            loading={loading}
            disabled={!watch('name') || loading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </div>
        <p className="pt-96">
          By continuing, you agree to{' '}
          <a href="/#" className="underline">
            Hypha’s Terms
          </a>{' '}
          of Service and{' '}
          <a href="/#" className="underline">
            Privacy Policy
          </a>
          .
        </p>
      </main>
    </div>
  )
}
