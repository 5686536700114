import { TELEMETRY_ACTIONS } from '@common/interfaces/clients/telemetry.interface'
import { Client, db, DocumentType, Workspace } from '@/db'
import { AllSchema } from '@/contexts/schema'
import { io } from './socket'

export interface BaseWorkspace {
  _clientId: string
  _id: string
  _relationType: 'admin' | 'user'
  _allowedOperation: 'edit' | 'view'
  _permissionGroups: string[]
}

export interface SchemaData {
  _clientId: string
  _workspaceId: string
  schema?: AllSchema
}

export interface TelemetryData {
  workspaceId?: string
  documentId: string
  documentType: string
  documentTitle: string
  propertyName?: string | 'permission'
  propertyType?: string
  action: TELEMETRY_ACTIONS
  value?: any
  comment?: string
  label?: string
}

export async function fetchWorkspacesApi(ids: BaseWorkspace[]) {
  return Promise.all<Workspace>(ids.map((id) => io.emit('getWorkspace', id)))
}

export function fetchClientsApi(ids: string[]) {
  return Promise.all<Client>(ids.map((_id) => io.emit('getClient', { _id })))
}

export async function updateSchemaApi(schema: DocumentType[]) {
  const status = await io.emit<boolean>('updateWorkspace', {
    _id: db.activeWorkspace._id,
    _partId: db.activeWorkspace._partId,
    schema,
  })

  if (!status) throw new Error('Failed to update schema')

  return true
}

export async function createTelemetryApi(data: TelemetryData) {
  data.workspaceId = db.activeWorkspace._id
  return io.emit<boolean>('createTelemetry', data)
}
