import { Group } from '@visx/group'
import { Text } from '@visx/text'
import { Block } from '@common/interfaces/fields/diagram-field.interface'
import { ImageFieldData } from '@common/interfaces/fields/image-field.interface'
import { Doc } from '@/db'
import { useImage } from '@/features/files'
import { useDiagramPosition, useDiagramSize } from '../hooks'
import { DiagramBadge } from './DiagramBadge'
import { hexToRgb } from '@/utils/colors'

interface DiagramBlockProps {
  block: Block
  onClick: (filterName?: string, value?: string) => void
  image?: ImageFieldData
  doc?: Doc
  makeFilter?: boolean | undefined
  filter?: string | undefined
}

export const DiagramBlock = ({
  block,
  onClick,
  image,
  doc,
  makeFilter,
  filter,
}: DiagramBlockProps) => {
  const {
    x,
    y,
    width,
    height,
    label,
    textColor,
    backgroundColor = '#7EC5FEDD',
    opacity = 100,
    showCoverImage,
    filterName,
    value,
  } = block
  const [groupX, groupY] = useDiagramPosition(x, y)
  const [w, h] = useDiagramSize(width, height)

  const textPadding = 10
  const textWidth = w - textPadding * 2
  const textHeight = h - textPadding * 2
  const img = useImage(image)

  const rgb = hexToRgb(backgroundColor as string) ?? { r: 255, g: 255, b: 255 }

  return (
    <Group
      transform={`translate(${groupX}, ${groupY})`}
      opacity={opacity / 100}
      onClick={() => onClick(filterName, value)}
      className={doc || makeFilter ? 'doc-block' : ''}
    >
      {Boolean(h) && (
        <rect
          width={w}
          height={h}
          rx="6"
          fill={
            filter && filter === value
              ? (`rgba(${rgb.r},${rgb.g},${rgb.b},${0.5})` as string)
              : backgroundColor
          }
        />
      )}
      {showCoverImage && <image href={img?.url} width={w} />}
      <Text
        width={textWidth}
        height={textHeight}
        x={textPadding + textWidth / 2}
        y={textPadding + textHeight / 2}
        textAnchor={w ? 'middle' : 'start'}
        verticalAnchor={h ? 'middle' : 'start'}
        fill={textColor}
        style={{ fontWeight: 600 }}
      >
        {label}
      </Text>
      {doc && <DiagramBadge w={w} h={h} doc={doc} />}
    </Group>
  )
}
