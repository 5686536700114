import { useFilterState } from '@/contexts/filter'
import { Slider, SliderValue } from '@nextui-org/react'
import { FilterOptionProps } from '../Filter'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { QueryEntry } from '@common/interfaces/fields/query-field.interface.ts'
import { useQueryEntry } from '@/hooks'
import { NumberFieldType } from '@common/interfaces/fields/number-field.interface.ts'

export const FilterNumber = ({ filter, fieldSchema }: FilterOptionProps<NumberFieldType>) => {
  const { updateFilter } = useFilterState()
  const [value, setValue] = useState<SliderValue>([0, 100000])

  const query = useMemo<QueryEntry | undefined>(() => {
    if (filter.query) return filter.query
  }, [fieldSchema, filter.query])

  const { docs } = useQueryEntry(query)

  const maxValue = useMemo(() => {
    const arrayOfNumbers = docs.map((d) => (d.fields?.[filter.fieldName] as number) ?? 0)

    return arrayOfNumbers.length ? Math.max(...arrayOfNumbers) : 100000
  }, [docs])

  useEffect(() => {
    setValue([0, maxValue])
  }, [maxValue])

  const onChange = useCallback(() => {
    updateFilter(filter.fieldName, value)
  }, [filter.fieldName, value])

  return (
    <div>
      <Slider
        label="Select number range"
        maxValue={maxValue}
        minValue={0}
        value={value}
        onChange={setValue}
        onChangeEnd={onChange}
        classNames={{
          labelWrapper: 'gap-2',
        }}
      />
    </div>
  )
}
