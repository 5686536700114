import { ReactNode } from 'react'
import { components, GroupBase, SingleValueProps } from 'react-select'
import { Icon, IconName } from '@/ui/Icon'

type Props<T, IsMulti extends boolean, Group extends GroupBase<T>> = {
  children: ReactNode
} & SingleValueProps<T, IsMulti, Group>

export const CustomSingleValue = <T, IsMulti extends boolean, Group extends GroupBase<T>>({
  children,
  ...singleValueProps
}: Props<T, IsMulti, Group>): JSX.Element => {
  const innerProps = {
    ...singleValueProps.innerProps,
  }

  return (
    <components.SingleValue {...singleValueProps} innerProps={innerProps}>
      <Icon name={children as IconName} />
    </components.SingleValue>
  )
}
