import {
  createElement,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { FieldContext, FieldContextValue, useDocContext } from '@/contexts'
import { Doc, FieldValueType, SomeField } from '@/db'
import { keyHash } from '@/utils'
import { useCondition, useDynamicField } from '../hooks'
import { FieldError } from './FieldError'
import { getField } from '@/utils/fields'

interface FieldProps extends PropsWithChildren {
  field: SomeField
  showSkeleton?: boolean
  childDoc?: Doc
}

const EXCEPTION_FIELDS = new Set(['title', 'description', 'image', 'banner', 'authordate'])

export const FieldItem = memo(({ field, showSkeleton, childDoc, children }: FieldProps) => {
  const { doc: contextDoc, isEditMode, save } = useDocContext()
  const doc = childDoc ?? contextDoc

  const fieldComp = useDynamicField(field.type)
  const fieldId = keyHash.genDocumentField(doc._id, field.name)
  const docValue = field.name === 'title' ? doc.title : getField(field.name, doc.fields)
  const hideName = useMemo(
    () => field.hideName ?? EXCEPTION_FIELDS.has(field.name.toLowerCase()),
    [field],
  )

  const [value, setValue] = useState(docValue)

  const fullField = { id: fieldId, ...field, hideName }
  const { isHiddenEdit, isHiddenView, isEditable = false } = useCondition(fullField, isEditMode)

  const saveValue = useCallback(
    <T extends FieldValueType>(newValue: T) => {
      setValue(newValue)
      save({ name: field.name, value: newValue })
      if (doc._type === 'MetricData') {
        const statusField = getField<string>('Approval', doc.fields)

        if (statusField !== 'pending') {
          save({ name: 'Approval', value: 'pending' })
        }
      }
    },
    [save, doc._type],
  )

  const contextValue: FieldContextValue = useMemo(
    () => ({
      field: fullField,
      isEditable,
      value,
      docValue,
      fieldId,
      saveValue,
      showSkeleton,
    }),
    [field, docValue, value, isEditable, showSkeleton],
  )

  useEffect(() => {
    if (docValue && value && docValue !== value) setValue(docValue)
  }, [doc._id, docValue])

  if (!fieldComp) return null

  if (isHiddenEdit || isHiddenView) return null

  return (
    <FieldContext.Provider value={contextValue}>
      {children}
      <ErrorBoundary FallbackComponent={FieldError}>{createElement(fieldComp)}</ErrorBoundary>
    </FieldContext.Provider>
  )
})

FieldItem.displayName = 'FieldItem'
