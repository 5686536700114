import { createAsyncThunk } from '@reduxjs/toolkit'
import { getOpenAIEmbeddingApi, getOpenAIRecommendationApi } from '@/api/ai'
import { setEmbeddingStatus, setRecommendationDocIds } from '@/store/ai-recommendations/slice'

export const embeddingStatus = createAsyncThunk(
  'ai-recommendation/embedding',
  async (
    payload: { workspaceId: string; documentId: string; text: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { workspaceId, documentId, text } = payload

      const status = await getOpenAIEmbeddingApi(workspaceId, documentId, text)
      dispatch(setEmbeddingStatus(status))

      return status
    } catch (error) {
      window.Rollbar.error('Embedding', error as Error)
      return rejectWithValue(false)
    }
  },
)

export const getOpenAIRecommendation = createAsyncThunk(
  'ai-recommendation/embedding',
  async (
    payload: { workspaceId: string; documentId: string; count?: number },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { workspaceId, documentId, count } = payload

      const ids = await getOpenAIRecommendationApi(workspaceId, documentId, count)

      const recommendationDocIds = ids
        .filter((arr) => arr[1].startsWith('DOCUMENT'))
        .map((arr) => arr[1])

      dispatch(setRecommendationDocIds({ documentId, recommendationDocIds }))
    } catch (error) {
      window.Rollbar.error(error as Error)
      rejectWithValue([])
    }
  },
)
