import { useMemo } from 'react'
import { SomeField } from '@/db'
import { AccordionField } from '@/fields/accordion'
import { AreaField } from '@/fields/area/AreaField'
import { BannerField } from '@/fields/banner'
import { BooleanField } from '@/fields/boolean'
import { ChildrenField } from '@/fields/children'
import { ColorField } from '@/fields/color'
import { DashboardField } from '@/fields/dashboard'
import { DateField } from '@/fields/date'
import { DiagramField } from '@/fields/diagram'
import { FieldComponent } from '@/fields/fieldType'
import { FileAttachmentField } from '@/fields/file-attachment'
import { FilterField } from '@/fields/filter'
import { GraphField } from '@/fields/graph'
import { HtmlField } from '@/fields/html'
import { IFrameField } from '@/fields/iframe'
import { ImageField } from '@/fields/image'
import { KeyValueField } from '@/fields/keyValue'
import { MultiLinkField } from '@/fields/multilink'
import { NumberField } from '@/fields/number'
import { QueryField } from '@/fields/query'
import { ReverseLink } from '@/fields/reverselink'
import { CollaborateField } from '@/fields/collaborate'
import { StackBarChartField } from '@/fields/stack-bar-chart'
import { StaticField } from '@/fields/static/StaticField'
import { StatusField } from '@/fields/status'
import { TableField } from '@/fields/table'
import { TabsField } from '@/fields/tabs'
import { TextField } from '@/fields/text'
import { UploadField } from '@/fields/upload'
import { UrlField } from '@/fields/url'
import { WhiteboardField } from '@/fields/whiteboard'
import { WorkflowField } from '@/fields/workflow'
import { Bookmark } from '@/fields/bookmark/BookmarkField'

const components: Record<SomeField['type'], FieldComponent> = {
  text: TextField,
  number: NumberField,
  boolean: BooleanField,
  image: ImageField,
  color: ColorField,
  status: StatusField,
  html: HtmlField,
  singlelink: MultiLinkField,
  multilink: MultiLinkField,
  reverselink: ReverseLink,
  children: ChildrenField,
  query: QueryField,
  accordion: AccordionField,
  tabs: TabsField,
  banner: BannerField,
  dashboard: DashboardField,
  diagram: DiagramField,
  table: TableField,
  keyValue: KeyValueField,
  whiteboard: WhiteboardField,
  filter: FilterField,
  url: UrlField,
  iframe: IFrameField,
  date: DateField,
  upload: UploadField,
  workflow: WorkflowField,
  attachment: FileAttachmentField,
  area: AreaField,
  graph: GraphField,
  activityChart: StackBarChartField,
  collaborate: CollaborateField,
  static: StaticField,
  bookmark: Bookmark,
}

export const useDynamicField = (fieldType: SomeField['type']) =>
  useMemo(() => {
    const component = components[fieldType]
    if (!component) return null

    return component
  }, [fieldType])
