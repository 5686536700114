import Dexie from 'dexie'
import { Doc, User } from '@/db'

export const deleteAllDatabases = () => {
  Dexie.getDatabaseNames((names) => {
    names.forEach((name: string) => {
      const database = new Dexie(name)
      database.delete().catch((err) => {
        window.Rollbar.error('Could not delete database: ', name, err)
      })
    })
  })
}

export function isAdmin(user: User) {
  return user._relationType === 'admin'
}

export function isOwner(doc: Doc, user: User) {
  return doc._userId === user._id
}
