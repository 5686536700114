import { MouseEvent, useEffect, useState } from 'react'
import { Doc } from '@/db'
import { Filter } from '@/features/filter'
import { List } from '@/schema/cards/List.tsx'
import { usePath } from '@/hooks'

export type DocsListProps = {
  docs: Doc[]
  onDelete?: (doc: Doc, event?: MouseEvent<HTMLButtonElement>) => void
  type?: 'children' | 'multilink' | 'query'
  parentDoc?: Doc
}

export const DocsList = (props: DocsListProps) => {
  const { docs, onDelete, type = 'children', parentDoc } = props
  const [currentDoc, setCurrentDoc] = useState<Doc>()
  const { setModal } = usePath()

  useEffect(() => {
    if (currentDoc) {
      if (onDelete) {
        onDelete(currentDoc)
      } else {
        setModal({ type: 'delete', doc: currentDoc })
      }
    }
  }, [currentDoc])

  return (
    <>
      <Filter>
        <List docs={docs} type={type} parentDoc={parentDoc} setCurrentDoc={setCurrentDoc} />
      </Filter>
    </>
  )
}
