import { add, sub } from 'date-fns'
import { Parser } from 'expr-eval'
import { Doc, Roles, SomeField, User } from '@/db'
import { queryAccountRoles, queryPermissionsGroupsByIds } from '@/db/permissions/queries'
import { parallel, singlePromise } from './promises'
import { hasSomeFieldValue } from './fields'
import type { Areas } from '@/schema/components/AreaRenderer'
import { DocumentPermissionGroupEntry } from '@common/interfaces/documents/permission.interface'

function time(d: string | number, ...args: string[]) {
  let date = new Date(d)

  if (args?.length) {
    const times: { [key: string]: string } = {
      sec: 'seconds',
      min: 'minutes',
      h: 'hours',
      d: 'days',
      w: 'weeks',
      m: 'months',
      y: 'years',
    }
    const adds: { [key: string]: string } = {}
    const subs: { [key: string]: string } = {}

    args.forEach((t) => {
      const [op, val, type] = t.split(/(\d+)/).filter(Boolean)
      if (op === '+') {
        adds[times[type.trim()]] = val
      } else {
        subs[times[type.trim()]] = val
      }
    })
    date = add(date, adds)
    date = sub(date, subs)
  }

  return +date
}

type Tools = {
  groups?: DocumentPermissionGroupEntry[]
  roles?: Roles
}

type ToolsKey = keyof Tools
type AsyncScope = Partial<Record<ToolsKey, boolean>>

async function getTools(doc: Doc, user: User, asyncScope: AsyncScope) {
  const asyncFn: Partial<Tools> = {}
  const keys = Object.keys(asyncScope) as ToolsKey[]

  await parallel(keys, async (key) => {
    if (!asyncScope[key]) return

    if (key === 'groups') {
      const groups = await singlePromise(`groups_${user._id}`, () =>
        queryPermissionsGroupsByIds(user.permissionGroups),
      )
      asyncFn[key] = groups
    } else if (key === 'roles') {
      asyncFn[key] = await queryAccountRoles(doc)
    }
  })

  return asyncFn
}

export async function createEvaluator(
  doc: Doc,
  user: User,
  options?: {
    contextDoc?: Doc
    areas?: Map<Areas | string, SomeField[]>
    field?: SomeField
    isNew?: boolean
    asyncScope?: AsyncScope
  },
) {
  const { contextDoc, areas, field, isNew = false, asyncScope = {} } = options ?? {}

  const tools = await getTools(doc, user, asyncScope)

  const findGroup = (name: string) => tools.groups?.some((g) => g.name === name) ?? false
  const isCollaborator = findGroup(doc._id)

  function isEmptyContent() {
    if (!field) return false
    if (field.type === 'area') {
      const areaFields = areas?.get(field.name)
      if (!areaFields) return false
      return !hasSomeFieldValue(areaFields, doc.fields)
    } else {
      if (field.type === 'html') {
        return (
          !(doc.fields[field.name] !== '<p class="editor-paragraph"><br></p>') ||
          !doc.fields[field.name]
        )
      }
      return !doc.fields[field.name]
    }
  }

  const scope = {
    now: Date.now(),
    time,
    user,
    obj: doc,
    isOwner: doc._userId === user._id,
    f: { title: doc.title, ...doc.fields },
    contextDoc,
    isCollaborator,
    isEmptyContent,
    isNew,
    ...tools,
    groups: findGroup,
  }

  return (exp: string | boolean) => {
    if (typeof exp === 'boolean') return exp
    try {
      return !!Parser.evaluate(exp, scope as any)
    } catch (e: any) {
      window.Rollbar.error(`Expression error in the field`, e)
      return false
    }
  }
}

export function evaluator(
  exp: string,
  doc: Doc,
  user: User,
  contextDoc?: Doc,
  extScope: Record<string, any> = {},
) {
  const scope = {
    now: Date.now(),
    time,
    obj: doc,
    user,
    isOwner: doc._userId === user._id,
    f: { title: doc.title, ...doc.fields },
    contextDoc,
    ...extScope,
  }

  try {
    return !!Parser.evaluate(exp, scope as any)
  } catch (e: any) {
    window.Rollbar.error(`Expression error in the field`, e)
    return false
  }
}
