import { useState } from 'react'
import { Button } from '@/ui'
import { HtmlFieldType } from '@common/interfaces/fields/html-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { HtmlParser, Rte } from '@/features/rte'
import { FieldContainer } from '@/schema'
import { FieldComponent } from '../fieldType'

export const HtmlField: FieldComponent = () => {
  const { isCompound } = useDocContext()
  const { isEditable, field, value } = useFieldContext<HtmlFieldType>()
  const isEditState = isEditable && (field.isDefaultEditable || field.alwaysEditable)
  const [isEdit, setIsEdit] = useState(!!isEditState)
  const { ...rteStyles } = field.styles ?? {}

  const showButton = isEditable && !field.alwaysEditable

  return (
    <FieldContainer className="relative">
      {isEditable && isEdit ? (
        <Rte withCollab={!isCompound} withAI placeholder={field.placeholder} />
      ) : (
        <HtmlParser styles={rteStyles}>{value}</HtmlParser>
      )}
      {showButton && (
        <Button
          className="mt-2"
          variant="outline"
          icon={isEdit ? 'check-check' : 'pencil'}
          onClick={() => setIsEdit(!isEdit)}
        >
          {isEdit ? 'Done' : 'Edit'}
        </Button>
      )}
    </FieldContainer>
  )
}

HtmlField.isEmpty = (value) => !value || value === '<p class="editor-paragraph"><br></p>'
