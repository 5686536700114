import { createContext, useContext } from 'react'
import { FieldValueType } from '@/db'

interface FilterContextType {
  filters: Partial<Record<string, FieldValueType>>
  updateFilter: (fieldName: string, value: FieldValueType) => void
  removeFilter: (name: string) => void
  clearFilters: () => void
}

export const FilterContext = createContext<FilterContextType | null>(null)

export function useFilterState() {
  return useContext(FilterContext)!
}
