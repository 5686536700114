export type FormatType = 'number' | 'money' | 'percent'

export const formatNumber = (value?: number, type?: FormatType) => {
  if (!value) return '0'

  if (type === 'number') {
    return `#${value}`
  }

  if (typeof value === 'string') return value

  const options: Intl.NumberFormatOptions = {
    maximumSignificantDigits: 3,
    notation: 'compact',
  }

  if (type === 'money') {
    options.style = 'currency'
    options.currency = 'USD'
  }

  const result = value.toLocaleString('en-US', options)

  if (type === 'percent') {
    return `${result}%`
  }

  return result
}
