import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type FilterState = Record<string, string[] | string | undefined>

const filtersSlice = createSlice({
  name: 'filters',
  initialState: {} as FilterState,
  reducers: {
    updateFilter(state, action: PayloadAction<{ name: string; value: string[] | string }>) {
      const { name, value } = action.payload
      state[name] = value
    },
    clearFilters() {
      return {}
    },
  },
})

export const { clearFilters, updateFilter } = filtersSlice.actions

export const { reducer } = filtersSlice
