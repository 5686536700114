import { useLiveQuery } from 'dexie-react-hooks'
import { LinkFieldType } from '@common/interfaces/fields/link-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { queryReverseLinkDocs } from '@/db/docs/queries'
import { FieldContainer } from '@/schema'
import { DocsList, SelectCard } from '@/schema/cards'
import { FieldComponent } from '../fieldType'

export const ReverseLink: FieldComponent = () => {
  const { doc, isEditMode } = useDocContext()
  const { field } = useFieldContext<LinkFieldType>()
  const reverseLinks = field.reverseLinks ?? []
  const isRightSidebar = field.area === 'right-sidebar'

  const { docs = [] } =
    useLiveQuery(() => queryReverseLinkDocs(doc._id, reverseLinks), [doc._id]) ?? {}

  if (!(docs.length || isEditMode)) return null

  return (
    <FieldContainer>
      {isRightSidebar && (
        <div className="flex flex-wrap gap-1">
          {docs.map((item) => (
            <SelectCard key={item._id} isEditMode={false} doc={item} />
          ))}
        </div>
      )}
      {!isRightSidebar && <DocsList docs={docs} />}
    </FieldContainer>
  )
}
