import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { DocProvider } from '@/contexts'
import { useActiveDoc } from '@/hooks/useActiveDoc'
import { FieldItem } from '@/schema/components'

export const WhiteboardPage = () => {
  const { activeDoc, schema, isEditMode } = useActiveDoc()
  const { whiteboardName } = useParams()
  const field = useMemo(
    () => schema?.fields.find((item) => item.name === whiteboardName),
    [whiteboardName, schema],
  )

  if (!activeDoc || !schema || !field) {
    return null
  }

  return (
    <DocProvider doc={activeDoc} schema={schema} isEditMode={isEditMode}>
      <FieldItem field={field} />
    </DocProvider>
  )
}
