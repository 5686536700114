/* eslint-disable jsx-a11y/anchor-is-valid */
import { MouseEvent } from 'react'
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react'
import { Button } from '@/ui'
import { usePath, useToggle } from '@/hooks'
import { useLiveQuery } from 'dexie-react-hooks'
import { queryDocChildren } from '@/db/docs/queries'
import { Link, useNavigate } from 'react-router-dom'
import { ModalState } from '@/contexts'
import { ModalDeleteState } from '@/contexts/PathContext'
import { deleteDoc } from '@/db/docs/sync'

export const DeleteDocModal = ({ close, doc, onDelete }: ModalState<ModalDeleteState>) => {
  const children = useLiveQuery(() => queryDocChildren(doc?._id), [doc?._id])
  const [loading, { on, off }] = useToggle(false)
  const navigate = useNavigate()
  const { getDocPath } = usePath()
  if (!doc) return

  const handleDelete = async (event: MouseEvent<HTMLButtonElement>) => {
    on(event)
    onDelete?.(doc, event)

    event.preventDefault()
    const status = await deleteDoc(doc)
    if (status) {
      close()
      navigate(-1)
    }
    off()
  }

  return (
    <Modal isOpen={true} onClose={close} className="overflow-y-visible">
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">Delete {doc._type}</ModalHeader>
        <ModalBody className="pb-4 overflow-y-auto max-h-96">
          <p>
            <b>{doc.title} </b>
            {!children?.length ? (
              <span>will be deleted</span>
            ) : (
              <span>can&apos;t be deleted. It has following documents:</span>
            )}
          </p>
          {!!children?.length && (
            <ul className="list-decimal list-inside grid gap-1">
              {children?.map((child) => (
                <li key={child._id}>
                  <Link className="text-focus" to={getDocPath(child)} onClick={close}>
                    {child.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!!children?.length}
            id={'delete' + doc.title}
            aria-label={'delete ' + doc.title}
            loading={loading}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
