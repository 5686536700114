import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Checkbox, Input } from '@nextui-org/react'
import { Button } from '@/ui'
import { useDocContext } from '@/contexts'
import { changeParent, ChangeParentFormData } from '@/db/docs/sync'

export const ChangeParentForm = () => {
  const { doc } = useDocContext()
  const [loading, setLoading] = useState(false)
  const { register, watch, handleSubmit } = useForm<ChangeParentFormData>()

  const onSubmit = async (data: ChangeParentFormData) => {
    setLoading(true)
    await changeParent({ doc, ...data })
    setLoading(false)
  }

  const isHome = watch('isHome')

  return (
    <div className="field-theme">
      <h3 className="text-focus mb-4">Change parent</h3>
      <form onSubmit={handleSubmit(onSubmit)} className="flex gap-2">
        <Checkbox size="sm" id="isHome" {...register('isHome')}>
          Home
        </Checkbox>
        <Input
          size="sm"
          id="parentSlug"
          type="text"
          placeholder="Parent slug"
          labelPlacement="outside"
          className="h-3"
          {...register('parentSlug', { required: !isHome })}
          isDisabled={isHome}
        />
        <Button type="submit" loading={loading}>
          Save
        </Button>
      </form>
    </div>
  )
}
