import { Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import get from 'lodash.get'
import { TableListType } from '@common/interfaces/fields/table-field.interface'
import { useFieldContext } from '@/contexts'
import { HtmlParser } from '@/features/rte'
import { usePath } from '@/hooks'
import { useQueryEntry } from '@/hooks'
import { FieldContainer } from '@/schema'
import { scm, useSchemaState } from '@/contexts/schema'
import { Preview } from '@/ui'
import css from '../../TableField.module.css'
import { ListLinkedDocuments } from './ListLinkedDocuments'
import { useSort } from '@/schema/hooks'

export const TableList = () => {
  const { getDocPath } = usePath()
  const { field } = useFieldContext<TableListType>()
  const { rows = [] } = field
  const fieldsSchema = useSchemaState((state) => state.fields)
  const cellStyle = 'bg-neutral50 p-4 text-center rounded-md'

  const { docs: data } = useQueryEntry(field.query)
  const docs = useSort(data, field.sort)

  const renderListDocuments = useMemo(
    () =>
      rows.map(({ label, name }) => (
        <Fragment key={name}>
          <div className="bg-white font-bold grid items-center text-center">{label}</div>
          {docs.map((doc) => {
            const fieldValue = get(doc.fields, name)
            const fieldType = scm.getFieldSchema(doc._type, name)?.type

            switch (fieldType) {
              case 'html': {
                return doc.fields.description ? (
                  <HtmlParser className={clsx(cellStyle, 'text-break')}>{fieldValue}</HtmlParser>
                ) : (
                  <div key={doc._id} className={cellStyle}>
                    No description
                  </div>
                )
              }
              case 'image': {
                return fieldValue ? (
                  <Preview key={doc._id} image={fieldValue} className="w-full bg-neutral50 p-4" />
                ) : (
                  <div key={doc._id} className={cellStyle}>
                    No cover image
                  </div>
                )
              }
              case 'multilink':
              case 'children': {
                return <ListLinkedDocuments key={doc._id} doc={doc} fieldName={name} />
              }

              default:
                return (
                  <div key={doc._id + name} className={cellStyle}>
                    {fieldValue as string}
                  </div>
                )
            }
          })}
        </Fragment>
      )),
    [rows, docs, fieldsSchema],
  )

  return (
    <FieldContainer
      className="p-2 grid gap-2 w-full overflow-x-auto"
      style={{ gridTemplateColumns: `repeat(${docs.length + 1}, minmax(min-content, 200px))` }}
    >
      <div />
      {docs.map((doc) => (
        <Link
          aria-label={`link to ${doc.title}`}
          to={getDocPath(doc)}
          key={doc._id}
          className={css.col}
          style={{ backgroundColor: (doc.fields.color as string) ?? '--p' }}
        >
          {doc.title}
        </Link>
      ))}
      {renderListDocuments}
    </FieldContainer>
  )
}
