import { useMemo, useState } from 'react'
import { DocumentPermissionGroupEntry } from '@common/interfaces/documents/permission.interface'
import { User } from '@/db'
import { updatePermissionGroup, updateUserPermissionGroup } from '@/db/permissions/sync'
import { Avatar, Button } from '@/ui'
import { AddPermissionUser } from './AddPermissionUser'

interface UserPermissionGroupProps {
  group: DocumentPermissionGroupEntry
  users: User[]
  allUsers: User[]
}

export const UserPermissionGroup = ({ group, users, allUsers }: UserPermissionGroupProps) => {
  const [groupName, setGroupName] = useState(group.name)
  const userSet = useMemo(() => new Set(users.map((user) => user._id)), [users])

  const options = useMemo(
    () =>
      allUsers
        .filter((user) => !userSet.has(user._id))
        .map((user) => ({
          label: user.name || user.email,
          value: user._id,
          permissionGroups: new Set(user.permissionGroups),
        })),
    [userSet, allUsers],
  )

  const removeUser = (user: User) => {
    user.permissionGroups = user.permissionGroups.filter((id) => id !== group._id)
    updateUserPermissionGroup(user._id, group._id, 'remove')
  }

  const renameGroup = () => {
    const newName = groupName.trim()
    if (newName !== group.name) {
      updatePermissionGroup({ groupId: group._id, name: newName })
    }
  }

  const isEveryone = group._id === 'GROUP#EVERYONE'

  return (
    <section>
      <textarea
        value={groupName}
        disabled={isEveryone}
        className="text-subtitle resize-none h-6 w-1/3 p-0.5"
        onChange={(e) => setGroupName(e.target.value)}
        onBlur={renameGroup}
      />
      {!isEveryone && (
        <div className="grid grid-cols-4 gap-3 py-3 items-end">
          {users.map((user) => (
            <section key={user._id} className="border border-neutral100 p-2 rounded-xl">
              <div className="flex items-center mb-1 gap-2">
                <Avatar size={1} image={user.profileImage} letters={user.name?.[0]} />
                <h4 className="text-focus">{user.name}</h4>
                <Button
                  className="ml-auto"
                  variant="ghost"
                  icon="minus"
                  onClick={() => removeUser(user)}
                />
              </div>
              <p>{user.email}</p>
            </section>
          ))}
          <AddPermissionUser groupId={group._id} options={options} />
        </div>
      )}
    </section>
  )
}
