import { useMemo } from 'react'
import { WORKSPACE_DOCUMENT_TYPE_NAME } from '@common/interfaces/clients/document-type.interface'
import { DocumentType } from '@/db'
import { SchemaTreeContext } from '../../contexts/SchemaTreeContext'
import { convertSchemaToTree } from '../../utils'
import { Branch } from './Branch'

type SchemaTreeProps = {
  schema: DocumentType[]
}

export const SchemaTree = ({ schema }: SchemaTreeProps) => {
  const mainTree = useMemo(() => convertSchemaToTree(schema), [schema])
  const root = mainTree[WORKSPACE_DOCUMENT_TYPE_NAME]

  if (!root) return null

  return (
    <SchemaTreeContext.Provider value={{}}>
      <Branch branch={root} level={0} index={0} />
    </SchemaTreeContext.Provider>
  )
}
