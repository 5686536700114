import { useEffect, useState } from 'react'
import { Autocomplete, AutocompleteItem, AutocompleteSection } from '@nextui-org/react'
import { Key } from '@react-types/shared'
import { LinkFieldType } from '@common/interfaces/fields/link-field.interface.ts'
import { useFieldContext } from '@/contexts'
import { Doc } from '@/db'
import { FieldContainer } from '@/schema'
import { useGroup } from '@/schema/hooks'

type SingleLinkProps = {
  docs: Doc[]
  relatedDocs: Doc[]
}

export const SingleLink = ({ docs, relatedDocs }: SingleLinkProps) => {
  const { isEditable, value, saveValue, field } = useFieldContext<LinkFieldType, string[]>()
  const [selectedValue, setValue] = useState<Key>('')
  const { groupsArr } = useGroup(docs, field.group)

  useEffect(() => {
    if (relatedDocs.length !== 1) return

    setValue(relatedDocs[0]._id)
  }, [relatedDocs])

  const deleteRelation = () => {
    saveValue([])
    setValue('')
  }

  const handleSelectChange = async (docId) => {
    if (!docId && relatedDocs.length && value?.length) {
      return deleteRelation()
    }

    if (docId) {
      saveValue([docId])
      setValue(docId)
    }
  }

  if (!isEditable && !selectedValue) {
    return null
  }

  return (
    <FieldContainer>
      <Autocomplete
        allowsCustomValue={true}
        size="sm"
        label="Select"
        selectedKey={selectedValue}
        onSelectionChange={handleSelectChange}
      >
        {groupsArr
          ? groupsArr.map((group) => (
              <AutocompleteSection key={group.label} showDivider title={group.label}>
                {group.docs.map((item) => (
                  <AutocompleteItem key={item._id}>{item.title}</AutocompleteItem>
                ))}
              </AutocompleteSection>
            ))
          : docs.map((doc) => <AutocompleteItem key={doc._id}>{doc.title}</AutocompleteItem>)}
      </Autocomplete>
    </FieldContainer>
  )
}
