import { Modal, ModalContent, ModalBody } from '@nextui-org/react'
import { Preview } from '@/ui'
import { ModalState } from '@/contexts'
import { ModalImageState } from '@/contexts/PathContext'

export const ImageModal = ({ image, close }: ModalState<ModalImageState>) => (
  <Modal size="full" backdrop="opaque" isOpen={true} onClose={close}>
    <ModalContent>
      <ModalBody className="bg-neutral900 h-full p-10" onClick={close}>
        <Preview
          fit="contain"
          className="w-full h-full bg-neutral900"
          image={image}
          fileUrl={typeof image === 'string' ? image : undefined}
          iconSize={36}
        />
      </ModalBody>
    </ModalContent>
  </Modal>
)
