import { useMemo } from 'react'
import clsx from 'clsx'
import { HtmlParser } from '@/features/rte'
import { CardProps } from '@/schema/cards/cardsTypes.ts'

export const StatusCard = ({ doc, headerElements }: CardProps) => {
  const { _type, title, description } = doc
  const status = doc.fields?.status as string

  const bgColor = useMemo(() => {
    if (status === 'pending') {
      return 'bg-neutral400'
    }

    if (status === 'completed') {
      return 'bg-primary'
    }

    return 'bg-neutral200'
  }, [status])

  return (
    <div className={clsx('h-full px-5 py-7 rounded-md relative', bgColor)}>
      {headerElements}
      <p className="pb-1.5">{_type}</p>

      <h4 className="text-focus mb-1.5 break-words line-clamp-2">{title}</h4>

      {description && (
        <HtmlParser className="text-neutral700 h-[42px] break-words line-clamp-2">
          {doc.fields.description}
        </HtmlParser>
      )}
    </div>
  )
}
