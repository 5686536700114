import { createElement, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Table2DType } from '@common/interfaces/fields/table-field.interface'
import { useFieldContext } from '@/contexts'
import { CardSchema, Doc } from '@/db'
import { usePath, useQueryEntry } from '@/hooks'
import { FieldContainer } from '@/schema'
import { useSchemaState } from '@/contexts/schema'
import css from '../TableField.module.css'
import { CardFooterElements, CardHeaderElements } from '@/schema/cards/components/CardElements'
import { getCardComponent } from '@/schema/cards/List.tsx'
import { sortDocs } from '@/utils/docsSort.ts'

type Cell = { col: string; row: string; id: string; data?: Doc[] }

function idToLine(id: string) {
  return id.replace('#', '')
}

export const TableView = () => {
  const { field } = useFieldContext<Table2DType>()
  const { docs: columnDocs } = useQueryEntry(field.columns.query)
  const { docs: rowDocs } = useQueryEntry(field.rows.query)
  const { docs: data } = useQueryEntry(field.data.query)
  const { getDocPath } = usePath()

  const cols = sortDocs(columnDocs)
  const rows = sortDocs(rowDocs)

  const sortedData = useMemo(() => {
    const { colField, rowField } = field.data
    const cells = new Map<string, Cell>()

    data.forEach(async (fromObj) => {
      const colRelToIds = fromObj.fields[colField] as string[]
      const rowRelToIds = fromObj.fields[rowField] as string[]

      colRelToIds?.forEach((colToId: string) => {
        const col = idToLine(colToId)

        rowRelToIds?.forEach((rowToId: string) => {
          const row = idToLine(rowToId)

          const cellId = `${col}_${row}`
          const cell = cells.get(cellId)

          if (cell) {
            cell.data?.push(fromObj)
          } else {
            cells.set(cellId, { col, row, id: cellId, data: [fromObj] })
          }
        })
      })
    })

    const emptyCellLen = cols.length * rows.length - cells.size

    const emptyCells = Array.from({ length: emptyCellLen }, (_, i) => (
      <div key={i} className="border border-neutral200 bg-neutral50 rounded-md" />
    ))

    const dataCells = Array.from(cells.values())

    return { emptyCells, dataCells }
  }, [data, rows, cols, field])

  const gridStyles = useMemo(
    () => ({
      gridTemplateColumns: 'max-content '.concat(
        cols.map((el) => `[${idToLine(el._id)}] 1fr`).join(' '),
      ),
      gridTemplateRows: 'min-content '.concat(
        rows.map((el) => `[${idToLine(el._id)}] auto`).join(' '),
      ),
    }),
    [cols, rows],
  )

  const { emptyCells, dataCells } = sortedData

  const docsSchema = useSchemaState((state) => state.docTypes)

  const { card: rowsCard } = field.rows?.layout || {
    card: { type: 'default' },
  }

  const { card: columnsCard } = field.columns?.layout || {
    card: { type: 'default' },
  }

  const { card: layoutCard } = field.data?.layout || {
    card: { type: 'miniCard' },
  }

  const createCardElement = useCallback(
    (item: Doc, card?: CardSchema, index?: number) =>
      createElement(
        getCardComponent(card?.type || 'default'),
        {
          doc: item,
          docSchema: docsSchema[item._type],
          author: card?.elements?.author,
          date: card?.elements?.date,
          showQuote: card?.elements?.showQuote,
          index,
        },
        card?.type !== 'faqVertical' && (
          <CardHeaderElements
            docItem={item}
            date={card?.elements?.date}
            docSchema={docsSchema[item._type]}
          />
        ),
        <CardFooterElements
          docSchema={docsSchema[item._type]}
          docItem={item}
          date={card?.elements?.date}
        />,
      ),
    [rowsCard, columnsCard, docsSchema],
  )

  const dataCellContent = (cell: Cell) => (
    <div
      key={cell.id}
      className="grid content-start gap-2 px-2 font-bold p-2 bg-neutral50 rounded-md grid-cols-2"
      style={{ gridColumnStart: cell.col, gridRowStart: cell.row, gridAutoRows: 'max-content' }}
    >
      {cell.data?.map((doc, index) => (
        <Link aria-label={`link to ${doc.title}`} key={doc._id} to={getDocPath(doc)}>
          {createCardElement(doc, layoutCard, index)}
        </Link>
      ))}
    </div>
  )

  return (
    <FieldContainer className="grid gap-2 mb-10 overflow-x-auto rounded-md pt-2" style={gridStyles}>
      <div className="sticky left-0 z-20 bg-white" />
      {cols.map((item, index) => (
        <Link
          aria-label={`link to ${item.title}`}
          to={getDocPath(item)}
          key={item._id}
          className={css.col}
          style={{ gridRowStart: 1, gridColumnStart: idToLine(item._id) }}
        >
          {createCardElement(item, columnsCard, index)}
        </Link>
      ))}
      {rows.map((item, index) => (
        <Link
          aria-label={`link to ${item.title}`}
          to={getDocPath(item)}
          key={item._id}
          className={css.row}
          style={{ gridRowStart: idToLine(item._id), gridColumnStart: 1 }}
        >
          {createCardElement(item, rowsCard, index)}
        </Link>
      ))}
      {dataCells.map((cell) => dataCellContent(cell))}
      {emptyCells}
    </FieldContainer>
  )
}
