import { createElement, memo } from 'react'
import { useDocContext } from '@/contexts'
import { useDynamicLayout } from './hooks'

export const Renderer = memo(() => {
  const { schema } = useDocContext()
  const layout = useDynamicLayout(schema.layout.name, schema.name)

  if (!layout) {
    return null
  }

  return createElement(layout)
})

Renderer.displayName = 'Renderer'
