import { MouseEvent, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react'
import { Button } from '@/ui'
import { User } from '@/db'
import { deleteUser, makeUserAdmin } from '@/db/users/sync'
import css from '@/features/filter/Filter.module.css'
import { useToggle } from '@/hooks'
import { selectAccount } from '@/store/account/selectors'
import { Select } from '@/ui'
import { Avatar } from '@/ui'
import { UpdateOtherUsernameModal } from '@/ui/Users/UpdateOtherUsernameModal.tsx'
import { UpdateOtherUserPasswordModal } from '@/ui/Users/UpdateOtherUserPasswordModal.tsx'

export const UserRow = ({ userEntry }: { userEntry: User }) => {
  const account = useSelector(selectAccount)
  const [isOpened, { on, off }] = useToggle()
  const [isUsernameModalOpen, { on: openUserModal, off: closeUserModal }] = useToggle()
  const options = [
    { value: 'admin', label: 'Admin' },
    { value: 'user', label: 'Collaborator' },
  ]
  const colourStyles = {
    option: (styles: any, { isFocused }: { isFocused: boolean }) => ({
      ...styles,
      backgroundColor: isFocused && '#F5F5F5',
      color: '#2F2F2F',
    }),
  }

  const handleDeleteUser = useCallback((event: MouseEvent, userId: string) => {
    event.preventDefault()
    deleteUser(userId)
  }, [])

  const handleRoleChange = useCallback(
    (option: { label: string; value: string } | null, userId: string) => {
      makeUserAdmin(userId, option?.value === 'admin')
    },
    [],
  )

  return (
    <>
      <div className="bg-white h-16 flex items-center px-4">
        <Avatar image={userEntry.profileImage} letters={userEntry.name?.[0] ?? ''} />
        <div className="pl-[10px] basis-full">
          <div>{userEntry.name}</div>
          <div className="flex justify-between items-center">
            <div className="text-neutral700">{userEntry.email}</div>
            {!userEntry._isVerified && (
              <div className="px-2.5 py-0.5 border border-neutral400 text-neutral700 rounded-md font-bold leading-5">
                Pending
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="bg-white h-16 flex items-center pl-4">
        {account._relationType === 'admin' ? (
          <Select
            aria-label="choose role"
            className={css.selectContainer}
            value={options.find((option) => option.value === userEntry._relationType)}
            options={options}
            onChange={(option) => handleRoleChange(option, userEntry._id)}
            isSearchable={false}
            styles={colourStyles}
          />
        ) : (
          <div className="text-neutral700 capitalize">{userEntry._relationType}</div>
        )}
      </div>
      <div className="bg-white h-16 flex items-center justify-center">
        {account._relationType === 'admin' && (
          <Dropdown>
            <DropdownTrigger>
              <Button variant="ghost" icon="ellipsis" />
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              <DropdownItem
                key="excludeBtn"
                onClick={(event) => handleDeleteUser(event, userEntry._id)}
              >
                Remove
              </DropdownItem>
              <DropdownItem key="update" onClick={on}>
                Update password
              </DropdownItem>
              <DropdownItem key="edit" onClick={openUserModal}>
                Update username
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}
      </div>

      <UpdateOtherUserPasswordModal isOpened={isOpened} user={userEntry} onClose={off} />
      <UpdateOtherUsernameModal
        isOpened={isUsernameModalOpen}
        user={userEntry}
        onClose={closeUserModal}
      />
    </>
  )
}
