import { useCallback, useEffect, useState } from 'react'
import { isImg } from '@common/guards'
import { FieldValueType, ImageFieldData } from '@/db'
import { singlePromise } from '@/utils/promises'
import { checkImage } from '@/utils/fields'
import { fetchImage } from '@/api/files'
import { queryUrlCache } from '@/db/common/queries'
import { saveUrlCache } from '@/db/common/sync'

export function useImage(image: FieldValueType | undefined) {
  const [cachedImage, setCachedImage] = useState<ImageFieldData>()

  const getImage = useCallback(async () => {
    if (!isImg(image)) return

    const url = image.url || (await queryUrlCache(image.fileName))?.url
    if (url && (await checkImage(url))) {
      return setCachedImage({ ...image, url })
    }

    const img = await singlePromise(image.fileName, () => fetchImage(image))
    if (img?.url) {
      setCachedImage(img)
      saveUrlCache(img.fileName, img.url)
    }
  }, [image])

  useEffect(() => {
    getImage()
  }, [getImage])

  return cachedImage
}
