import { useEffect, useRef } from 'react'
import clsx from 'clsx'
import JSONEditor, { JSONEditorOptions } from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'
import { useDidUpdateEffect } from '@/hooks'
import css from './json-editor.module.css'

interface JsonEditorProps<V> {
  value: V
  onChange?: (value: V) => void
  options?: JSONEditorOptions
  style?: React.CSSProperties
  className?: string
}

export const JsonEditor = <V extends object | unknown[]>({
  value,
  onChange = () => null,
  options = {},
  style = {},
  className,
}: JsonEditorProps<V>): JSX.Element => {
  const editorWrapper = useRef<HTMLDivElement>(null)
  const editor = useRef<JSONEditor>()

  useEffect(() => {
    const wrapper = editorWrapper.current
    if (!wrapper) return () => null

    editor.current = new JSONEditor(wrapper, {
      mode: 'tree',
      modes: ['tree', 'code', 'view'],
      onChange: () => onChange(editor.current?.get() as V),
      onChangeText: () => onChange(editor.current?.get() as V),
      ...options,
    })
    editor.current.set(value)

    return () => {
      editor.current?.destroy()
    }
  }, [options])

  useDidUpdateEffect(() => {
    editor.current?.set(value)
  }, [value])

  return <div style={style} className={clsx(css.editor, className)} ref={editorWrapper} />
}
