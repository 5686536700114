import css from '../schema/cards/list.module.css'

const GRID_CONTAINER_CLASS_NAMES: { [key: number]: string } = {
  1: css.oneColumnGrid,
  2: css.twoColumnGrid,
  3: css.threeColumnGrid,
  4: css.fourColumnGrid,
  5: css.wideCardGrid,
  6: css.smallCardGrid,
}

export const getClassName = (number = 0) => {
  if (GRID_CONTAINER_CLASS_NAMES[number]) {
    return GRID_CONTAINER_CLASS_NAMES[number]
  }

  return css.wideCardGrid
}

const GRID_GAP_X_CLASS_NAMES: { [key: number]: string } = {
  0: css.gapX0,
  7: css.gapX7,
  11: css.gapX11,
}

const GRID_GAP_Y_CLASS_NAMES: { [key: number]: string } = {
  0: css.gapY0,
  7: css.gapY7,
  11: css.gapY11,
}

export const getGapClassNames = (gapXValue = 7, gapYValue = 7) => {
  const gapClasses: string[] = []
  if (!GRID_GAP_X_CLASS_NAMES[gapXValue]) {
    gapClasses.push(css.gapX7)
  } else {
    gapClasses.push(GRID_GAP_X_CLASS_NAMES[gapXValue])
  }
  if (!GRID_GAP_Y_CLASS_NAMES[gapYValue]) {
    gapClasses.push(css.gapY7)
  } else {
    gapClasses.push(GRID_GAP_Y_CLASS_NAMES[gapYValue])
  }
  return gapClasses
}
