import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { AllSchema } from '@/contexts/schema'
import { api } from './axios'

interface AuthResponse {
  status: boolean
  token: {
    _partId: string
    token: string
    _addedOn: string
    _userId: string
    _id: string
    tokenExpiry: string
    _modifiedOn: string
    _type: string
  }
}

export async function checkToken(tokenId: string, tokenType?: string) {
  const url = tokenType === 'verifyEmail' ? `/verify/email/${tokenId}` : `/token/${tokenId}`
  const { data } = await api.get<AuthResponse>(url)
  const { token, tokenExpiry, _userId: userId } = data.token

  return {
    token,
    tokenExpiry,
    status: data.status,
    userId,
  }
}

export interface SignupData {
  email: string
  name: string
  companyName: string
  color: string
  schema?: AllSchema
}

export async function signup(data: SignupData) {
  try {
    return await api.post('/signup', data)
  } catch (e) {
    window.Rollbar.error(e as Error)
    return null
  }
}

export async function signin(payload: { email: string; password?: string }) {
  try {
    return await api.post('/login', payload)
  } catch (e) {
    const { response } = e as AxiosError<any>
    return Promise.reject(response?.data?.message)
  }
}

export async function checkEmail(email: string) {
  try {
    await api.post('/check/email', { email })
    toast.error('The email exists')
    return false
  } catch (e) {
    const { response } = e as AxiosError<any>
    if (response?.data?.statusCode === 403) {
      toast.error(response?.data.message)
      return false
    }
    window.Rollbar.error(e as Error)
    return true
  }
}

export async function checkClient(name: string) {
  try {
    await api.post('/check/client', { name })
    return false
  } catch (e) {
    const { response } = e as AxiosError<any>
    if (response?.data?.statusCode === 403) {
      toast.error(response?.data.message)
      return false
    }
    window.Rollbar.error(e as Error)
    return true
  }
}
