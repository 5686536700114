import { useClickOutside } from './useClickOutside'
import { useToggle } from './useToggle'

export function useToggleOutside<T extends HTMLDivElement>(defaultValue = false) {
  const [open, tools] = useToggle(defaultValue)

  const ref = useClickOutside<T>(tools.off)

  return [ref, open, tools] as const
}
