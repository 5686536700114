import { useCallback, useState } from 'react'
import { Doc } from '@/db'
import { saveField } from '@/db/docs/sync.ts'
import { updateDocumentPropagateFlag } from '@/db/permissions/sync.ts'
import { useAccountRoles } from '@/hooks'
import { Button } from '@/ui'

export const ApproveButton = ({
  doc,
  fieldToChange,
  hideRejectButton,
}: {
  doc: Doc
  fieldToChange?: {
    name: string
    valueToApprove: string
    valueToDisapprove: string
    valueToReject?: string
    type: string
  }
  hideRejectButton?: boolean | undefined
}) => {
  const { editor } = useAccountRoles(doc, false)

  const [approveLoading, setApproveLoading] = useState(false)
  const [rejectLoading, setRejectLoading] = useState(false)

  const handleApprove = useCallback(async () => {
    if (fieldToChange) {
      const { name, valueToApprove, valueToDisapprove } = fieldToChange
      const statusField = doc.fields?.[name]

      setApproveLoading(true)

      if (statusField === valueToApprove) {
        await updateDocumentPropagateFlag(doc, { setViewer: false }, true)
      } else if (statusField === valueToDisapprove) {
        await updateDocumentPropagateFlag(doc, { setViewer: true }, true)
      }

      setApproveLoading(false)

      if (statusField === valueToApprove) {
        saveField(doc._id, { name, value: valueToDisapprove })
      } else {
        saveField(doc._id, { name, value: valueToApprove })
      }
    }
  }, [fieldToChange, doc])

  const handleReject = useCallback(async () => {
    if (fieldToChange) {
      const { name, valueToReject } = fieldToChange

      if (!valueToReject) return

      setRejectLoading(true)

      await updateDocumentPropagateFlag(doc, { setViewer: false }, true)

      setRejectLoading(false)

      saveField(doc._id, { name, value: valueToReject })
    }
  }, [fieldToChange, doc])

  if (!fieldToChange) {
    return null
  }

  if (!editor) {
    return null
  }

  const { name, valueToApprove } = fieldToChange

  const docStatus = doc.fields?.[name]

  return (
    <>
      <Button
        loading={approveLoading}
        onClick={handleApprove}
        variant={docStatus === valueToApprove ? 'outline' : 'default'}
      >
        {docStatus === valueToApprove ? 'Unapprove' : 'Approve'}
      </Button>
      {!hideRejectButton && (
        <Button loading={rejectLoading} onClick={handleReject} variant="outline">
          Reject
        </Button>
      )}
    </>
  )
}
