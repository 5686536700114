import { useMemo, useState } from 'react'
import clsx from 'clsx'
import { WebsocketProvider } from 'y-websocket'
import * as Y from 'yjs'
import { WhiteboardFieldType } from '@common/interfaces/fields/whiteboard-field.interface'
import { useFieldContext } from '@/contexts'
import { useToggle } from '@/hooks'
import { getYWebsocketUrl } from '@/utils'
import { FieldComponent } from '../fieldType'
import { Board, DocBar, Toolbar, WhiteboardHeader } from './components'
import { BoardContext } from './context'
import { useWhiteboardMode } from './hooks'
import { SelectedCluster, YObject } from './whiteboardTypes'

export const WhiteboardField: FieldComponent = () => {
  const { isWhiteboardMode } = useWhiteboardMode()
  const { field } = useFieldContext<WhiteboardFieldType>()

  const [, setClusterSelected] = useState<SelectedCluster>()
  const [isDocBarOpened, { toggle: toggleDocBar, off: closeDocBar }] = useToggle()

  const contextValue = useMemo(() => {
    const collabId = encodeURIComponent(field.id)

    const yDoc = new Y.Doc()
    const provider = isWhiteboardMode
      ? new WebsocketProvider(getYWebsocketUrl(), collabId, yDoc)
      : null
    const yObjects = yDoc.getMap<YObject>('yObjects')

    const getYObj = (id: string) => {
      const yObj = yObjects.get(id)

      if (!yObj) {
        const newYObj = new Y.Map<number>()
        yObjects.set(id, newYObj)
        return newYObj
      }

      return yObj
    }

    return {
      yDoc,
      provider,
      yObjects,
      getYObj,
    }
  }, [])

  return (
    <BoardContext.Provider value={contextValue}>
      {isWhiteboardMode && <WhiteboardHeader />}
      <div
        className={clsx(
          !isWhiteboardMode
            ? 'relative overflow-hidden border border-neutral100 rounded aspect-video'
            : 'h-screen',
        )}
      >
        <Board />
        <Toolbar
          setClusterSelected={setClusterSelected}
          isDocBarOpened={isDocBarOpened}
          toggleDocBar={toggleDocBar}
        />
      </div>
      {isDocBarOpened && <DocBar onClose={closeDocBar} />}
    </BoardContext.Provider>
  )
}
