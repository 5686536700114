import { Image } from 'lucide-react'
import { ImageFieldType } from '@common/interfaces/fields/image-field.interface'
import { useFieldContext } from '@/contexts'
import { ImageFieldData } from '@/db'
import { useToggle } from '@/hooks'
import { Button } from '@/ui'
import { Cover } from './Cover'
import { FieldComponent } from '../../fieldType'
import { ImageUpdateModal } from './ImageUpdateModal'
import { useSlr } from '@/store'
import { selectClientWorkspace } from '@/store/account'

export const ImageFieldEdit: FieldComponent = () => {
  const { client } = useSlr(selectClientWorkspace)
  const [isUpdateOpened, { on: openUpdateModal, off: closeUpdateModal }] = useToggle(false)
  const { field, value, saveValue } = useFieldContext<ImageFieldType, ImageFieldData>()
  const { actionText = 'Add image' } = field

  return (
    <div className="relative">
      {!value?.fileName ? (
        <Button id="addImage" aria-label="add image" variant="outline" onClick={openUpdateModal}>
          <Image size={16} />
          {actionText}
        </Button>
      ) : (
        <Cover>
          <div className="flex gap-1">
            <Button
              icon="upload"
              id="updateButton"
              aria-label="update image"
              className="bg-neutral800 opacity-90 text-sm text-white"
              onClick={openUpdateModal}
            >
              Update image
            </Button>

            <Button
              icon="trash"
              variant="outline"
              id="unsplashButton"
              aria-label="unsplash image"
              className="bg-neutral800 opacity-90 text-white"
              onClick={() => {
                saveValue({
                  source: 'unsplash',
                  _clientId: client._id,
                  fileName: '',
                  _documentId: '',
                })
              }}
            />
          </div>
        </Cover>
      )}
      {isUpdateOpened && <ImageUpdateModal onClose={closeUpdateModal} />}
    </div>
  )
}
