import clsx from 'clsx'
import { DataListParams } from '@common/interfaces/fields/field.interface.ts'
import { useFieldContext } from '@/contexts'
import { useImage } from '@/features/files'
import { Icon } from '@/ui'
import { IconName } from '@/ui/Icon'
import { showUntitledText } from '@/utils/parseString'
import { selectDocSchema, useSchemaState } from '@/contexts/schema'
import css from '../card.module.css'
import { CardProps } from '../cardsTypes'

export const SimpleCard = ({ doc, children, headerElements }: CardProps) => {
  const img = useImage(doc.fields.image)
  const { field } = useFieldContext<DataListParams>()
  const docSchema = useSchemaState((state) => selectDocSchema(state, doc._type))
  const { icon } = docSchema ?? { icon: undefined }
  const fontSize = field.layout?.card?.fontSize

  return (
    <div
      className={clsx('w-full relative h-full border rounded-lg flex flex-col', css.cover)}
      style={{
        backgroundImage: `linear-gradient(0, rgba(255, 255, 255, .5), rgba(255,255, 255, 0.3)), url(${img?.url})`,
      }}
    >
      <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
      <div className="px-3.5 pt-2.5 pb-2.5 h-[110px] grid place-items-center">
        <div className="flex items-center w-full justify-center gap-2">
          {icon && <Icon name={icon as IconName} size={16} className="shrink-0" />}
          <p
            title={doc.title}
            className={clsx('text-heading2', css.clampThreeRows)}
            style={fontSize ? { fontSize: `${fontSize} !important` } : undefined}
          >
            {showUntitledText(doc.title)}
          </p>
        </div>
      </div>
      {children}
    </div>
  )
}
