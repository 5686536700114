import { CardProps } from '@/schema/cards/cardsTypes.ts'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'
import { Icon } from '@/ui'

export const ButtonCard = ({ doc }: CardProps) => {
  const { field } = useFieldContext<ChildrenFieldType>()
  const buttonText = field.layout?.card?.text ?? doc.title
  const styles = field.layout?.card?.styles

  return (
    <div
      className="group text-subtitle relative flex items-center h-[46px] px-[35px] py-[10px] rounded-full border-2 border-neutral900 hover:!opacity-100"
      style={styles}
    >
      <span className="translate-x-0 transition-transform group-hover:translate-x-[-12px]">
        {buttonText}
      </span>
      <Icon
        name="arrow-right"
        size={20}
        className="absolute right-[18px] top-[50%] translate-y-[-50%] opacity-0 transition-[opacity] group-hover:opacity-100"
      />
    </div>
  )
}
