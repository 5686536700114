import { MouseEvent } from 'react'
import clsx from 'clsx'
import { WhiteboardFieldType } from '@common/interfaces/fields/whiteboard-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { createInteraction } from '@/db/interactions/sync'
import { Button } from '@/ui'
import { camelToCapitalizeWords } from '@/utils/parseString'
import { useFieldsTypes, useWhiteboardMode } from '../hooks'
import { SelectedCluster } from '../whiteboardTypes'
import { ClusterIcon, DocIcon, NoteIcon } from './icons'

interface ToolbarProps {
  setClusterSelected: (selected: SelectedCluster) => void
  toggleDocBar: (event: MouseEvent) => void
  isDocBarOpened: boolean
}

export const Toolbar = ({ setClusterSelected, toggleDocBar, isDocBarOpened }: ToolbarProps) => {
  const { doc } = useDocContext()
  const { field } = useFieldContext<WhiteboardFieldType>()
  const { isWhiteboardMode, toggleWhiteboardMode } = useWhiteboardMode()
  const { clusterObjectFields = [] } = field
  const { fieldTypes } = useFieldsTypes(clusterObjectFields.map((c) => c.fieldName))

  const addNote = () => {
    createInteraction(doc._id, {
      type: 'note',
      refId: field.name,
      value: {
        color: '',
        pos: { x: 0, y: 0 },
        text: 'New note',
      },
    })
  }

  if (!isWhiteboardMode) {
    return (
      <Button
        variant="outline"
        icon="maximize-2"
        className="absolute left-3 top-3"
        onClick={() => toggleWhiteboardMode(field.name)}
      />
    )
  }

  return (
    <div className="absolute left-4 top-1/4 w-16 grid gap-0.5 p-1 rounded-md shadow-xl bg-white">
      <Button
        variant="ghost"
        className="h-auto px-0 py-1.5 grid gap-0.5 justify-items-center text-center"
        onClick={addNote}
      >
        <div className="pl-1" draggable>
          <NoteIcon />
        </div>
        <span className="text-xs">Note</span>
      </Button>
      {fieldTypes.map((fieldType) => (
        <Button
          key={fieldType.fieldName}
          variant="ghost"
          className="h-auto px-0 py-1.5 grid gap-0.5 justify-items-center text-center"
          onClick={() => setClusterSelected(fieldType)}
        >
          <div className="pl-1">
            <ClusterIcon />
          </div>
          <span className="text-xs">{camelToCapitalizeWords(fieldType.docTypes[0])}</span>
        </Button>
      ))}
      <Button
        variant="ghost"
        className={clsx(
          'h-auto px-0 py-1.5 grid gap-0.5 justify-items-center text-center',
          isDocBarOpened && 'btn-active',
        )}
        onClick={toggleDocBar}
      >
        <div className="pl-1">
          <DocIcon />
        </div>
        <span className="text-xs">Document</span>
      </Button>
    </div>
  )
}
