import { AccordionFieldType } from '@common/interfaces/fields/area-fields.interace'
import { useFieldContext } from '@/contexts'
import { AreaRenderer } from '@/schema'
import { Collapse } from '@/ui'

export const AccordionView = () => {
  const { field } = useFieldContext<AccordionFieldType>()

  if (!field.accordionAreas) {
    return null
  }

  return (
    <div className="pb-5">
      {field.accordionAreas?.map((accordion) => (
        <Collapse
          key={accordion.value}
          title={accordion.label}
          className="text-sm text-neutral900 py-3.5"
          borderClassName="border-solid border-neutral200 border rounded-md mb-[10px]"
          arrowSize={20}
          arrowColor="#1E1E1E"
        >
          <div className="pl-4 py-4">
            <AreaRenderer areaName={accordion.value} />
          </div>
        </Collapse>
      ))}
    </div>
  )
}
