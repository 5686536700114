import { FC, useMemo } from 'react'
import clsx from 'clsx'
import { useToggleOutside } from '@/hooks'
import { DropdownContext } from './context'
import { DropdownMenu } from './DropdownMenu'
import { DropdownToggle } from './DropdownToggle'

export type DropdownProps = {
  isOpen?: boolean
  horizontal?: 'center' | 'right' | 'left' | number
  className?: string
  children: string | JSX.Element | JSX.Element[]
}

type DropdownComponent = FC<DropdownProps> & {
  Toggle: typeof DropdownToggle
  Menu: typeof DropdownMenu
}

export const Dropdown: DropdownComponent = ({ className, children }) => {
  const [ref, isOpen, { toggle, off }] = useToggleOutside()

  const values = useMemo(() => ({ isOpen, toggle, off }), [isOpen])

  return (
    <div className={clsx('relative', className)} ref={ref}>
      <DropdownContext.Provider value={values}>{children}</DropdownContext.Provider>
    </div>
  )
}

Dropdown.Toggle = DropdownToggle
Dropdown.Menu = DropdownMenu
