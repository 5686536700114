import Dexie, { Table } from 'dexie'
import { DocumentPermissionGroupEntry } from '@common/interfaces/documents/permission.interface'
import { closeSession } from '@/store/session/slice'
import { LS } from '@/utils'
import {
  Client,
  Doc,
  DocPermissions,
  Interaction,
  Relation,
  UrlCache,
  User,
  Workspace,
  WorkspaceFlags,
} from './dbTypes'

class WorkspaceDb extends Dexie {
  docs: Table<Doc>
  users: Table<User>
  relations: Table<Relation>
  interactions: Table<Interaction>
  permissions: Table<DocPermissions>
  permissionGroups: Table<DocumentPermissionGroupEntry>
  urls: Table<UrlCache>

  constructor(dbName: string) {
    super(dbName)
    this.version(4.8).stores({
      docs: '_id, _slug, _userId, _type, _parentId, title, *_ancestors, userId, secondaryId',
      relations:
        '_id, [fromId+fieldName], [toId+fieldName], [toId+fieldName+fromDocType], [fromId+fieldName+toDocType+toId], [fromId+fieldName+toId], [fromId+toId], [fromDocType+reverseFieldName], toId, relationType',
      interactions: '_id, [_type+_partId], _userId, _refId, _sourceId',
      users: '_id, email, _emailId, _sourceId, name',
      permissions: '_id, *permissions',
      permissionGroups: '_id, name',
      urls: 'name',
    })
  }
}

class CommonDb extends Dexie {
  clients: Table<Client>

  workspaces: Table<Workspace>

  activeWorkspace: Workspace

  activeClient: Client

  activeAccount: User

  accountPermissions: Set<string>

  ws: WorkspaceDb

  flags: WorkspaceFlags = {}

  setWorkspaceDb(activeWorkspace: Workspace, activeClient: Client, account: User) {
    if (
      this.ws &&
      this.activeWorkspace._id === activeWorkspace._id &&
      this.activeClient._id === activeClient._id
    ) {
      this.activeWorkspace = activeWorkspace
      this.activeClient = activeClient
      this.activeAccount = account

      return this.ws
    }

    this.activeWorkspace = activeWorkspace
    this.activeClient = activeClient
    this.activeAccount = account

    this.flags = { ...this.flags, ...activeWorkspace._envVars }
    const localPermissionsFlag = LS.get('enablePermissions')
    if (localPermissionsFlag !== null) {
      this.flags.enablePermissions = localPermissionsFlag
    }

    this.ws = new WorkspaceDb(`${activeWorkspace._partId}_${activeWorkspace._id}`)
    return this.ws
  }

  constructor() {
    super('hypha')
    this.version(3.3).stores({
      clients: '_id, _userId',
      workspaces: '_id, _titleSlug, _partId',
    })
  }
}

export const db = new CommonDb()

function onDBClose(error) {
  closeSession()
  window.Rollbar.error('DatabaseClosed error', error)
}

db.on('close', onDBClose)
db.ws?.on('close', onDBClose)
