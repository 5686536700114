import { useMemo } from 'react'
import { useDocContext } from '@/contexts'
import { SelectedCluster } from '../whiteboardTypes'
import { isLinkField } from '../whiteboardUtils'

export function useFieldsTypes(fieldNames?: string[]) {
  const { schema } = useDocContext()

  return useMemo(() => {
    const fieldTypes: SelectedCluster[] = []
    const allTypes: string[] = []

    fieldNames?.forEach((fieldName) => {
      const fieldItem = schema.fields.find((item) => item.name === fieldName)

      if (!isLinkField(fieldItem)) return

      const docTypes = fieldItem?.allowed ?? []

      fieldTypes.push({
        fieldName,
        docTypes,
      })

      allTypes.push(...docTypes)
    })

    return { fieldTypes, allTypes, isLinkField }
  }, [schema])
}
