import type {
  FieldTemplatePropertyType,
  FieldTemplatePropertyValue,
  SchemaTemplate,
} from '../types'

const getFieldTemplate = (
  required: boolean,
  value: FieldTemplatePropertyValue,
  type: FieldTemplatePropertyType,
) => ({
  required,
  value,
  type,
})

const layoutProperty = {
  common: {
    classes: getFieldTemplate(false, [], 'multiselect'),
  },
  datalist: {
    columns: getFieldTemplate(false, '', 'number'),
    gapX: getFieldTemplate(false, '', 'number'),
    gapY: getFieldTemplate(false, '', 'number'),
    showSort: getFieldTemplate(false, false, 'boolean'),
    showSearch: getFieldTemplate(false, false, 'boolean'),
    showTypes: getFieldTemplate(false, false, 'boolean'),
    showAddActionInView: getFieldTemplate(false, false, 'boolean'),
    card: {
      required: false,
      value: {
        type: getFieldTemplate(false, '', 'select'),
        footerElements: getFieldTemplate(false, [], 'multiselect'),
        imageFit: getFieldTemplate(false, false, 'boolean'),
        countElement: {
          required: false,
          value: {
            icon: getFieldTemplate(false, '', 'icon'),
            type: getFieldTemplate(false, '', 'text'),
          },
          type: 'object',
        },
        elements: {
          required: false,
          value: {
            author: getFieldTemplate(false, false, 'boolean'),
            date: getFieldTemplate(false, false, 'boolean'),
          },
          type: 'object',
        },
        query: {
          required: false,
          value: {
            type: getFieldTemplate(false, '', 'text'),
            fieldName: getFieldTemplate(false, '', 'text'),
            resultType: getFieldTemplate(false, '', 'text'),
          },
          type: 'object',
        },
      },
      type: 'object',
    },
  },
}

const axisProperty = {
  min: getFieldTemplate(true, '', 'number'),
  max: getFieldTemplate(true, '', 'number'),
  minLabel: getFieldTemplate(true, '', 'text'),
  maxLabel: getFieldTemplate(true, '', 'text'),
  title: getFieldTemplate(true, '', 'text'),
  display: getFieldTemplate(false, true, 'boolean'),
}

const sortOrderProperty = {
  name: getFieldTemplate(true, '', 'text'),
  isDesc: getFieldTemplate(false, false, 'boolean'),
}

const relationFilterProperty = {
  type: getFieldTemplate(false, '', 'select'),
  docTypes: getFieldTemplate(false, [], 'multiselect'),
  scope: getFieldTemplate(false, '', 'select'),
  scopeLevel: getFieldTemplate(false, '', 'number'),
  fieldName: getFieldTemplate(false, '', 'text'),
}

const queryEntryProperty = {
  scope: getFieldTemplate(false, '', 'select'),
  scopeLevel: getFieldTemplate(false, '', 'number'),
  // docTypes: {
  //   required: false,
  //   value: [],
  //   type: 'multiselect',
  // },
  types: getFieldTemplate(false, [], 'multiselect'),
  limit: getFieldTemplate(false, '', 'number'),
  resultType: getFieldTemplate(false, '', 'select'),
  source: getFieldTemplate(false, '', 'select'),
  viewOnly: getFieldTemplate(false, false, 'boolean'),
  fieldFilter: getFieldTemplate(false, '', 'code'),
  relationFilter: {
    required: false,
    value: {
      ...relationFilterProperty,
    },
    type: 'object',
  },
  sortOrder: {
    required: false,
    value: {
      ...sortOrderProperty,
    },
    type: 'array',
  },
}

const metricsProperty = {
  label: getFieldTemplate(true, '', 'text'),
  icon: getFieldTemplate(true, '', 'icon'),
  iconColor: getFieldTemplate(true, '', 'color'),
  color: getFieldTemplate(true, '', 'color'),
  query: {
    required: true,
    value: {
      ...queryEntryProperty,
    },
    type: 'object',
  },
  field: getFieldTemplate(false, '', 'text'),
  distinct: getFieldTemplate(false, false, 'boolean'),
}

const filterByProperty = {
  type: getFieldTemplate(false, '', 'text'),
  reverseFieldName: getFieldTemplate(false, '', 'text'),
}

const dataListParams = {
  layout: {
    required: false,
    value: {
      ...layoutProperty.datalist,
    },
    type: 'object',
  },
  filters: getFieldTemplate(false, '', 'select'),
  filterBy: {
    required: false,
    value: {
      ...filterByProperty,
    },
    type: 'object',
  },
  sort: {
    required: false,
    value: {
      ...sortOrderProperty,
    },
    type: 'array',
  },
}

const conditionProperty = {
  readonly: getFieldTemplate(false, false, 'condition'),
  editable: getFieldTemplate(false, false, 'condition'),
  hiddenView: getFieldTemplate(false, false, 'condition'),
  hiddenViewIfEmpty: getFieldTemplate(false, false, 'condition'),
  hiddenEdit: getFieldTemplate(false, false, 'condition'),
}

const linkFieldProperties = {
  reverseName: getFieldTemplate(false, '', 'text'),
  allowed: getFieldTemplate(false, [], 'multiselect'),
  hideName: getFieldTemplate(false, false, 'boolean'),
  isMultiSelect: getFieldTemplate(false, false, 'boolean'),
  condition: {
    required: false,
    value: {
      ...conditionProperty,
    },
    type: 'object',
  },
  reverseLinks: {
    required: false,
    value: {
      documentType: getFieldTemplate(true, '', 'text'),
      fieldName: getFieldTemplate(true, '', 'text'),
    },
    type: 'array',
  },
  ...dataListParams,
}

const commonFields: SchemaTemplate = {
  name: getFieldTemplate(true, '', 'text'),
  niceName: getFieldTemplate(false, '', 'text'),
  helpText: getFieldTemplate(false, '', 'text'),
  helpPanel: getFieldTemplate(false, '', 'text'),
  type: getFieldTemplate(true, '', 'text'),
  viewComponent: getFieldTemplate(false, '', 'text'),
  area: getFieldTemplate(false, '', 'text'),
}

export const fieldTemplates: Record<string, any> = {
  image: {
    ...commonFields,
    type: getFieldTemplate(true, 'image', 'text'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    layout: {
      required: false,
      value: {
        ...layoutProperty.common,
      },
      type: 'object',
    },
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
  },
  text: {
    ...commonFields,
    type: getFieldTemplate(true, 'text', 'text'),
    placeholder: getFieldTemplate(false, '', 'text'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    checkDuplicate: getFieldTemplate(false, false, 'boolean'),
    layout: {
      required: false,
      value: {
        ...layoutProperty.common,
      },
      type: 'object',
    },
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
  },
  banner: {
    ...commonFields,
    type: getFieldTemplate(true, 'banner', 'text'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    layout: {
      required: false,
      value: {
        ...layoutProperty.common,
      },
      type: 'object',
    },
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
  },
  html: {
    ...commonFields,
    type: getFieldTemplate(true, 'description', 'text'),
    placeholder: getFieldTemplate(false, '', 'text'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    layout: {
      required: false,
      value: {
        ...layoutProperty.common,
        backgroundColor: getFieldTemplate(false, '', 'color'),
      },
      type: 'object',
    },
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
  },
  dashboard: {
    ...commonFields,
    type: getFieldTemplate(true, 'dashboard', 'text'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    layout: {
      required: false,
      value: {
        ...layoutProperty.common,
      },
      type: 'object',
    },
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
    metrics: {
      required: false,
      value: {
        ...metricsProperty,
      },
      type: 'array',
    },
  },
  children: {
    ...commonFields,
    type: getFieldTemplate(true, 'children', 'text'),
    reverseName: getFieldTemplate(false, '', 'text'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    autoUnsplash: getFieldTemplate(false, false, 'boolean'),
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
    // group: {
    //   required: false,
    //   value: {},
    //   type: 'object',
    // },
    allowed: getFieldTemplate(false, [], 'multiselect'),
    ...dataListParams,
  },
  query: {
    ...commonFields,
    type: getFieldTemplate(true, 'query', 'text'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
    query: {
      required: true,
      value: {
        ...queryEntryProperty,
      },
      type: 'object',
    },
    ...dataListParams,
  },
  tabs: {
    ...commonFields,
    type: getFieldTemplate(true, 'tabs', 'text'),
    tabNumber: getFieldTemplate(false, '', 'number'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    layout: {
      required: false,
      value: {
        ...layoutProperty.common,
        backgroundColor: getFieldTemplate(false, '', 'color'),
      },
      type: 'object',
    },
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
    tabAreas: {
      required: false,
      value: {
        value: getFieldTemplate(true, '', 'text'),
        label: getFieldTemplate(true, '', 'text'),
      },
      type: 'array',
    },
  },
  accordion: {
    ...commonFields,
    type: getFieldTemplate(true, 'accordion', 'text'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    layout: {
      required: false,
      value: {
        ...layoutProperty.common,
        backgroundColor: getFieldTemplate(false, '', 'color'),
      },
      type: 'object',
    },
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
    accordionAreas: {
      required: false,
      value: {
        value: getFieldTemplate(true, '', 'text'),
        label: getFieldTemplate(true, '', 'text'),
      },
      type: 'array',
    },
  },
  status: {
    ...commonFields,
    type: getFieldTemplate(true, 'status', 'text'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    layout: {
      required: false,
      value: {
        ...layoutProperty.common,
        backgroundColor: getFieldTemplate(false, '', 'color'),
      },
      type: 'object',
    },
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
    options: {
      required: true,
      value: {
        label: getFieldTemplate(true, '', 'text'),
        value: getFieldTemplate(true, '', 'text'),
      },
      type: 'array',
    },
  },
  color: {
    ...commonFields,
    type: getFieldTemplate(true, '', 'color'),
    placeholder: getFieldTemplate(false, '', 'text'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    layout: {
      required: false,
      value: {
        ...layoutProperty.common,
        backgroundColor: getFieldTemplate(false, '', 'color'),
      },
      type: 'object',
    },
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
  },
  diagram: {
    ...commonFields,
    type: getFieldTemplate(true, 'diagram', 'text'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
    axisX: {
      required: true,
      value: {
        ...axisProperty,
      },
      type: 'object',
    },
    axisY: {
      required: true,
      value: {
        ...axisProperty,
      },
      type: 'object',
    },
    backgroundColor: getFieldTemplate(false, '', 'color'),
    diagramItemDataFieldName: getFieldTemplate(false, '', 'text'),
    blocks: {
      required: true,
      value: {
        x: getFieldTemplate(true, '', 'number'),
        y: getFieldTemplate(true, '', 'number'),
        z: getFieldTemplate(false, '', 'number'),
        title: getFieldTemplate(false, '', 'text'),
        label: getFieldTemplate(false, '', 'text'),
        width: getFieldTemplate(false, '', 'number'),
        height: getFieldTemplate(false, '', 'number'),
        opacity: getFieldTemplate(false, '', 'number'),
        angle: getFieldTemplate(false, '', 'number'),
        backgroundColor: getFieldTemplate(false, '', 'color'),
        textColor: getFieldTemplate(false, '', 'text'),
        showCoverImage: getFieldTemplate(false, false, 'boolean'),
      },
      type: 'array',
    },
    query: {
      required: true,
      value: {
        ...queryEntryProperty,
      },
      type: 'object',
    },
    ...dataListParams,
  },
  multilink: {
    ...commonFields,
    type: getFieldTemplate(true, 'multilink', 'text'),
    ...linkFieldProperties,
  },
  singlelink: {
    ...commonFields,
    type: getFieldTemplate(true, 'singlelink', 'text'),
    ...linkFieldProperties,
  },
  reverselink: {
    ...commonFields,
    type: getFieldTemplate(true, 'reverselink', 'text'),
    ...linkFieldProperties,
  },
  boolean: {
    ...commonFields,
    type: getFieldTemplate(true, 'boolean', 'text'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    layout: {
      required: false,
      value: {
        ...layoutProperty.common,
        backgroundColor: getFieldTemplate(false, '', 'color'),
      },
      type: 'object',
    },
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
  },
  geo: {
    ...commonFields,
  },
  table: {
    ...commonFields,
    type: getFieldTemplate(true, 'table', 'text'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
    columns: {
      required: true,
      value: {
        query: {
          required: true,
          value: {
            ...queryEntryProperty,
          },
          type: 'object',
        },
        ...dataListParams,
      },
      type: 'object',
    },
    rows: {
      required: true,
      value: {
        query: {
          required: true,
          value: {
            ...queryEntryProperty,
          },
          type: 'object',
        },
        ...dataListParams,
      },
      type: 'object',
    },
    data: {
      required: true,
      value: {
        rowField: getFieldTemplate(true, '', 'text'),
        colField: getFieldTemplate(true, '', 'text'),
        query: {
          required: true,
          value: {
            ...queryEntryProperty,
          },
          type: 'object',
        },
        ...dataListParams,
      },
      type: 'object',
    },
  },
  keyValue: {
    ...commonFields,
    type: getFieldTemplate(true, 'keyValue', 'text'),
    hideName: getFieldTemplate(false, false, 'boolean'),
    layout: {
      required: false,
      value: {
        ...layoutProperty.common,
        backgroundColor: getFieldTemplate(false, '', 'color'),
      },
      type: 'object',
    },
    condition: {
      required: false,
      value: {
        ...conditionProperty,
      },
      type: 'object',
    },
    fields: {
      required: false,
      value: {
        name: getFieldTemplate(true, '', 'text'),
        type: getFieldTemplate(true, '', 'select'),
      },
      type: 'array',
    },
  },
}
