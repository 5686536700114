import { Bookmark as BookmarkIcon } from 'lucide-react'
import { Tooltip } from '@/ui'
import { CardElementsProps } from './CardElements/cardElements'
import { useCallback, useEffect, useState } from 'react'
import { saveField } from '@/db/docs/sync'
import { useSlr } from '@/store'
import { selectAccount } from '@/store/account'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface'

export const Bookmark = ({ docItem }: CardElementsProps) => {
  const { field } = useFieldContext<ChildrenFieldType>()
  const fieldName = 'bookmarked'
  const user = useSlr(selectAccount)
  const [value, setValue] = useState<string[]>((docItem?.fields.bookmarked as string[]) ?? [])
  const [checked, setChecked] = useState(false)
  const changeHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault()
      if (!docItem) return
      setValue((prev) => {
        const index = prev.findIndex((id) => id === user._id)
        if (index !== -1) {
          const data = [...prev]
          data.splice(index, 1)
          saveField(docItem._id, { name: fieldName, value: data })
          return data
        } else {
          saveField(docItem._id, { name: fieldName, value: [...prev, user._id] })
          return [...prev, user._id]
        }
      })
    },
    [docItem],
  )

  const backgroundColor = field.layout?.card?.iconColor ?? 'white'

  useEffect(() => {
    if (value?.length) {
      const isSelected = value.includes(user._id)
      setChecked(isSelected)
    } else {
      setChecked(false)
    }
  }, [value])

  return (
    <div className="flex gap-1 items-center relative z-50">
      {
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          className="rounded-full aspect-square grid place-items-center items-center justify-center w-10 h-10"
          style={{ background: backgroundColor }}
          onClick={(e) => {
            changeHandler(e)
          }}
        >
          <Tooltip content={checked ? 'Added to my collection' : 'Add to my collection'}>
            <BookmarkIcon
              fill={checked ? '#000' : backgroundColor ? backgroundColor : '#fff'}
              color="#1E1E1E"
              size={18}
            />
          </Tooltip>
        </div>
      }
    </div>
  )
}
