import { PropsWithChildren } from 'react'
import { isImg } from '@common/guards'
import { ImageFieldType } from '@common/interfaces/fields/image-field.interface'
import { useFieldContext } from '@/contexts'
import { ImageFieldData } from '@/db'
import { Preview } from '@/ui'
import { ImageFieldProps } from '../types'
import { CoverAuthor } from './CoverAuthor'
import clsx from 'clsx'

export const Cover = ({
  image,
  className,
  children,
  classes,
}: PropsWithChildren<ImageFieldProps>) => {
  const { value, field } = useFieldContext<ImageFieldType, ImageFieldData>()
  const { ...containerStyles } = field.styles ?? {}
  const imageField = isImg(image) ? image : value
  const secondaryStyles = field.secondaryStyles

  if (!imageField?.fileName) {
    return null
  }

  return (
    <figure
      className={className ?? 'relative w-full rounded-lg bg-neutral200 cover-image min-h-14'}
      style={containerStyles}
    >
      <Preview
        image={imageField}
        className={clsx('w-full object-fill rounded-lg', classes)}
        styles={secondaryStyles}
      />
      <div className="absolute bottom-0 w-full p-3 flex flex-row-reverse justify-between items-end">
        {children}
        <CoverAuthor image={imageField} />
      </div>
    </figure>
  )
}
