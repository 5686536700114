import { KeyboardEvent, useState } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createLineBreakNode, $createParagraphNode, $createTextNode, $getRoot } from 'lexical'
import { HtmlFieldType } from '@common/interfaces/fields/html-field.interface'
import { getChatGptResponse } from '@/api/ai'
import { useFieldContext } from '@/contexts'
import { useAIContext, useAutoSizeTextArea, useToggle } from '@/hooks'
import { Button, Icon } from '@/ui'
import { colors } from '@/ui/theme'
import { getHtmlString } from '@/utils/parseString'
import { useRteContext } from '../../hooks/useRteContext'
import css from './ai-plugin.module.css'

export const AIPlugin = () => {
  const [request, setRequest] = useState('')
  const [loading, { on, off }] = useToggle(false)
  const { toggleAI } = useRteContext()
  const [editor] = useLexicalComposerContext()
  const textAreaRef = useAutoSizeTextArea(request)
  const { value } = useFieldContext<HtmlFieldType, string>()
  const requestContext = useAIContext()

  const onSubmit = async () => {
    on()
    const history = getHtmlString(value)
    const response = await getChatGptResponse(request, requestContext, history)
    const responseText = response.replace(/^[\n]+/, '')

    editor.update(() => {
      const root = $getRoot()

      const responseParagraph = $createParagraphNode()
      responseParagraph.append($createTextNode(responseText))
      root.append(responseParagraph)

      const breakParagraph = $createParagraphNode()
      breakParagraph.append($createLineBreakNode())
      root.append(breakParagraph)

      off()
      setRequest('')
    })
  }

  const onEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      onSubmit()
    }
  }

  return (
    <div className={css.input}>
      <Icon name="wand-sparkles" size={16} color={colors.primary} />
      <textarea
        placeholder="Ask AI to generate..."
        value={request}
        onChange={(e) => setRequest(e.target.value)}
        onKeyDown={onEnter}
        ref={textAreaRef}
        disabled={loading}
      />
      <Button
        variant="outline"
        aria-label="Send AI prompt"
        icon="arrow-up"
        loading={loading}
        onClick={onSubmit}
      />
      <Button
        icon="x"
        aria-label="Close AI helper"
        variant="ghost"
        onClick={() => toggleAI(false)}
      />
    </div>
  )
}
