import nunjucks from 'nunjucks'
import emailTemplate from '@/assets/invite_email.html?raw'
import { inviteUserToDocument } from '@/api/docs'

interface TemplateScope {
  docUrl: string
  docTitle: string
  userName: string
  senderName: string
  email: string
  subject: string
}

export function sendInviteEmail(params: TemplateScope) {
  const { email, subject, ...scope } = params

  const htmlBody = nunjucks.renderString(emailTemplate, {
    domain: window.location.origin,
    ...scope,
  })

  return inviteUserToDocument({
    email,
    subject,
    htmlBody,
  })
}
