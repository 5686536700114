import clsx from 'clsx'
import { useLiveQuery } from 'dexie-react-hooks'
import { FileText } from 'lucide-react'
import { queryDoc } from '@/db/docs/queries'
import { Icon } from '@/ui'
import { IconName } from '@/ui/Icon'
import { showUntitledText } from '@/utils/parseString'
import { selectDocSchema, useSchemaState } from '@/contexts/schema'
import css from '../card.module.css'
import { CardProps } from '../cardsTypes'

export const ListItemCard = ({ doc, children }: CardProps) => {
  const parentDoc = useLiveQuery(() => queryDoc({ _id: doc._parentId }), [doc._parentId])
  const schema = useSchemaState((state) => selectDocSchema(state, parentDoc?._type))

  return (
    <div className={clsx('relative h-12 border-t border-neutral300', css.container)}>
      <div className="flex items-center">
        <div className="flex mr-2.5 bg-neutral100 w-7 h-7 items-center justify-center rounded-md">
          {schema?.icon ? (
            <Icon name={schema.icon as IconName} color="#5F6160" size={16} />
          ) : (
            <FileText color="#5F6160" size={16} />
          )}
        </div>
        <div className={css.clampThreeRows}>{showUntitledText(doc.title)}</div>
      </div>

      {children}
    </div>
  )
}
