import { selectDocSchema, useSchemaState } from '@/contexts/schema'
import { WORKSPACE_DOCUMENT_TYPE_NAME } from '@common/interfaces/clients/document-type.interface.ts'
import { useSlr } from '@/store'
import { selectAccount } from '@/store/account'
import { useLiveQuery } from 'dexie-react-hooks'
import { queryUserDoc } from '@/db/docs/queries.ts'

export function useActivityLabel() {
  const workspaceSchema = useSchemaState((state) =>
    selectDocSchema(state, WORKSPACE_DOCUMENT_TYPE_NAME),
  )

  const account = useSlr(selectAccount)
  const userDoc = useLiveQuery(() => queryUserDoc(account._id), [account._id])

  const labelForActivity = workspaceSchema?.labelForActivity

  if (!labelForActivity) {
    return 'other'
  }

  const { byFieldName, byUserRole } = labelForActivity

  if (byFieldName) {
    return (userDoc?.fields?.[byFieldName] as string) ?? 'other'
  }

  if (byUserRole) {
    return account._relationType
  }
}
