import clsx from 'clsx'
import { showUntitledText } from '@/utils/parseString'
import css from '../card.module.css'
import { CardProps } from '../cardsTypes'
import { CardDocType } from '../components/CardDocType'

export const NoImageCard = ({ doc, docSchema, children, headerElements }: CardProps) => (
  <div className="relative w-full h-full border rounded-lg bg-white flex flex-col">
    <div className="px-4 pt-4 pb-2.5">
      {headerElements}
      <CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="mb-1.5" />
      <p className={clsx('text-focus', css.clampThreeRows)}>{showUntitledText(doc.title)}</p>
    </div>
    {children}
  </div>
)
