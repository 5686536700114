import { Button, Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react'
import { useDocContext } from '@/contexts'
import { FieldItem } from '@/schema/components'
import { Icon } from '@/ui'
import { QuickPanelCreate } from './QuickPanelCreate'
import { useAppDispatch, useSlr } from '@/store'
import { selectIsPanelOpen } from '@/store/docs/selectors'
import { setIsPanelOpen } from '@/store/docs'

export const QuickPanelButtons = () => {
  const { areas } = useDocContext()
  const fields = areas.get('panel')
  const isOpen = useSlr(selectIsPanelOpen)
  const dispatch = useAppDispatch()

  return fields?.map((field) => (
    <Popover
      isOpen={isOpen}
      onOpenChange={(open) => dispatch(setIsPanelOpen(open))}
      key={field.name}
      id={'panel' + field.name}
      placement="right"
      offset={16}
      style={{ zIndex: '20' }}
      classNames={{
        base: 'top-[-4px] h-[80vh] z-10 before:bg-default-200',
      }}
      backdrop="opaque"
      shadow="lg"
      radius="md"
    >
      <PopoverTrigger>
        <Button
          startContent={<Icon name="book" size={16} className="mr-3" />}
          variant="light"
          className={
            'h-auto w-full rounded justify-start gap-0 contrast-75 text-inherit items-center py-1.5 px-2 data-[hover=true]:bg-primary data-[hover=true]:bg-opacity-10 overflow-hidden'
          }
        >
          {field.niceName}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="h-[98vh] w-[840px] z-10 pb-2 px-4 block overflow-y-auto pt-12">
        <div className="overflow-y-auto w-full h-full px-2">
          <FieldItem field={field}>
            <QuickPanelCreate />
          </FieldItem>
        </div>
      </PopoverContent>
    </Popover>
  ))
}
