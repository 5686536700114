import { useFieldContext } from '@/contexts'
import { queryDocWithSchema } from '@/db/docs/queries'
import { createOneRelation, deleteOneRelation, updateRelationMeta } from '@/db/relations/sync'
import { DragEnd } from '../hooks'
import { BoardPoint, DocEntry } from '../whiteboardTypes'
import { getClusterPoint, isLinkField } from '../whiteboardUtils'
import { BoardObject } from './BoardObject'

interface DocumentProps extends DocEntry {
  inCluster?: boolean
}

export const Document = ({ doc, rel, inCluster }: DocumentProps) => {
  const { field, saveValue } = useFieldContext()
  const pos: BoardPoint = rel._meta?.[field.name]

  const handleEdit = (value: string) => {
    doc.title = value
    saveValue(value)
  }

  const updatePosition: DragEnd = (point: BoardPoint, cluster) => {
    if (inCluster && cluster) return false

    if (!inCluster && cluster) {
      queryDocWithSchema(cluster.id).then((clusterData) => {
        if (!clusterData) return

        const { doc: clusterDoc, schema } = clusterData
        const clusterField = schema.fields.find(
          (f) => isLinkField(f) && f.allowed?.includes(doc._type),
        )
        if (!isLinkField(clusterField)) return

        createOneRelation({
          fromId: clusterDoc._id,
          fromDocType: clusterDoc._type,
          toId: doc._id,
          toDocType: doc._type,
          fieldName: clusterField.name,
          relationType: 'multilink',
          reverseFieldName: clusterField.reverseName ?? '',
          _meta: {
            [field.name]: getClusterPoint(cluster, point),
          },
        })
      })

      return rel.fromId !== cluster.id
    }

    if (inCluster && !cluster) {
      deleteOneRelation(rel)
      return false
    }

    updateRelationMeta(rel._id, field.name, point)
    return false
  }

  const deleteNote = () => {
    deleteOneRelation(rel)
  }

  return (
    <BoardObject
      data={doc}
      pos={pos}
      width={214}
      height={206}
      dragEnd={updatePosition}
      onEdit={handleEdit}
      onDelete={deleteNote}
    >
      <div className="px-3 py-5">
        <p className="font-bold">{doc.title}</p>
        <p className="text-sm uppercase text-neutral500 mb-2">{doc._type}</p>
      </div>
    </BoardObject>
  )
}
