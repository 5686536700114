import { IFrameFieldType } from '@common/interfaces/fields/iframe-field.interface'
import { useFieldContext } from '@/contexts'
import { FieldContainer } from '@/schema'
import { FieldComponent } from '../fieldType'
import { IFrameEdit } from './components/IFrameEdit'

export const IFrameField: FieldComponent = () => {
  const { value, field, isEditable } = useFieldContext<IFrameFieldType, string>()

  if (isEditable) {
    return <IFrameEdit />
  }

  if (!value) {
    return null
  }

  return (
    <FieldContainer>
      <iframe
        width={String(field.width) || '560'}
        height={String(field.height) || '315'}
        src={value}
        title="video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </FieldContainer>
  )
}
