import React, { ReactNode, SVGProps, useEffect, useMemo } from 'react'
import debounce from 'lodash.debounce'
import * as Y from 'yjs'
import { Doc, NoteInteraction } from '@/db'
import { useToggle } from '@/hooks'
import { Button } from '@/ui'
import { useBoardContext } from '../context'
import { DragEnd, useDragging } from '../hooks'
import css from '../whiteboard.module.css'
import { BoardPoint } from '../whiteboardTypes'
import { isNote } from '../whiteboardUtils'

interface ObjectProps extends SVGProps<SVGForeignObjectElement> {
  data: Doc | NoteInteraction
  pos: BoardPoint
  onEdit: (newValue: string) => void
  onDelete: () => void
  dragEnd: DragEnd
  children: ReactNode
  docs?: ReactNode
  corner?: ReactNode
}

export const BoardObject = ({
  data,
  pos,
  onEdit,
  onDelete,
  dragEnd,
  children,
  docs,
  corner,
  ...attr
}: ObjectProps) => {
  const { getYObj, provider } = useBoardContext()
  const yObj = getYObj(data._id)

  const [ref, { isDragging, focus }] = useDragging(pos, {
    disable: !provider,
    drag: (point) => {
      yObj.set('x', point.x)
      yObj.set('y', point.y)
    },
    end: dragEnd,
  })

  const [isEdit, { toggle }] = useToggle(false)

  const title = isNote(data) ? data.value?.text : data.title

  const sendChangeDebounced = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onEdit(e.target.value)
      }, 1000),
    [],
  )

  useEffect(() => {
    const el = ref.current as SVGForeignObjectElement
    if (el == null && !provider) return () => undefined

    function observeMove(_: any, transact: Y.Transaction) {
      if (transact.origin === provider) {
        el.setAttribute('transform', `translate(${yObj.get('x')}, ${yObj.get('y')})`)
      }
    }

    yObj.observe(observeMove)

    return () => {
      yObj.unobserve(observeMove)
    }
  }, [])

  return (
    <g ref={ref}>
      <foreignObject id={data._id} className={isNote(data) ? css.note : css.doc} {...attr}>
        {isEdit ? (
          <textarea
            className="px-3 py-5 bg-transparent outline-none w-full h-full resize-none"
            defaultValue={title}
            onChange={sendChangeDebounced}
          />
        ) : (
          children
        )}
      </foreignObject>
      <g>{docs}</g>
      {(focus || isEdit) && !isDragging && (
        <foreignObject y={-50} width={attr.width} height={45} className="drag-object">
          <div className="w-24 mx-auto flex gap-1 justify-center rounded bg-white border border-neutral200 p-1">
            <Button variant={isEdit ? 'default' : 'ghost'} icon="pencil" onClick={toggle} />
            <div className="border-neutral200 border-r" />
            <Button variant="ghost" icon="trash" onClick={onDelete} />
          </div>
        </foreignObject>
      )}
      {corner}
    </g>
  )
}
