import { FC, useCallback, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { CustomSelectIconOption } from '@/features/schemaEditor/components/CustomSelectIconOption'
import { CustomSingleValue } from '@/features/schemaEditor/components/CustomSingleValue'
import { IconNames } from '@/features/schemaEditor/components/IconNames'
import { FieldTemplateProperty } from '@/features/schemaEditor/types'
import { Select } from '@/ui/Select'
import css from './select.module.css'

type FieldIconProps = {
  propertyName: string
  templateProperty: FieldTemplateProperty
  nestedStructure: string
}

export const FieldIcon: FC<FieldIconProps> = ({
  propertyName,
  templateProperty,
  nestedStructure,
}) => {
  const { getValues, setValue } = useFormContext()

  const options = useMemo(
    () =>
      IconNames.map((icon) => ({
        label: icon,
        value: icon,
      })),
    [IconNames],
  )

  const selectedValue = getValues(nestedStructure)

  useEffect(() => {
    if (selectedValue) {
      setValue(nestedStructure, selectedValue)
    }
  }, [selectedValue])

  const selectChangeHandler = useCallback((option) => {
    if (!option) {
      window.Rollbar.error('Icon select -> `option` not found', option)
      return
    }

    setValue(nestedStructure, option.value)
  }, [])

  const defaultValue = useMemo(
    () => (selectedValue ? { value: selectedValue, label: selectedValue } : ''),
    [selectedValue],
  )

  return (
    <div className="form-control">
      <label className="cursor-pointer label justify-start flex-col items-start p-0">
        <span className="label-text capitalize font-bold px-1 py-2">{propertyName}</span>

        <Select
          required={templateProperty.required}
          options={options}
          components={{ Option: CustomSelectIconOption, SingleValue: CustomSingleValue }}
          className={css.dropdown}
          menuPortalTarget={document.body}
          onChange={selectChangeHandler}
          defaultValue={defaultValue}
        />
      </label>
    </div>
  )
}
