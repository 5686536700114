import { HtmlParser } from '@/features/rte'
import { showUntitledText } from '@/utils/parseString'
import { CardProps } from '../cardsTypes'

export const FullDescriptionCard = ({ doc, headerElements }: CardProps) => (
  <div className="relative h-full p-4 border border-neutral300 rounded-md">
    <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
    <div>
      <h5 title={doc.title} className="mb-3 font-bold leading-5">
        {showUntitledText(doc.title)}
      </h5>
      <HtmlParser className="text-regular text-neutral700 [&>ul]:list-disc pl-2 break-words">
        {doc.fields.description}
      </HtmlParser>
    </div>
  </div>
)
