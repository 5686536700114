import { Doc } from '@/db'
import { useAccountRoles } from '@/hooks'
import { Button } from '@/ui'

interface TableActionElementsProps {
  doc: Doc
  deleteDoc: () => void
  editDoc: () => void
}

export const TableActionElements = ({ doc, editDoc, deleteDoc }: TableActionElementsProps) => {
  const roles = useAccountRoles(doc)

  if (!roles.editor) return null

  return (
    <div className="flex items-center gap-2">
      <Button variant="outline" icon="pencil" tooltip="Edit" onClick={editDoc} />
      <Button variant="outline" icon="trash" tooltip="Delete" onClick={deleteDoc} />
    </div>
  )
}
