import { SubmitHandler, useForm } from 'react-hook-form'
import clsx from 'clsx'
import { queryDocsByType } from '@/db/docs/queries.ts'
import { queryFromDocRelations } from '@/db/relations/queries.ts'
import { createOneRelation, deleteOneRelation } from '@/db/relations/sync.ts'
import { Button } from '@/ui'
import { sequentially } from '@/utils'

type Inputs = {
  type: string
  fieldName: string
  reverseName: string
}

export const FixReverseFieldNameOfRelations = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { type, fieldName, reverseName } = data

    const docsByType = await queryDocsByType(type)

    if (!docsByType?.length) return

    await sequentially(docsByType, async (doc) => {
      const relations = await queryFromDocRelations(doc._id, fieldName)
      if (!relations?.length) {
        return
      }

      const filteredRelations = relations.filter((rel) => !rel.reverseFieldName)

      if (!filteredRelations.length) {
        return
      }

      await Promise.all(
        filteredRelations.map(async (rel) => {
          const status = await deleteOneRelation(rel)

          if (!status) return Promise.resolve()

          const { fromId, toId, relationType, fieldName, _meta, fromDocType, toDocType } = rel

          return createOneRelation({
            fromId,
            toId,
            relationType,
            fieldName,
            reverseFieldName: reverseName,
            _meta,
            fromDocType,
            toDocType,
          })
        }),
      )
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className="form-control w-full max-w-xs">
        <div className="label">
          <span className="label-text">Document type</span>
        </div>
        <input
          type="text"
          placeholder="Type here"
          className={clsx('input input-bordered w-full max-w-xs', { 'input-error': errors.type })}
          {...register('type', { required: true })}
        />
      </label>

      <label className="form-control w-full max-w-xs">
        <div className="label">
          <span className="label-text">Field name</span>
        </div>
        <input
          type="text"
          placeholder="Type here"
          className={clsx('input input-bordered w-full max-w-xs', {
            'input-error': errors.fieldName,
          })}
          {...register('fieldName', { required: true })}
        />
      </label>

      <label className="form-control w-full max-w-xs">
        <div className="label">
          <span className="label-text">Reverse name</span>
        </div>
        <input
          type="text"
          placeholder="Type here"
          className={clsx('input input-bordered w-full max-w-xs', {
            'input-error': errors.reverseName,
          })}
          {...register('reverseName', { required: true })}
        />
      </label>

      <Button
        variant="outline"
        className="max-[400px]:w-full mt-3"
        type="submit"
        loading={isSubmitting}
      >
        Fix Relations ReverseFieldName
      </Button>
    </form>
  )
}
