import { createElement } from 'react'
import clsx from 'clsx'
import { useFieldContext } from '@/contexts'
import { DataListParams } from '@/db'
import { cardElements, CardElementsProps } from './cardElements'

export const CardFooterElements = ({
  docItem,
  date,
  isEditMode,
  docSchema,
  styles,
}: CardElementsProps) => {
  const { field } = useFieldContext<DataListParams>()
  const footerElements = field.layout?.card?.footerElements
  const type = field.layout?.card?.type
  const withoutPaddinds = ['categoryCard', 'faqVertical', '002.InfoCard', '003.InfoCard']

  if (!footerElements) {
    return null
  }
  const buttons = ['downloadButton', 'fileViewButton', 'bookmark', 'typeIcon']
  return (
    <div
      className={clsx(
        'mt-auto flex gap-x-2.5 w-full text-sm text-neutral700 items-center gap-4 justify-between',
        !withoutPaddinds.includes(type as string) ? 'px-4 pb-3.5' : 'px-0 pb-0',
      )}
      style={styles}
    >
      <div className="flex items-center gap-x-2.5">
        {footerElements.map((componentName) => {
          const component = cardElements[componentName]

          const isButton = buttons.includes(componentName)
          if (isButton) return
          if (!component) {
            return null
          }

          return createElement<CardElementsProps>(component, {
            key: componentName,
            docItem,
            date,
            isEditMode,
            docSchema,
            name: componentName,
          })
        })}
      </div>
      <div className="flex gap-x-2">
        {footerElements.map((componentName) => {
          const component = cardElements[componentName]
          const isButton = buttons.includes(componentName)
          if (!isButton) return

          if (!component) {
            return null
          }

          return createElement<CardElementsProps>(component, {
            key: componentName,
            docItem,
            date,
            isEditMode,
            docSchema,
            name: componentName,
          })
        })}
      </div>
    </div>
  )
}
