import axios from 'axios'
import get from 'lodash.get'

if (get(window, 'env.BackendUrl') || !import.meta.env.VITE_API_URL) {
  window.Rollbar.error('process.env.NEXT_PUBLIC_API_HOST is not set')
}

const apiUrl = get(window, 'env.BackendUrl') || import.meta.env.VITE_API_URL
export const api = axios.create({
  baseURL: `${apiUrl}/api`,
})

export const unsplashApi = axios.create({
  baseURL: `${import.meta.env.VITE_UNSPLASH_URL}/api/unsplash`,
})
