import { useEffect, useMemo, useState } from 'react'
import { Group } from '@visx/group'
import {
  DiagramFieldData,
  DiagramFieldType,
} from '@common/interfaces/fields/diagram-field.interface'
import { KeyValueData } from '@common/interfaces/fields/keyvalue-field.interface'
import { useFieldContext } from '@/contexts'
import { Doc } from '@/db'
import { useQueryEntry } from '@/hooks'
import { FieldContainer } from '@/schema'
import { colors } from '@/ui/theme'
import { FieldComponent } from '../fieldType'
import { DiagramAxis, DiagramBlock, DiagramDocBlock, DiagramSettings } from './components'
import { DiagramContext, DiagramContextValue } from './context'
import { useDiagramWidth } from './hooks/useDiagramWidth'
import { useAppDispatch } from '@/store'
import { clearFilters, updateFilter } from '@/store/filters/slice'

export const DiagramField: FieldComponent = () => {
  const { field, value, isEditable, saveValue } = useFieldContext<
    DiagramFieldType,
    DiagramFieldData
  >()
  const dispatch = useAppDispatch()
  const { docs } = useQueryEntry(field.query)
  const { ref, currentWidth } = useDiagramWidth()
  const {
    axisX = field.axisX,
    axisY = field.axisY,
    backgroundColor = field.backgroundColor || colors.neutral50,
    diagramItemDataFieldName = field.diagramItemDataFieldName,
  } = value ?? {}

  const settings = useMemo(
    () => ({
      axisX,
      axisY,
      blocks: field.blocks.concat(value?.blocks ?? []),
      backgroundColor,
      diagramItemDataFieldName,
    }),
    [value, currentWidth, field],
  )

  const [filter, setFilter] = useState<string | undefined>('')

  const handleChange = (filterName?: string, selectedValue?: string) => {
    if (field.makeFilter && filterName && selectedValue) {
      return setFilter((prev) => {
        if (prev !== selectedValue) {
          dispatch(updateFilter({ name: filterName, value: selectedValue }))
          if (value) saveValue({ ...value, selectedFilter: selectedValue })
          return selectedValue
        }
        if (prev === selectedValue) {
          dispatch(clearFilters())
          if (value) saveValue({ ...value, selectedFilter: '' })
          return ''
        }
      })
    }
  }
  useEffect(
    () => () => {
      dispatch(clearFilters())
      if (value) saveValue({ ...value, selectedFilter: '' })
    },
    [],
  )

  const xTotal = Math.abs(axisX.min) + axisX.max
  const yTotal = Math.abs(axisY.min) + axisY.max
  const aspectRatio = xTotal / yTotal
  const outerWidth = currentWidth
  const outerHeight = outerWidth / aspectRatio
  const margin = 50
  const innerWidth = outerWidth - margin * 2
  const innerHeight = outerHeight - margin * 2
  const deltaX = innerWidth / xTotal
  const deltaY = innerHeight / yTotal
  const outerDeltaX = outerWidth / xTotal
  const outerDeltaY = outerHeight / xTotal

  const contextValue: DiagramContextValue = useMemo(
    () => ({
      outerWidth,
      outerHeight,
      innerWidth,
      innerHeight,
      margin,
      deltaX,
      deltaY,
      outerDeltaX,
      outerDeltaY,
      xTotal,
      yTotal,
      settings,
    }),
    [settings],
  )

  const getDiagramData = (doc: Doc) => {
    const diagramData = settings.diagramItemDataFieldName
      ? (doc.fields[settings.diagramItemDataFieldName] as KeyValueData)
      : {}
    return diagramData ?? {}
  }

  return (
    <FieldContainer ref={ref} className="max-md:overflow-x-auto flex justify-center">
      <DiagramContext.Provider value={contextValue}>
        {isEditable && <DiagramSettings />}
        <svg
          className="mb-9 font-sans text-regular w-full"
          style={{
            width: outerWidth,
            height: outerHeight,
            borderRadius: 18,
          }}
        >
          <defs>
            <style>{`.doc-block { cursor: pointer; }`}</style>
          </defs>
          <rect
            fill={backgroundColor}
            className="w-full"
            x={0}
            y={0}
            width={outerWidth}
            height={outerHeight}
          />
          <Group left={margin} top={margin}>
            {axisX.display && <DiagramAxis showLine={axisX.showLine} type="x" data={axisX} />}
            {axisY.display && <DiagramAxis showLine={axisY.showLine} type="y" data={axisY} />}
            {settings.blocks.map((block, i) => (
              <DiagramBlock
                onClick={handleChange}
                filter={filter}
                key={i}
                block={block}
                makeFilter={field.makeFilter}
              />
            ))}
            {docs
              .sort((docA, docB) => {
                const zA = (getDiagramData(docA).z as number) ?? 0
                const zB = (getDiagramData(docB).z as number) ?? 0
                return zA - zB
              })
              .map((item) => (
                <DiagramDocBlock key={item._id} doc={item} />
              ))}
          </Group>
        </svg>
      </DiagramContext.Provider>
    </FieldContainer>
  )
}
