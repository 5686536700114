import { PropsWithChildren, forwardRef, useRef } from 'react'
import { useMemo, useState } from 'react'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { QuoteNode } from '@lexical/rich-text'
import { RteContext, RteContextValue } from './contexts'
import { AIPlugin } from './plugins/AIPlugin'
import { AutoLinkPlugin } from './plugins/AutoLinkPlugin'
import { CollabPlugin } from './plugins/CollabPlugin'
import { MentionNode } from './plugins/MentionsPlugin/MentionNode'
import { NewMentionsPlugin } from './plugins/MentionsPlugin/MentionsPlugin'
import { OnBlurPlugin } from './plugins/OnBlurPlugin'
import { ToolbarPlugin } from './plugins/ToolbarPlugin'
import './styles/rich-text.css'
import { rteTheme } from './styles/theme'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { LexicalEditor } from 'lexical'
import { FieldChangePlugin } from './plugins/FieldChangePlugin'

type RteProps = {
  withAI?: boolean
  withCollab?: boolean
  placeholder?: string
  onBlur?: () => void
}

export const Rte = forwardRef<LexicalEditor, RteProps>(
  ({ onBlur, withAI = false, withCollab = false, placeholder }, ref) => {
    const [enableAI, setEnableAI] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)

    const initialConfig = useMemo(
      () => ({
        namespace: 'HyphaEditor',
        theme: rteTheme,
        onError(error: Error) {
          window.Rollbar.error(error as Error)
        },
        nodes: [ListNode, ListItemNode, QuoteNode, AutoLinkNode, LinkNode, MentionNode],
      }),
      [],
    )

    const contextValue = useMemo<RteContextValue>(
      () => ({
        withAI,
        enableAI,
        toggleAI: setEnableAI,
        containerRef,
      }),
      [enableAI, withAI],
    )

    return (
      <RteContext.Provider value={contextValue}>
        <LexicalComposer initialConfig={{ ...initialConfig, editorState: null }}>
          <div
            ref={containerRef}
            className="editor-container prose min-w-full prose-p:m-0 border border-neutral200 bg-white rounded prose-sm text-inherit"
          >
            <ToolbarPlugin />
            <div className="editor-inner">
              <RichTextPlugin
                contentEditable={
                  <ContentEditable className="editor-input" ariaLabel="Add description" />
                }
                placeholder={
                  <div className="editor-placeholder">{placeholder ?? 'Add description...'}</div>
                }
                ErrorBoundary={ErrorBoundary}
              />
              <ListPlugin />
              <LinkPlugin />
              <AutoLinkPlugin />
            </div>
            <NewMentionsPlugin />
            {enableAI && <AIPlugin />}
            {withCollab && <CollabPlugin />}
            <FieldChangePlugin isInitializeState={!withCollab} />
            {onBlur && <OnBlurPlugin onBlur={onBlur} />}
          </div>
          <OnChangePlugin
            onChange={(_, editor) => {
              if (typeof ref === 'function') {
                ref(editor)
              } else if (ref) {
                ref.current = editor
              }
            }}
          />
        </LexicalComposer>
      </RteContext.Provider>
    )
  },
)

Rte.displayName = 'Rte'

const ErrorBoundary = ({ children }: PropsWithChildren) => <div>{children}</div>
