import { toast } from 'react-toastify'
import Dexie from 'dexie'
import { io } from '@/api'
import { updateSchemaApi } from '@/api/workspaces'
import { db } from '../db'
import { AllSchema, useSchemaState } from '@/contexts/schema'

async function saveSchema(schema: AllSchema) {
  await db.transaction('rw', db.workspaces, () =>
    db.workspaces.update(db.activeWorkspace._id, { schema }),
  )
  useSchemaState.getState().setSchema(schema)
}

export async function updateSchema(schema: AllSchema) {
  if (!schema.length) {
    throw Error('Empty schema')
  }
  await updateSchemaApi(schema)
  await saveSchema(schema)
}

export function listenWorkspaces() {
  io.on(
    'mergeRemoveUserWorkspaceRelation',
    async (data: { _userId: string; _clientId: string; _workspaceId: string }) => {
      await Dexie.delete(`${data._clientId}_${data._workspaceId}`)
      db.transaction('rw', db.clients, db.workspaces, () => {
        db.clients.delete(data._clientId)
        db.workspaces.delete(data._workspaceId)
      })
    },
  )

  io.on('mergeUpdateWorkspace', async (changes: { schema: AllSchema }) => {
    saveSchema(changes.schema)
    toast.info('Schema updated by admin')
  })
}
