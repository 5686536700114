import { LinkFieldType } from '@common/interfaces/fields/link-field.interface'
import { NoteInteraction, SomeField } from '@/db'
import { BoardPoint } from './whiteboardTypes'

class BoardState {
  element: SVGSVGElement | null = null
  x = 0
  y = 0
  width = 0
  height = 0
  ratio = 1

  setViewport(viewport: string) {
    const [x, y, width, height] = viewport.split(' ').map(parseFloat)
    this.x = x
    this.y = y
    this.width = width
    this.height = height
  }

  updateViewport({ x = this.x, y = this.y, width = this.width, height = this.height }) {
    this.x = x
    this.y = y
    this.width = width
    this.height = height

    return this.getViewport()
  }

  changeViewport({ x = this.x, y = this.y, width = this.width, height = this.height }) {
    return `${x} ${y} ${width} ${height}`
  }

  getViewport() {
    return `${this.x} ${this.y} ${this.width} ${this.height}`
  }

  getLocalPoint(x: number, y: number) {
    return [x * boardState.ratio + boardState.x, y * boardState.ratio + boardState.y] as const
  }

  setScale(ratio: number) {
    this.ratio = ratio
  }

  getCenterPoint(size: number) {
    return {
      x: this.x + this.width / 2 - size / 2,
      y: this.y + this.height / 2 - size / 2,
    }
  }
  getRandomPoint(size: number) {
    return {
      x: this.x + Math.random() * (this.width - size),
      y: this.y + Math.random() * (this.height - size),
    }
  }
}

export const boardState = new BoardState()

export function isLinkField(item?: SomeField): item is LinkFieldType {
  return item?.type === 'children' || item?.type === 'multilink'
}

export function getGridPosition(
  num: number,
  width: number,
  height: number,
  gridSize = 3,
  gridPos?: { x?: number; y?: number },
) {
  return {
    x: (gridPos?.x ?? 0) + 50 + (num % gridSize) * (width + 12),
    y: (gridPos?.y ?? 0) + 50 + Math.floor(num / gridSize) * (height + 12),
  }
}

export function isNote(data: any): data is NoteInteraction {
  return data._type === 'note'
}

export function getClusterPoint(cluster: Element, point: BoardPoint) {
  const bound = cluster.getBoundingClientRect()
  const [cx, cy] = boardState.getLocalPoint(bound.x, bound.y)
  const { x, y } = point
  return { x: x - cx, y: y - cy }
}

export function isSameTarget(parent: Element | null, child: Element | null): boolean {
  if (!child) {
    return false
  }

  if (child.classList.contains('board-object')) {
    return false
  }

  if (child === parent) {
    return true
  }

  return isSameTarget(parent, child.parentElement)
}
