import { FC, useMemo } from 'react'
import clsx from 'clsx'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { Doc } from '@/db'
import { getClassName, getGapClassNames } from '@/utils/docsList'
import { generateArray } from '@/utils/docsSort'
import { EmptyCard } from './variants/EmptyCard'

export const ListEmptyState: FC<{
  height: number
}> = ({ height }) => {
  const { isEditMode } = useDocContext()
  const { field } = useFieldContext<ChildrenFieldType, Doc[]>()

  const { columns, gapX, gapY, classes } = field.layout || {
    columns: 3,
    gapX: 7,
    gapY: 7,
    card: { type: 'default' },
  }

  const columnsArray = useMemo(() => {
    if (columns === 1) {
      return []
    }

    if (columns) {
      return generateArray(columns - 1)
    }

    return generateArray(4)
  }, [columns, field.name])

  if (!isEditMode) {
    return null
  }

  const text = field.emptyState?.text || ''

  const subText = field.emptyState?.subText || ''

  return (
    <>
      <div className="relative">
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center z-10">
          <p className="text-subtitle text-neutral900 text-center pb-1.5">{text}</p>
          <span className="text-sm text-neutral700 text-center pb-5">{subText}</span>
        </div>
        <div
          className={clsx(
            getClassName(columns || 4),
            getGapClassNames(gapX, gapY),
            classes,
            'overflow-hidden',
          )}
          style={{ height }}
        >
          {columnsArray.map((key) => (
            <EmptyCard
              height={height}
              className={clsx('bg-neutral50', {
                'max-sm:hidden': key > 1,
                'max-md:hidden': key > 2,
                'max-lg:hidden': key > 5,
              })}
              key={key}
            />
          ))}
        </div>
      </div>
    </>
  )
}
