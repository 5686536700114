export function uppercaseFirstLetter(str = '') {
  if (!str) return ''

  return str[0].toUpperCase() + str.slice(1)
}

export function camelToCapitalizeWords(str: string | undefined) {
  if (!str) return ''

  const isAbbr = str.match(/[A-Z]{3,}/g)
  if (isAbbr) return str

  const words = str.split(/(?=[A-Z])/)
  words[0] = uppercaseFirstLetter(words[0])
  return words.join(' ')
}

export function toSentence(str?: string) {
  if (!str) return ''

  const [first, ...rest] = str.split(/(?=[A-Z])/)
  return `${uppercaseFirstLetter(first)} ${rest.join(' ').toLowerCase()}`.trim()
}

export function getHtmlString(html = '') {
  if (!html && !html.trim()) return ''

  const span = document.createElement('span')
  span.innerHTML = html
  const text = span.textContent ?? ''
  span.remove()

  return text
}

export const showUntitledText = (str: string | undefined) => {
  if (!str && !str?.trim()) {
    return 'Untitled'
  }

  return str
}

export const extractInstanceName = (domain: string) => {
  if (domain === 'localhost') {
    return domain
  }
  return domain.substring(0, domain.indexOf('.'))
}

export function toUnderscore(str: string, separator = ' ') {
  return str.split(separator).join('_').toLowerCase()
}

export function kebabToCamel(str: string) {
  return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase())
}
