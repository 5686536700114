import clsx from 'clsx'
import { formatDistance, subDays } from 'date-fns'
import { useLiveQuery } from 'dexie-react-hooks'
import { FileText } from 'lucide-react'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'
import { useFieldContext } from '@/contexts'
import { Doc } from '@/db'
import { HtmlParser } from '@/features/rte'
import { Preview } from '@/ui'
import { showUntitledText } from '@/utils/parseString'
import css from '../card.module.css'
import { CardProps } from '../cardsTypes'
import { CardDocType } from '../components/CardDocType'
import { queryUserDoc } from '@/db/docs/queries'

export const HorizontalCard = ({ doc, docSchema, author, date, children }: CardProps) => {
  const user = useLiveQuery(() => queryUserDoc(doc._userId), [])
  const { field } = useFieldContext<ChildrenFieldType, Doc[]>()
  const hideImage = field?.layout?.card?.hideImage

  return (
    <div
      className={clsx('relative flex gap-x-4 h-full border border-neutral300 rounded-md', {
        [css.carouselHorizontalCard]: field.isCarousel,
      })}
    >
      {!hideImage && (
        <Preview
          image={doc.fields.image}
          className="w-40 h-40 rounded-md object-top"
          iconSize={36}
        />
      )}

      <div className="pt-3 pb-3 pr-3">
        <div className="flex items-center pb-3">
          {author && <span className="text-sm text-neutral700">{user?.title}</span>}
          {!author && !date && <FileText color="#5F6160" size={16} />}
          <CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="mb-1.5" />
          {date && (
            <span className="text-sm text-neutral700 pl-2.5">
              {formatDistance(subDays(new Date(doc._addedOn), 0), new Date(), {
                addSuffix: true,
              })}
            </span>
          )}
        </div>

        <h5
          title={doc.title}
          className={clsx(
            'mb-3 font-bold leading-5',
            field.isCarousel ? 'line-clamp-2' : css.clampThreeRows,
          )}
        >
          {showUntitledText(doc.title)}
        </h5>
        <HtmlParser
          className={clsx('text-regular text-neutral700 h-[42px] break-words', css.cardTitleClamp)}
        >
          {doc.fields.description}
        </HtmlParser>
      </div>

      {children}
    </div>
  )
}
