import { ChangeEvent, useCallback, useState } from 'react'
import clsx from 'clsx'
import { Pin as PinIcon } from 'lucide-react'
import { useDocContext } from '@/contexts'
import { Doc } from '@/db'
import { Tooltip } from '@/ui'
import { colors } from '@/ui/theme'
import styles from './Pin.module.css'

interface PinProps {
  isEditMode: boolean
  doc: Doc
  className?: string
}

export const Pin = ({ isEditMode, doc, className }: PinProps) => {
  const { save } = useDocContext()
  const { fields } = doc
  const fieldName = 'pinned'

  const [checked, setChecked] = useState(fields.pinned)

  const changeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      if (!isEditMode) {
        return
      }
      setChecked((prev) => {
        const newValue = !prev
        save({ name: fieldName, value: newValue })
        return newValue
      })
    },
    [isEditMode],
  )

  let pinStyle = 'right-[42px] '
  if (!isEditMode) {
    pinStyle = 'right-3'
  }

  if (fields.pinned || isEditMode) {
    return (
      <label
        htmlFor="togglePinUnpin"
        className={clsx(className, 'bg-base-100 flex p-0.5 rounded-md', pinStyle)}
      >
        <input
          type="checkbox"
          id="togglePinUnpin"
          checked={!!checked}
          onChange={changeHandler}
          className={styles.pinInput}
        />
        {isEditMode && (
          <Tooltip content={checked ? 'Unpin' : 'Pin'}>
            <PinIcon color={checked ? colors.primary : colors.neutral900} size={18} />
          </Tooltip>
        )}
        {!isEditMode && <PinIcon color="#1E1E1E" size={18} />}
      </label>
    )
  }
  return null
}
