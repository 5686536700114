import { useEffect, useState } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { queryUsersByName } from '@/db/users/queries'
import { usePath } from '@/hooks'

type MentionsDataType = { name: string; icon: string; ref: string }

const mentionsCache = new Map()

const lookupService = {
  search(mentionData: any, getDocPath, callback: (results: Array<MentionsDataType>) => void): void {
    setTimeout(() => {
      const results = mentionData.map((doc) => ({
        name: '@' + doc.name || doc.title,
        icon: doc.name ? 'user' : 'file',
        ref: doc._slug ? getDocPath(doc) : '',
      }))
      callback(results)
    }, 500)
  },
}

export const useMentionLookupService = (mentionString: string | null) => {
  const [results, setResults] = useState<Array<MentionsDataType>>([])
  const users: any = useLiveQuery(() => queryUsersByName(mentionString), [mentionString])
  // const docs: any = useLiveQuery(() => queryDocsByTitle(mentionString), [mentionString])

  const { getDocPath } = usePath()
  // const mentionData = users?.concat(docs)

  useEffect(() => {
    const cachedResults = mentionsCache.get(mentionString)

    if (mentionString == null) {
      setResults([])
      return
    }

    if (cachedResults === null) {
      return
    } else if (cachedResults !== undefined) {
      setResults(cachedResults)
      return
    }

    mentionsCache.set(mentionString, null)

    lookupService.search(users, getDocPath, (newResults) => {
      mentionsCache.set(mentionString, newResults)
      setResults(newResults)
    })
  }, [mentionString])

  return results
}
