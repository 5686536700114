import { useFilterState } from '@/contexts/filter'
import { ChangeHandler, Select } from '@/ui'
import { SelectOption } from '@/ui/Select'
import { FilterOptionProps } from '../Filter'
import { StatusFieldType } from '@common/interfaces/fields/status-field.interface'

export const FilterEnum = ({ filter, fieldSchema }: FilterOptionProps<StatusFieldType>) => {
  const { updateFilter, removeFilter } = useFilterState()
  const placeholderText = filter.placeholder ?? `Filter by ${fieldSchema.niceName.toLowerCase()}`

  const handleChange: ChangeHandler<SelectOption> = (option, actionMeta) => {
    const { action } = actionMeta
    if (action === 'clear' || option === null) {
      return removeFilter(filter.fieldName)
    }

    updateFilter(filter.fieldName, option.value)
  }

  return (
    <Select
      placeholder={placeholderText}
      isClearable
      options={fieldSchema.options}
      isSearchable={false}
      onChange={handleChange}
    />
  )
}
