import clsx from 'clsx'
import { FieldValueType } from '@/db'
import { useImage } from '@/features/files'
import { IconName } from '../Icon'

type ImageProps = {
  image?: FieldValueType
  className?: string
  children?: JSX.Element
  icon?: IconName
  iconSize?: number
  fit?: 'cover' | 'contain' | 'fill'
  fileUrl?: string
  styles?: React.CSSProperties
  onClick?: (event) => void
}

export const Preview: React.FC<ImageProps> = ({
  image,
  className,
  children,
  fit = 'cover',
  fileUrl,
  styles,
  onClick,
}) => {
  const img = useImage(image)
  const url = fileUrl ?? img?.url

  const fitClass = {
    'object-cover': fit === 'cover',
    'object-contain': fit === 'contain',
    'object-fill': fit === 'fill',
  }

  if (children) return children

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <img
      draggable={false}
      className={clsx('shrink-0', fitClass, className)}
      src={url}
      style={styles}
      onClick={onClick}
      alt={img?.description ?? ''}
    />
  )
}
