import { createAsyncThunk } from '@reduxjs/toolkit'
import { setStatus } from '.'
import { DocStatus } from './slice'

export const updateStatus = createAsyncThunk(
  'docs/updateStatus',
  async (payload: DocStatus, { dispatch }) => {
    dispatch(setStatus(payload))

    if (payload === 'success' || payload === 'failed') {
      setTimeout(() => {
        dispatch(setStatus('idle'))
      }, 4000)
    }
  },
)
