import { CardElementsProps } from '@/schema/cards'
import { Icon, Tooltip } from '@/ui'

export const PublicBadge = ({ docItem }: CardElementsProps) => {
  if (!docItem || !docItem.fields.public) return null

  return (
    <div className="flex gap-1 items-center">
      {docItem.fields.public && (
        <div className="rounded bg-green100 h-6 aspect-square grid place-items-center">
          <Tooltip content="Document could be public">
            <Icon name="monitor-check" size={14} />
          </Tooltip>
        </div>
      )}
    </div>
  )
}
