import { FC, useCallback, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { MultiChangeHandler } from '@/ui/Select'
import { Select } from '@/ui/Select'
import { useGetOptions } from '../hooks/useGetOptions'
import { FieldTemplateProperty } from '../types'

type FieldSelectProps = {
  propertyName: string
  templateProperty: FieldTemplateProperty
  nestedStructure: string
}

type Option = {
  label: string
  value: string
}

export const FieldMultiSelect: FC<FieldSelectProps> = ({
  propertyName,
  templateProperty,
  nestedStructure,
}) => {
  const { setValue, getValues } = useFormContext()
  const options: Option[] = useGetOptions(propertyName)

  const selectedValues = getValues(nestedStructure)

  useEffect(() => {
    if (Array.isArray(selectedValues)) {
      setValue(nestedStructure, selectedValues)
    }
  }, [selectedValues])

  const selectChangeHandler: MultiChangeHandler<Option> = useCallback((options) => {
    if (!options) return

    setValue(nestedStructure, [...options.map((option) => option.value)])
  }, [])

  const defaultValues = useMemo(
    () =>
      Array.isArray(selectedValues)
        ? selectedValues?.map((option) => ({ value: option, label: option }))
        : [],
    [selectedValues],
  )

  return (
    <div className="form-control">
      <label className="cursor-pointer label justify-start flex-col items-start p-0">
        <span className="label-text capitalize font-bold px-1 py-2">{propertyName}</span>

        <Select<Option, true>
          isMulti
          required={templateProperty.required}
          className="w-[300px]"
          options={options}
          onChange={selectChangeHandler}
          defaultValue={defaultValues}
        />
      </label>
    </div>
  )
}
