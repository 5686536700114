import { $generateHtmlFromNodes } from '@lexical/html'
import { $createParagraphNode, $getRoot, $getSelection, LexicalEditor } from 'lexical'
import { useRef } from 'react'

export function useRte() {
  const ref = useRef<LexicalEditor | null>(null)

  const clearHtml = () => {
    const editor = ref.current

    editor?.update(() => {
      const root = $getRoot()
      const selection = $getSelection()
      const paragraph = $createParagraphNode()
      root.clear()
      root.append(paragraph)

      if (selection !== null) {
        paragraph.select()
      }
    })
  }

  const getHtml = () => {
    const editor = ref.current

    return new Promise<string>((resolve) => {
      if (!editor) {
        return resolve('')
      }

      editor.update(() => {
        const newValue = $generateHtmlFromNodes(editor, null)
        resolve(newValue)
      })
    })
  }

  return [
    ref,
    {
      clearHtml,
      getHtml,
    },
  ] as const
}
