import { docsSlice } from './slice'

export const { reducer } = docsSlice
export const {
  setActiveDocId,
  toggleEditMode,
  updateFieldState,
  setStatus,
  setNewDoc,
  setNewDocFields,
  resetNewDoc,
  setIsPanelOpen,
} = docsSlice.actions
export { updateStatus } from './actions'
export type * from './types'
export { selectActiveDocId, selectIsEditMode, fieldsSelectors, selectStatusDoc } from './selectors'
