import { db } from '../db'

export function queryUser(id: string) {
  return db.ws.users.get(id).catch<undefined>(console.error)
}

export function queryUserByEmail(email: string) {
  return db.ws.users.get({ email }).catch<undefined>(console.error)
}

export function queryAllUsers() {
  return db.ws.users.toArray().catch<undefined>(console.error)
}

export function queryUsersByName(mantionString: string | null) {
  if (mantionString == null) return []
  return db.ws.users.where('name').startsWithIgnoreCase(mantionString).toArray()
}
