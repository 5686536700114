import { IS_CISCO } from '@/api'
import LogoIcon from '@/assets/logo.svg?react'
import ciscoLogo from '../SignInPage/cisco.png'

export const PrivacyPolicyPage = () => {
  const companyName = IS_CISCO ? 'Purposefy' : 'IKEA Innovation Methods Site'

  return (
    <>
      <header className="max-w-[1440px] mx-auto p-10 max-sm:p-5">
        <div className="flex items-center">
          {IS_CISCO ? (
            <img src={ciscoLogo} width={200} alt="Company logotype" />
          ) : (
            <>
              <LogoIcon />
              <strong className="text-heading2 ml-3.5">Hypha</strong>
            </>
          )}
        </div>
      </header>
      <div className="max-w-[760px] mx-auto max-lg:px-5">
        <h1 className="text-title pt-6 pb-9">{companyName} Privacy Policy</h1>

        <p>
          This Privacy Policy explains how we at {companyName} handle personal identifiable
          information that you provide to us (or is provided to us on your behalf) that is
          consequently processed by us (including by our sub-processors) to provide services to you
          and users of {companyName} software.
        </p>
        <p>
          This Privacy Policy also describes the collection of certain other types of technical and
          web browsing information through cookies and similar technologies on the {companyName}{' '}
          website (
          <a href="https://www.hypha.cc/" target="_blank" rel="noreferrer" className="font-bold">
            hypha.cc
          </a>
          ).
        </p>
        <p className="pb-14">
          Please read this Privacy Policy before using {companyName} software or our website or
          prior to submitting personal information to us.
        </p>

        <h5 className="pb-3 font-bold">Our contact details</h5>
        <p>Name: Mr. Louis French</p>
        <p>Address: 1st Floor, The Eagle Brew House, 163 City Road, London, EC1V 1NR</p>
        <p className="pb-14">E-mail: privacy@hypha.cc</p>

        <h5 className="pb-3 font-bold">The type of personal information we collect</h5>

        <p>
          {companyName} currently collects and processes the following information when you use{' '}
          {companyName} SaaS product as an end user:
        </p>
        <ul className="list-disc pl-4 pb-9">
          <li>First name</li>
          <li>Last name</li>
          <li>Email address</li>
        </ul>

        <p>
          In addition, as a user, you are able to add a photo and a personal description although
          this is not mandatory. {companyName} also performs passive collection of certain other
          technical and web browsing information when you visit our website (hypha.cc) and when you
          use {companyName} software via your web browser without you actively submitting such
          information.
        </p>
        <p>
          This information may include, for example, your browser type and language, your operating
          system, your Internet Protocol (IP) address, the URLs of sites you visited before and
          after visiting our website, the web search that landed you on the {companyName} website or
          our {companyName}
          product URL, web pages and advertisements you view and links you click on across the
          website and product user interface. This information may be collected using various
          technologies, such as cookies, Internet tags, and web beacons. Your web browser also
          automatically transmits to {companyName} some of this information, such as the URL of the
          website you just visited and the browser version your computer/device is operating.
        </p>
        <p>
          Passive information collection technologies can make your use of our product and website
          easier by allowing {companyName} to provide a better service, customise our product based
          on consumer preferences, compile statistics, analyse trends, and otherwise administer and
          improve how we serve our users.
        </p>
        <p className="pb-14">
          You can also learn more about cookies by visiting{' '}
          <a href="https://allaboutcookies.org/" target="_blank" rel="noreferrer">
            allaboutcookies.org
          </a>
        </p>

        <h5 className="font-bold pb-3">How we get the personal information and why we have it</h5>

        <p>
          Most of the personal information we process is provided to us via one of the following
          reasons:
        </p>
        <p>
          Via usage of the {companyName} product as an end user under an active software agreement
          between your employer organisation and {companyName}
        </p>
        <ul className="list-disc pl-4 pb-9">
          <li>Via signing up to our subscription mailing list</li>
          <li>Via emails sent to any of our company mailboxes</li>
          <li>Via chat, email support or {companyName} help centre in Zendesk</li>
        </ul>

        <p>We use the information that you have given us to:</p>

        <ul className="list-disc pl-4">
          <li>Facilitate access to and support of the {companyName} platform</li>
          <li>Perform business development activities</li>
          <li>Communication via email to subscribers to {companyName} information</li>
        </ul>

        <p>
          Under the General Data Protection Regulation (GDPR), the lawful bases we rely on for
          processing this information are:
        </p>
        <p>
          (a) Your consent for receiving marketing materials. You are able to remove your consent at
          any time. You can do this by contacting: privacy@hypha.cc
        </p>
        <p>(b) We have a contractual obligation.</p>
        <p className="pb-14">
          (c) We have a legitimate interest to best serve existing users of the {companyName}{' '}
          platform.
        </p>

        <h5 className="font-bold pb-3">How we store your personal information</h5>

        <p>Your information is stored securely within the EU.</p>
        <p className="pb-14">
          Your personal data will be stored for as long as it is needed to deliver and make
          available to you the {companyName} service or in accordance with any legal or contractual
          requirements.
        </p>

        <h5 className="font-bold pb-3">Your data protection rights</h5>

        <p>Under data protection law, you have rights including:</p>

        <ul className="list-disc pl-4">
          <li>
            Your right of access - You have the right to ask us for copies of your personal
            information.
          </li>
          <li>
            Your right to rectification - You have the right to ask us to rectify personal
            information you think is inaccurate. You also have the right to ask us to complete
            information you think is incomplete.
          </li>
          <li>
            Your right to erasure - You have the right to ask us to erase your personal information
            in certain circumstances.
          </li>
          <li>
            Your right to restriction of processing - You have the right to ask us to restrict the
            processing of your personal information in certain circumstances.
          </li>
          <li>
            Your right to object to processing - You have the right to object to the processing of
            your personal information in certain circumstances.
          </li>
          <li>
            Your right to data portability - You have the right to ask that we transfer the personal
            information you gave us to another organisation, or to you, in certain circumstances.
          </li>
        </ul>

        <p>
          You are not required to pay any charge for exercising your rights. If you make a request,
          we have one month to respond to you.
        </p>
        <p className="pb-14">
          Please contact us at{' '}
          <a href="mailto:privacy@hypha.cc" className="font-bold">
            privacy@hypha.cc
          </a>{' '}
          if you wish to make such a request.
        </p>

        <h5 className="font-bold pb-3">How to complain</h5>

        <p>
          If you have any concerns about our use of your personal information, you can make a
          complaint to us at:{' '}
          <a href="mailto:privacy@hypha.cc" className="font-bold">
            privacy@hypha.cc
          </a>
          .
        </p>
        <p>You can also complain to the ICO if you are unhappy with how we have used your data.</p>

        <p>The ICO’s address:</p>
        <p>Information Commissioner’s Office</p>
        <p>Wycliffe House</p>
        <p>Water Lane</p>
        <p>Wilmslow</p>
        <p>Cheshire</p>
        <p>SK9 5AF</p>

        <p>Helpline number: 0303 123 1113</p>
        <p className="pb-14">
          ICO website:{' '}
          <a href="https://www.ico.org.uk" target="_blank" rel="noreferrer">
            https://www.ico.org.uk
          </a>
        </p>
      </div>
    </>
  )
}
