import { HtmlParser } from '@/features/rte'
import { Avatar } from '@nextui-org/react'
import { Preview } from '@/ui'
import css from '../card.module.css'
import { CardProps } from '../cardsTypes'
import { ImageFieldData } from '@common/interfaces/fields/image-field.interface.ts'

export const PersonaCard = ({ doc, children, showQuote, headerElements }: CardProps) => {
  const quote = doc.fields['Persona Quote']
  const image = doc.fields?.image as ImageFieldData

  return (
    <div className="h-full p-4 border border-neutral300 rounded-lg relative">
      <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
      {image?.fileName ? (
        <Preview
          image={doc.fields?.image}
          icon="user"
          iconSize={26}
          className="w-20 h-20 mb-4 rounded-full object-top"
        />
      ) : (
        <div className="mb-4">
          <Avatar name={doc.title?.[0]} className="w-20 h-20 !text-heading" />
        </div>
      )}

      <p title={doc.title} className="text-focus break-words whitespace-pre-wrap">
        {doc.title}
      </p>
      {doc.fields.subtitle && (
        <p className="text-caption text-neutral600 pt-1 break-words whitespace-pre-wrap">
          {doc.fields.subtitle as string}
        </p>
      )}
      {doc.fields?.role && (
        <p className="text-caption text-neutral600 pt-1 break-words whitespace-pre-wrap">
          {doc.fields.role as string}
        </p>
      )}
      {showQuote && <HtmlParser className={css.personaHtml}>{quote}</HtmlParser>}
      {children}
    </div>
  )
}
