import type { DocumentType, SomeField } from '@/db'
import { AllSchema, SchemaState, useSchemaState } from './state'

const defaultFields: Partial<Record<string, SomeField>> = {
  userId: {
    type: 'text',
    name: 'userId',
    niceName: 'Secondary ID',
    area: 'x',
  },
  secondaryId: {
    type: 'text',
    name: 'secondaryId',
    area: 'advanced',
    niceName: 'Secondary ID',
  },
  color: {
    type: 'color',
    name: 'color',
    area: 'advanced',
    niceName: 'Secondary ID',
  },
}

function getSchema(): AllSchema {
  return useSchemaState.getState().allSchema
}

export function selectDocSchema(state: SchemaState, docType = '') {
  return state.docTypes[docType]
}

function getDocSchema(name?: string) {
  return selectDocSchema(useSchemaState.getState(), name)
}

export function selectFieldSchema<T = SomeField>(state: SchemaState, docType = '', fieldName = '') {
  return (state.fields[`${docType}_${fieldName}`] ?? defaultFields[fieldName]) as T | undefined
}

function getFieldSchema<T = SomeField>(docType?: string, fieldName?: string) {
  return selectFieldSchema<T>(useSchemaState.getState(), docType, fieldName)
}

function sortBySchema<T extends { _id: string; _type: string }>(docs: T[] = []) {
  return docs.sort((a, b) => {
    const orderA = useSchemaState.getState().order[a._type] ?? docs?.length + 1
    const orderB = useSchemaState.getState().order[b._type] ?? docs?.length + 1

    if (orderA > orderB) return 1
    if (orderA < orderB) return -1
    return 0
  })
}

function getAllowedParents(docType: string, isSingleEntry = false) {
  const allowedDocs = useSchemaState.getState().allSchema.filter((doc) => {
    const checkSingleEntry = isSingleEntry ? doc.singleEntry : true

    return (
      checkSingleEntry &&
      doc.fields.some((field) => field['allowed']?.includes(docType) && field.type === 'children')
    )
  })
  return allowedDocs.map((doc) => doc.name)
}

function getFieldName(doc: DocumentType | undefined, docType: string | undefined) {
  if (!doc || !docType) return null
  const field = doc.fields.find(
    (field) => field['allowed']?.includes(docType) && field.type === 'children',
  )
  return field
}

export const scm = {
  getDocSchema,
  getSchema,
  getFieldSchema,
  sortBySchema,
  getAllowedParents,
  getFieldName,
}
