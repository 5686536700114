import { Link } from 'react-router-dom'
import { useLiveQuery } from 'dexie-react-hooks'
import { queryDoc } from '@/db/docs/queries.ts'
import { usePath } from '@/hooks'

export const AncestorsList = ({ parentId }: { parentId: string }) => {
  const doc = useLiveQuery(() => queryDoc({ _id: parentId }))
  const { getDocPath } = usePath()

  if (!doc) {
    return <div className="text-center opacity-30">-</div>
  }

  return (
    <Link to={getDocPath(doc)} className="line-clamp-2">
      {doc.title}
    </Link>
  )
}
