import { Icon, Preview } from '@/ui'
import { CardProps } from '../cardsTypes'
import { HtmlParser } from '@/features/rte'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface'
import { CardDocType } from '@/schema/cards/components/CardDocType.tsx'

export const FullImageCard = ({ doc, docSchema, children }: CardProps) => {
  const { field } = useFieldContext<ChildrenFieldType>()
  const fontColor = field.layout?.card?.fontColor || '#fff'
  const fontSize = field.layout?.card?.fontSize
  const lineHeight = field.layout?.card?.lineHeight
  const hideDescription = field.layout?.card?.hideDescription
  const styles = field?.layout?.card?.styles
  const secondaryStyles = field?.layout?.card?.secondaryStyles
  const fields = field.layout?.card?.fields ?? []

  const getElemets = (name: string, type: string) => {
    if (type === 'html' && doc.fields[name]) {
      return (
        <div className="mb-1.5">
          <HtmlParser
            className="prose prose-sm
            text-break whitespace-pre-line line-clamp-3 [&>ul]:list-disc [&>ul]:pl-3"
            styles={{ color: fontColor }}
          >
            {doc.fields[name]}
          </HtmlParser>
        </div>
      )
    }
  }

  return (
    <div className="relative h-[320px] bg-neutral400 shadow" style={styles}>
      <Preview
        className="w-full h-full"
        image={doc.fields.image}
        iconSize={36}
        fit="cover"
        styles={secondaryStyles}
      />
      <div className="absolute left-0 top-0 flex flex-col w-full h-full px-6 pt-8 pb-6">
        <div className="grow w-[45%]" style={{ color: fontColor }}>
          <CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="mb-1.5" />
          <h3
            className="text-heading line-clamp-3 mb-1.5"
            style={{ fontSize, color: fontColor, lineHeight }}
          >
            {doc.title}
          </h3>

          {fields && fields.map((field) => getElemets(field.fieldName, field.type))}

          {doc.fields?.description && !hideDescription && (
            <HtmlParser
              className="prose prose-sm
               text-break whitespace-pre-line line-clamp-3 [&>ul]:list-disc [&>ul]:pl-3"
              styles={{ color: fontColor }}
            >
              {doc.fields.description}
            </HtmlParser>
          )}
        </div>

        <div className="flex items-center justify-between">
          {children}

          <div className="bg-white rotate-45 flex items-center justify-center shrink-0 w-8 h-8 rounded-full">
            <Icon name="arrow-right" size={16} />
          </div>
        </div>
      </div>
    </div>
  )
}
