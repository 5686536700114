import { combineReducers } from '@reduxjs/toolkit'
import { reducer as account } from './account'
import { reducer as aiRecommendations } from './ai-recommendations'
import { reducer as logs } from './auditLogs'
import { reducer as docs } from './docs'
import { reducer as filters } from './filters'
import { reducer as session } from './session'
import { reducer as activityLabel } from './activity-label'

export const rootReducer = combineReducers({
  session,
  account,
  docs,
  aiRecommendations,
  filters,
  logs,
  activityLabel,
})
