import { useCallback, useMemo, Key, useState } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { queryAllDocs } from '@/db/docs/queries.ts'
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
} from '@nextui-org/react'
import { ParentUrl } from '@/fields/table/components/TableList/ParentUrl.tsx'
import { format } from 'date-fns'

const columns = [
  { label: 'Type', key: 'type' },
  { label: 'Name', key: 'name' },
  { label: 'Parent', key: 'parentId' },
  { label: 'Last viewed', key: 'lastViewed' },
  { label: 'Last edited', key: 'lastEdited' },
  { label: 'Views', key: 'totalViews' },
  { label: 'Edits', key: 'totalEdits' },
  { label: 'Likes', key: 'totalLikes' },
]

type ActivityReport = {
  id: string
  type: string
  name: string
  parentId: string
  lastViewed: string
  lastEdited: string
  totalViews: number
  totalEdits: number
  totalLikes: number
}

export const ActivityReport = () => {
  const allDocs = useLiveQuery(() => queryAllDocs(), [])

  const [page, setPage] = useState(1)
  const rowsPerPage = 50

  const tableData = useMemo(() => {
    if (!allDocs?.length) {
      return []
    }

    return allDocs.reduce((arr: ActivityReport[], entry) => {
      const item: ActivityReport = {
        id: entry._id,
        type: entry._type,
        name: entry.title,
        parentId: entry._parentId,
        lastViewed: '',
        lastEdited: '',
        totalViews: 0,
        totalEdits: 0,
        totalLikes: 0,
      }

      const activities = (entry.fields?.ACTIVITY as []) ?? []

      let lastViewDate = ''
      let lastEditDate = ''

      if (!activities.length) {
        item.lastViewed = ''
        item.lastEdited = ''
        item.totalViews = 0
        item.totalEdits = 0
      } else {
        const activitiesTotalByOperation = activities.reduce(
          (obj: { [key: string]: number }, activity) => {
            Object.entries(activity).forEach(([key, value]) => {
              if (key === 'timestamp') {
                return
              }

              const operation = key.split('_')[1]

              if (operation === 'create' || operation === 'delete') {
                return
              }

              if (operation === 'read') {
                lastViewDate = activity['timestamp']
              }

              if (operation === 'update') {
                lastEditDate = activity['timestamp']
              }

              if (!obj[operation]) {
                obj[operation] = value as number
              } else {
                obj[operation] += value as number
              }
            })

            return obj
          },
          {},
        )

        item.totalViews = activitiesTotalByOperation['update'] ?? 0
        item.totalEdits = activitiesTotalByOperation['read'] ?? 0
        item.lastViewed = lastViewDate
        item.lastEdited = lastEditDate
      }

      arr.push(item)

      return arr
    }, [])
  }, [allDocs])

  const pages = Math.ceil(tableData.length / rowsPerPage)

  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage
    const end = start + rowsPerPage

    return tableData.slice(start, end)
  }, [page, tableData])

  const renderCell = useCallback((item: ActivityReport, columnKey: Key) => {
    const cellValue = item[columnKey as keyof ActivityReport]

    switch (columnKey) {
      case 'name':
        return <div className="w-[200px] break-words">{cellValue}</div>
      case 'parentId':
        return (
          <div className="w-[190px]">
            <ParentUrl parentId={item.parentId} withTitle />
          </div>
        )
      case 'lastViewed':
        if (!cellValue) {
          return null
        }

        return <div>{format(new Date(cellValue), 'MM/dd/yyyy hh:mm a')}</div>
      case 'lastEdited':
        if (!cellValue) {
          return null
        }

        return <div>{format(new Date(cellValue), 'MM/dd/yyyy hh:mm a')}</div>
      case 'totalViews':
      case 'totalEdits':
      case 'totalLikes':
        return <div className="text-center">{cellValue}</div>
      default:
        return cellValue
    }
  }, [])

  return (
    <div>
      <Table
        isStriped
        aria-label="Activity report content"
        bottomContent={
          <div className="flex w-full justify-center">
            <Pagination
              isCompact
              showControls
              showShadow
              color="primary"
              page={page}
              total={pages}
              onChange={(page) => setPage(page)}
            />
          </div>
        }
      >
        <TableHeader columns={columns}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody emptyContent="No rows to display." items={items}>
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
