import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'
import { NumberFieldType } from '@common/interfaces/fields/number-field.interface.ts'
import { useFieldContext } from '@/contexts'
import { FieldContainer } from '@/schema'

export const DateField = () => {
  const { value, saveValue, field, isEditable } = useFieldContext<NumberFieldType, string>()
  const [date, setDate] = useState(value)

  const dateChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value)
    sendChangeDebounced(event.target.value)
  }, [])

  const sendChangeDebounced = useMemo(
    () =>
      debounce((val: string) => {
        saveValue(val)
      }, 1000),
    [],
  )

  if (isEditable) {
    return (
      <FieldContainer>
        <input
          type="date"
          className="p-2"
          placeholder={field.placeholder ?? 'Add date'}
          value={date as string}
          onChange={dateChangeHandler}
        />
      </FieldContainer>
    )
  }

  return (
    <FieldContainer>
      <p>{value}</p>
    </FieldContainer>
  )
}
