import clsx from 'clsx'
import { HtmlParser } from '@/features/rte'
import { Button, Preview } from '@/ui'
import { showUntitledText } from '@/utils/parseString'
import { CardProps } from '../cardsTypes'
import { CardDocType } from '../components/CardDocType'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'

export const BannerCard = ({ doc, docSchema, children, headerElements, setImage }: CardProps) => {
  const { field } = useFieldContext<ChildrenFieldType>()
  const hideDescription = field.layout?.card?.hideDescription
  const styles = field?.layout?.card?.styles
  const secondaryStyles = field?.layout?.card?.secondaryStyles
  const fontSize = field.layout?.card?.fontSize
  const fontColor = field.layout?.card?.fontColor
  const lineHeight = field.layout?.card?.lineHeight
  const backgroundColor = doc.fields.color ?? ''
  const fields = field.layout?.card?.fields ?? []

  const getElemets = (name: string, type: string) => {
    if (type === 'html' && doc.fields[name]) {
      return (
        <div className="mb-1.5">
          <HtmlParser
            className="prose h-20 text-regular text-neutral900 text-break whitespace-pre-line line-clamp-4 [&>ul]:list-disc [&>ul]:pl-3"
            styles={{ color: fontColor }}
          >
            {doc.fields[name]}
          </HtmlParser>
        </div>
      )
    }
  }

  return (
    <div
      className={clsx('relative grid grid-cols-2 overflow-hidden h-full')}
      style={{ backgroundColor: backgroundColor ? (backgroundColor as string) : '#FBD400' }}
    >
      <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>

      <div className="flex flex-col gap-6 p-9 justify-center">
        <div>
          <CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="pb-1.5" />
          <p
            title={doc.title}
            className={clsx('text-title text-neutral900 break-words line-clamp-2')}
            style={{ fontSize, color: fontColor, lineHeight }}
          >
            {showUntitledText(doc.title)}
          </p>
        </div>

        {(!doc.fields?.description ||
          doc.fields?.description === '<p class="editor-paragraph"><br></p>') &&
          fields &&
          fields.map((field) => getElemets(field.fieldName, field.type))}

        {doc.fields?.description &&
          doc.fields?.description !== '<p class="editor-paragraph"><br></p>' &&
          !hideDescription && (
            <div>
              <HtmlParser
                styles={{ color: fontColor ?? '#5F6160' }}
                className="prose h-20 text-regular text-neutral900 text-break whitespace-pre-line line-clamp-4 [&>ul]:list-disc [&>ul]:pl-3"
              >
                {doc.fields.description}
              </HtmlParser>
            </div>
          )}

        <div className="flex items-center justify-between px-0">
          <div>
            <div className="mt-auto">
              <Button className="bg-neutral900">Read the article</Button>
            </div>
          </div>
          <div>{children}</div>
        </div>
      </div>

      <div style={styles}>
        <Preview
          fit="cover"
          className="w-full h-full"
          styles={secondaryStyles}
          image={doc.fields.image}
          iconSize={36}
          onClick={(e) => setImage?.(e, doc.fields.image)}
        />
      </div>
    </div>
  )
}
