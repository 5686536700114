import clsx from 'clsx'
import { Icon, Preview } from '@/ui'
import { showUntitledText } from '@/utils/parseString'
import { CardProps } from '../cardsTypes'
import { CardDocType } from '../components/CardDocType'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'

export const TileCard = ({ doc, docSchema, children, headerElements }: CardProps) => {
  const { field } = useFieldContext<ChildrenFieldType>()
  const styles = field?.layout?.card?.styles
  const secondaryStyles = field?.layout?.card?.secondaryStyles
  const fontSize = field.layout?.card?.fontSize
  const fontColor = field.layout?.card?.fontColor

  const backgroundColor = doc.fields.color ?? ''

  return (
    <div
      className={clsx(
        'relative flex flex-col h-full overflow-hidden transition-transform hover:scale-[1.01]',
      )}
      style={{
        backgroundColor: backgroundColor ? (backgroundColor as string) : '#348DCD',
      }}
    >
      <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
      <div className={clsx('h-[250px]')} style={styles}>
        <Preview
          fit="cover"
          className="w-full h-full"
          styles={secondaryStyles}
          image={doc.fields.image}
          iconSize={36}
        />
      </div>

      <div className="grow flex items-center justify-between p-6">
        <div>
          <CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="pb-1.5" />
          <p
            title={doc.title}
            className="grow text-heading text-neutral900 break-words"
            style={{ fontSize, color: fontColor }}
          >
            {showUntitledText(doc.title)}
          </p>
        </div>

        <Icon name="arrow-right" color="#fff" size={32} className="shrink-0" />
      </div>

      {children}
    </div>
  )
}
