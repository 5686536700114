import { io } from '@/api'
import {
  createRelationApi,
  CreateRelationPL,
  deleteRelationApi,
  updateRelationMetaApi,
} from '@/api/relations'
import { treeManager } from '@/schema'
import { keyHash, LS, moreData } from '@/utils'
import { db } from '../db'
import { Relation } from '../dbTypes'
import { getField } from '@/utils/fields'
import { queryDoc } from '../docs/queries'

export async function fetchRelations() {
  const isoDate = LS.get('disconnectTime')

  if (isoDate) {
    await moreData<Relation>({
      initEvent: 'findDocumentRelationsByDate',
      moreEvent: 'moreFindDocumentRelationsByDate',
      payload: { isoDate },
      cb: (data) => bulkPutRelations(data.items),
    })
  } else {
    await moreData<Relation>({
      initEvent: 'findWorkspaceDocumentRelations',
      moreEvent: 'moreFindWorkspaceDocumentRelations',
      payload: isoDate ? { _excludeDeleted: true } : { isoDate },
      cb: (data) => db.ws.relations.bulkPut(data.items),
    })
  }

  await treeManager.initialize()
}

export async function bulkPutRelations(relations: Relation[], initial = false) {
  const deletedIds: string[] = []
  const relationFields = new Map<string, Set<string>>()

  const newRelations = relations.filter((rel) => {
    if (rel.isDeleted || rel.relationType === 'children') {
      deletedIds.push(rel._id)
      return false
    }

    const mapKey = `${rel.fromId}_$_${rel.fieldName}`
    const relField = relationFields.get(mapKey)
    if (relField) {
      relField.add(rel.toId)
    } else {
      relationFields.set(mapKey, new Set([rel.toId]))
    }

    return true
  })

  if (newRelations.length === 0 && deletedIds.length === 0) return

  return db.ws.transaction('rw', db.ws.relations, db.ws.docs, async () => {
    await Promise.all([
      db.ws.relations.bulkDelete(deletedIds),
      db.ws.relations.bulkPut(newRelations),
    ])

    for (const [key, rels] of relationFields.entries()) {
      const [docId, fieldName] = key.split('_$_')
      const doc = await queryDoc({ _id: docId })
      if (!doc) continue

      const currentValue = getField<string[]>(fieldName, doc.fields)
      if (Array.isArray(currentValue) && !initial) {
        currentValue?.forEach((id) => rels.add(id))
      }
      await db.ws.docs.update(docId, { [`fields.${fieldName}`]: Array.from(rels) })
    }
  })
}

function createLocalRelation(rel: CreateRelationPL) {
  const { fieldName, fromId, toId } = rel
  const { id, reverseId } = keyHash.getDocumentRelation(fieldName, fromId, toId)

  const newRel: Relation = {
    ...rel,
    _id: id,
    _partId: db.activeWorkspace._id,
    _reverseId: reverseId,
    _addedOn: new Date().toISOString(),
    _modifiedOn: new Date().toISOString(),
  }

  return newRel
}

export async function createOneRelation(rel: CreateRelationPL) {
  const newRel = createLocalRelation(rel)

  try {
    const createdRel = await createRelationApi(rel)
    await bulkPutRelations([createdRel])

    return newRel
  } catch (error) {
    await db.ws.relations.delete(newRel._id).catch(console.error)
    window.Rollbar.error(error as Error)
  }
}

export async function createManyRelation(newRels: CreateRelationPL[]) {
  const localRels = newRels.map(createLocalRelation)

  try {
    await bulkPutRelations(localRels)

    return await Promise.all<Relation>(newRels.map(createRelationApi))
  } catch (error) {
    await db.ws.relations.bulkDelete(localRels.map((rel) => rel._id))
    window.Rollbar.error(error as Error)
  }
}

export async function deleteOneRelation(rel: Relation) {
  try {
    await db.ws.relations.delete(rel._id)
    await deleteRelationApi(rel._id)

    return true
  } catch (error) {
    await db.ws.relations.put(rel)
    window.Rollbar.error(error as Error)
    return false
  }
}

export async function deleteManyRelation(rels: Relation[]) {
  try {
    const ids = rels.map((rel) => rel._id)
    await db.ws.relations.bulkDelete(ids)
    await Promise.all(ids.map(deleteRelationApi))

    return true
  } catch (error) {
    await db.ws.relations.bulkPut(rels)
    window.Rollbar.error(error as Error)
    return false
  }
}

export async function updateRelationMeta(id: string, keyName: string, value: any) {
  try {
    await updateRelationMetaApi({
      id,
      keyName,
      value,
    })

    await db.ws.relations.update(id, { [`_meta.${keyName}`]: value })
  } catch (error) {
    window.Rollbar.error(error as Error)
  }
}

export function listenRelations() {
  io.on('mergeDocumentCreateRelation', async (relation: Relation) => {
    bulkPutRelations([relation])
  })
  io.on('mergeDocumentDeleteRelation', async (relation: Relation) => {
    db.ws.relations.delete(relation._id)
  })
}
