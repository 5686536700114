import clsx from 'clsx'
import { HtmlParser } from '@/features/rte'
import { Icon, Preview } from '@/ui'
import { showUntitledText } from '@/utils/parseString'
import { CardProps } from '../cardsTypes'
import { CardDocType } from '../components/CardDocType'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'
import {
  cardElements,
  CardElementsProps,
} from '@/schema/cards/components/CardElements/cardElements.ts'
import { createElement } from 'react'

export const IkeaDefaultCard = ({
  doc,
  docSchema,
  children,
  headerElements,
  setImage,
}: CardProps) => {
  const { field } = useFieldContext<ChildrenFieldType>()
  const hideDescription = field.layout?.card?.hideDescription
  const extraElements = field.layout?.card?.extraElements
  const styles = field?.layout?.card?.styles
  const secondaryStyles = field?.layout?.card?.secondaryStyles
  const fontSize = field.layout?.card?.fontSize
  const fontColor = field.layout?.card?.fontColor

  const backgroundColor = doc.fields.color ?? ''

  return (
    <div
      className={clsx(
        'relative flex flex-col h-full rounded shadow-lg overflow-hidden transition-transform hover:scale-[1.01]',
      )}
      style={{ backgroundColor: backgroundColor ? (backgroundColor as string) : '#EFEFEF' }}
    >
      <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
      <div className={clsx('h-[280px]')} style={styles}>
        <Preview
          fit="cover"
          className="w-full h-full"
          styles={secondaryStyles}
          image={doc.fields.image}
          iconSize={36}
          onClick={(e) => setImage?.(e, doc.fields.image)}
        />
      </div>

      <div className="flex flex-col grow gap-4 p-6">
        {extraElements && (
          <div className="flex items-center gap-4" style={field?.layout?.card?.extraElementsStyles}>
            {extraElements.map((componentName) => {
              const component = cardElements[componentName]

              if (!component) {
                return null
              }

              return createElement<CardElementsProps>(component, {
                key: componentName,
                docItem: doc,
                docSchema,
              })
            })}
          </div>
        )}

        <div>
          <CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="pb-1.5" />
          <p
            title={doc.title}
            className={clsx('text-focus text-neutral900 break-words')}
            style={{ fontSize, color: fontColor }}
          >
            {showUntitledText(doc.title)}
          </p>
        </div>

        {doc.fields?.description && !hideDescription && (
          <div className="grow">
            <HtmlParser
              styles={{ color: fontColor ?? '#5F6160' }}
              className="prose text-regular text-neutral700 text-break whitespace-pre-line line-clamp-4 [&>ul]:list-disc [&>ul]:pl-3"
            >
              {doc.fields.description}
            </HtmlParser>
          </div>
        )}

        <div className="flex justify-end">
          <div className="bg-white flex items-center justify-center w-10 h-10 rounded-full">
            <Icon name="arrow-right" />
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
