import { Data, db, Interaction, InteractionType } from '@/db'
import { io } from './socket'

export async function fetchInteractionsApi(payload: { _id: string; type: InteractionType }) {
  const interactions = await io.emit<Data<Interaction>>('findDocumentInteractions', payload)

  return interactions.items
}

export interface CreateInteractionDTO {
  type: InteractionType
  interaction: Interaction
}

export async function createInteractionApi(payload: {
  _partId: string
  _type: string
  value: any
  _refId?: string
  _sourceId?: string
  _userId?: string
  _addedOn?: string
  _modifiedOn?: string
}) {
  const res = await io.emit<Interaction>('createDocumentInteraction', {
    workspaceId: db.activeWorkspace._id,
    type: payload._type,
    interaction: payload,
  })
  if (!res) throw new Error('Failed to create interaction')

  return res
}

export async function updateInteractionApi(payload: {
  _documentId: string
  _id: string
  value: any
}) {
  const res = await io.emit<Interaction>('updateDocumentInteraction', payload)
  if (!res) throw new Error('Failed to update interaction')

  return res
}

export async function deleteInteractionApi(payload: { _documentId: string; _id: string }) {
  const res = await io.emit<boolean>('removeDocumentInteraction', payload)
  if (!res) throw new Error('Failed to delete interaction')

  return res
}
