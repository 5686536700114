import get from 'lodash.get'
import { KeyHash } from '@common/key-hash.class'

export function random(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

export const keyHash = new KeyHash()

export const getYWebsocketUrl = () => {
  const prodUrl = get<Window, string>(window, 'env.ColabBackendUrl')
  if (prodUrl) {
    return prodUrl.replace('https', 'wss')
  }

  return import.meta.env.VITE_Y_URL
}

export const getWSUrl = () => {
  const prodUrl = get<Window, string>(window, 'env.MicroserviceWSUrl')
  if (prodUrl) {
    return prodUrl.replace('https', 'wss')
  }

  return import.meta.env.VITE_WS_URL
}

export { LS } from './localStorage'
export { moreData, sequentially, sequentiallyChunked } from './promises'
export { getDocAIContext, getDocsWithAIContexts } from './docAIContext'
