import { ReactNode } from 'react'
import { components, GroupBase, MenuListProps } from 'react-select'

type Props<T, IsMulti extends boolean, Group extends GroupBase<T>> = {
  children: ReactNode
} & MenuListProps<T, IsMulti, Group>

export const MenuList = <T, IsMulti extends boolean, Group extends GroupBase<T>>({
  children,
  ...props
}: Props<T, IsMulti, Group>): JSX.Element => (
  <components.MenuList {...props}>{children}</components.MenuList>
)
