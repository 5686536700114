import { FC, useCallback, useState } from 'react'
import { Autocomplete, AutocompleteItem, AutocompleteSection } from '@nextui-org/react'
import { Key } from '@react-types/shared'
import { LinkFieldType } from '@common/interfaces/fields/link-field.interface'
import { useFieldContext } from '@/contexts'
import { queryDoc } from '@/db/docs/queries'
import { useGroup } from '@/schema/hooks'
import type { MultiLinkChildProps } from '../MultiLinkField'

interface MultiLinkModalProps extends MultiLinkChildProps {
  hideSelect?: () => void
}

export type LinkOption = {
  label: string
  value: string
  id: string
}

export const MultiLinkSelect: FC<MultiLinkModalProps> = ({
  allowedDocs,
  hideSelect,
  addRelation,
}) => {
  const { field } = useFieldContext<LinkFieldType>()
  const { groupsArr } = useGroup(allowedDocs, field.group)

  const [value, setValue] = useState<Key>('')

  const handleSelectChange = useCallback(async (docId) => {
    const toDoc = await queryDoc({ _id: docId })
    if (toDoc) addRelation(toDoc)

    setValue('')
  }, [])

  return (
    <Autocomplete
      size="sm"
      label="Select"
      autoFocus
      selectedKey={value}
      onSelectionChange={handleSelectChange}
      onClose={hideSelect}
    >
      {groupsArr
        ? groupsArr.map((group) => (
            <AutocompleteSection key={group.label} showDivider title={group.label}>
              {group.docs.map((item) => (
                <AutocompleteItem key={item._id}>{item.title}</AutocompleteItem>
              ))}
            </AutocompleteSection>
          ))
        : allowedDocs.map((doc) => <AutocompleteItem key={doc._id}>{doc.title}</AutocompleteItem>)}
    </Autocomplete>
  )
}
