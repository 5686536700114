import { useState } from 'react'
import clsx from 'clsx'
import { useFieldContext } from '@/contexts'
import { DataListParams } from '@/db'
import { HtmlParser } from '@/features/rte'
import { Preview } from '@/ui'
import { CardProps } from '../cardsTypes'

export const IkeaCard = ({ doc, children }: CardProps) => {
  const [hovered, setHovered] = useState(false)
  const { field } = useFieldContext<DataListParams>()
  const imageFit = field.layout?.card?.imageFit

  return (
    <>
      <div
        className="relative h-full w-full border border-white hover:border-neutral400 rounded-lg bg-white flex flex-col"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className="relative overflow-hidden h-60 bg-neutral200 rounded-lg">
          <Preview
            fit={imageFit ? 'contain' : 'cover'}
            className="w-full h-full rounded-lg hover:ease-in hover:duration-300 hover:scale-125 hover:-rotate-12"
            image={doc.fields.image}
            iconSize={36}
          />
          <div
            className={clsx(
              'opacity-0 absolute ease-in duration-300 bottom-0 w-full h-10 flex justify-center items-center text-white bg-neutral900 rounded-b-lg',
              hovered && 'opacity-100',
            )}
          >
            <p>click for more</p>
          </div>
        </div>
        <div className="px-4 pt-4 pb-2.5">
          <HtmlParser className="text-regular text-neutral700 [&>ul]:list-disc break-words line-clamp-2">
            {doc.fields.description}
          </HtmlParser>
        </div>
        {children}
      </div>
    </>
  )
}
