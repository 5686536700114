import clsx from 'clsx'
import { formatDistanceToNowStrict } from 'date-fns'
import { useLiveQuery } from 'dexie-react-hooks'
import { FileText } from 'lucide-react'
import { queryDoc } from '@/db/docs/queries'
import { Icon } from '@/ui'
import { IconName } from '@/ui/Icon'
import { camelToCapitalizeWords, showUntitledText } from '@/utils/parseString'
import { selectDocSchema, useSchemaState } from '@/contexts/schema'
import css from '../card.module.css'
import { CardProps } from '../cardsTypes'

export const ListItem2RowsCard = ({ doc, children }: CardProps) => {
  const parentDoc = useLiveQuery(() => queryDoc({ _id: doc._parentId }), [doc._parentId])
  const schema = useSchemaState((state) => selectDocSchema(state, parentDoc?._type))

  return (
    <div className={clsx('relative h-20 py-4 border-b border-neutral300', css.container)}>
      <div className="flex bg-neutral100 w-12 h-12 items-center justify-center rounded-md">
        {schema?.icon ? (
          <Icon name={schema.icon as IconName} color="#AEB3B7" size={23} />
        ) : (
          <FileText color="#AEB3B7" size={23} />
        )}
      </div>
      <div>
        <p className={clsx('font-bold', css.clampOneRow)}>{showUntitledText(doc.title)}</p>
        <div className="text-sm text-neutral700 pt-[9px] flex items-center">
          <div>{camelToCapitalizeWords(doc._type)}</div>
          <div className="px-2.5">·</div>
          <div className="truncate">
            Created {formatDistanceToNowStrict(new Date(doc._addedOn), { addSuffix: true })}
          </div>
        </div>
      </div>

      {children}
    </div>
  )
}
