import React, { useCallback, useState } from 'react'
import { clearCdnCache, getReadStaticUrl } from '@/api/files'
import { FileUpload, saveStaticFile } from '@/features/files'
import { useAppDispatch, useSlr } from '@/store'
import { selectClientWorkspace, updateClient, updateWorkspaceVars } from '@/store/account'
import { Preview, Toggle } from '@/ui'
import { LS } from '@/utils'
import { FetchDocument } from './devtools/FetchDocument'
import { FixLinkFields } from './devtools/FixLinkFields'
import { FixReverseFieldNameOfRelations } from './devtools/FixReverseFieldNameOfRelations.tsx'
import { GenerateDocsCache } from './devtools/GenerateDocsCache.tsx'
import { SyncUsers } from './devtools/SyncUsers'
import { MoveIdeas } from './devtools/MoveIdeas'
import { FixMetricsDataRelation } from '@/routes/WorkspaceSetttings/devtools/FixMetrcsDataRelation.tsx'

const FAVICON_NAME = 'favicon.ico'

export const AdvancedSettings: React.FC = () => {
  const { client, workspace } = useSlr(selectClientWorkspace)
  const [enableIntercom, setEnableIntercom] = useState(workspace._envVars?.enableIntercom ?? false)
  const [faviconUrl, setFaviconUrl] = useState(client.staticFiles?.favicon)
  const dispatch = useAppDispatch()

  const enableIntercomHandler = useCallback(
    (checked: boolean) => {
      setEnableIntercom(checked)
      dispatch(updateWorkspaceVars({ enableIntercom: checked }))
    },
    [client._id, workspace._envVars],
  )

  const onFaviconUploadHandler = useCallback(
    (file: File) => {
      saveStaticFile({ file, fileName: FAVICON_NAME }).then((status) => {
        if (status) {
          clearCdnCache([`/static/${FAVICON_NAME}`]).then(() => {
            setFaviconUrl(getReadStaticUrl(FAVICON_NAME))
          })
          dispatch(
            updateClient({
              _id: client._id,
              name: client.name,
              staticFiles: { favicon: FAVICON_NAME },
            }),
          )
        }
      })
    },
    [faviconUrl],
  )

  return (
    <div>
      <form>
        <div className="form-control">
          <Toggle label="Enable Intercom" on={enableIntercom} onChange={enableIntercomHandler} />
        </div>
        <div className="form-control mb-4 flex flex-col pb-7">
          <p className="label text-focus">Favicon</p>
          <FileUpload onUpload={onFaviconUploadHandler} className="w-32 h-32 mb-3 rounded-md">
            <Preview fileUrl={faviconUrl} />
          </FileUpload>
        </div>
      </form>
      {LS.get('dev') && (
        <div className="grid gap-2">
          <div className="flex flex-wrap">
            <FixLinkFields />
            <GenerateDocsCache />
            <SyncUsers />
            <MoveIdeas />
          </div>
          <FetchDocument />
          <FixReverseFieldNameOfRelations />
          <FixMetricsDataRelation />
        </div>
      )}
    </div>
  )
}
