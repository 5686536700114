import clsx from 'clsx'
import { useFieldContext } from '@/contexts'
import { DataListParams } from '@/db'
import { HtmlParser } from '@/features/rte'
import { Preview } from '@/ui'
import { showUntitledText } from '@/utils/parseString'
import { CardProps } from '../cardsTypes'

export const FAQCard = ({ doc, index, setImage }: CardProps) => {
  const { field } = useFieldContext<DataListParams>()
  const reversed = field.layout?.card?.reversed
  const imageFit = field.layout?.card?.imageFit
  const reverseEverySecondItem =
    field.layout?.card?.reverseEverySecondItem && index && index % 2 !== 1
  const { ...containerStyles } = field.styles ?? {}
  return (
    <>
      <div
        className={clsx(
          'relative h-full w-full p-4 rounded-md flex',
          (reversed || reverseEverySecondItem) && 'flex-row-reverse',
        )}
        style={containerStyles}
      >
        <div className="flex-1 flex flex-col justify-center">
          {doc.title && (
            <h5 title={doc.title} className="mb-3 text-title font-bold leading-9">
              {showUntitledText(doc.title)}
            </h5>
          )}
          <HtmlParser className="text-regular text-neutral900 [&>ul]:list-disc break-words">
            {doc.fields.description}
          </HtmlParser>
        </div>
        <div
          className={clsx(
            'w-[40%] h-max flex relative',
            reversed || reverseEverySecondItem ? 'pr-6' : 'pl-6',
          )}
        >
          <Preview
            fit={imageFit ? 'contain' : 'cover'}
            className="w-min h-min rounded-lg"
            image={doc.fields.image}
            iconSize={16}
            onClick={(e) => setImage?.(e, doc.fields.image)}
          />
        </div>
      </div>
    </>
  )
}
