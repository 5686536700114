import { Group } from '@visx/group'
import { Line } from '@visx/shape'
import { Text } from '@visx/text'
import { Axis } from '@common/interfaces/fields/diagram-field.interface'
import { useDiagramContext } from '../hooks'

type AxisProps = {
  data: Axis
  type: 'x' | 'y'
  showLine?: boolean
}

export const DiagramAxis = ({ data, type, showLine = true }: AxisProps) => {
  const { deltaX, deltaY, innerWidth, innerHeight, margin } = useDiagramContext()

  const isHorizontal = type === 'x'

  const startText = isHorizontal ? innerWidth / 2 : innerHeight / 2
  const startSubText = isHorizontal ? innerHeight + margin / 2 : innerWidth + margin / 2
  const start = isHorizontal ? Math.abs(data.min) * deltaX : Math.abs(data.min) * deltaY
  const oppositeType = isHorizontal ? 'y' : 'x'

  const end = isHorizontal ? innerWidth : innerHeight
  const lineEnd = isHorizontal ? innerHeight + margin / 3 : innerWidth + margin / 3

  return (
    <Group>
      <Text
        {...{ [type]: startText, [oppositeType]: -(margin / 2) }}
        textAnchor="middle"
        angle={isHorizontal ? 0 : -90}
        verticalAnchor="middle"
        style={{ fontWeight: 'bold' }}
        width={200}
      >
        {data.title}
      </Text>
      <Text
        {...{ [type]: startText, [oppositeType]: startSubText }}
        textAnchor="middle"
        angle={isHorizontal ? 0 : -90}
        verticalAnchor="middle"
        style={{ fontWeight: 'bold' }}
        width={200}
      >
        {data.subTitle}
      </Text>
      <Text
        {...{ [type]: 0, [oppositeType]: -(margin / 2) }}
        angle={isHorizontal ? 0 : -90}
        textAnchor={isHorizontal ? 'start' : 'end'}
        style={{ textTransform: 'uppercase', fontSize: 11 }}
        verticalAnchor="middle"
      >
        {data.minLabel}
      </Text>
      <Text
        {...{ [type]: end, [oppositeType]: -(margin / 2) }}
        angle={isHorizontal ? 0 : -90}
        textAnchor={isHorizontal ? 'end' : 'start'}
        style={{ textTransform: 'uppercase', fontSize: 11 }}
        verticalAnchor="middle"
      >
        {data.maxLabel}
      </Text>
      <Line
        strokeWidth="1"
        strokeDasharray="4"
        stroke={showLine ? '#bbb' : '#f5f5f5'}
        from={{ [type]: start, [oppositeType]: 0 }}
        to={{ [type]: start, [oppositeType]: lineEnd }}
      />
    </Group>
  )
}
