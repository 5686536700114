import clsx from 'clsx'
import { HtmlParser } from '@/features/rte'
import { showUntitledText } from '@/utils/parseString'
import { CardProps } from '../cardsTypes'
import { CardDocType } from '../components/CardDocType'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'

export const TemplateCard = ({ doc, docSchema, children, headerElements }: CardProps) => {
  const { field } = useFieldContext<ChildrenFieldType>()
  const hideDescription = field.layout?.card?.hideDescription
  const styles = field?.layout?.card?.styles
  const fontSize = field.layout?.card?.fontSize
  const fontColor = field.layout?.card?.fontColor
  const lineHeight = field.layout?.card?.lineHeight
  const backgroundColor = field.layout?.card?.backgroundColor ?? '#FBD400'

  return (
    <div
      className="relative flex flex-col h-full overflow-hidden transition-transform hover:scale-[1.01]"
      style={{ ...styles, backgroundColor: backgroundColor as string }}
    >
      <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>

      <div className="flex flex-col grow gap-3 p-5 pr-11">
        <div>
          <CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="pb-1.5" />
          <h2
            title={doc.title}
            className={clsx('text-heading2 text-neutral900 break-words line-clamp-3')}
            style={{ fontSize, color: fontColor, lineHeight }}
          >
            {showUntitledText(doc.title)}
          </h2>
        </div>

        {doc.fields?.description && !hideDescription && (
          <div className="grow">
            <HtmlParser
              styles={{ color: fontColor ?? '#5F6160' }}
              className="prose text-caption text-neutral700 text-break whitespace-pre-line line-clamp-3 [&>ul]:list-disc [&>ul]:pl-3"
            >
              {doc.fields.description}
            </HtmlParser>
          </div>
        )}
      </div>
      {children}
    </div>
  )
}
