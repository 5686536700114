import { ChangeEvent, useMemo, useState } from 'react'
import { useDebounce } from './useDebounce'

export function useSearch<T>(
  defaultValue = '',
  initialList?: T[],
  paramCb?: (item: T) => string | undefined,
) {
  const [value, setSearch] = useState(defaultValue)
  const search = useDebounce(value, 500)

  const query = search.toLowerCase()
  const list = useMemo(
    () => initialList?.filter((item) => paramCb?.(item)?.toLowerCase().includes(query)) ?? [],
    [search, initialList],
  )

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  return {
    value,
    search,
    setSearch,
    handleSearch,
    list,
  }
}
