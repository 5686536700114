import { ImageFieldType } from '@common/interfaces/fields/image-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { ImageFieldData } from '@/db'
import { Cover } from '.'
import { FieldComponent } from '../fieldType'
import { PersonaImage } from './components/PersonaImage'
import {
  cardElements,
  CardElementsProps,
} from '@/schema/cards/components/CardElements/cardElements.ts'
import { createElement } from 'react'
import { selectDocSchema, useSchemaState } from '@/contexts/schema'
import { ImageFieldEdit } from './components/ImageFieldEdit'

export const ImageField: FieldComponent = () => {
  const { doc } = useDocContext()
  const { field, isEditable } = useFieldContext<ImageFieldType, ImageFieldData>()
  const { viewComponent } = field
  const docSchema = useSchemaState((state) => selectDocSchema(state, doc._type))

  if (isEditable) {
    return <ImageFieldEdit />
  }

  const buttons = ['downloadButton', 'fileViewButton', 'bookmark']

  return (
    <div className="relative">
      {viewComponent === 'persona' ? <PersonaImage /> : <Cover />}
      {field?.extraElements && (
        <div className="flex items-center gap-4 justify-between" style={field?.extraElementsStyles}>
          <div className="flex items-center gap-x-2.5">
            {field.extraElements.map((componentName) => {
              const component = cardElements[componentName]

              const isButton = buttons.includes(componentName)
              if (isButton) return
              if (!component) {
                return null
              }

              return createElement<CardElementsProps>(component, {
                key: componentName,
                docItem: doc,
                docSchema,
              })
            })}
          </div>
          <div>
            {field.extraElements.map((componentName) => {
              const component = cardElements[componentName]
              const isButton = buttons.includes(componentName)
              if (!isButton) return

              if (!component) {
                return null
              }

              return createElement<CardElementsProps>(component, {
                key: componentName,
                docItem: doc,
                docSchema,
              })
            })}
          </div>
        </div>
      )}
    </div>
  )
}
