import { useEffect, useState } from 'react'
import { produce } from 'immer'
import { BranchProps, BranchType } from '../../types'
import { Branch } from './Branch'

export const BranchTree = ({ branch, level }: BranchProps) => {
  const [tree, setTree] = useState<BranchType[]>([])

  useEffect(() => {
    if (branch.children) {
      setTree(branch.children)
    }
  }, [branch.children])

  const moveBranch = (dragIndex: number, hoverIndex: number) => {
    const newTree = produce(tree, (draft) => {
      draft.splice(dragIndex, 1)
      draft.splice(hoverIndex, 0, tree[dragIndex])
    })

    setTree(newTree)
  }

  return (
    <div className="pl-4 border-l border-dashed border-neutral300">
      {tree.map((child, i) => (
        <Branch
          key={child.name}
          index={i}
          branch={child}
          level={level}
          parent={branch}
          tree={tree}
          moveBranch={moveBranch}
        />
      ))}
    </div>
  )
}
