import { toast } from 'react-toastify'
import { WORKSPACE_DOCUMENT_TYPE_NAME } from '@common/interfaces/clients/document-type.interface'
import { createDocApi } from '@/api/docs'
import { fetchRandomImage } from '@/api/files'
import { createRelationApi } from '@/api/relations'
import { createTelemetryApi } from '@/api/workspaces'
import { db, Doc, FieldValueType, Relation, RelationType } from '@/db'
import { store } from '@/store'
import { queryAncestors, queryDocsByType, queryHome } from '../queries'
import { saveField } from './updateDocsMutation'
import { fetchDocumentPermissions } from '@/db/permissions/sync'
import { scm } from '@/contexts/schema'
import { ReadAccess } from '@common/interfaces/documents/document.interface'

export async function createHomeDoc() {
  const homeDoc = await queryHome()

  if (homeDoc || scm.getDocSchema(WORKSPACE_DOCUMENT_TYPE_NAME)) return

  try {
    const doc = await createDocApi({
      title: 'Home',
      _type: WORKSPACE_DOCUMENT_TYPE_NAME,
      _parentId: '',
      _ancestors: [],
    })
    await db.ws.docs.add(doc)

    await createTelemetryApi({
      documentId: doc._id,
      documentType: doc._type,
      documentTitle: doc.title,
      action: 'create',
    })

    const usersDoc = await createDoc({
      title: 'Team',
      type: 'Users',
      parentDoc: doc,
    })

    if (!usersDoc) throw new Error('Failed to create Users doc')

    await db.ws.docs.add(usersDoc)

    const accountDoc = await createDoc({
      title: db.activeAccount.name,
      type: 'User',
      parentDoc: usersDoc,
      userId: db.activeAccount._id,
      fields: {
        userId: db.activeAccount._id,
      },
    })

    if (!accountDoc) throw new Error('Failed to create Account doc')

    await db.ws.docs.add(accountDoc)
  } catch (error) {
    window.Rollbar.error(error as Error)
  }
}

export type CreateDocPL = {
  type: string
  title?: string
  parentDoc: Doc
  fieldName?: string
  reverseFieldName?: string
  relationType?: RelationType
  autoUnsplash?: boolean
  stealth?: boolean
  showToasts?: boolean
  userId?: string
  fields?: { [name: string]: FieldValueType }
  addedOn?: string
  modifiedOn?: string
  readAccess?: ReadAccess
}

export async function createDoc({
  type,
  parentDoc,
  title = 'Untitled',
  fieldName = 'children',
  reverseFieldName = 'parent',
  relationType = 'children',
  autoUnsplash = false,
  showToasts = true,
  userId,
  stealth,
  addedOn,
  modifiedOn,
  fields = {},
  readAccess,
}: CreateDocPL) {
  const toastId = showToasts && toast.loading(`Creating ${type}...`)
  const ancestors = await queryAncestors(parentDoc, true)

  try {
    const createdDoc = await createDocApi(
      {
        title: title || 'Untitled',
        _type: type,
        _parentId: parentDoc._id,
        _ancestors: ancestors,
        _userId: userId,
        disableEditPropagation: stealth,
        _addedOn: addedOn,
        _modifiedOn: modifiedOn,
      },
      readAccess,
    ).catch((err) => {
      console.error(err)
    })

    if (!createdDoc?._id) {
      throw new Error(`Failed to create ${type}`)
    }

    fetchDocumentPermissions(createdDoc._id).catch((err) => {
      console.error(`Error on fetching permissions on doc creation: ${err}`)
    })

    let newRel: Relation | void

    if (relationType !== 'children') {
      newRel = await createRelationApi({
        fromId: parentDoc._id,
        fromDocType: parentDoc._type,
        toId: createdDoc._id,
        toDocType: type,
        fieldName,
        reverseFieldName,
        relationType,
      }).catch((err) => {
        console.error(`Error on creating relation on doc creation: ${err}`)
      })
    }

    if (stealth) {
      fields.stealth = stealth
    }

    await db.ws
      .transaction('rw', db.ws.relations, db.ws.docs, async () => {
        await db.ws.docs.add(createdDoc)
        if (newRel) {
          db.ws.relations.put(newRel)
        }
      })
      .catch((err) => {
        console.error(`Error on writing new doc to indexedDb on doc creation: ${err}`)
      })

    const fieldNames = Object.keys(fields)

    if (fieldNames.length) {
      await Promise.all(
        fieldNames.map((fieldName) =>
          saveField(
            createdDoc._id,
            {
              name: fieldName,
              value: fields[fieldName],
            },
            { delay: 500 },
          ),
        ),
      ).catch((err) => {
        console.error(`Error on creating fields on doc creation: ${err}`)
      })
    }

    if (toastId) {
      toast.update(toastId, {
        render: `${type} is successfully created`,
        type: 'success',
        isLoading: false,
        autoClose: 1000,
      })
    }

    const newDoc: Doc = { ...createdDoc, fields }

    if (autoUnsplash) {
      addDocDefaultImage(newDoc)
    }

    const { activityLabel } = store.getState()

    createTelemetryApi({
      documentId: createdDoc._id,
      documentType: createdDoc._type,
      documentTitle: createdDoc.title,
      action: 'create',
      label: activityLabel?.label,
    })

    return newDoc
  } catch (e) {
    if (toastId) {
      toast.update(toastId, {
        render: `${type} creating is failed`,
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      })
    }

    window.Rollbar.error(e as Error)
    return undefined
  }
}

async function addDocDefaultImage(doc: Doc) {
  const unsplashImg = await fetchRandomImage(doc.title)
  if (unsplashImg) {
    saveField(doc._id, {
      name: 'image',
      value: {
        source: 'unsplash',
        _clientId: db.activeClient._id,
        fileName: unsplashImg.id,
      },
    })
  }
}

export async function convertDocTo(doc: Doc, type: string) {
  const parentTypes = scm.getAllowedParents(type, true)
  const parentDoc = await queryDocsByType(parentTypes[0])

  if (!parentDoc[0]) {
    window.Rollbar.error('Failed to find parent document', parentTypes)
    return
  }

  const newDoc = await createDoc({
    type,
    parentDoc: parentDoc[0],
    title: doc.title,
    fields: doc.fields,
  })

  return newDoc
}
