import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateSchema } from '@/db/workspaces/sync.ts'
import { scm, useSchemaState } from '@/contexts/schema'
import { SchemaForm } from './components/SchemaForm'
import { SchemaTree } from './components/SchemaTree'
import { documentTemplate } from './templates/documentTemplate'
import { fieldTemplates } from './templates/fieldTemplate'

export const SchemaEditor = () => {
  const { allSchema, updateDocument, updateField, addNewDocumentToParent } = useSchemaState()
  const [searchParams] = useSearchParams()

  const currentObject = useMemo(() => {
    const document = scm.getDocSchema(searchParams.get('doc') ?? '')
    if (!document) return null

    const docName = document.name
    const field = scm.getFieldSchema(docName, searchParams.get('field') ?? '')
    const isNew = searchParams.get('new')
    const newFieldType = searchParams.get('fieldType')

    let itemSchema
    let template
    let isField
    let key

    if (field && !isNew) {
      itemSchema = field
      template = fieldTemplates[field.type]
      isField = true
      key = `${docName}.${field.name}`
    } else if (newFieldType && isNew) {
      itemSchema = null
      template = fieldTemplates[newFieldType]
      isField = true
      key = `${docName}.${newFieldType}.${isNew}`
    } else if (document && field && isNew) {
      itemSchema = null
      template = documentTemplate
      isField = false
      key = docName
    } else {
      itemSchema = document
      template = documentTemplate
      isField = false
      key = docName
    }

    return {
      key,
      template,
      docName,
      fieldName: field?.name ?? '',
      schema: itemSchema,
      isField,
      isNew,
    }
  }, [allSchema, searchParams])

  const onSubmit = (data) => {
    if (!currentObject) return

    // eslint-disable-next-line no-console
    console.log(data)

    const { docName, fieldName, isField, schema, isNew } = currentObject

    if (isField) {
      if (isNew && !schema) {
        updateField(docName, data.name, data)
      } else {
        updateField(docName, schema.name, data)
      }
    } else {
      if (isNew) {
        updateDocument(data.name, data)
        addNewDocumentToParent(docName, fieldName, data)
      } else {
        updateDocument(docName, data)
      }
    }
  }

  const saveSchema = async (data) => {
    onSubmit(data)

    const schema = scm.getSchema()

    const toastId = toast.loading('Schema validation')

    try {
      await updateSchema(schema)
      toast.update(toastId, {
        render: 'Schema updated',
        type: 'success',
        isLoading: false,
        closeButton: true,
        autoClose: 2000,
      })
    } catch (e: any) {
      toast.update(toastId, {
        render: 'Could not update schema',
        type: 'error',
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
      })
    }
  }

  return (
    <>
      <main className="p-6 max-lg:px-5 overflow-y-auto">
        {currentObject && (
          <SchemaForm {...currentObject} updateSchema={saveSchema} onSubmit={onSubmit} />
        )}
      </main>
      <div className="border-l border-neutral200 p-4 max-lg:px-5 overflow-y-auto">
        <SchemaTree schema={allSchema} />
      </div>
    </>
  )
}
