import axios from 'axios'
import get from 'lodash.get'
import { ImageFieldData, db } from '@/db'
import { unsplashApi } from './axios'
import { io } from './socket'

const publicCdnUrl = get(window, 'env.PublicCdnUrl') || import.meta.env.VITE_PUBLIC_CDN_URL

type StaticPayloadType = {
  fileName: string
  hours: number
}

export function getUploadStaticUrl(payload: StaticPayloadType) {
  return io.emit('getStaticSignedUrl', payload)
}

export function getReadStaticUrl(fileName: string) {
  return `${publicCdnUrl}/static/${fileName}`
}

export async function clearCdnCache(relativePaths: string[]) {
  return io.emit('clearCdnCache', { relativePaths })
}

export async function fetchSignedImage(
  fileName: string,
  _documentId = db.activeWorkspace._id,
  crop = true,
) {
  try {
    const url = await io.emit<string>('getSignedUrl', {
      _clientId: db.activeClient._id,
      _documentId,
      fileName,
      hours: 1,
      ...(crop && { transform: { width: 1080 } }),
    })

    return url
  } catch (error) {
    window.Rollbar.error(error as Error)
    return undefined
  }
}

export async function fetchUnsplashUrlById(fileName: string) {
  try {
    const { data } = await unsplashApi.get<UnsplashEntity>(`/${fileName}`)

    return data
  } catch (error) {
    window.Rollbar.error(error as Error)
    return undefined
  }
}

export async function fetchImage(image: ImageFieldData): Promise<ImageFieldData | undefined> {
  if (image.source === 'cdn') {
    const url = await fetchSignedImage(image.fileName, image._documentId)
    if (!url) return

    return { ...image, url }
  }

  const data = await fetchUnsplashUrlById(image.fileName)
  if (!data) return

  return {
    ...image,
    url: data.urls.regular,
    author: data.user.name,
    description: data.alt_description,
    authorUrl: `https://unsplash.com/@${data.user.username}`,
  }
}

export async function searchUnsplashImages(text: string, params?: { page: number }) {
  return unsplashApi.get<UnsplashData>(`/search?query=${text}`, { params })
}

export async function fetchRandomImage(query: string) {
  try {
    const { data: img } = await unsplashApi.get<UnsplashEntity>(
      `/random?query=${query}&orientation=landscape`,
    )
    if (img) {
      return img
    }

    const { data: abstractImg } = await unsplashApi.get<UnsplashEntity>(
      `/random?query=abstract&orientation=landscape`,
    )
    return abstractImg
  } catch (error) {
    window.Rollbar.error(error as Error)
    return undefined
  }
}

export async function uploadCdnApi(payload: {
  _documentId: string
  fileName: string
  url: string
}) {
  const res = await io.emit('uploadUrl', {
    _clientId: db.activeClient._id,
    ...payload,
  })
  if (!res) throw new Error('Failed to upload image')

  return res
}

export async function uploadFileApi(url: string, body: string | ArrayBuffer | null | FormData) {
  try {
    await axios.put<{ url: string }>(url, body, {
      headers: { 'Content-Type': 'image/*' },
    })

    return true
  } catch (error) {
    window.Rollbar.error(error as Error)
    return false
  }
}

export function fetchUnsplashDownload(id: string) {
  return unsplashApi.get(`/${id}/download`).catch(console.error)
}

interface UnsplashData {
  results: UnsplashEntity[]
  total: number
  total_pages: number
}

export interface UnsplashEntity {
  id: string
  alt_description: string
  height: number
  width: number
  links: {
    download: string
    download_location: string
    html: string
    self: string
  }
  urls: { regular: string; small: string }
  user: {
    name: string
    first_name: string
    last_name: string
    username: string
  }
}
