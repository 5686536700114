import {
  ChangeEvent,
  Children,
  cloneElement,
  DragEvent,
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  isValidElement,
  PropsWithRef,
  RefAttributes,
  useCallback,
  useState,
} from 'react'
import clsx from 'clsx'
import { EditPicture, Button } from '@/ui'
import css from '../../files.module.css'

interface FileUploadProps extends InputHTMLAttributes<HTMLInputElement> {
  onUpload?: (file: File) => void
  className?: string
  hoverView?: JSX.Element
}

type DeleteButtonProps = {
  onDelete: () => void
  setFileUrl?: (url: string | undefined) => void
}

type FileUploadFC = ForwardRefExoticComponent<
  PropsWithRef<FileUploadProps> & RefAttributes<HTMLInputElement>
> & {
  DeleteButton: FC<DeleteButtonProps>
}

export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  (
    {
      children,
      className,
      onChange,
      onUpload,
      hoverView = <EditPicture>Upload or drop image</EditPicture>,
      ...attrs
    },
    ref,
  ) => {
    const [fileUrl, setFileUrl] = useState<string>()

    const handleUpload = useCallback(
      (file?: File) => {
        if (file) {
          setFileUrl(URL.createObjectURL(file))
          onUpload?.(file)
        }
      },
      [onUpload],
    )
    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event)
      handleUpload(event.target.files?.[0])
    }, [])

    const handleDrop = useCallback(
      (event: DragEvent<HTMLLabelElement>) => {
        event.preventDefault()
        handleUpload(event.dataTransfer.files?.[0])
      },
      [onUpload],
    )

    const handleDragOver = useCallback((event: React.DragEvent<HTMLLabelElement>) => {
      event.preventDefault()
      event.dataTransfer.effectAllowed = 'copy'
      event.dataTransfer.dropEffect = 'copy'
    }, [])

    return (
      <label
        className={clsx(css.upload, 'relative place-items-center overflow-hidden', className)}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <input
          type="file"
          accept="image/*"
          className="sr-only"
          {...attrs}
          onChange={handleChange}
          ref={ref}
        />
        {Children.map(children, (child) => {
          if (!isValidElement(child)) return
          return cloneElement<any>(child, {
            fileUrl,
            setFileUrl,
            ...child.props,
          })
        })}
        {hoverView &&
          cloneElement(hoverView, {
            className: clsx(hoverView.props.className, css.uploadHover),
            ...hoverView.props,
          })}
      </label>
    )
  },
) as FileUploadFC

const FileUploadButton = ({ onDelete, setFileUrl }: DeleteButtonProps) => {
  const handleDelete = useCallback(() => {
    setFileUrl?.(undefined)
    onDelete?.()
  }, [])

  return <Button icon="upload" onClick={handleDelete} />
}

FileUpload.DeleteButton = FileUploadButton

FileUpload.displayName = 'FileUpload'
