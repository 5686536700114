import { useState } from 'react'
import { Autocomplete, AutocompleteItem } from '@nextui-org/react'
import { Key } from '@react-types/shared'
import { Doc } from '@/db'

type CollaborateUserSelectProps = {
  inviteUser: (userId?: string) => Promise<void>
  users: Doc[]
}

export const CollaborateUserSelect = ({ users, inviteUser }: CollaborateUserSelectProps) => {
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<Key>('')

  const onSelectionChange = async (userId: Key) => {
    setSelected(userId)
    setLoading(true)
    await inviteUser(userId as string)
    setLoading(false)
    setSelected('')
  }

  return (
    <Autocomplete
      size="sm"
      label="Choose collaborators"
      selectedKey={selected}
      isLoading={loading}
      className="h-9"
      onSelectionChange={onSelectionChange}
    >
      {users.map((user) => (
        <AutocompleteItem key={user.userId ?? user._userId}>{user.title}</AutocompleteItem>
      ))}
    </Autocomplete>
  )
}
