import { useSlr } from '@/store'
import { selectStatusDoc } from '@/store/docs'
import { DocStatus } from '@/store/docs/slice'
import { Icon } from '@/ui'
import { IconName } from '@/ui/Icon'
import clsx from 'clsx'

const getNotification = (type: DocStatus): { title: string; icon: IconName } => {
  switch (type) {
    case 'pending':
      return {
        title: 'Saving...',
        icon: 'loader-circle',
      }
    case 'success':
      return {
        title: 'Saved',
        icon: 'circle-check',
      }
    case 'failed':
      return {
        title: 'Save failed',
        icon: 'cloud-off',
      }
    default:
      return {
        title: '...',
        icon: 'loader',
      }
  }
}

export const DocumentStatus = () => {
  const status = useSlr(selectStatusDoc) as DocStatus
  const { title, icon } = getNotification(status)

  return (
    <div>
      {status !== 'idle' && (
        <div
          className={clsx('flex justify-end items-center gap-1', {
            'text-neutral600': status === 'pending',
            'text-success': status === 'success',
            'text-error': status === 'failed',
          })}
        >
          <Icon name={icon} className={clsx({ 'animate-spin': status === 'pending' })} size={16} />
          <span>{title}</span>
        </div>
      )}
    </div>
  )
}
