import { useCallback } from 'react'
import clsx from 'clsx'
import { X } from 'lucide-react'
import { validateEmail } from '@/utils/regexp'

export type EmailChipProps = { email: string; onDelete: (email: string) => void }

export const EmailChip = ({ email, onDelete }: EmailChipProps) => {
  const isValidEmail = validateEmail(email)

  const deleteEmail = useCallback(() => {
    onDelete(email)
  }, [])

  return (
    <div
      className={clsx('bg-neutral400 flex items-center mr-2.5 mb-2.5 px-2.5 py-0.5 rounded-full', {
        'bg-error': !isValidEmail,
      })}
    >
      <span
        className={clsx('text-sm text-neutral900 pr-1.5', {
          'text-pink500': !isValidEmail,
        })}
      >
        {email}
      </span>
      <button onClick={deleteEmail}>
        <X color={isValidEmail ? '#5C6670' : '#660022'} size={14} className="cursor-pointer" />
      </button>
    </div>
  )
}
