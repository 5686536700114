import { useMemo } from 'react'
import { Annotation, Label, LineSubject } from '@visx/annotation'
import { AxisBottom } from '@visx/axis'
import { curveCardinal } from '@visx/curve'
import { GridColumns } from '@visx/grid'
import { scaleBand, scaleLinear } from '@visx/scale'
import { AreaClosed } from '@visx/shape'
import { Text } from '@visx/text'
import { useRect } from '@/hooks'
import { colors } from '@/ui/theme'
import { SparklinesDatum, SparklinesProps, useSparklinesDatum } from './hooks/useSparklinesDatum'

const noData = [
  { x: 1, y: 80 },
  { x: 2, y: 160 },
  { x: 3, y: 210 },
  { x: 5, y: 380 },
  { x: 4, y: 450 },
]

export const Sparklines = (props: SparklinesProps) => {
  const { currentDoc } = props
  const { ref, rect } = useRect<HTMLDivElement>()
  const { data, annotation, value } = useSparklinesDatum(props)

  const getDate = (d: SparklinesDatum) => d.x
  const getValue = (d: SparklinesDatum) => d.y ?? 0
  const innerHeight = rect.height - 20

  const datum = data.length ? data : noData

  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, rect.width],
        domain: datum.map(getDate),
        paddingOuter: 0.2,
        paddingInner: 1,
      }),
    [datum, rect.width],
  )

  const { yMax, yScale } = useMemo(() => {
    const maxValue = Math.max(...datum.map((docItem) => +docItem.y), annotation?.value ?? 0)

    return {
      yMax: innerHeight / maxValue,
      yScale: scaleLinear({
        range: [innerHeight, 0],
        domain: [0, maxValue],
        nice: true,
      }),
    }
  }, [datum, annotation, innerHeight])

  return (
    <>
      <div className="flex items-center">
        <p className="text-title pr-1">{value}</p>
        {currentDoc.fields?.aspiration && (
          <p className="text-focus text-neutral900">{currentDoc.fields.aspiration as string}</p>
        )}
      </div>
      <div className="w-full aspect-image" ref={ref}>
        {rect.height && props.sparkline && (
          <svg width={rect.width} height={rect.height}>
            <AreaClosed
              data={data.length ? data : noData}
              x={(d) => xScale(getDate(d)) ?? 0}
              y={(d) => yScale(getValue(d)) ?? 0}
              yScale={yScale}
              fill={data.length ? colors.blue100 : colors.neutral200}
              stroke={data.length ? colors.blue : colors.neutral300}
              strokeWidth={2}
              curve={curveCardinal}
            />
            {data.length ? (
              <AxisBottom
                top={innerHeight}
                scale={xScale}
                numTicks={4}
                stroke={colors.neutral400}
                tickStroke={colors.neutral400}
                tickLabelProps={{
                  fontSize: 6,
                }}
              />
            ) : (
              <Text
                width={rect.width}
                height={20}
                x={rect.width / 2}
                y={innerHeight + 8}
                textAnchor="middle"
                verticalAnchor="start"
                fill={colors.neutral400}
                style={{ fontWeight: 600 }}
              >
                No data
              </Text>
            )}
            <GridColumns
              top={0}
              scale={xScale}
              height={innerHeight}
              stroke={data.length ? colors.neutral400 : colors.neutral300}
              pointerEvents="none"
            />
            {annotation && (
              <Annotation y={innerHeight - annotation.value * yMax} dx={20}>
                <LineSubject
                  min={0}
                  max={rect.width}
                  dx={-4}
                  stroke={colors.neutral900}
                  strokeWidth={1}
                  orientation="horizontal"
                />
                <Label
                  title={annotation.title}
                  titleFontSize={12}
                  horizontalAnchor="start"
                  verticalAnchor="start"
                  backgroundPadding={{ top: 2, left: 4, right: 4, bottom: 2 }}
                  backgroundFill="none"
                  anchorLineStroke="transparent"
                />
              </Annotation>
            )}
          </svg>
        )}
      </div>
    </>
  )
}
