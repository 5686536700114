import { Navigate } from 'react-router-dom'
import { SchemaEditor } from '@/features/schemaEditor'
import { usePath } from '@/hooks'
import { useSlr } from '@/store'
import { selectAccount } from '@/store/account'

export const SchemaEditorPage: React.FC = () => {
  const account = useSlr(selectAccount)
  const { workspacePath } = usePath()

  if (account._relationType !== 'admin') {
    return <Navigate replace to={workspacePath} />
  }

  return (
    <div className="h-screen overflow-hidden">
      <div className="grid grid-cols-[1fr_350px] max-lg:grid-cols-1 h-full">
        <SchemaEditor />
      </div>
    </div>
  )
}
