import { useEffect } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { useDocContext } from '@/contexts'
import { Interaction } from '@/db'
import { queryComments } from '@/db/interactions/queries'
import { Comment } from '@/features/comments/Comment'
import { AddComment } from './AddComment'
import { fetchInteractions } from '@/db/interactions/sync'
import { singlePromise } from '@/utils/promises'

interface CommentSectionProps {
  styles?: { [key: string]: string }
}

export const CommentSection = ({ styles }: CommentSectionProps) => {
  const { doc } = useDocContext()
  const comments = useLiveQuery(() => queryComments(doc._id), [doc._id]) ?? []

  useEffect(() => {
    singlePromise(`comments/${doc._id}`, () => fetchInteractions(doc._id, 'comment'), {
      once: true,
    })
  }, [doc._id])

  return (
    <div style={styles}>
      <div className="field-theme pb-5 pt-5 mt-3 border-t border-neutral200">
        <h5 className="text-regular">{comments.length} comments</h5>
        <AddComment />
      </div>
      <div className="max-sm:pl-0 grid gap-5">
        {comments?.map((comment, index) => (
          <Comment key={index} comment={comment as Interaction<string>} />
        ))}
      </div>
    </div>
  )
}
