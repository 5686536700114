import './index.css'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { IntercomProvider } from 'react-use-intercom'
import { NextUIProvider } from '@nextui-org/react'
import { enableMapSet } from 'immer'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from '@/store'
import { App } from './App'

enableMapSet()

const root = createRoot(document.getElementById('root') as HTMLElement)

const appId = import.meta.env.VITE_INTERCOM_APP_ID

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        {appId && (
          <IntercomProvider appId={appId}>
            <DndProvider backend={HTML5Backend}>
              <NextUIProvider>
                <App />
              </NextUIProvider>
            </DndProvider>
          </IntercomProvider>
        )}
        <ToastContainer />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
)
