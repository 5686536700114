import { useNavigate } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react'
import { useLiveQuery } from 'dexie-react-hooks'
import { IS_CISCO } from '@/api'
import { queryUserDoc } from '@/db/docs/queries'
import { usePath } from '@/hooks'
import { useAppDispatch, useSlr } from '@/store'
import { selectAccount } from '@/store/account'
import { closeSession } from '@/store/session/slice'
import { Avatar, Button } from '@/ui'

export const SidebarProfile = () => {
  const { getDocPath } = usePath()
  const account = useSlr(selectAccount)
  const accoundDoc = useLiveQuery(() => queryUserDoc(account._id), [])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const redirectTo = (path: string) => {
    navigate({
      pathname: path,
      search: `?edit`,
    })
  }

  if (!accoundDoc) {
    return (
      <Button icon="log-out" variant="ghost" onClick={() => dispatch(closeSession())}>
        Logout
      </Button>
    )
  }

  return (
    <Dropdown placement="bottom-start">
      <DropdownTrigger>
        <Button variant="base" className="h-9 gap-2 px-1" aria-label="User Actions">
          <Avatar user={accoundDoc} />
          {accoundDoc?.title}
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="User Actions"
        variant="flat"
        itemClasses={{
          base: ['data-[hover=true]:bg-primary', 'data-[hover=true]:bg-opacity-10'],
        }}
      >
        <DropdownItem key="profile" onClick={() => redirectTo(getDocPath(accoundDoc))}>
          Profile
        </DropdownItem>
        <DropdownItem
          className={IS_CISCO ? 'hidden' : 'visible'}
          key="profile"
          onClick={() => redirectTo('/privacy-policy')}
        >
          Privacy Policy
        </DropdownItem>
        <DropdownItem key="profile" onClick={() => dispatch(closeSession())}>
          Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}
