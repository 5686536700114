import { ReactNode } from 'react'
import { components, ControlProps, GroupBase } from 'react-select'

type Props<T, IsMulti extends boolean, Group extends GroupBase<T>> = {
  children: ReactNode
} & ControlProps<T, IsMulti, Group>

export const Control = <T, IsMulti extends boolean, Group extends GroupBase<T>>({
  children,
  ...controlsProps
}: Props<T, IsMulti, Group>): JSX.Element => (
  <components.Control {...controlsProps}>{children}</components.Control>
)
