import clsx from 'clsx'
import { Icon } from '@/ui'
import { IconName } from '@/ui/Icon'
import { CardElementsProps } from '../CardElements/cardElements'
import { DocumentType } from '@/db'

const getData = (status: string, docSchema: DocumentType | undefined) => {
  if (!docSchema) return
  const options = docSchema?.optionsData
  const option = options?.find((item) => item.value === status)
  if (option) {
    return option
  }
}

export const CardDocStatus = ({ docItem, docSchema }: CardElementsProps) => {
  if (!docItem) return
  const status: string = docItem?.fields.status as string

  if (!status) return

  const data = getData(status, docSchema)

  if (!data) {
    return null
  }

  return (
    <div
      className={clsx('opacity-90 flex items-center py-1 px-1.5 rounded-md z-[1]')}
      style={{ background: data.bgColor, color: data.textColor }}
    >
      <p className="text-sm mr-1">{data.text}</p>
      <Icon color={data.color} name={data.icon as IconName} size={16} />
    </div>
  )
}
