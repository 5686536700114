import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'

interface BreadcrumbLinkProps extends PropsWithChildren {
  to: string
}

export const BreadcrumbLink = ({ to, children }: BreadcrumbLinkProps) => (
  <li className="capitalize truncate pl-1.5">
    <NavLink to={to} className={({ isActive }) => (isActive ? 'text-neutral900 pl-1.5' : 'pl-1.5')}>
      {children}
    </NavLink>
  </li>
)
