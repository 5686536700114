import { useLiveQuery } from 'dexie-react-hooks'
import { queryDocsById } from '@/db/docs/queries.ts'
import { createElement, useCallback } from 'react'
import { CardSchema, Doc } from '@/db'
import { getCardComponent } from '@/schema/cards/List.tsx'
import { CardFooterElements, CardHeaderElements } from '@/schema/cards/components/CardElements'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'
import { Link } from 'react-router-dom'
import { usePath } from '@/hooks'
import { useSchemaState } from '@/contexts/schema'

export const GroupLabelCard = ({ id }: { id: string }) => {
  const docById = useLiveQuery(() => queryDocsById(id), [id])
  const { getDocPath } = usePath()
  const { field } = useFieldContext<ChildrenFieldType>()
  const docsSchema = useSchemaState((state) => state.docTypes)

  const { card } = field.group?.layout || {
    card: { type: 'default' },
  }

  const createCardElement = useCallback(
    (item: Doc, card?: CardSchema, index?: number) =>
      createElement(
        getCardComponent(card?.type || 'default'),
        {
          doc: item,
          docSchema: docsSchema[item._type],
          author: card?.elements?.author,
          date: card?.elements?.date,
          showQuote: card?.elements?.showQuote,
          index,
        },
        card?.type !== 'faqVertical' && (
          <CardHeaderElements
            docItem={item}
            date={card?.elements?.date}
            docSchema={docsSchema[item._type]}
          />
        ),
        <CardFooterElements
          docSchema={docsSchema[item._type]}
          docItem={item}
          date={card?.elements?.date}
        />,
      ),
    [docsSchema],
  )

  if (!docById) {
    return null
  }

  return (
    <div className="pb-4">
      <Link
        aria-label={`link to ${docById.title}`}
        to={getDocPath(docById)}
        className="inline-flex"
      >
        {createCardElement(docById, card)}
      </Link>
    </div>
  )
}
