import { forwardRef, memo, RefObject } from 'react'
import { CollaborationPlugin } from '@lexical/react/LexicalCollaborationPlugin'
import { InitialEditorStateType } from '@lexical/react/LexicalComposer'
import { WebsocketProvider } from 'y-websocket'
import * as Y from 'yjs'
import { getYWebsocketUrl } from '@/utils'

interface CollabProps {
  collabId: string
  username: string
  cursorColor: string
  yDoc: Y.Doc
  initialEditorState: InitialEditorStateType
  cursorContainerRef: RefObject<HTMLDivElement>
}

export const CollabWrapper = memo(
  forwardRef(
    (
      {
        collabId,
        username,
        yDoc,
        initialEditorState,
        cursorContainerRef,
        cursorColor,
      }: CollabProps,
      ref: any,
    ) => (
      <CollaborationPlugin
        id={collabId}
        username={username}
        providerFactory={(id, yjsDocMap) => {
          yjsDocMap.set(id, yDoc)
          ref.current = new WebsocketProvider(getYWebsocketUrl(), collabId, yDoc)
          return ref.current
        }}
        cursorColor={cursorColor}
        shouldBootstrap
        cursorsContainerRef={cursorContainerRef}
        initialEditorState={initialEditorState}
      />
    ),
  ),
  (prev, next) => prev.collabId === next.collabId,
)

CollabWrapper.displayName = 'CollabMemoWrapper'
