import { useLayoutEffect, useRef } from 'react'
import Hammer from 'hammerjs'
import debounce from 'lodash.debounce'
import { useBoardContext } from '../context'
import { boardState } from '../whiteboardUtils'

export function useBoardZoom() {
  const { provider } = useBoardContext()
  const ref = useRef<SVGSVGElement | null>(null)

  useLayoutEffect(() => {
    const svgElement = ref.current as SVGSVGElement
    if (!svgElement) return () => undefined

    const size = {
      width: svgElement.parentElement?.clientWidth || 0,
      height: svgElement.parentElement?.clientHeight || 0,
    }
    if (!provider) {
      size.width *= 2
      size.height *= 2
    }
    svgElement.setAttribute('viewBox', boardState.updateViewport(size))

    if (!provider) return () => undefined

    const resize = debounce(() => {
      const newSize = { width: svgElement.clientWidth, height: svgElement.clientHeight }
      svgElement.setAttribute('viewBox', boardState.updateViewport(newSize))
      boardState.updateViewport(newSize)
    }, 200)

    window.addEventListener('resize', resize)

    const mc = new Hammer.Manager(svgElement)
    boardState.element = svgElement

    let paning = false

    function panstart(e: HammerInput) {
      const target = e.target as any
      paning = target === svgElement
      boardState.setViewport(svgElement.getAttribute('viewBox') as string)
    }

    function panmove(e: HammerInput) {
      if (!paning) return

      const startClient = {
        x: e.changedPointers[0].clientX,
        y: e.changedPointers[0].clientY,
      }

      let newSVGPoint = svgElement.createSVGPoint()
      let CTM = svgElement.getScreenCTM()
      newSVGPoint.x = startClient.x
      newSVGPoint.y = startClient.y
      const startSVGPoint = newSVGPoint.matrixTransform(CTM?.inverse())

      const moveToClient = {
        x: e.changedPointers[0].clientX + e.deltaX,
        y: e.changedPointers[0].clientY + e.deltaY,
      }

      newSVGPoint = svgElement.createSVGPoint()
      CTM = svgElement.getScreenCTM()
      newSVGPoint.x = moveToClient.x
      newSVGPoint.y = moveToClient.y
      const moveToSVGPoint = newSVGPoint.matrixTransform(CTM?.inverse())

      const delta = {
        dx: startSVGPoint.x - moveToSVGPoint.x,
        dy: startSVGPoint.y - moveToSVGPoint.y,
      }

      svgElement.setAttribute(
        'viewBox',
        boardState.changeViewport({ x: boardState.x + delta.dx, y: boardState.y + delta.dy }),
      )
    }

    function panend() {
      paning = false
      boardState.setViewport(svgElement.getAttribute('viewBox') as string)
    }

    let currentScale = 0
    let ratio = 1

    const isEventWheel = (e: any): e is WheelEvent => e.type === 'wheel'
    const isEventHammer = (e: any): e is HammerInput => e.type === 'pinchmove'

    function zoom(e: WheelEvent | HammerInput) {
      let startClient = { x: 0, y: 0 }
      if (isEventWheel(e)) {
        e.preventDefault()
        startClient = {
          x: e.clientX,
          y: e.clientY,
        }
      }
      if (isEventHammer(e)) {
        startClient = {
          x: e.center.x,
          y: e.center.y,
        }
      }

      const newSVGPoint = svgElement.createSVGPoint()
      let CTM = svgElement.getScreenCTM()
      newSVGPoint.x = startClient.x
      newSVGPoint.y = startClient.y
      const startSVGPoint = newSVGPoint.matrixTransform(CTM?.inverse())

      const zoomSize = { max: 2, min: 0.5 }

      const viewport = {
        width: svgElement.getBoundingClientRect().width,
        height: svgElement.getBoundingClientRect().height,
      }

      if (isEventWheel(e)) {
        let tmp = ratio + e.deltaY / 100
        if (tmp >= zoomSize.max) {
          tmp = zoomSize.max
        }
        if (tmp <= zoomSize.min) {
          tmp = zoomSize.min
        }
        ratio = tmp
      }

      if (isEventHammer(e)) {
        currentScale = e.scale
        ratio = 1 / currentScale

        if (ratio >= zoomSize.max) {
          ratio = zoomSize.max
          currentScale = 1 / zoomSize.max
        }

        if (ratio <= zoomSize.min) {
          ratio = zoomSize.min
          currentScale = 1 / zoomSize.min
        }
      }

      svgElement.setAttribute(
        'viewBox',
        boardState.updateViewport({
          width: viewport.width * ratio,
          height: viewport.height * ratio,
        }),
      )

      CTM = svgElement.getScreenCTM()
      const moveToSVGPoint = newSVGPoint.matrixTransform(CTM?.inverse())

      const delta = {
        dx: startSVGPoint.x - moveToSVGPoint.x,
        dy: startSVGPoint.y - moveToSVGPoint.y,
      }

      svgElement.setAttribute(
        'viewBox',
        boardState.updateViewport({
          x: boardState.x + delta.dx,
          y: boardState.y + delta.dy,
        }),
      )

      boardState.setScale(ratio)
    }

    const pinch = new Hammer.Pinch()
    const pan = new Hammer.Pan()

    pinch.requireFailure(pan)

    mc.add([pinch, pan])

    mc.on('pinchmove', zoom)
    mc.on('panstart', panstart)
    mc.on('panmove', panmove)
    mc.on('panend', panend)

    // svgElement.addEventListener('mousemove', reportCurrentPoint, false)
    // svgElement.addEventListener('mousedown', mouseDown, false)
    // svgElement.addEventListener('mousemove', drag, false)
    // svgElement.addEventListener('mouseup', mouseUp, false)
    svgElement.addEventListener('wheel', zoom, false)

    return () => {
      window.removeEventListener('resize', resize)
      svgElement.removeEventListener('wheel', zoom, false)
      mc.destroy()
    }
  }, [])

  return [ref] as const
}
