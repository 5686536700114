import { HtmlParser } from '@/features/rte'
import { CardProps } from '@/schema/cards/cardsTypes.ts'
import { Preview } from '@/ui'
import { colors } from '@/ui/theme'

export const ImpactCard = ({ doc, headerElements }: CardProps) => {
  const bgColor = doc.fields?.color as string

  return (
    <div
      className="h-full rounded-md p-3"
      style={{ backgroundColor: bgColor ?? colors.neutral100 }}
    >
      {headerElements}
      <div className="flex pb-1.5">
        {doc.fields?.upload && (
          <Preview
            image={doc.fields.upload}
            icon="user"
            iconSize={26}
            fit="contain"
            className="w-16 h-full rounded-md shrink-0"
          />
        )}
        <p className="text-title pl-2 break-words">{(doc.fields.fact as string) || 0}</p>
      </div>

      {doc.fields?.description && (
        <HtmlParser className="text-focus break-words">{doc.fields.description}</HtmlParser>
      )}
    </div>
  )
}
