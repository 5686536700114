import clsx from 'clsx'
import { ChevronsUpDown } from 'lucide-react'
import { useToggleOutside } from '@/hooks'
import { useSlr } from '@/store'
import { selectAccount, selectClientWorkspace } from '@/store/account'
import { Preview } from '@/ui'
import css from '../Sidebar.module.css'
import { ClientsDropdown } from './ClientsDropdown'

export const CurrentWorkspace = () => {
  const { workspace } = useSlr(selectClientWorkspace)
  const account = useSlr(selectAccount)

  const isAdmin = account._relationType === 'admin'

  const [ref, isWorkspacesDropDownOpened, { toggle: toggleWorkspacesDropDown, off }] =
    useToggleOutside(false)

  return (
    <div ref={ref} className="mr-2">
      <button
        onClick={toggleWorkspacesDropDown}
        className={clsx(css.workspace, isWorkspacesDropDownOpened && 'bg-primary bg-opacity-10')}
      >
        <Preview image={workspace.image} icon="user" fit="contain" className="h-6" />
        <b className={css.workspaceTitle}>{workspace?.name}</b>
        {isAdmin && <ChevronsUpDown size={14} />}
      </button>
      {isWorkspacesDropDownOpened && isAdmin && <ClientsDropdown close={off} />}
    </div>
  )
}
