import { useMemo } from 'react'
import { Accordion, AccordionItem } from '@nextui-org/react'
import { useLiveQuery } from 'dexie-react-hooks'
import { Doc } from '@/db'
import { queryDocChildren } from '@/db/docs/queries'
import { usePath } from '@/hooks'
import { scm, selectDocSchema, useSchemaState } from '@/contexts/schema'
import { IconName } from '@/ui/Icon'
import { SidebarLink } from './SideBarLink'
import { useSort } from '@/schema/hooks'

export const SideBarSection = ({
  level = 1,
  doc,
  activeSection,
}: {
  level?: number
  doc: any
  activeSection: Doc | null
}) => {
  const { getDocPath } = usePath()
  const docSchema = useSchemaState((state) => selectDocSchema(state, doc?._type))
  const docsSchema = useSchemaState((state) => state.docTypes)
  const { icon: iconNameFromSchema, hideSubsections = false } = docSchema ?? {}
  const childrenDocs =
    useLiveQuery(() => (hideSubsections || !doc._id ? [] : queryDocChildren(doc._id)), [doc._id]) ??
    []

  const altIcon = (doc?.fields?.icon ?? iconNameFromSchema) as IconName

  const icon = altIcon ?? 'list'

  const fieldSort = docSchema?.fields.find((f) => f.type === 'children')

  const entries = useSort(
    childrenDocs.filter((entry) => {
      const entryDocSchema = docsSchema[entry._type]
      return !entryDocSchema?.hideInMenu
    }),
    fieldSort?.sort,
  )

  const sortedDocs = useMemo(() => scm.sortBySchema(entries), [entries])

  if (!sortedDocs?.length) {
    return (
      <SidebarLink to={getDocPath(doc)} icon={icon as IconName} className="">
        {doc.title}
      </SidebarLink>
    )
  }

  const itemClasses = {
    title: 'font-normal text-regular',
    trigger: ' py-0.5 rounded-lg flex items-center overflow-hidden',
    indicator: 'text-medium hover:bg-primary hover:bg-opacity-10 p-2 rounded-full',
  }

  return (
    <Accordion itemClasses={itemClasses} showDivider={false} isCompact className="pl-0">
      <AccordionItem
        key={doc._id}
        aria-label={doc.title}
        title={
          <SidebarLink
            key={doc.title}
            icon={icon as IconName}
            to={getDocPath(doc)}
            color={doc.fields?.color as string}
          >
            {doc.title}
          </SidebarLink>
        }
        isCompact
      >
        {sortedDocs.map((child) => (
          <SideBarSection
            key={child._id}
            level={level + 1}
            doc={child}
            activeSection={activeSection}
          />
        ))}
      </AccordionItem>
    </Accordion>
  )
}
