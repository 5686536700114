import { useCallback, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import clsx from 'clsx'
import { DocumentImportData, UserData } from '@common/interfaces/clients/import-data.interface'
import { Button, Collapse } from '@/ui'
import { FileUpload } from '../files'
import { importData, inviteUsers } from './importerUtils'
import { ImportResultMessage, useImporterState } from './importerState'

export const ImportDataSettings = () => {
  const results = useImporterState((state) => state.messages)
  const clearMessages = useImporterState((state) => state.clearMessages)
  const [file, setFile] = useState<File | null>(null)
  const [resultType, setResultType] = useState<'all' | 'error' | 'success'>('all')
  const inputRef = useRef<HTMLInputElement>(null)

  const handleUpload = useCallback((uploadedFile: File) => {
    setFile(uploadedFile)
    clearMessages()

    const reader = new FileReader()

    reader.onload = async () => {
      const toastId = toast.loading('Importing data...')
      try {
        const data: DocumentImportData | UserData[] = JSON.parse(reader.result as string)
        if (Array.isArray(data)) {
          await inviteUsers(data)
        } else {
          await importData(data)
        }
        toast.update(toastId, {
          render: 'Import has been finished',
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        })
      } catch (error) {
        window.Rollbar.error(error as Error)
        toast.update(toastId, {
          render: 'Import has failed',
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        })
      }
    }

    reader.onerror = () => {
      toast.error('An error occurred while reading the file:')
    }

    reader.readAsText(uploadedFile)
  }, [])

  const clearFile = useCallback(() => {
    setFile(null)
    clearMessages()
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }, [])

  const filteredResults = useCallback(
    (dataResults: ImportResultMessage[]) => {
      if (resultType === 'all') return dataResults

      return dataResults.filter((result) => result.type === resultType)
    },
    [results, resultType],
  )

  return (
    <div>
      <FileUpload accept="application/JSON" onUpload={handleUpload}>
        <div className="grid place-items-center min-h-[200px] rounded-md border border-dashed border-neutral300 p-12 text-center mb-5">
          {!file ? (
            <div className="grid gap-2">
              <span className="font-bold">Drop a file here to import</span>
              <span className="font-bold text-neutral700">Or</span>
              <span>Browse</span>
            </div>
          ) : (
            <div className="grid grid-flow-col gap-4 items-center">
              <p className="font-bold">{file.name}</p>
              <Button variant="outline" onClick={clearFile}>
                Clear
              </Button>
            </div>
          )}
        </div>
      </FileUpload>
      <div className="flex gap-3">
        <label className="flex gap-1">
          <input
            type="radio"
            name="import"
            checked={resultType === 'all'}
            onChange={() => setResultType('all')}
          />
          <span>All</span>
        </label>
        <label className="flex gap-1">
          <input
            type="radio"
            name="import"
            checked={resultType === 'error'}
            onChange={() => setResultType('error')}
          />
          <span>Errors</span>
        </label>
        <label className="flex gap-1">
          <input
            type="radio"
            name="import"
            checked={resultType === 'success'}
            onChange={() => setResultType('success')}
          />
          <span>Success</span>
        </label>
      </div>
      <div className="grid gap-4">
        {Object.keys(results).map((key) => (
          <Collapse key={key} className="p-0 text-subtitle" title={key} open>
            <div className="grid gap-1 py-2">
              {filteredResults(results[key]).map((result) => (
                <div
                  className={clsx({
                    'text-error': result.type === 'error',
                    'text-success': result.type === 'success',
                  })}
                  key={result.message}
                >
                  {result.message}
                </div>
              ))}
            </div>
          </Collapse>
        ))}
      </div>
    </div>
  )
}
