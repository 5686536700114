import { fetchSignedImage, getUploadStaticUrl, uploadFileApi } from '@/api/files'
import { db } from '@/db'

export const getDataFromBlob = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(blob)
  })

type SaveNewFileParams = {
  file: File
  fileName?: string
  docId?: string
}

export const saveNewFile = async ({
  file,
  fileName,
  docId = db.activeWorkspace._id,
}: SaveNewFileParams) => {
  const url = await fetchSignedImage(fileName ?? file.name, docId, false)
  if (!url) return Promise.resolve(undefined)

  return new Promise<string | undefined>((resolve) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)

    reader.onload = async () => {
      const status = await uploadFileApi(url, reader.result)
      if (status) {
        resolve(url)
      } else {
        resolve(undefined)
      }
    }
  })
}

type SaveStaticFileParams = {
  file: File
  fileName: string
}

export async function saveStaticFile(entry: SaveStaticFileParams): Promise<boolean> {
  const imgUrl = (await getUploadStaticUrl({ fileName: entry.fileName, hours: 1 })) as string

  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.readAsArrayBuffer(entry.file)
    reader.onload = async () => {
      const res = await uploadFileApi(imgUrl, reader.result)
      if (res) {
        resolve(true)
      } else {
        resolve(false)
      }
    }
  })
}
