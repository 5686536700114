import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { FieldTemplateProperty } from '@/features/schemaEditor/types'
import { ColorPicker } from '@/ui/ColorPicker'

type FieldColorProps = {
  propertyName: string
  templateProperty: FieldTemplateProperty
  nestedStructure: string
}

export const FieldColor: FC<FieldColorProps> = ({ propertyName, nestedStructure }) => {
  const { setValue, getValues } = useFormContext()
  const value = getValues(nestedStructure)

  return (
    <div className="form-control w-full max-w-[300px]">
      <label className="cursor-pointer label justify-start flex-col items-start p-0">
        <span className="label-text capitalize font-bold px-1 py-2">{propertyName}</span>
      </label>

      <ColorPicker value={value} onChange={(color) => setValue(nestedStructure, color)} />
    </div>
  )
}
