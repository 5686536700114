import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { TabsFieldType } from '@common/interfaces/fields/area-fields.interace'
import { useFieldContext } from '@/contexts'
import { AreaRenderer, FieldContainer } from '@/schema'
import { Tabs } from '@/ui'

export const TabsField = () => {
  const { field } = useFieldContext<TabsFieldType>()
  const [searchParams, setSearchParams] = useSearchParams()
  const firstTab = field.tabAreas?.[0]
  const [activeTab, setActiveTab] = useState(firstTab?.value || '')

  const tabChange = (id: string) => {
    setActiveTab(id)

    setSearchParams({ [field.name + '_' + activeTab]: id })
  }

  const currentTab = searchParams.get(field.name + '_' + activeTab)

  useEffect(() => {
    if (currentTab) {
      setActiveTab(currentTab)
    }
  }, [currentTab])

  if (!field.tabAreas) {
    return null
  }

  return (
    <FieldContainer className="pb-5">
      <Tabs<string>
        type="button"
        tabs={field.tabAreas}
        activeTabId={activeTab}
        onChange={tabChange}
      />

      <div className="pt-8 grid gap-5 min-h-[200px]">
        <AreaRenderer areaName={activeTab} />
      </div>
    </FieldContainer>
  )
}
