import { useEffect, useState } from 'react'

export function useFetchQuery<T = void>(
  cb: () => Promise<T>,
  deps: any[],
  defaultValue?: undefined,
): T | undefined

export function useFetchQuery<T = void>(cb: () => Promise<T>, deps: any[], defaultValue: T): T

export function useFetchQuery<T = void>(
  cb: () => Promise<T>,
  deps: any[] = [],
  defaultValue?: T,
): T | undefined {
  const [response, setResponse] = useState<T | undefined>(() => defaultValue)

  useEffect(() => {
    cb().then((res) => setResponse(() => res))
  }, deps)

  return response
}
