/* eslint-disable */
import clsx from 'clsx'
import Microlink from '@microlink/react'
import { CardProps } from '../cardsTypes'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'

export const ReferenceCard = ({ doc, children, headerElements }: CardProps) => {
  const { field } = useFieldContext<ChildrenFieldType>()
  const styles = field?.layout?.card?.styles
  const secondaryStyles = field?.layout?.card?.secondaryStyles
  const fontSize = field.layout?.card?.fontSize
  const fontColor = field.layout?.card?.fontColor
  const lineHeight = field.layout?.card?.lineHeight

  return (
    <div
      className={clsx('relative h-full overflow-hidden transition-transform hover:scale-[1.01]')}
      style={styles}
    >
      <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
      {doc.fields?.url && (
        <div style={secondaryStyles}>
          <Microlink
            url={(doc.fields.url as any)?.url}
            size="large"
            className="w-full !max-w-full !border !border-neutral300"
          />
        </div>
      )}

      {doc.fields?.download && (
        <div className="flex flex-col border border-neutral300" style={secondaryStyles}>
          <div className="flex flex-col grow gap-3 p-5">
            <div>
              <h2
                style={{ fontSize, color: fontColor, lineHeight }}
                className={clsx('text-heading2 text-neutral900 break-words line-clamp-3')}
              >
                {(doc.fields.download as any).fileName}
              </h2>
            </div>
          </div>
          {children}
        </div>
      )}
    </div>
  )
}
