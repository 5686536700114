import { Button } from '@/ui'
import { CardElementsProps } from '../..'

export const DeleteButton = ({ docItem, isEditMode, onDelete }: CardElementsProps) => {
  if (!onDelete || !docItem) return
  return (
    <div className="flex gap-1 items-center">
      {isEditMode && (
        <Button
          tooltip="Delete"
          variant="ghost"
          icon="trash"
          className="bg-neutral100 rounded min-w-[1.5rem] w-6 min-h-[1.5rem] h-6"
          onClick={(e) => onDelete(e, docItem)}
        />
      )}
    </div>
  )
}
