import { useEffect, useMemo, useState } from 'react'
import { ActionMeta, MultiValue } from 'react-select'
import { Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react'
import { Select } from '@/ui'
import { useLiveQuery } from 'dexie-react-hooks'
import { ROLE_KEYS, ROLES } from '@common/interfaces/documents/permission.interface'
import { useDocContext } from '@/contexts'
import { queryPermissionGroups } from '@/db/permissions/queries'
import { fetchDocumentPermissions, updatePermissionGroup } from '@/db/permissions/sync'
import { DocumentReadAccess } from './DocumentReadAccess'
import { PropagatePermissions } from './PropagatePermissions'

type PermissionOption = { id: string; label: string; value: string; permissions: Set<string> }

type PermissionsModalProps = {
  onClose: () => void
}

type SelectsState = Record<ROLES, MultiValue<PermissionOption>>

export const PermissionsModal = ({ onClose }: PermissionsModalProps) => {
  const { doc } = useDocContext()
  const groups = useLiveQuery(() => queryPermissionGroups(), [])
  const docPermissions = useLiveQuery(() => fetchDocumentPermissions(doc._id), [doc._id])
  const [loading, setLoading] = useState(true)
  const [initialValue, setInitialValue] = useState<SelectsState>(
    ROLE_KEYS.reduce((obj, role) => {
      obj[role] = []
      return obj
    }, {} as SelectsState),
  )
  const [values, setValues] = useState(initialValue)

  const allGroups = useMemo(
    () =>
      groups?.map((g) => ({
        id: g._id,
        label: g.name,
        value: g._id,
        permissions: new Set(g.permissions),
      })) ?? [],
    [groups],
  )

  useEffect(() => {
    if (!loading) return
    if (!docPermissions || !allGroups.length) return

    const initialValueState: SelectsState = {
      editor: [],
      viewer: [],
      contributor: [],
    }

    allGroups.forEach((group) => {
      docPermissions.permissions.forEach((item) => {
        if (group.permissions.has(item)) {
          const role = item.match(/ROLE#(.+)/)?.[1]
          if (role) initialValueState[role].push(group)
        }
      })
    })
    setInitialValue(initialValueState)
    setValues({ ...initialValueState })
    setLoading(false)
  }, [allGroups, docPermissions])

  const handleChange = <T extends PermissionOption>(
    value: MultiValue<T>,
    actionMeta: ActionMeta<T>,
    role: ROLES,
  ) => {
    if (!value) return
    const permission = docPermissions?.permissions.find(
      (item) => item.includes(doc._id) && item.endsWith(role),
    )
    if (!permission) return

    function removeFromGroup(group: PermissionOption) {
      if (!permission) return

      group.permissions.delete(permission)
      updatePermissionGroup({
        groupId: group.id,
        name: group.label,
        permissions: Array.from(group.permissions),
        overridePermissions: true,
      })
    }

    const { action, option } = actionMeta
    if (action === 'select-option') {
      if (!option) return
      option.permissions.add(permission)
      updatePermissionGroup({
        groupId: option.id,
        name: option.label,
        permissions: Array.from(option.permissions),
        overridePermissions: true,
      })
    } else if (action === 'pop-value' || action === 'remove-value') {
      removeFromGroup(actionMeta.removedValue)
    } else if (action === 'clear') {
      value.map(removeFromGroup)
    }

    setValues({ ...values, [role]: value })
  }

  return (
    <Modal isOpen={true} onOpenChange={onClose} className="overflow-y-visible">
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">Permissions</ModalHeader>
        <ModalBody className="pb-4">
          <div>
            <label htmlFor="editSelect" className="text-focus label justify-start gap-2 capitalize">
              Editor
            </label>
            <Select
              isDisabled={loading}
              id="editSelect"
              className="flex-grow mb-2"
              isMulti
              value={values.editor}
              options={allGroups}
              onChange={(value, actionMeta) => handleChange(value, actionMeta, 'editor')}
            />
          </div>
          <div>
            <label
              htmlFor="contributorSelect"
              className="text-focus label justify-start gap-2 capitalize"
            >
              Contributor
            </label>
            <Select
              isDisabled={loading}
              id="contributorSelect"
              className="flex-grow mb-2"
              isMulti
              value={values.contributor}
              options={allGroups}
              onChange={(value, actionMeta) => handleChange(value, actionMeta, 'contributor')}
            />
            <PropagatePermissions type="setContributor">
              Propagate contribution permissions
            </PropagatePermissions>
          </div>
          <div>
            <label
              htmlFor="viewerSelect"
              className="text-focus label justify-start gap-2 capitalize"
            >
              Viewer
            </label>
            <Select
              isDisabled={loading}
              id="viewerSelect"
              className="flex-grow mb-2"
              isMulti
              value={values.viewer}
              options={allGroups}
              onChange={(value, actionMeta) => handleChange(value, actionMeta, 'viewer')}
            />
            <PropagatePermissions type="setViewer">Propagate view permissions</PropagatePermissions>
          </div>
          <DocumentReadAccess />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
