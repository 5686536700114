import { useMemo } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { Doc, Roles } from '@/db'
import { queryAccountRoles } from '@/db/permissions/queries'

export function useAccountRoles(doc: Doc, withOwner = true): Roles {
  const roles = useLiveQuery(() => queryAccountRoles(doc, withOwner), [doc._id, withOwner])

  return useMemo(
    () => ({
      editor: false,
      contributor: false,
      viewer: false,
      ...roles,
    }),
    [roles?.editor, roles?.contributor, roles?.viewer],
  )
}
