import { Input } from '@nextui-org/react'
import { useLiveQuery } from 'dexie-react-hooks'
import { Search } from 'lucide-react'
import { queryAllUsers } from '@/db/users/queries'
import { InviteButton } from '@/features/invite'
import { useSearch } from '@/hooks'
import { UserRow } from './UserRow'

export const Users = () => {
  const allUsers = useLiveQuery(() => queryAllUsers(), []) ?? []
  const { list: users, setSearch } = useSearch('', allUsers, (item) => item.name)

  return (
    <>
      <div className="flex justify-between pb-7 max-[400px]:flex-wrap">
        <div className="input-group input-group-md max-[400px]:w-full max-[400px]:mb-4">
          <Input
            type="search"
            placeholder="Search"
            labelPlacement="outside"
            onChange={(e) => setSearch(e.target.value)}
            startContent={<Search size={18} />}
          />
        </div>
        <InviteButton btnText="Invite" className="max-[400px]:w-full" />
      </div>

      <div className="max-[570px]:overflow-x-auto">
        <div className="grid grid-cols-[1fr_1fr_76px] border border-neutral300 gap-px bg-neutral300 max-[570px]:w-[530px]">
          <div className="bg-neutral50 h-[52px] flex items-center pl-4 font-bold text-neutral900">
            Name
          </div>
          <div className="bg-neutral50 h-[52px] flex items-center pl-4 font-bold text-neutral900">
            Role
          </div>
          <div className="bg-neutral50 h-[52px]" />

          {users.map((userEntry) => (
            <UserRow key={userEntry._id} userEntry={userEntry} />
          ))}
        </div>
      </div>
    </>
  )
}
