import { ColorFieldType } from '@common/interfaces/fields/color-field.interface'
import { useFieldContext } from '@/contexts'
import { FieldContainer } from '@/schema'
import { ColorPicker } from '@/ui/ColorPicker'
import { FieldComponent } from '../fieldType'

export const ColorField: FieldComponent = () => {
  const { value, saveValue } = useFieldContext<ColorFieldType, string>()

  const handleOnChange = (color: string) => {
    saveValue(color)
  }

  return (
    <FieldContainer>
      <ColorPicker value={value} onChange={handleOnChange} />
    </FieldContainer>
  )
}
