import { ReverseLinkParams } from '@common/interfaces/fields/link-field.interface'
import { db } from '../db'

export function queryFromDocRelations(fromId: string, fieldName: string) {
  return db.ws.relations
    .where('[fromId+fieldName]')
    .equals([fromId, fieldName])
    .toArray()
    .catch<undefined>(console.error)
}

export function queryToDocRelationsByType(toId: string, configs: ReverseLinkParams[]) {
  return db.ws.relations
    .where('[toId+fieldName+fromDocType]')
    .anyOf(configs.map(({ fieldName, documentType }) => [toId, fieldName, documentType]))
    .toArray()
    .catch<undefined>(console.error)
}

export function queryRelantionsByFieldList(fromId: string, fieldNames: string[]) {
  return db.ws.relations
    .where('[fromId+fieldName]')
    .anyOf(fieldNames.map((name) => [fromId, name]))
    .toArray()
    .catch<undefined>(console.error)
}

export function queryRelationsByIds(ids: string[]) {
  return db.ws.relations.where('_id').anyOf(ids).toArray().catch<undefined>(console.error)
}

export function queryRelation(keys: { _id?: string; fromId?: string; toId?: string }) {
  return db.ws.relations.get(keys).catch<undefined>(console.error)
}

export function queryRelationWithParent(toId: string) {
  return db.ws.relations.get({ toId, relationType: 'children' }).catch<undefined>(console.error)
}

export function queryFieldRelation(fieldName: string, toId: string, fromDocType: string) {
  return db.ws.relations.get({ fieldName, toId, fromDocType }).catch<undefined>(console.error)
}

export function queryRelationBetweenDocs(fromId: string, toId: string) {
  return db.ws.relations
    .where('[fromId+toId]')
    .equals([fromId, toId])
    .first()
    .catch<undefined>(console.error)
}

export function queryAllRelations() {
  return db.ws.relations.toArray().catch<undefined>(console.error)
}

export function queryRelationsByDocId(docId: string) {
  return db.ws.relations.where('toId').anyOf(docId).toArray().catch<undefined>(console.error)
}
