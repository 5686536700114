import { useLiveQuery } from 'dexie-react-hooks'
import { MessageCircle } from 'lucide-react'
import { queryCommentsCount } from '@/db/interactions/queries'
import { CardElementsProps } from '../..'

export const CommentsCount = ({ docItem }: CardElementsProps) => {
  const doc = docItem || { _id: '' }
  const count = useLiveQuery(() => queryCommentsCount(doc._id), [doc._id]) ?? 0

  return (
    <div className="flex items-center">
      <MessageCircle size={18} />
      <span className="pl-1">{count}</span>
    </div>
  )
}
