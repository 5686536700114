import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { label: string } = {
  label: '',
}

const activityLabelSlice = createSlice({
  name: 'activity-label',
  initialState,
  reducers: {
    setActivityLabel(state, action: PayloadAction<string>) {
      state.label = action.payload
    },
  },
})

export const { setActivityLabel } = activityLabelSlice.actions

export const { reducer } = activityLabelSlice
