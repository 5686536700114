import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Client, User, Workspace } from '@/db'
import { updateClient, updateProfile } from './actions'

export type InitStatus = 'pending' | 'preloaded' | 'fulfilled'

export type Progress = {
  loaded: number
  total: number
}

export interface AccountInitialState {
  data: User
  init: InitStatus
  client: Client
  workspace: Workspace
  workspaceIds: string[]
  clientIds: string[]
  progress: Progress
}

const accountSlice = createSlice({
  name: 'account',
  initialState: { progress: { loaded: 0, total: 0 } } as AccountInitialState,
  reducers: {
    setAccount(state, action: PayloadAction<Omit<AccountInitialState, 'progress'>>) {
      return { ...state, ...action.payload }
    },
    updateAccountData(state, action: PayloadAction<Partial<User>>) {
      state.data = { ...state.data, ...action.payload }
    },
    setInit(state, action: PayloadAction<InitStatus>) {
      state.init = action.payload
    },
    updateOneWorkspace(state, action) {
      state.workspace = { ...state.workspace, ...action.payload }
    },
    setProgress(state, action: PayloadAction<Progress>) {
      state.progress = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(updateProfile.fulfilled, (state, action: PayloadAction<Partial<User>>) => {
      state.data = { ...state.data, ...action.payload }
    })
    builder.addCase(updateClient.fulfilled, (state, action: PayloadAction<Partial<Client>>) => {
      state.client = { ...state.client, ...action.payload }
    })
  },
})

export const { setAccount, setInit, updateOneWorkspace, setProgress, updateAccountData } =
  accountSlice.actions

export const { reducer } = accountSlice
