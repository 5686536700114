import { useLiveQuery } from 'dexie-react-hooks'
import { Doc } from '@/db'
import { queryDoc } from '@/db/docs/queries'
import { usePath } from '@/hooks'
import { BreadcrumbLink } from './BreadcrumbLink'

interface BreadcrumbProps {
  doc: Doc
  activeDocId?: string
  level: number
}

export const Breadcrumb = ({ doc, level }: BreadcrumbProps) => {
  const { getDocPath } = usePath()
  const parentDoc = useLiveQuery(() => queryDoc({ _id: doc._parentId }), [doc._parentId])

  if (!parentDoc || level > 13) {
    return null
  }

  return (
    <>
      <Breadcrumb doc={parentDoc} level={level + 1} />
      <BreadcrumbLink to={getDocPath(doc)}>{doc.title}</BreadcrumbLink>
    </>
  )
}
