import { Modal, ModalBody, ModalContent, useDisclosure } from '@nextui-org/react'
import clsx from 'clsx'
import { getUnixTime } from 'date-fns'
import { ImageFieldData } from '@common/interfaces/fields/image-field.interface.ts'
import { UploadFieldType } from '@common/interfaces/fields/upload-field.interface.ts'
import { useDocContext, useFieldContext } from '@/contexts'
import { db } from '@/db'
import { FileUpload, saveNewFile } from '@/features/files'
import { FieldContainer } from '@/schema'
import { Icon, Preview } from '@/ui'

export const UploadField = () => {
  const { doc } = useDocContext()
  const { value, isEditable, saveValue } = useFieldContext<UploadFieldType, ImageFieldData>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cdnImg = value && value.source === 'cdn' ? value : undefined

  const saveFile = async (file: File) => {
    if (!file) return

    const fileName = `${getUnixTime(new Date())}-${file.name}`
    const status = await saveNewFile({
      fileName,
      file,
      docId: doc._id,
    })

    if (status) {
      saveValue({
        source: 'cdn',
        fileName,
        _clientId: db.activeClient._id,
        _documentId: doc._id,
      })
    }
  }

  if (isEditable) {
    return (
      <FieldContainer>
        <div className="w-[200px] h-[150px] bg-neutral200">
          <FileUpload onUpload={saveFile} className={clsx('block rounded-md w-full h-full')}>
            <Preview image={cdnImg} className={clsx('w-full h-full')} fit="contain" iconSize={36} />
          </FileUpload>
        </div>
      </FieldContainer>
    )
  }

  return (
    <>
      <FieldContainer>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div className="w-[200px] h-[150px] cursor-pointer relative" onClick={onOpen}>
          <Preview image={value} className="w-full h-full rounded-md" fit="cover" />

          <div className="opacity-0 hover:opacity-100 bg-neutral900 bg-opacity-50 duration-300 absolute inset-0 z-10 flex justify-center items-center rounded-md">
            <Icon name="expand" color="#fff" />
          </div>
        </div>
      </FieldContainer>

      <Modal
        size="5xl"
        radius="none"
        isOpen={isOpen}
        onClose={onClose}
        classNames={{
          closeButton: 'bg-neutral200',
        }}
      >
        <ModalContent>
          <ModalBody className="px-0 py-0">
            <Preview image={value} className="w-full h-full" fit="contain" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
