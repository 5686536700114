import { Doc } from '@/db'

export const arrToObject = <T extends Record<string, any>, K extends keyof T = any, R = T>(
  arr: T[],
  key: K,
  cb?: (item: T, index: number) => R,
) => {
  const obj: Record<string, R> = {}
  const getValue = (item: T, index: number) => (cb ? cb(item, index) : item) as R

  arr.forEach((item, i) => {
    obj[item[key]] = getValue(item, i)
  })

  return obj
}

export const generateArray = (length: number) => {
  const arr: number[] = []
  for (let i = 0; i < length; i++) {
    arr.push(i + 1)
  }
  return arr
}

export function getApprovedMetricData(docs?: Doc[], isApproval = false) {
  if (!docs) return []

  if (isApproval) {
    return docs.filter((doc) => {
      if (doc._type !== 'MetricData') return true

      return doc.fields.Approval === 'approved'
    })
  }

  return docs
}

export function sortDocs(docs?: Doc[]) {
  if (!docs) return []

  return docs.sort((a, b) => {
    const aPinned = a.fields.pinned || false
    const bPinned = b.fields.pinned || false
    return (
      Number(bPinned) - Number(aPinned) ||
      a.title.localeCompare(b.title, undefined, { numeric: true, sensitivity: 'base' })
    )
  })
}

export function groupArr<T>(
  arr: T[] | undefined,
  accessor: (item: T) => string | null | undefined,
): Record<string, T[]>

export function groupArr<T>(
  arr: T[] | undefined,
  accessor: (item: T) => string | null | undefined,
  toArr: boolean,
): [string, T[]][]

export function groupArr<T>(
  arr: T[] = [],
  accessor: (item: T) => string | null | undefined,
  toArr = false,
) {
  const groups = arr.reduce(
    (acc, item) => {
      const key = accessor(item)

      if (key === undefined || key === null) return acc

      if (acc[key]) {
        acc[key].push(item)
      } else {
        acc[key] = [item]
      }

      return acc
    },
    {} as Record<string, T[]>,
  )

  if (toArr) {
    return Object.entries(groups)
  }

  return groups
}

export function sumArr<T>(arr: T[], accessor: (item: T) => string | number | null | undefined) {
  return arr.reduce((acc, item) => acc + Number(accessor(item) ?? 0), 0)
}

export function getArrDiff<T>(newArr: T[] = [], oldArr: T[] = []) {
  const added: T[] = []

  const newArrCopy = [...newArr]
  const oldArrSet = new Set(oldArr)

  for (const item of newArrCopy) {
    if (oldArrSet.has(item)) {
      oldArrSet.delete(item)
    } else {
      added.push(item)
    }
  }

  return {
    added,
    removed: [...oldArrSet],
  }
}

export function getSetDiff<T>(newSet: Set<T>, oldSet: Set<T>) {
  const added = new Set<T>()
  const oldCopy = new Set(oldSet)

  for (const item of newSet) {
    if (oldCopy.has(item)) {
      oldCopy.delete(item)
    } else {
      added.add(item)
    }
  }

  return {
    added,
    removed: oldCopy,
  }
}

export function toMap<T>(arr: T[], keyAccessor: (item: T) => string) {
  return arr.reduce((acc, item) => {
    acc.set(keyAccessor(item), item)
    return acc
  }, new Map<string, T>())
}

export function changeArr<T>(arr: T[], action: 'add' | 'remove', items: T[]) {
  const arrSet = new Set(arr)
  items.forEach((item) => {
    if (action === 'add') {
      arrSet.add(item)
    } else {
      arrSet.delete(item)
    }
  })

  return Array.from(arrSet)
}

export function compareArr<T>(arr1: T[] = [], arr2: T[] = []) {
  if (arr1.length !== arr2.length) return false

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }

  return true
}

export async function asyncFilter<T>(data: T[], cb: (item: T) => Promise<boolean>): Promise<T[]> {
  const filteredData: T[] = []

  await Promise.all(
    data.map(async (item) => {
      if (await cb(item)) {
        filteredData.push(item)
      }
      return Promise.resolve()
    }),
  )

  return filteredData
}
