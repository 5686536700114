import { useState } from 'react'
import { getUnixTime } from 'date-fns'
import { KeyValueData, KeyValueFieldType } from '@common/interfaces/fields/keyvalue-field.interface'
import { useFieldContext } from '@/contexts'
import { FileUpload } from '@/features/files'
import { saveNewFile } from '@/features/files'
import { useSlr } from '@/store'
import { selectClientWorkspace } from '@/store/account'
import { Button, Preview } from '@/ui'

export const UploadImage = () => {
  const { value, saveValue, field } = useFieldContext<KeyValueFieldType, KeyValueData>()
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const { client } = useSlr(selectClientWorkspace)

  const onDelete = () => {
    setImageUrl('')
    if (value && value.image) {
      const newValue = { ...value }
      delete newValue.image
      saveValue(newValue)
    }
  }

  const handleUpload = async (file: File) => {
    if (client) {
      const fileName = `${getUnixTime(new Date())}-${file.name}`

      const imgUrl = await saveNewFile({
        fileName,
        file,
        docId: field.id,
      })
      if (imgUrl) {
        setImageUrl(imgUrl)
        const newValue = { ...value }
        newValue.image = {
          source: 'cdn',
          _clientId: client._id,
          _documentId: field.id,
          fileName,
        }
        saveValue(newValue)
      }
    }
  }

  return (
    <div className="flex">
      <FileUpload
        onUpload={handleUpload}
        className="relative bg-neutral200 w-32 h-32 mb-3 rounded-md cursor-pointer overflow-hidden"
      >
        <Preview image={value?.image} className="w-full h-full" />
      </FileUpload>
      {(imageUrl || (value && value.image)) && (
        <Button variant="ghost" tooltip="Delete image" icon="trash" onClick={onDelete} />
      )}
    </div>
  )
}
