import { useCallback, useState } from 'react'

export function useToggle(defaultValue = false) {
  const [open, setToggle] = useState(defaultValue)

  const on = useCallback((event?) => {
    event && event?.stopPropagation()
    setToggle(true)
  }, [])
  const off = useCallback((event?) => {
    event && event?.stopPropagation()
    setToggle(false)
  }, [])
  const toggle = useCallback((event?) => {
    event?.stopPropagation()
    setToggle((v) => !v)
  }, [])

  return [open, { on, off, toggle, set: setToggle }] as const
}
