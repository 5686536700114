import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useGetOptions } from '../hooks/useGetOptions'
import { FieldTemplateProperty } from '../types'

type FieldSelectProps = {
  propertyName: string
  templateProperty: FieldTemplateProperty
  nestedStructure: string
  ancestor?: string | undefined
}

export const FieldSelect: FC<FieldSelectProps> = ({
  propertyName,
  templateProperty,
  nestedStructure,
  ancestor,
}) => {
  const { register } = useFormContext()
  let name = propertyName

  if (propertyName === 'type' && ancestor) {
    name = `${ancestor}_${propertyName}`
  }

  const options = useGetOptions(name)

  return (
    <div className="form-control w-full max-w-[300px]">
      <label className="cursor-pointer label justify-start flex-col items-start p-0">
        <span className="label-text capitalize font-bold px-1 py-2">{propertyName}</span>

        <select
          className="select select-bordered w-full min-h-[30px] h-11"
          {...register(nestedStructure, { required: templateProperty.required })}
        >
          <option value="">Select...</option>
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  )
}
