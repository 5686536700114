import { useDocContext } from '@/contexts'
import { NoteInteraction, NoteValue } from '@/db'
import { deleteInteraction, updateInteraction } from '@/db/interactions/sync'
import { BoardPoint } from '../whiteboardTypes'
import { BoardObject } from './BoardObject'

interface NoteProps {
  note: NoteInteraction
}

export const Note = ({ note }: NoteProps) => {
  const { doc } = useDocContext()
  const { pos } = note.value ?? {}

  const handleBlur = (value: string) => {
    note.value.text = value
    updateInteraction<NoteValue>(doc._id, note._id, note)
  }

  const updatePosition = (point: BoardPoint) => {
    note.value.pos = point
    updateInteraction<NoteValue>(doc._id, note._id, note)
  }

  const deleteNote = () => {
    deleteInteraction(note)
  }

  return (
    <BoardObject
      data={note}
      pos={pos}
      width={214}
      height={214}
      dragEnd={updatePosition}
      onEdit={handleBlur}
      onDelete={deleteNote}
    >
      <div className="px-3 py-5 break-words">{note.value.text}</div>
    </BoardObject>
  )
}
