import { FunctionComponent, useMemo } from 'react'
import { DocumentType } from '@common/interfaces/clients/document-type.interface'
import { Home } from '../layouts/Home'
import { HomeNarrow } from '../layouts/HomeNarrow'
import { Section } from '../layouts/Section'
import { Single } from '../layouts/Single'
import { User } from '../layouts/User'

type Layout = DocumentType['layout']['name'] | 'default'
type Type = DocumentType['name']
type Key = `${Layout}_${Type}`

const layouts: Record<Key, FunctionComponent> = {
  default_home: Home,
  default_home_narrow: HomeNarrow,
  default_section: Section,
  default_single: Single,
  single_User: User,
  section_User: User,
}

export const useDynamicLayout = (layout: Layout, type: Type) =>
  useMemo(() => {
    const key: Key = `${layout}_${type}`

    const customLayout = layouts[key]

    if (!customLayout) {
      return layouts[`default_${layout}`]
    }

    return customLayout
  }, [layout, type])
