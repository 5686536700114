import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { items: any[] } = {
  items: [],
}

const auditLogsSlice = createSlice({
  name: 'audit-logs',
  initialState,
  reducers: {
    setItems(state, action: PayloadAction<any[]>) {
      state.items = [...state.items, ...action.payload]
    },
  },
})

export const { setItems } = auditLogsSlice.actions

export const { reducer } = auditLogsSlice
