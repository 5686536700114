import { useMemo } from 'react'
import { SomeField } from '@/db'
import { useSlr } from '@/store'
import { fieldsSelectors } from '@/store/docs'
import { useFetchQuery } from '@/hooks'
import { createEvaluator } from '@/utils/evaluator'
import { useDocContext } from '@/contexts'

export function useCondition(field: SomeField, isEditMode: boolean) {
  const { doc, user, areas, isNew, schema } = useDocContext()
  const { editable, readonly, hiddenEdit, hiddenView } = field.condition ?? {}
  const fieldState = useSlr((state) => fieldsSelectors.selectById(state, field.name))
  const evaluate = useFetchQuery(
    () => createEvaluator(doc, user, { areas, field, isNew, asyncScope: schema.expScope }),
    [doc, user],
    () => true,
  )

  const bl = useMemo(
    () => ({
      isEditable: editable ? evaluate(editable) : true,
      isReadOnly: readonly ? evaluate(readonly) : false,
      isHiddenEdit: hiddenEdit ? evaluate(hiddenEdit) : false,
      isHiddenView: hiddenView ? evaluate(hiddenView) : false,
      // isHiddenViewIfEmpty: hiddenViewIfEmpty ? evaluate(hiddenViewIfEmpty) : false,
    }),
    [evaluate],
  )

  const isEditable = bl.isReadOnly ? false : bl.isEditable && isEditMode
  const isViewMode = !isEditMode

  const isNotVisible = !fieldState?.isVisible
  const isHiddenEdit = isEditMode && isNotVisible && bl.isHiddenEdit
  const isHiddenView = isViewMode && isNotVisible && bl.isHiddenView

  return { isViewMode, isEditable, isHiddenEdit, isHiddenView }
}
