import { Link } from 'react-router-dom'
import { usePath } from '@/hooks'
import { Button, Preview } from '@/ui'
import { CardProps } from '../cardsTypes'

export const TinyPersonaCard = ({ isEditMode, doc, onDelete }: CardProps) => {
  const { getDocPath } = usePath()
  return (
    <div className="rounded-full bg-neutral200 mr-2 mb-1 py-1 px-1.5 flex items-center relative">
      <Link aria-label={`link to ${doc.title}`} to={getDocPath(doc)} className="flex items-center">
        <Preview image={doc.fields?.image} icon="user" className="w-6 h-6 rounded-full mr-1" />

        <p title={doc.title} className="text-focus">
          {doc.title}
        </p>
      </Link>

      {isEditMode && onDelete && (
        <Button
          icon="x"
          id={'delete ' + doc.title}
          tooltip={'delete ' + doc.title}
          className="flex ml-1"
          onClick={(e) => onDelete(e, doc)}
        />
      )}
    </div>
  )
}
