import { createElement, FC, FunctionComponent } from 'react'
import clsx from 'clsx'
import { ActionField } from '@common/interfaces/clients/document-type.interface'
import { useDocContext } from '@/contexts'
import { LikeField } from '@/fields/like'
import { CommentsCount } from '../../cards/components/CommentsCount'

const components: Record<ActionField, FunctionComponent> = {
  comment: CommentsCount,
  like: LikeField,
}

type ActionComponentsProps = {
  className?: string
}

export const RighBarActions: FC<ActionComponentsProps> = ({ className }) => {
  const { schema } = useDocContext()

  const actionComponentNames = schema.layout.actionFields

  if (!actionComponentNames) {
    return null
  }

  return (
    <div className={clsx('flex items-center', className)}>
      {actionComponentNames.map((componentName) => {
        const component = components[componentName]

        if (!component) {
          return null
        }

        return createElement(component, { key: componentName })
      })}
    </div>
  )
}
