import clsx from 'clsx'
import { HtmlParser } from '@/features/rte'
import { Preview } from '@/ui'
import { showUntitledText } from '@/utils/parseString'
import { CardProps } from '../cardsTypes'

export const ImpactStoryCard = ({ doc, children, headerElements, setImage }: CardProps) => (
  <div className="grid grid-cols-[3fr_4fr] border-neutral300 gap-4 border rounded-lg p-4">
    <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
    <div className="">
      {/*<CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="mb-1.5" />*/}
      <p title={doc.title} className={clsx('text-focus break-words pb-2')}>
        {showUntitledText(doc.title)}
      </p>

      {doc.fields?.image && (
        <Preview
          fit="cover"
          className="w-full h-auto rounded-xl object-top"
          image={doc.fields.image}
          iconSize={36}
          onClick={(e) => setImage?.(e, doc.fields.image)}
        />
      )}
    </div>

    <div className="">
      {doc.fields?.overview && (
        <HtmlParser className="prose prose-sm text-regular text-neutral700 [&>ul]:list-disc pl-1 text-break whitespace-pre-line line-clamp-6 mb-1.5">
          {doc.fields.overview}
        </HtmlParser>
      )}

      {doc.fields?.engagement && (
        <HtmlParser className="prose prose-sm text-regular text-neutral700 [&>ul]:list-disc pl-1 text-break whitespace-pre-line line-clamp-6 mb-1.5">
          {doc.fields.engagement}
        </HtmlParser>
      )}

      {doc.fields?.impact && (
        <HtmlParser className="prose prose-sm text-regular text-neutral700 [&>ul]:list-disc pl-1 text-break whitespace-pre-line line-clamp-6 mb-1.5">
          {doc.fields.impact}
        </HtmlParser>
      )}
    </div>
    {children}
  </div>
)
