import { useLayoutEffect, useRef, useState } from 'react'
import { useResize } from './useResize'

type Rect = Omit<DOMRect, 'toJSON'>

export function useRect<T extends Element>() {
  const { width, height } = useResize()
  const ref = useRef<T>(null)
  const [rect, setRect] = useState<Rect>({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  })

  useLayoutEffect(() => {
    const el = ref.current
    if (!el) return

    const elRect = el.getBoundingClientRect()
    setRect(elRect)
  }, [width, height])

  return {
    ref,
    rect,
  }
}
