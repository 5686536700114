import React, { MouseEvent, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import sanitizeHtml from 'sanitize-html'
import { FieldValueType } from '@/db'
import { HtmlFieldType } from '@common/interfaces/fields/html-field.interface'
import { useFieldContext } from '@/contexts'
import { CardDocType } from '@/schema/cards/components/CardDocType'
import { Icon } from '@/ui'
import { IconName } from '@/ui/Icon'

interface HtmlParserProps {
  children?: FieldValueType
  className?: string
  styles?: { [key: string]: string } | React.CSSProperties
}

export const HtmlParser = ({ children, className, styles }: HtmlParserProps) => {
  const { field } = useFieldContext<HtmlFieldType>()
  const html = useMemo(() => {
    if (!children || typeof children !== 'string') return ''

    return sanitizeHtml(children, {
      allowedTags: ['p', 'span', 'b', 'strong', 'i', 'ul', 'ol', 'li', 'a', 'blockquote', 'br'],
      allowedAttributes: {
        '*': ['class', 'title', 'style'],
        a: ['href'],
      },
    })
  }, [children])

  const navigate = useNavigate()
  const handleAnchorClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const target = e.target as HTMLElement
      const targetLink = target.closest('a')

      if (targetLink?.classList.contains('mention')) {
        e.preventDefault()
        const url = new URL(targetLink.href)
        navigate(url.pathname)
      }
    },
    [navigate],
  )

  if (field?.viewComponent === 'block') {
    return (
      <div className="bg-[#f1f1f1] min-h-28">
        {field.borderTop && <div className="w-full h-4" style={{ background: field.borderTop }} />}
        <div className="grid grid-cols-2 overflow-hidden h-full px-8 pb-6 pt-4">
          <div className="h-full flex flex-col justify-between">
            <CardDocType
              type={field?.niceName}
              className="pb-1.5 text-heading2 w-[60%] font-bold"
            />
            {field.icon && <Icon name={field.icon as IconName} size={34} />}
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div
            onClick={handleAnchorClick}
            className={
              className ??
              'prose prose-sm leading-normal min-w-full prose-p:m-0 text-break whitespace-pre-line [&>ul>li]:pl-0'
            }
            style={styles}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    )
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      onClick={handleAnchorClick}
      className={
        className ??
        'prose prose-sm leading-normal min-w-full prose-p:m-0 text-break whitespace-pre-line [&>ul>li]:pl-0'
      }
      style={styles}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}
