import { FC, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Editor from '@monaco-editor/react'
import { FieldTemplateProperty } from '../types'

type FieldConditionProps = {
  propertyName: string
  templateProperty: FieldTemplateProperty
  nestedStructure: string
}

export const FieldCondition: FC<FieldConditionProps> = ({ propertyName, nestedStructure }) => {
  const { setValue, getValues } = useFormContext()
  const [booleanCondition, setBooleanCondition] = useState(false)
  const [stringCondition, setStringCondition] = useState('')
  const [showTextField, setShowTextField] = useState(false)
  const value = getValues(nestedStructure)

  useEffect(() => {
    if (value && typeof value === 'string') {
      setStringCondition(value)
      setValue(nestedStructure, value)
      setShowTextField(true)
    } else if (typeof value === 'boolean' && value) {
      setBooleanCondition(value)
      setValue(nestedStructure, value)
      setShowTextField(false)
    }
  }, [value])

  const booleanConditionChangeHandler = useCallback((e) => {
    setStringCondition('')
    setBooleanCondition(e.target.checked)
    setValue(nestedStructure, e.target.checked)
  }, [])

  const stringConditionChangeHandler = useCallback((value) => {
    setStringCondition(value)
    setBooleanCondition(false)
    setValue(nestedStructure, value)
  }, [])

  const textFieldChangeHandler = useCallback((e) => {
    setShowTextField(e.target.checked)

    if (e.target.checked) {
      setBooleanCondition(false)
      setValue(nestedStructure, false)
    } else {
      setStringCondition('')
      setValue(nestedStructure, '')
    }
  }, [])

  return (
    <div className="flex flex-col w-full max-w-[300px]">
      <span className="label-text capitalize font-bold px-1 py-2">{propertyName}</span>

      <div className="form-control">
        <label className="label cursor-pointer justify-start">
          <span className="label-text pr-4">Show code editor instead of toggle</span>
          <input
            type="checkbox"
            className="checkbox checkbox-primary"
            onChange={textFieldChangeHandler}
          />
        </label>
      </div>

      {showTextField ? (
        <div className="form-control w-full">
          <Editor
            width="100%"
            height="100px"
            defaultLanguage="javascript"
            theme="vs-dark"
            options={{ minimap: { enabled: false } }}
            value={stringCondition}
            onChange={stringConditionChangeHandler}
          />
        </div>
      ) : (
        <div className="form-control w-full">
          <input
            type="checkbox"
            className="toggle toggle-primary"
            checked={booleanCondition}
            onChange={booleanConditionChangeHandler}
          />
        </div>
      )}
    </div>
  )
}
