import { useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react'
import { useLiveQuery } from 'dexie-react-hooks'
import { ModalState } from '@/contexts'
import { queryDocsByTypeList } from '@/db/docs/queries'
import { createDoc } from '@/db/docs/sync'
import { usePath } from '@/hooks'
import { scm, useSchemaState } from '@/contexts/schema'
import { useAppDispatch } from '@/store'
import { Button } from '@/ui'
import { resetNewDoc } from '@/store/docs'
import { ModalCopyState } from '@/contexts/PathContext'

export type Option = {
  label: string
  value: string
  id: string
}

export const CopyDocModal = ({ type, close, doc, copyType }: ModalState<ModalCopyState>) => {
  const navigate = useNavigate()
  const { getDocPath } = usePath()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [parentId, setParentId] = useState<string>()
  const [title, setTitle] = useState(doc.title ?? '')
  const docsSchema = useSchemaState((state) => state.docTypes)
  const parentTypes = useMemo(() => scm.getAllowedParents[copyType], [copyType, docsSchema])
  const parentDocs = useLiveQuery(() => queryDocsByTypeList(parentTypes), [parentTypes])

  const handleChange = (event) => {
    event?.stopPropagation()
    setTitle(event.target.value)
  }

  const onSubmit = async () => {
    let parentDoc = parentDocs?.[0]
    if (parentId) {
      parentDoc = parentDocs?.find((item) => item._id === parentId)
    }
    if (!parentDoc) return

    const newTypeDoc = await createDoc({
      title: title.trim(),
      type: copyType,
      parentDoc: parentDoc,
      fields: { ...doc.fields },
    })

    if (newTypeDoc) {
      searchParams.delete('row')
      setSearchParams(searchParams)
      dispatch(resetNewDoc())
      navigate({ pathname: getDocPath(newTypeDoc), search: `?edit` })
    }

    close?.()
  }

  return (
    <Modal isOpen={!!type} onOpenChange={close}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">Convert to {copyType}</ModalHeader>
        <ModalBody>
          {parentDocs?.length && (
            <Select onChange={(e) => setParentId(e.target.value)} label={'Select where to convert'}>
              {parentDocs.map((item) => (
                <SelectItem key={item._id}>{item.title}</SelectItem>
              ))}
            </Select>
          )}
          <Input label="title" onChange={handleChange} key={'outside'} type="text" value={title} />{' '}
        </ModalBody>
        <ModalFooter>
          <Button
            aria-label={'Convert to ' + copyType}
            disabled={!title?.trim() || !copyType || !parentDocs?.length}
            onClick={onSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
