import clsx from 'clsx'
import { HtmlParser } from '@/features/rte'
import { Button, Preview } from '@/ui'
import { showUntitledText } from '@/utils/parseString'
import { CardProps } from '../cardsTypes'
import { CardDocType } from '../components/CardDocType'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'
import { ImageFieldData } from '@/db'

export const SmallCard = ({ doc, docSchema, children, headerElements, setImage }: CardProps) => {
  const { field } = useFieldContext<ChildrenFieldType>()
  const hideDescription = field.layout?.card?.hideDescription
  const hideTitle = field.layout?.card?.hideTitle
  const styles = field?.layout?.card?.styles
  const secondaryStyles = field?.layout?.card?.secondaryStyles
  const fontSize = field.layout?.card?.fontSize
  const fontColor = field.layout?.card?.fontColor
  const lineHeight = field.layout?.card?.lineHeight
  const backgroundColor = doc.fields.color ?? ''
  const fit = field?.layout?.card?.imageFit as 'cover' | 'contain' | 'fill'

  const fields = field.layout?.card?.fields

  const getElemets = (name: string, type: string) => {
    if (type === 'image' && doc.fields[name]) {
      return (
        <div style={styles}>
          <Preview
            fit={fit ?? 'contain'}
            className="w-full h-full object-center"
            styles={secondaryStyles}
            image={doc.fields[name]}
            iconSize={36}
            onClick={(e) => setImage?.(e, doc.fields.image)}
          />
        </div>
      )
    }
    if (type === 'html' && doc.fields[name]) {
      return (
        <div className="items-center p-6 flex">
          <HtmlParser
            styles={{ color: fontColor ?? '#5F6160' }}
            className="text-regular text-neutral700 text-break whitespace-pre-line line-clamp-4 [&>ul]:list-disc [&>ul]:pl-3"
          >
            {doc.fields[name]}
          </HtmlParser>
        </div>
      )
    }
  }

  if (!fields) {
    return (
      <div
        className={clsx('relative grid grid-cols-2 overflow-hidden')}
        style={{ backgroundColor: backgroundColor ? (backgroundColor as string) : '#FFF' }}
      >
        <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>

        <div className="flex flex-col gap-4 p-9">
          <div>
            <CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="pb-1.5" />
            <p
              title={doc.title}
              className={clsx('text-focus text-neutral900 break-words')}
              style={{ fontSize, color: fontColor, lineHeight }}
            >
              {showUntitledText(doc.title)}
            </p>
          </div>

          {doc.fields?.description && !hideDescription && (
            <div className="grow">
              <HtmlParser
                styles={{ color: fontColor ?? '#5F6160' }}
                className="prose text-regular text-neutral700 text-break whitespace-pre-line line-clamp-4 [&>ul]:list-disc [&>ul]:pl-3"
              >
                {doc.fields.description}
              </HtmlParser>
            </div>
          )}

          <div className="mt-auto">
            <Button className="bg-neutral900">Read the article</Button>
          </div>

          {children}
        </div>

        <div style={styles}>
          <Preview
            fit="cover"
            className="w-full h-full"
            styles={secondaryStyles}
            image={doc.fields.image}
            iconSize={36}
            onClick={(e) => setImage?.(e, doc.fields.image)}
          />
        </div>
      </div>
    )
  }

  const getColumsClass = () =>
    fields.filter((field) => {
      if (field.type === 'image') {
        const image = doc.fields[field.fieldName] as ImageFieldData
        if (!image.fileName) return false
        return true
      }
      if (doc.fields[field.fieldName]) return true
    }).length

  const columnClass = {
    'grid-cols-1': getColumsClass() === 1,
    'grid-cols-2': getColumsClass() === 2,
    'grid-cols-3': getColumsClass() === 3,
    'grid-cols-4': getColumsClass() === 4,
  }

  return (
    <div>
      {!hideTitle && doc.title && (
        <div
          className="text-heading line-clamp-1"
          style={{ marginBottom: '18px', fontSize: '28px', paddingLeft: '16px' }}
        >
          {doc.title}
        </div>
      )}
      <div
        className={clsx('relative grid overflow-hidden', columnClass)}
        style={{ backgroundColor: backgroundColor ? (backgroundColor as string) : '#FFF' }}
      >
        <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
        {fields.map((field) => getElemets(field.fieldName, field.type))}
      </div>
    </div>
  )
}
