import { TextFieldType } from '@common/interfaces/fields/text-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { AreaRenderer, FieldContainer } from '@/schema'
import { showUntitledText } from '@/utils/parseString'
import { FieldComponent } from '../fieldType'
import { TextEdit } from './components/TextEdit'
import emailIcon from './images/email-icon.png'
import { Icon } from '@/ui'
import { IconName } from '@/ui/Icon'

export const TextField: FieldComponent = () => {
  const { value, field, isEditable } = useFieldContext<TextFieldType, string>()
  const { doc, schema } = useDocContext()
  const icon = doc.fields.icon
  const styles = field?.styles

  if (isEditable || field.name === 'secondaryId') {
    return <TextEdit />
  }

  if (field.viewComponent === 'quote') {
    return (
      <FieldContainer>
        <blockquote className="quote">{showUntitledText(value)}</blockquote>
      </FieldContainer>
    )
  }

  if (field.viewComponent === 'title') {
    return (
      <div className="grid grid-flow-col gap-4 items-center justify-between content-start">
        <div>
          {!schema.hideType && <p className="doc-type pb-1">{schema.niceName}</p>}
          <div className="flex gap-2">
            {icon && field?.showIcon && (
              <div className="pt-[9px]">
                <Icon name={icon as IconName} strokeWidth={2} size={30} />
              </div>
            )}
            <h1 className="text-title break-words whitespace-pre-wrap" style={styles}>
              {showUntitledText(value)}
            </h1>
          </div>
        </div>
        <AreaRenderer areaName="title" className="flex gap-4" />
      </div>
    )
  }

  if (field.viewComponent === 'email') {
    return (
      <FieldContainer>
        <a href={`mailto:${value}`} className="flex items-center gap-x-2 text-blue400">
          <img className="rounded-full" src={emailIcon} width="30" alt="email-icon" />

          {value}
        </a>
      </FieldContainer>
    )
  }

  return (
    <FieldContainer>
      <p className="break-words whitespace-pre-wrap">{showUntitledText(value)}</p>
    </FieldContainer>
  )
}
