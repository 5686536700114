type LSKeys = {
  disconnectTime: string
  docBarPosition: [number, number]
  pendingRequests: Record<string, any>
  cacheDataVersion: number
  enablePermissions: boolean | string
  dev: boolean
  docIds: Record<string, 'id' | 'sent' | 'received' | 'failed'>
}

export class LS {
  private static stringValues = new Set<keyof LSKeys>(['disconnectTime', 'enablePermissions'])

  static get<K extends keyof LSKeys>(key: K) {
    try {
      const str: any = localStorage.getItem(key)
      if (LS.stringValues.has(key)) return str as LSKeys[K]

      if (str) {
        const item: LSKeys[K] = JSON.parse(str)
        return item
      }

      return null
    } catch (e) {
      localStorage.removeItem(key)
      window.Rollbar.error(e as Error)
      return null
    }
  }

  static set<K extends keyof LSKeys>(key: K, value: LSKeys[K]) {
    if (value === null || value === undefined) return

    if (typeof value === 'string') {
      localStorage.setItem(key, value)
    } else {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }

  static remove<K extends keyof LSKeys>(key: K) {
    localStorage.removeItem(key)
  }

  static clear() {
    LS.remove('disconnectTime')
    LS.remove('docBarPosition')
    LS.remove('pendingRequests')
    LS.remove('docIds')
    LS.set('cacheDataVersion', +import.meta.env.VITE_CACHE_DATA_VERSION)
  }

  static isNewVersion() {
    const currentVersion = LS.get('cacheDataVersion') ?? 0
    const newVersion = +import.meta.env.VITE_CACHE_DATA_VERSION
    return newVersion > currentVersion
  }
}

export function reload(clearCache = false, url = new URL(window.location.href)) {
  if (clearCache) {
    url.searchParams.set('v', import.meta.env.VITE_CACHE_DATA_VERSION)
  }
  setTimeout(() => {
    window.location.href = url.toString()
  })
}
