import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  colors as colorDict,
  Config,
  NumberDictionary,
  uniqueNamesGenerator,
} from 'unique-names-generator'
import { IS_CISCO } from '@/api'
import { checkClient, checkEmail, signup, SignupData } from '@/api/auth'
import LogoIcon from '@/assets/logo.svg?react'
import { pathes } from '@/routes'
import ciscoLogo from '@/routes/SignInPage/cisco.png'
import { useSlr } from '@/store'
import { selectSessionStatus } from '@/store/session/selectors'
import { Button } from '@/ui'
import { userColors } from '@/ui/theme'
import { random } from '@/utils'

type FormData = Omit<SignupData, 'color' | 'schema'>

export const SignUpPage = () => {
  const [loading, setLoading] = useState(false)
  const [showClientAlert, setShowClientAlert] = useState(false)
  const [step, setStep] = useState<keyof FormData>('email')
  const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

  const { register, handleSubmit, getValues, watch, setValue } = useForm<FormData>()

  const { status } = useSlr(selectSessionStatus)
  const navigate = useNavigate()

  const randomNameConfig: Config = {
    dictionaries: [
      [watch('name') || 'Name'],
      [watch('companyName') || 'Company Name'],
      colorDict,
      NumberDictionary.generate({ min: 10, max: 1000 }),
    ],
    style: 'capital',
    separator: ' ',
  }

  const genRandomNames = (limit: number) => {
    const tmpList: string[] = []

    new Array(limit).fill(0).forEach(() => {
      tmpList.push(uniqueNamesGenerator(randomNameConfig))
    })

    setNameSuggestions(tmpList)
  }

  const randomNamesRender = useMemo(
    () =>
      nameSuggestions.map((name) => (
        <button
          key={name}
          className="badge"
          onClick={() => setValue('companyName', name)}
          onKeyDown={() => setValue('companyName', name)}
        >
          {name}
        </button>
      )),
    [nameSuggestions],
  )

  const description = useMemo(
    () => ({
      email: 'Sign up using your work email.',
      name: 'Tell us a bit about yourself.',
      companyName: 'What’s the name of your Hypha?',
    }),
    [],
  )

  if (status === 'success') {
    return <Navigate replace to={pathes.HOME} />
  }

  const onSubmit = (data: FormData) => {
    const index = random(0, userColors.length)

    setLoading(true)
    checkClient(getValues('companyName') as string).then((isValid: boolean) => {
      if (isValid) {
        signup({ ...data, color: userColors[index] }).then(() =>
          navigate(pathes.SUCCESS, { replace: true }),
        )
      } else {
        genRandomNames(5)
        setShowClientAlert(true)
        setLoading(false)
      }
    })
  }

  const onNext = async () => {
    if (step === 'email') {
      const email = getValues('email')
      setLoading(true)
      if (email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
        if (await checkEmail(email)) setStep('name')
      } else {
        toast.error('Please enter a valid email address')
      }
      setLoading(false)
    } else if (step === 'name') {
      setStep('companyName')
    }
  }

  return (
    <div className="p-10 max-sm:p-5">
      <header className="flex justify-between">
        <div className="flex items-center">
          {IS_CISCO ? (
            <img src={ciscoLogo} width={200} alt="Company logotype" />
          ) : (
            <>
              <LogoIcon />
              <strong className="text-heading2 ml-3.5">Hypha</strong>
            </>
          )}
        </div>
        <div className="max-sm:text-right max-sm:pl-4">
          <p className="text-neutral700">Already have an account?</p>
          <Link aria-label={`link to log in`} className="font-bold text-primary" to="/signin">
            Log in to {IS_CISCO ? 'Purposefy' : 'Hypha'}
          </Link>
        </div>
      </header>
      <main className="grid justify-items-center text-neutral700 pt-20 max-sm:p-0 max-sm:pt-10">
        <h1 className="text-title mb-3.5 max-sm:text-center">
          Welcome to {IS_CISCO ? 'Purposefy' : 'Hypha'}
        </h1>
        <p>{description[step]}</p>
        <div className="pt-8 grid max-w-[340px] w-full gap-5">
          {step === 'email' && (
            <input
              type="email"
              className="input input-bordered"
              placeholder="user@company.com"
              {...register('email', { required: true })}
            />
          )}
          {step === 'name' && (
            <input
              type="text"
              className="input input-bordered"
              placeholder="your name"
              {...register('name')}
            />
          )}
          {step === 'companyName' && nameSuggestions.length > 0 && (
            <h5>Please give your Hypha a unique name</h5>
          )}
          {step === 'companyName' && (
            <input
              type="text"
              className="input input-bordered"
              placeholder="Hypha name"
              {...register('companyName', {
                required: true,
                onChange: () => setShowClientAlert(false),
              })}
            />
          )}
          {showClientAlert && (
            <div
              className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4"
              role="alert"
            >
              <p className="font-bold">Warning</p>
              <p>I am afraid that name is already taken</p>
            </div>
          )}
          {step === 'companyName' && randomNamesRender}
          {step === 'companyName' ? (
            <Button loading={loading} onClick={handleSubmit(onSubmit)}>
              Sign up
            </Button>
          ) : (
            <Button loading={loading} onClick={onNext}>
              Next
            </Button>
          )}
        </div>
        <p className="pt-96 max-sm:pt-14 max-sm:text-center">
          By continuing, you agree to {IS_CISCO ? 'Purposefy' : 'Hypha'}’s{' '}
          <Link
            aria-label={`link to privacy policy`}
            to="/privacy-policy"
            target="_blank"
            className="underline"
          >
            Privacy Policy
          </Link>
          .
        </p>
      </main>
    </div>
  )
}
