import { PropsWithChildren, useId, useState } from 'react'
import { toast } from 'react-toastify'
import { useDocContext } from '@/contexts'
import { updateDocumentPropagateFlag } from '@/db/permissions/sync'

type PropagatePermissionsProps = {
  type: 'setViewer' | 'setContributor' | 'disableEditPropagation'
} & PropsWithChildren

export const PropagatePermissions = ({ type, children }: PropagatePermissionsProps) => {
  const { doc } = useDocContext()
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(!!doc[type])
  const id = useId()

  const handleChange = async (isChecked: boolean) => {
    setChecked(isChecked)
    setLoading(true)
    const toastId = toast.loading("Updating children documents, please don't close the tab")
    await updateDocumentPropagateFlag(doc, { [type]: isChecked })
    toast.update(toastId, {
      render: 'Successfully finished',
      type: 'success',
      isLoading: false,
      autoClose: 2000,
    })
    setChecked(isChecked)
    setLoading(false)
  }

  return (
    <div className="flex gap-2 items-center">
      <input
        id={id}
        type="checkbox"
        className="toggle toggle-xs"
        disabled={loading}
        checked={checked}
        onChange={(e) => handleChange(e.target.checked)}
      />
      <label htmlFor={id} className="text-caption">
        {children}
      </label>
    </div>
  )
}
