import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { db } from '@/db'
import { ModalWrapper } from '@/features/modals'
import { Sidebar } from '@/features/sidebar'
import { useAppDispatch, useSlr } from '@/store'
import { selectAccount, selectClientWorkspace } from '@/store/account'
import { colors } from '@/ui/theme'
import { hexToHSL } from '@/utils/colors'
import { extractInstanceName, uppercaseFirstLetter } from '@/utils/parseString'
import { RendererError } from './RendererError'
import { setActivityLabel } from '@/store/activity-label/slice.ts'
import { useActivityLabel } from '@/hooks/useActivityLabel.ts'

export const MainLayout = () => {
  const dispatch = useAppDispatch()
  const { workspace } = useSlr(selectClientWorkspace)
  const { client } = useSlr(selectClientWorkspace)
  const account = useSlr(selectAccount)
  const { boot, shutdown } = useIntercom()
  const htmlTag = document.querySelector('html')
  const activityLabel = useActivityLabel()

  useEffect(() => {
    if (!workspace?.color) {
      htmlTag?.setAttribute('data-theme', 'light')
      return
    }

    const hsl = hexToHSL(workspace.color)
    document.documentElement.setAttribute('style', `--nextui-primary: ${hsl} !important`)
    colors.primary = workspace.color
  }, [workspace])

  useEffect(() => {
    if (!db.flags.enableIntercom) {
      return shutdown()
    }

    boot({
      name: account.name,
      email: account.email,
      company: { companyId: client._id, name: client.name },
      createdAt: Math.floor(new Date(account._addedOn).getTime() / 1000).toString(),
      customAttributes: {
        hypha_version: '2.0',
        'Client ID': account._id,
        Instance: uppercaseFirstLetter(extractInstanceName(window.location.hostname)),
      },
    })

    return () => shutdown()
  }, [account._id, account.name, account.email, client._id, client.name])

  useEffect(() => {
    if (activityLabel) {
      dispatch(setActivityLabel(activityLabel))
    }
  }, [activityLabel])

  return (
    <div className="main-container">
      <ErrorBoundary
        fallback={
          <p className="text-error w-[230px] p-3">There may be an error in the categories scheme</p>
        }
      >
        <Sidebar />
      </ErrorBoundary>

      <ErrorBoundary FallbackComponent={RendererError}>
        <Outlet />
      </ErrorBoundary>

      <ModalWrapper />
    </div>
  )
}
