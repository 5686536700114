import { forwardRef, HTMLAttributes, PropsWithChildren, useMemo } from 'react'
import clsx from 'clsx'
import { useFieldContext } from '@/contexts'
import { Icon } from '@/ui'
import { IconName } from '@/ui/Icon'
import { hexToRgb } from '@/utils/colors'
import { selectDocSchema, useSchemaState } from '@/contexts/schema'
import { WORKSPACE_DOCUMENT_TYPE_NAME } from '@common/interfaces/clients/document-type.interface'

const FieldWrapper = ({
  className,
  children,
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  const { field } = useFieldContext()
  const isRightBarArea = field.area === 'right-sidebar'

  const fieldClasses = useMemo(() => {
    if (className) return String(`f-${field.name} ${className}`)
    if (isRightBarArea) return 'right-bar-field'
    return 'field-theme'
  }, [isRightBarArea, className])

  return (
    <div className={clsx(`f-${field.name}`, fieldClasses)} id={`field_${field.name}`}>
      {children}
    </div>
  )
}

const FieldTitle = () => {
  const { field } = useFieldContext()
  const { hideName, niceName, helpPanelStyles } = field

  if (hideName) return null

  return (
    <h3
      className={field.area === 'advanced' ? 'text-focus' : 'text-subtitle'}
      style={helpPanelStyles}
    >
      {niceName}
    </h3>
  )
}

const FieldHelpPanel = () => {
  const { field } = useFieldContext()
  const { helpPanel, showHelpPanel, helpPanelIcon } = field
  const workspaceSchema = useSchemaState((state) =>
    selectDocSchema(state, WORKSPACE_DOCUMENT_TYPE_NAME),
  )
  const shouldShowHelpPanel = showHelpPanel && helpPanel

  const color = workspaceSchema?.helpPanelColor || '#EFEFEF'

  const rgb = hexToRgb(color as string) ?? { r: 255, g: 255, b: 255 }

  const icon = helpPanelIcon || 'circle-help'

  if (!shouldShowHelpPanel) return null

  return (
    <div
      className="flex p-2.5 rounded-sm"
      style={{ background: `rgba(${rgb.r},${rgb.g},${rgb.b},${0.8})` as string }}
    >
      <div className="p-1 bg-white rounded-md h-fit w-fit">
        <Icon size={16} name={icon as IconName} />
      </div>
      <p className="pl-2">{helpPanel}</p>
    </div>
  )
}

interface FieldContainerProps extends HTMLAttributes<HTMLDivElement> {
  Title?: typeof FieldTitle
  HelpPanel?: typeof FieldHelpPanel
  Wrapper?: typeof FieldWrapper
}

export const FieldContainer = forwardRef<HTMLDivElement, PropsWithChildren<FieldContainerProps>>(
  (
    { children, Title = FieldTitle, HelpPanel = FieldHelpPanel, Wrapper = FieldWrapper, ...attrs },
    ref,
  ) => (
    <Wrapper>
      <Title />
      <HelpPanel />
      <div ref={ref} {...attrs}>
        {children}
      </div>
    </Wrapper>
  ),
)

FieldContainer.displayName = 'FieldContainer'
