import { DocumentImportData } from '@common/interfaces/clients/import-data.interface'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export type ImportResultMessage = { type: 'success' | 'error'; message: string }
type Keys = keyof DocumentImportData | 'users'

interface ImporterState {
  messages: Record<string, ImportResultMessage[]>
  addMessage: (key: Keys, message: ImportResultMessage) => void
  clearMessages: () => void
}

export const useImporterState = create<ImporterState>()(
  immer((set) => ({
    messages: {},
    addMessage: (key, message) => {
      set((state) => {
        if (!state.messages[key]) {
          state.messages[key] = []
        }
        state.messages[key].push(message)
      })
    },
    clearMessages: () => {
      set((state) => {
        state.messages = {}
      })
    },
  })),
)
