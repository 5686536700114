import React, { MouseEvent, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { useLiveQuery } from 'dexie-react-hooks'
import { Search } from 'lucide-react'
import { WhiteboardFieldType } from '@common/interfaces/fields/whiteboard-field.interface'
import { useFieldContext } from '@/contexts'
import { queryDocsAnyOf } from '@/db/docs/queries'
import { useSearch } from '@/hooks'
import { Button, Select } from '@/ui'
import cssDropdown from '@/ui/Select/select.module.css'
import { LS } from '@/utils'
import { camelToCapitalizeWords } from '@/utils/parseString'
import { useFieldsTypes } from '../hooks'
import { DocBarItem } from './DocBarItem'

interface DocBarProps {
  onClose: (event: MouseEvent) => void
}

export const DocBar = ({ onClose }: DocBarProps) => {
  const { field } = useFieldContext<WhiteboardFieldType>()
  const { placeableObjectFields = [] } = field
  const { allTypes } = useFieldsTypes(placeableObjectFields)

  const [pos, setPos] = useState(LS.get('docBarPosition'))
  const docBarRef = useRef<HTMLDivElement>(null)

  const docs = useLiveQuery(async () => queryDocsAnyOf('_type', allTypes), [field]) ?? []

  const { handleSearch, list } = useSearch('', docs, (item) => item.title)

  const dragStart = (event: React.MouseEvent) => {
    const container = docBarRef.current
    if (container == null) return
    const bounds = container.getBoundingClientRect()
    const shiftX = event.pageX - bounds.left
    const shiftY = event.pageY - bounds.top
    let hasNotPosition = !pos
    const onDrag = (e: any) => {
      const x = e.pageX - shiftX
      const y = e.pageY - shiftY
      if (hasNotPosition) {
        setPos([x, y])
        hasNotPosition = false
      }
      container.style.left = `${x}px`
      container.style.top = `${y}px`
    }
    document.onmousemove = onDrag
  }

  const dragEnd = () => {
    const container = docBarRef.current
    if (container == null) return

    const newPos: [number, number] = [container.offsetLeft, container.offsetTop]
    setPos(newPos)
    LS.set('docBarPosition', newPos)
    document.onmousemove = null
  }

  const options = useMemo(
    () =>
      allTypes.map((item) => ({
        label: camelToCapitalizeWords(item),
        value: item,
      })),
    [],
  )

  return (
    <div
      ref={docBarRef}
      className={clsx(
        'absolute w-[568px] bg-white border border-neutral50 rounded-lg shadow-lg overflow-hidden',
        !pos && !document.onmousemove && 'left-24 top-1/4',
      )}
      style={{ left: pos?.[0], top: pos?.[1] }}
    >
      <div className="p-5 grid gap-5">
        <div className="flex gap-3 items-center">
          <Button icon="grip-vertical" onMouseDown={dragStart} onMouseUp={dragEnd} />
          <Select
            className={cssDropdown.whiteboard}
            placeholder="Filter by type"
            value={options[0]}
            options={options}
            isSearchable={false}
          />
          <label className="h-8 flex gap-3 items-center rounded border border-neutral300 px-4 py-0.5 flex-grow">
            <Search size={18} />
            <input
              type="text"
              placeholder="Search"
              onChange={handleSearch}
              className="outline-none h-full flex-grow"
            />
          </label>
          <Button icon="x" onClick={onClose} />
        </div>
        <p className="text-sm text-neutral600 font-bold">
          Choose from the list or create a new one
        </p>
        <div className="grid grid-cols-2 gap-2 content-start h-52 overflow-y-auto">
          {list.map((item) => (
            <DocBarItem key={item._id} item={item} />
          ))}
        </div>
      </div>
      <div className="bg-neutral50 px-3 pt-2.5 h-[50px]">
        <Button icon="plus">Create new</Button>
      </div>
    </div>
  )
}
