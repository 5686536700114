import clsx from 'clsx'

interface EmptyCardProps {
  height: number
  className?: string
}

export const EmptyCard = ({ height, className }: EmptyCardProps) => (
  <div
    className={clsx('w-full border rounded-lg border-neutral200', className)}
    style={{
      height: `${height}px`,
    }}
  ></div>
)
