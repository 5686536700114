import { Card, CardBody, CardFooter, CardHeader } from '@nextui-org/react'
import clsx from 'clsx'
import { HtmlParser } from '@/features/rte'
import { Preview } from '@/ui'
import { showUntitledText } from '@/utils/parseString'
import { CardProps } from '../cardsTypes'
import { CardDocType } from '../components/CardDocType'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'

export const PrimaryCard = ({ doc, docSchema, children, headerElements, setImage }: CardProps) => {
  const { field } = useFieldContext<ChildrenFieldType>()
  const hideDescription = field.layout?.card?.hideDescription
  const fullHeightMode = field.layout?.card?.fullHeightMode

  return (
    <Card
      className={clsx('py-4 relative border-neutral300', {
        'flex flex-col h-full': fullHeightMode,
      })}
    >
      {headerElements}
      <CardHeader className={clsx('pb-0 pt-2 px-4 flex-col items-start', { grow: fullHeightMode })}>
        <CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="mb-1.5" />
        <p title={doc.title} className={clsx('text-focus break-words')}>
          {showUntitledText(doc.title)}
        </p>
      </CardHeader>
      {doc.fields?.image && (
        <CardBody className={clsx('overflow-visible py-2', { 'grow-0': fullHeightMode })}>
          <Preview
            fit="cover"
            className="w-full h-auto rounded-xl object-top"
            image={doc.fields.image}
            iconSize={36}
            onClick={(e) => setImage?.(e, doc.fields.image)}
          />
        </CardBody>
      )}

      {doc.fields?.description && !hideDescription && (
        <CardFooter>
          <HtmlParser className="prose prose-sm text-regular text-neutral700 [&>ul]:list-disc pl-1 text-break whitespace-pre-line line-clamp-6">
            {doc.fields.description}
          </HtmlParser>
        </CardFooter>
      )}
      {children}
    </Card>
  )
}
