import { FC, HTMLAttributes } from 'react'
import clsx from 'clsx'
import css from './dropdown.module.css'
import { useDropdownContext } from './hooks'

export type DropdownToggleProps = {
  className?: string
} & HTMLAttributes<HTMLDivElement>

export const DropdownToggle: FC<DropdownToggleProps> = ({ className, children }) => {
  const { toggle, isOpen } = useDropdownContext()

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={clsx(css.toggle, className, { activeDropdown: isOpen })} onClick={toggle}>
      {children}
    </div>
  )
}
