import { db } from '../db'
import { Interaction, InteractionType, NoteValue } from '../dbTypes'

export function queryInteractionById(id: string) {
  return db.ws.interactions.get(id).catch<undefined>(console.error)
}

export function queryComments(docId: string) {
  return db.ws.interactions
    .where({ _type: 'comment', _partId: docId })
    .sortBy('_addedOn')
    .catch<undefined>(console.error)
}

export function queryCommentsCount(docId: string) {
  return db.ws.interactions
    .where({ _type: 'comment', _partId: docId })
    .count()
    .catch<undefined>(console.error)
}

export function queryLikes(docId: string) {
  return db.ws.interactions
    .where({ _type: 'like', _partId: docId })
    .count()
    .catch<undefined>(console.error)
}

export function queryNotes(docId: string, refId: string) {
  return db.ws.interactions
    .where({ _type: 'note', _partId: docId, _refId: refId })
    .toArray()
    .catch(console.error) as Promise<Interaction<NoteValue>[] | undefined>
}

export function queryUserInteraction(docId: string, userId: string, type: InteractionType) {
  return db.ws.interactions
    .get({ _partId: docId, _userId: userId, _type: type })
    .catch<undefined>(console.error)
}

export function queryAllInteractions() {
  return db.ws.interactions.toArray().catch<undefined>(console.error)
}
