import { Navigate } from 'react-router-dom'
import { IS_CISCO } from '@/api'
import ciscoLogo from '@/routes/SignInPage/cisco.png'
import { useSlr } from '@/store'
import { selectSessionStatus } from '@/store/session/selectors'
import { pathes } from '../configs'
import ikeaLogo from '../SignInPage/Ikea_logo.svg.png'

export const SuccessPage = () => {
  const { status } = useSlr(selectSessionStatus)

  if (status === 'success') {
    return <Navigate replace to={pathes.HOME} />
  }

  return (
    <div className="p-10">
      <header className="flex justify-between">
        <div className="flex items-center">
          {IS_CISCO ? (
            <img src={ciscoLogo} width={200} alt="Company logotype" />
          ) : (
            <>
               <img src={ikeaLogo} width={200} alt="Company logotype" />
              {/* <strong className="text-heading2 ml-3.5">Hypha</strong> */}
            </>
          )}
        </div>
      </header>
      <main className="grid justify-items-center text-neutral700 pt-20">
        <h1 className="text-title mb-3.5 text-neutral900">Please close this tab and check your e-mail to continue</h1>
        {/* <p className="pt-96">
          By continuing, you agree to Hypha’s{' '}
          <Link
            aria-label={`link to privacy policy`}
            to="/privacy-policy"
            target="_blank"
            className="underline"
          >
            Privacy Policy
          </Link>
          .
        </p> */}
      </main>
    </div>
  )
}
