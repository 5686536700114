import { DataListParams } from '@common/interfaces/fields/field.interface.ts'
import { useFieldContext } from '@/contexts'
import { Icon } from '@/ui'
import { IconName } from '@/ui/Icon'
import { showUntitledText } from '@/utils/parseString'
import { selectDocSchema, useSchemaState } from '@/contexts/schema'
import css from '../card.module.css'
import { CardProps } from '../cardsTypes'

export const OneFourthCard = ({ doc, children, headerElements }: CardProps) => {
  const backgroundColor = (doc?.fields?.color as string) ?? '#efefef'
  const { field } = useFieldContext<DataListParams>()
  const docSchema = useSchemaState((state) => selectDocSchema(state, doc._type))
  const { icon } = docSchema ?? { icon: undefined }
  const fontSize = field.layout?.card?.fontSize
  const styles = field?.layout?.card?.styles

  return (
    <div className="relative w-full h-full rounded-lg flex flex-col" style={{ backgroundColor }}>
      <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
      <div className="px-3.5 pt-2.5 pb-2.5 h-[110px] grid place-items-center">
        <div style={styles} className="flex items-center w-full justify-center text-center gap-2">
          {icon && <Icon name={icon as IconName} size={16} className="shrink-0" />}
          <p title={doc.title} className={css.clampFourRows} style={{ fontSize }}>
            {showUntitledText(doc.title)}
          </p>
        </div>
      </div>
      {children}
    </div>
  )
}
