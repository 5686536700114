import { useMemo } from 'react'
import get from 'lodash.get'
import { Metric as MetricInterface } from '@common/interfaces/fields/dashboard-field.interface'
import { useQueryEntry } from '@/hooks'
import { Icon } from '@/ui'
import { IconName } from '@/ui/Icon'
import { formatNumber } from '@/utils/numberFormatter'

interface MetricProps {
  metric: MetricInterface
}

export const Metric = ({ metric }: MetricProps) => {
  const { docs, count } = useQueryEntry(metric.query)

  const docsFieldsField = useMemo(() => {
    const { field } = metric
    if (field) {
      return docs.map((doc) => get(doc, field))
    }
    return []
  }, [docs, metric.field])

  const result = useMemo(() => {
    if (metric.query.resultType === 'count') {
      return count
    }
    if (metric.query.resultType === 'sum') {
      const sum = docsFieldsField.reduce(
        (acc, item) => acc + (typeof item === 'number' ? item : 0),
        0,
      )
      return formatNumber(sum)
    }
    if (metric.query.resultType === 'distinctCount') {
      const distinct = [...new Set(docsFieldsField)]
      return distinct.length
    }
    if (metric.query.resultType === 'dash') {
      return '-'
    }

    return docsFieldsField[0]
  }, [docsFieldsField])

  return (
    <div className="flex bg-white rounded-xl items-center justify-center py-5 max-md:aspect-auto max-md:py-6">
      <div
        className="flex items-center justify-center w-12 h-12 rounded-xl"
        style={{ backgroundColor: metric.color }}
      >
        <Icon name={metric.icon as IconName} color={metric.iconColor} size={34} />
      </div>
      <div className="ml-4">
        <div className="text-[32px] font-bold text-neutral700 leading-[38px]">
          {(result as number | string) || 0}
        </div>
        <div className="text-sm text-neutral700 text-center">{metric.label}</div>
      </div>
    </div>
  )
}
