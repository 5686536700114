import nunjucks from 'nunjucks'
import { WORKSPACE_DOCUMENT_TYPE_NAME } from '@common/interfaces/clients/document-type.interface'
import { Doc } from '@/db'
import { queryDoc, queryToDocsByField, queryUserDoc } from '@/db/docs/queries'
import { scm } from '@/contexts/schema'
import { getHtmlString } from './parseString'

export async function getDocAIContext(doc: Doc, value?: string) {
  const parent = await queryDoc({ _id: doc._parentId ?? '' })
  const user = await queryUserDoc(doc._userId)
  const schema = scm.getDocSchema(doc._type)

  const context = value || schema?.aiContext || ''

  const hasParent = doc._type !== WORKSPACE_DOCUMENT_TYPE_NAME && parent
  if (!hasParent || !user || !context || !schema) return ''

  const multilinkEntries = await Promise.all(
    schema.fields
      .filter((field) => field.type === 'multilink')
      .map(async (field) => {
        const { docs } = (await queryToDocsByField(doc._id, field.name)) ?? {}

        const titles = docs?.map((d) => d.title).join(', ') ?? []
        return [field.name, titles]
      }),
  )

  if (!multilinkEntries) return ''

  const links = Object.fromEntries(multilinkEntries)
  const description = getHtmlString(doc.fields.description as string)

  const scope = {
    doc: {
      ...doc,
      description,
    },
    parent,
    author: user.title,
    links,
  }

  return nunjucks.renderString(context, scope)
}

export async function getDocsWithAIContexts(docs: Doc[]) {
  return Promise.all(
    docs.map(async (doc) => {
      doc.aiContext = await getDocAIContext(doc)
      return doc
    }),
  )
}
