import { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { produce } from 'immer'
import { useFieldContext } from '@/contexts'
import { DataLinkParams, FieldValueType, FilterItemType } from '@/db'
import { FilterSearch } from './components/FilterSearch'
import { FilterSelect } from './components/FilterSelect'
import { FilterContext } from '@/contexts/filter'
import { scm } from '@/contexts/schema'
import { LinkFieldType } from '@common/interfaces/fields/link-field.interface'
import { FilterEnum } from './components/FilterEnum'
import { FilterBoolean } from '@/features/filter/components/FilterBoolean.tsx'
import { FilterNumber } from '@/features/filter/components/FilterNumber.tsx'

export type FilterOptionProps<T> = {
  filter: FilterItemType
  fieldSchema: T
}

export const Filter = ({ children }: PropsWithChildren) => {
  const { field } = useFieldContext<DataLinkParams>()
  const [filters, setFilters] = useState<Partial<Record<string, FieldValueType>>>({})
  const { showSearch, showSort, showFilters } = field.layout || {}

  const updateFilter = useCallback((fieldName: string, value: FieldValueType) => {
    setFilters((prevFilters) =>
      produce(prevFilters, (draft) => {
        draft[fieldName] = value
      }),
    )
  }, [])

  const removeFilter = useCallback((name: string) => {
    setFilters((prevFilters) =>
      produce(prevFilters, (draft) => {
        delete draft[name]
      }),
    )
  }, [])

  const clearFilters = useCallback(() => {
    setFilters({})
  }, [])

  const contextValue = useMemo(
    () => ({ filters, updateFilter, removeFilter, clearFilters }),
    [filters],
  )

  const showFilterBar = showSearch || showSort || showFilters

  const getFilterItem = (filter: FilterItemType) => {
    const fieldSchema = scm.getFieldSchema(field.filterDocType, filter.fieldName)
    if (!fieldSchema) return null

    switch (fieldSchema.type) {
      case 'status':
        return <FilterEnum key={filter.fieldName} filter={filter} fieldSchema={fieldSchema} />
      case 'multilink':
      case 'singlelink':
        return (
          <FilterSelect
            key={filter.fieldName}
            filter={filter}
            fieldSchema={fieldSchema as LinkFieldType}
          />
        )
      case 'boolean':
        return <FilterBoolean key={filter.fieldName} filter={filter} fieldSchema={fieldSchema} />
      case 'number':
        return <FilterNumber key={filter.fieldName} filter={filter} fieldSchema={fieldSchema} />
      default:
        return null
    }
  }

  return (
    <FilterContext.Provider value={contextValue}>
      {showFilterBar && (
        <div className="flex justify-between items-center gap-4">
          {showSearch && <FilterSearch />}
          {showFilters && (
            <div className="flex items-center gap-2 flex-wrap justify-end">
              {Array.isArray(field.filters) && field.filters.map((filter) => getFilterItem(filter))}
              {/* {showSort && <FilterSort />} */}
            </div>
          )}
        </div>
      )}
      {children}
    </FilterContext.Provider>
  )
}
