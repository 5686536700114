import { useMemo } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useLiveQuery } from 'dexie-react-hooks'
import Fuse from 'fuse.js'
import { db } from '@/db'
import { FUSE_SEARCH_OPTIONS } from '@/features/header'
import { usePath } from '@/hooks'
import { SmallRowCard } from '@/schema/cards'

export const SearchPage = () => {
  const { getDocPath } = usePath()
  const [params] = useSearchParams()
  const query = params.get('query')?.toLowerCase()

  const docs = useLiveQuery(() => db.ws.docs.toArray().catch(console.error), [])

  const fuse = new Fuse(docs ?? [], FUSE_SEARCH_OPTIONS)

  const results = useMemo(() => {
    if (!query) return []
    return fuse.search(query).map((result) => result.item)
  }, [query, docs])

  return (
    <div className="h-screen overflow-auto">
      <main className="nodoc-page max-md:px-5">
        <h1 className="text-heading mb-9">Search results for “{params.get('query')}”</h1>
        <div className="grid gap-1.5">
          {results?.map((doc) => (
            <Link aria-label={`link to ${doc.title}`} key={doc._id} to={getDocPath(doc)}>
              <SmallRowCard doc={doc} />
            </Link>
          ))}
        </div>
      </main>
    </div>
  )
}
