import { PropsWithChildren } from 'react'
import clsx from 'clsx'

interface CardDocTypeProps extends PropsWithChildren {
  hide?: boolean
  type?: string
  className?: string
}

export const CardDocType = ({ hide, className, type }: CardDocTypeProps) => {
  if (hide) return null

  return <p className={clsx('doc-type', className)}>{type}</p>
}
