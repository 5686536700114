import { createContext, CSSProperties, useContext } from 'react'
import type { Field, FieldData, FieldValueType, SomeField } from '@/db'

export interface FieldContextValue<F = SomeField, T = FieldValueType> {
  field: F & { id: string; styles?: CSSProperties }
  isEditable: boolean
  docValue: T | undefined
  value: T | undefined
  saveValue: (newValue: T) => void
  showSkeleton?: boolean
}

export const FieldContext = createContext<FieldContextValue<unknown, unknown> | object>({})

export function useFieldContext<F extends Field, T = FieldData>() {
  return useContext(FieldContext) as FieldContextValue<F, T>
}
