export const ACTION_KEYS = ['view', 'edit'] as const;
export type ACTIONS = (typeof ACTION_KEYS)[number];

export const ROLE_KEYS = ['viewer', 'editor', 'contributor'] as const;
export type ROLES = (typeof ROLE_KEYS)[number];

export const ROLE_ACTION_LOOKUP: { [key in ROLES]: ACTIONS[] } = {
  editor: ['edit', 'view'],
  viewer: ['view'],
  contributor: [],
};

export const EVERYONE_GROUP_ID = 'GROUP#EVERYONE';

interface PermissionBase {
  workspaceId: string;
  documentId: string;
  documentTypeId?: string;
  role?: string | ROLES;
  action?: ACTIONS;
  isDeleted?: boolean;
  _addedOn?: string;
  _modifiedOn?: string;
}

export interface DocumentPermission extends PermissionBase {
  key: string;
}

export interface DocumentPermissionEntry extends PermissionBase {
  _partId: string;
  _id: string;
}

export interface DocumentPermissionGroupEntry {
  _partId: string;
  _id: string;
  name: string;
  isAuto: boolean;
  permissions: string[];
  bundleKey?: string;
  _addedOn: string;
  _modifiedOn: string;
}
