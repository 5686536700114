import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { tryImg } from '@common/guards'
import { Block } from '@common/interfaces/fields/diagram-field.interface'
import { KeyValueData } from '@common/interfaces/fields/keyvalue-field.interface'
import { Doc } from '@/db'
import { usePath } from '@/hooks'
import { useDiagramContext } from '../hooks'
import { DiagramBlock } from './DiagramBlock'

interface DiagramDocBlockProps {
  doc: Doc
}

export const DiagramDocBlock = ({ doc }: DiagramDocBlockProps) => {
  const { settings } = useDiagramContext()
  const { axisX, axisY } = settings
  const { getDocPath } = usePath()
  const navigate = useNavigate()
  // todo fix the TypeScript "as KeyValueData" and "as number"
  const diagramData: KeyValueData = settings.diagramItemDataFieldName
    ? (doc.fields[settings.diagramItemDataFieldName] as KeyValueData)
    : {}

  const params = useMemo(() => {
    const width = (diagramData?.width as number) || 20
    const x = (diagramData?.x as number) || axisX.min
    const height = (diagramData?.height as number) || 20
    const y = -(diagramData?.y as number) || axisY.min

    const res: Block = { label: doc.title, x, y, width, height }

    const label = diagramData?.label as string
    const textColor = diagramData?.textColor as string
    const backgroundColor = diagramData?.backgroundColor as string
    const opacity = diagramData?.opacity as number
    const z = diagramData?.opacity as number

    if (label) {
      res.label = label
    }
    if (textColor) {
      res.textColor = textColor
    }
    if (backgroundColor) {
      res.backgroundColor = backgroundColor
    }
    if (opacity) {
      res.opacity = opacity
    }
    if (z) {
      res.z = z
    }
    res.showCoverImage = diagramData?.showCoverImage as boolean

    return res
  }, [doc])

  const handleClick = () => {
    navigate(getDocPath(doc))
  }
  const { image } = doc.fields

  return <DiagramBlock block={params} doc={doc} onClick={handleClick} image={tryImg(image)} />
}
