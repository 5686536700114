import { useState } from 'react'
import { HtmlParser } from '@/features/rte'
import { showUntitledText } from '@/utils/parseString'
import { CardProps } from '../cardsTypes'

export const TeamMemberCard = ({ doc, headerElements }: CardProps) => {
  const [hovered, setHovered] = useState(false)

  return (
    <div
      className="w-full h-full border rounded-md bg-neutral100"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={
        hovered
          ? { scale: '1.05', transition: 'all 0.3s ease', borderColor: '#d2d2d2' }
          : { borderColor: '#efefef', scale: '1', transition: 'all 0.3s ease' }
      }
    >
      <div className="relative p-3">
        {headerElements}
        <h4 className="text-focus">{showUntitledText(doc.title)}</h4>
        <HtmlParser className="pt-2.5">{doc.fields.description}</HtmlParser>
      </div>
    </div>
  )
}
