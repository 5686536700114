import { db } from '../db'
import { Doc } from '../dbTypes'

export async function queryClientWorkspaces(ids: string[]) {
  try {
    const workspaces = await db.workspaces
      .where('_id')
      .anyOf(ids)
      .toArray()
      .catch<undefined>(console.error)

    if (workspaces) {
      const clientWorkspaceList = await Promise.all(
        workspaces.map(async (workspace) => {
          const client = await db.clients.get(workspace._partId)
          return {
            client,
            workspace,
          }
        }),
      )

      return clientWorkspaceList
    }

    return undefined
  } catch (e) {
    window.Rollbar.error(e as Error)
    return undefined
  }
}

export async function getRelationIdsByFieldName(
  fieldName: string | undefined,
  docId: string,
  directionId: 'fromId' | 'toId',
) {
  const ids: string[] = []
  await db.ws.relations
    .where(directionId)
    .equals(docId)
    .and((item) =>
      directionId === 'fromId' ? item.fieldName === fieldName : item.reverseFieldName === fieldName,
    )
    .each((rel) => {
      ids.push(directionId === 'fromId' ? rel.toId : rel.fromId)
    })
  return ids
}

export async function queryDataByMultilink(fieldName: string, toIds: string[]) {
  try {
    const fromIds: string[] = []
    await db.ws.relations
      .where('toId')
      .anyOf(toIds)
      .and((item) => item.fieldName === fieldName)
      .each((rel) => {
        fromIds.push(rel.fromId)
      })

    const docs = await db.ws.docs.where('_id').anyOf(fromIds).toArray()

    return docs
  } catch (e) {
    window.Rollbar.error(e as Error)
    return undefined
  }
}

export async function queryMatchRelations(cols: Doc[], rows: Doc[]) {
  try {
    const data: { colId: string; rowId: string; isPrimary: boolean }[] = []

    await Promise.all(
      cols.map(async (col) =>
        Promise.all(
          rows.map(async (row) => {
            const rel1 = await db.ws.relations
              .where('[fromId+toId]')
              .equals([col._id, row._id])
              .first()

            const rel2 = await db.ws.relations
              .where('[fromId+toId]')
              .equals([row._id, col._id])
              .first()

            if (rel1 && rel2) {
              data.push({
                colId: col._id,
                rowId: row._id,
                isPrimary:
                  rel1.fieldName.startsWith('primary') && rel2.fieldName.startsWith('primary'),
              })
            }
          }),
        ),
      ),
    )

    return data
  } catch (e) {
    window.Rollbar.error(e as Error)
    return undefined
  }
}

export function queryUrlCache(name: string) {
  return db.ws.urls.get(name).catch(console.error)
}
