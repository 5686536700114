import { useMemo } from 'react'
import { format } from 'date-fns'
import { useLiveQuery } from 'dexie-react-hooks'
import get from 'lodash.get'
import { useDocContext } from '@/contexts'
import type { Doc, GroupDocsBy } from '@/db'
import { queryDocsByType } from '@/db/docs/queries.ts'
import { groupArr } from '@/utils/docsSort'
import { evaluator } from '@/utils/evaluator'

const getGroupLabel = (docDate: string, label: string) => {
  const date = new Date(docDate)

  switch (label) {
    case 'day':
      return format(date, 'do MMMM yyyy')
    case 'month':
      return format(date, 'MMMM yyyy')
    case 'year':
      return format(date, 'yyyy')
    default:
      return ''
  }
}

export function objToLabelArr<T>(groups?: Record<string, T[]>) {
  if (!groups) return
  return Object.entries(groups).map(([label, docs]) => ({ label, docs }))
}

export const useGroup = (docs: Doc[], group?: GroupDocsBy) => {
  const { user } = useDocContext()
  const docsByType =
    useLiveQuery(
      () => queryDocsByType(group?.byAncestor || group?.groupByChildren || group?.byLinkedDocument),
      [],
    ) ?? []

  const docsList = useLiveQuery(() => queryDocsByType(group?.byGrandAncestor), []) ?? []

  const groupsObj = useMemo(() => {
    if (!docs.length) return
    if (!group) return

    const {
      fieldName,
      customGroups,
      additionalFilter,
      groupByChildren,
      byAncestor,
      othersLabel = 'others',
      byGrandAncestor,
      byLinkedDocument,
    } = group

    if (groupByChildren || byAncestor) {
      const groupedDocsByParent = groupArr(docs, (docItem) => {
        const parentDoc = docsByType?.find((doc) => docItem._ancestors.includes(doc._id))
        if (!parentDoc) return

        if (byGrandAncestor) {
          const grandParentDoc = docsList?.find((doc) => parentDoc._ancestors.includes(doc._id))

          if (grandParentDoc) {
            return `${grandParentDoc.title} - ${parentDoc.title}`
          }
        }

        return parentDoc.title
      })

      const keys = Object.keys(groupedDocsByParent)
      if (!keys.length) {
        return { '': docs }
      }

      return groupedDocsByParent
    }

    if (customGroups) {
      const groupDetails = customGroups[fieldName]

      return groupDetails.reduce(
        (docsByGroup, detail) => {
          docsByGroup[detail.label] = docs.filter((doc) => evaluator(detail.filterBy, doc, user))

          return docsByGroup
        },
        {} as Record<string, Doc[]>,
      )
    }

    const additionalInfo = additionalFilter?.[fieldName]
    if ((fieldName === '_addedOn' || fieldName === 'date') && additionalInfo) {
      const { label, filterBy } = additionalInfo
      let filteredDocs = docs.filter((doc) => evaluator(filterBy, doc, user))

      if (fieldName === 'date') {
        filteredDocs = filteredDocs.sort((a, b) => {
          const dateA = new Date((a.fields?.date as string) ?? a._addedOn)
          const dateB = new Date((b.fields?.date as string) ?? b._addedOn)

          return Number(dateB) - Number(dateA)
        })
      }

      return groupArr(filteredDocs, (entity) => {
        const documentDate = entity.fields?.[fieldName] ?? entity._addedOn
        const groupLabel = getGroupLabel(documentDate as string, label)

        return groupLabel
      })
    }

    if (byLinkedDocument && docsByType.length && fieldName) {
      return docsByType.reduce(
        (docsByField, d) => {
          const docsWithLinkedField = docs.filter((entry) =>
            (entry.fields?.[fieldName] as string[])?.includes(d._id),
          )

          if (docsWithLinkedField.length) {
            docsByField[d._id] = docsWithLinkedField
          }

          return docsByField
        },
        {} as Record<string, Doc[]>,
      )
    }

    return groupArr(docs, (docItem) => {
      let groupKey = docItem.fields[fieldName] as string
      if (fieldName.includes('.')) {
        groupKey = get(docItem.fields, fieldName) as string
      }
      if (typeof groupKey === 'string') return groupKey
      return othersLabel
    })
  }, [docs, group, docsByType])

  return useMemo(() => {
    const groupsArr = objToLabelArr(groupsObj)

    if (group?.sort) {
      groupsArr?.sort((a, b) =>
        a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' }),
      )
    }

    return {
      groupsArr,
      groupsObj,
    }
  }, [groupsObj, group?.sort])
}
