import { createContext, useContext } from 'react'
import { WebsocketProvider } from 'y-websocket'
import * as Y from 'yjs'
import { YObject } from '../whiteboardTypes'

export type BoardContextValue<P = WebsocketProvider> = {
  yDoc: Y.Doc
  provider: P | null
  yObjects: Y.Map<YObject>
  getYObj: (id: string) => Y.Map<number>
}

export const BoardContext = createContext<BoardContextValue | null>(null)

export function useBoardContext() {
  return useContext(BoardContext) as BoardContextValue
}
