import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import { cn } from '@/utils/cn'

export const TooltipProvider = TooltipPrimitive.Provider

export const TooltipContainer = TooltipPrimitive.Root

export const TooltipTrigger = TooltipPrimitive.Trigger

export const TooltipPortal = TooltipPrimitive.Portal

export const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-md bg-neutral100 drop-shadow-md px-3 py-1.5 text-sm animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:bg-slate-50 dark:text-slate-900',
      className,
    )}
    {...props}
  />
))

export const Tooltip = ({
  children,
  content,
}: {
  children: React.ReactNode
  content: React.ReactNode
}) => (
  <TooltipProvider>
    <TooltipContainer>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipPortal>
        <TooltipContent sideOffset={8}>{content}</TooltipContent>
      </TooltipPortal>
    </TooltipContainer>
  </TooltipProvider>
)
TooltipContent.displayName = TooltipPrimitive.Content.displayName
