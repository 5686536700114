import { useMemo } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import {
  QueryEntry,
  QueryEntry2,
  QueryFieldType,
} from '@common/interfaces/fields/query-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { Doc } from '@/db'
import { ColumnItem, queryManager2 } from '@/db/common/QueryManager2'
import { queryDocsByType } from '@/db/docs/queries'
import { useSlr } from '@/store'
import { selectFilters } from '@/store/filters/selectors'
import { sumArr } from '@/utils/docsSort'

export function useQueryEntry2(query?: QueryEntry | QueryEntry2, currentDoc?: Doc) {
  const { field } = useFieldContext<QueryFieldType>()
  const { doc, user } = useDocContext()
  const filters = useSlr(selectFilters)
  const states = useLiveQuery(() => queryDocsByType('State'), [])
  const { dynamicFilter, mainFilter = '' } = query as QueryEntry2

  const rows = useLiveQuery(
    () => queryManager2.queryData(currentDoc ?? doc, user, query as QueryEntry2),
    [query, doc],
  )

  return useMemo(() => {
    if (!rows || !states) return { rows: [], filtered: [] }
    const filterSize = sumArr(Object.keys(filters), (key) => filters[key]?.length ?? 0)
    if (field.defaultHighlighted && filterSize === 0) return { rows, filtered: rows }

    let filtered = rows

    const filterData = (row: ColumnItem, relationFieldName: string, filterName: string) => {
      const filterIds = filters[filterName] as string[]
      const relations = row.columns[relationFieldName]
      if (!relations?.size) return false
      if (!filterIds?.length) return true
      const hasRelation = filterIds.some((id) => {
        if (id === states[0]?._parentId) {
          const hasState = states.some((item) => relations.has(item._id))
          if (hasState) return true
        }

        return relations.has(id)
      })
      return hasRelation
    }

    const mainRelationFieldName = Object.keys(dynamicFilter).find(
      (key) => dynamicFilter[key] === mainFilter,
    )
    if (mainFilter && mainRelationFieldName) {
      if (filters[mainFilter]?.length) {
        filtered = filtered.filter((row) => filterData(row, mainRelationFieldName, mainFilter))
      } else {
        filtered = []
      }
    }

    filtered = filtered.filter((row) =>
      Object.entries(dynamicFilter).every(([relationFieldName, filterName]) =>
        filterData(row, relationFieldName, filterName),
      ),
    )

    return { rows, filtered }
  }, [query, filters, rows])
}
