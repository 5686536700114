import { MultiValue } from 'react-select'
import { useQueryEntry } from '@/hooks'
import { Select } from '@/ui'
import { ChangeHandler, SelectOption } from '@/ui/Select'
import { useSort } from '@/schema/hooks'
import { useFilterState } from '@/contexts/filter'
import { useMemo } from 'react'
import { useFilterDefaultValue } from '../hooks/useFilterDefaultValue'
import { QueryEntry } from '@common/interfaces/fields/query-field.interface'
import { FilterOptionProps } from '../Filter'
import { LinkFieldType } from '@common/interfaces/fields/link-field.interface'

export const FilterSelect = ({ filter, fieldSchema }: FilterOptionProps<LinkFieldType>) => {
  const { placeholder, isMulti, defaultValue } = filter

  const query = useMemo<QueryEntry | undefined>(() => {
    if (filter.query) return filter.query

    if (fieldSchema.query) return fieldSchema.query

    return { types: fieldSchema.allowed ?? [], scope: 'global' }
  }, [fieldSchema, filter.query])

  const { docs: optionDocs } = useQueryEntry(query)
  const sortedDocs = useSort(optionDocs, filter.sort)
  const { updateFilter, removeFilter } = useFilterState()
  const { selectDefaultOption } = useFilterDefaultValue(defaultValue, (value) =>
    updateFilter(filter.fieldName, value),
  )

  const allOptions = useMemo(
    () =>
      sortedDocs.map<SelectOption>((item) => ({
        label: item.title,
        value: item._id,
      })),
    [sortedDocs],
  )

  const handleMultiChange = (newOptions: MultiValue<SelectOption> | null) => {
    if (!newOptions?.length) {
      return removeFilter(filter.fieldName)
    }

    updateFilter(
      filter.fieldName,
      newOptions.map((v) => v.value),
    )
  }

  const handleSingleChange: ChangeHandler<SelectOption> = (option, actionMeta) => {
    const { action } = actionMeta
    if (action === 'clear' || !option) {
      removeFilter(filter.fieldName)
      return
    }

    updateFilter(filter.fieldName, [option.value])
  }

  const placeholderText = placeholder ?? fieldSchema.niceName

  if (!optionDocs.length) return null

  return (
    <div className="grid grid-flow-col gap-1">
      <Select
        placeholder={placeholderText}
        isMulti={isMulti}
        className="min-w-[160px]"
        options={allOptions}
        defaultValue={selectDefaultOption(allOptions)}
        isClearable
        onChange={(value, actionMeta) =>
          isMulti
            ? handleMultiChange(value as MultiValue<SelectOption>)
            : handleSingleChange(value as SelectOption, actionMeta)
        }
      />
    </div>
  )
}
