import { toast } from 'react-toastify'
import { io } from '@/api'
import { AncestorsUpdatePayload, deleteDocApi } from '@/api/docs'
import { deleteRelationApi } from '@/api/relations'
import { createTelemetryApi } from '@/api/workspaces'
import { scm } from '@/contexts/schema'
import { store } from '@/store'
import { keyHash } from '@/utils'
import { db } from '../db'
import { BatchData, Doc, FieldValueType } from '../dbTypes'
import { putDocument } from './mutations/fetchDocsMutation'
import { saveFieldLocal } from './mutations/updateDocsMutation'
import { fetchDocumentPermissions } from '../permissions/sync'

export { createDoc, createHomeDoc, convertDocTo } from './mutations/createDocsMutation'
export type { CreateDocPL } from './mutations/createDocsMutation'
export { saveField, changeParent } from './mutations/updateDocsMutation'
export type { ChangeParentFormData } from './mutations/updateDocsMutation'
export {
  fetchDocs,
  fetchUpdatedDocs,
  fetchDocsDebounced,
  fetchGroupsDocs,
} from './mutations/fetchDocsMutation'

export async function deleteDoc(doc: Doc) {
  const fieldName = scm.getFieldSchema(doc._type, 'children')?.name ?? 'children'

  try {
    await deleteDocApi({ _id: doc._id, fieldName })

    const { activityLabel } = store.getState()

    await createTelemetryApi({
      documentId: doc._id,
      documentType: doc._type,
      documentTitle: doc.title,
      action: 'delete',
      label: activityLabel?.label,
    })
    const rels = await db.ws.relations.where({ toId: doc._id }).toArray()
    const relIds = rels.map((rel) => rel._id)
    await db.ws.transaction('rw', db.ws.relations, db.ws.docs, () => {
      db.ws.docs.delete(doc._id)
      db.ws.relations.bulkDelete(relIds)
    })
    await Promise.all(rels.map((rel) => deleteRelationApi(rel._id)))
    return true
  } catch (error) {
    toast.error(error as string)
    return false
  }
}

export function listenDocs() {
  io.on('mergeDocumentCreate', async (doc: Doc) => {
    putDocument(doc, true)
    fetchDocumentPermissions(doc._id)
  })

  io.on('mergeDocumentDelete', (doc: Doc) => {
    db.ws.docs.delete(doc._id)
  })

  io.on('mergeBatchUpdateDocumentAncestory', (payload: BatchData<AncestorsUpdatePayload>) => {
    db.ws.transaction('rw', db.ws.docs, async () => {
      Promise.all(
        payload.batch.map(async ({ _id, ancestors = [], _parentId }) => {
          const doc = await db.ws.docs.get(_id)
          if (!doc) return

          db.ws.docs.update(doc._id, {
            _parentId,
            _ancestors: ancestors.map((ancestor) => ancestor.documentId) ?? doc._ancestors,
          })
        }),
      )
    })
  })

  io.on('mergeFieldSave', async (data: { _id: string; status: boolean; value: FieldValueType }) => {
    const { docId, fieldName } = keyHash.parseFieldId(data._id)
    const doc = await db.ws.docs.get(docId)
    if (!doc) return

    saveFieldLocal(doc, { name: fieldName, value: data.value })
  })
}
