import { useMemo } from 'react'
import clsx from 'clsx'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'
import { useFieldContext } from '@/contexts'
import { Doc } from '@/db'
import { HtmlParser } from '@/features/rte'
import { Preview } from '@/ui'
import css from '../card.module.css'
import { CardProps } from '../cardsTypes'

export const HorizontalPersonaCard = ({ doc, children, showQuote, headerElements }: CardProps) => {
  const { field } = useFieldContext<ChildrenFieldType, Doc[]>()
  const quote = doc.fields['Persona Quote']
  const fieldsToDisplay = field?.layout?.card?.fieldsToDisplay
  const imageFit = field.layout?.card?.imageFit
  const fieldElements = useMemo(() => {
    if (!fieldsToDisplay) {
      return []
    }

    const elements: JSX.Element[] = []

    fieldsToDisplay.forEach((name) => {
      const docField = doc.fields[name]

      if (!docField) {
        return
      }

      elements.push(
        <p key={name} className="text-caption text-neutral600 pt-1 break-words whitespace-pre-wrap">
          {docField as string}
        </p>,
      )
    })

    return elements
  }, [fieldsToDisplay])

  return (
    <div
      className={clsx('relative flex gap-x-4 h-full border border-neutral300 rounded-md', {
        [css.carouselHorizontalCard]: field.isCarousel,
      })}
    >
      <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>
      <Preview
        fit={imageFit ? 'contain' : 'cover'}
        image={doc.fields?.image}
        icon="user"
        iconSize={26}
        className="w-20 h-full rounded-md object-top"
      />

      <div className="pt-2 pb-2 pr-3">
        <p title={doc.title} className="text-focus break-words whitespace-pre-wrap">
          {doc.title}
        </p>
        {fieldElements}
        {showQuote && <HtmlParser className={css.personaHtml}>{quote}</HtmlParser>}
        {children}
      </div>

      {children}
    </div>
  )
}
