import { memo } from 'react'
import { Avatar, AvatarGroup } from '@nextui-org/react'
import { usePresence } from '@/schema/hooks/usePresence'
import { Tooltip } from '@/ui'
import { CollaboratorItem } from './CollaboratorItem'

export const Collaborators = memo(() => {
  const { collaborators, removeCollaborator } = usePresence()

  if (!collaborators.length) {
    return null
  }

  return (
    <AvatarGroup isBordered>
      {collaborators.length <= 4 ? (
        collaborators.map((collaborator) => (
          <CollaboratorItem
            key={collaborator.email}
            collaborator={collaborator}
            removeCollaborator={removeCollaborator}
          />
        ))
      ) : (
        <>
          {collaborators.slice(0, 4).map((collaborator) => (
            <CollaboratorItem
              key={collaborator.email}
              collaborator={collaborator}
              removeCollaborator={removeCollaborator}
            />
          ))}

          <Tooltip
            content={
              <div className="p-4">
                {collaborators.slice(-(collaborators.length - 4)).map((collaborator) => (
                  <div key={collaborator.name} className="flex items-center mb-1.5">
                    <Avatar
                      style={{ backgroundColor: collaborator.color }}
                      className="w-6 h-6 uppercase"
                      name={collaborator.name?.[0]}
                    />
                    <p className="pl-3">{collaborator.name}</p>
                  </div>
                ))}
              </div>
            }
          >
            <Avatar className="w-6 h-6 uppercase" name={(collaborators.length - 4).toString()} />
          </Tooltip>
        </>
      )}
    </AvatarGroup>
  )
})

Collaborators.displayName = 'Collaborators'
