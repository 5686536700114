export const FIELD_TYPES = [
  'text',
  'number',
  'boolean',
  'image',
  'color',
  'status',
  'html',
  'singlelink',
  'multilink',
  'reverselink',
  'children',
  'query',
  'accordion',
  'tabs',
  'banner',
  'dashboard',
  'diagram',
  'table',
  'keyValue',
  'whiteboard',
  'geo',
]
