import React from 'react'
import clsx from 'clsx'
import { nanoid } from 'nanoid'
import styles from './Skeleton.module.css'
import { SkeletonProps } from './types'
import {
  composeSkeletonElementStyle,
  defaultAnimationDuration,
  defaultColor,
  defaultHighlightColor,
} from './utils'

export const Skeleton: React.FC<SkeletonProps> = ({
  animation = 'pulse',
  count = 1,
  duration = defaultAnimationDuration,
  color = defaultColor,
  highlightColor = defaultHighlightColor,
  circle = false,
  skeletonSizes,
  className: customClassName,
  width,
  height,
  wrapperClassName,
  wrapperDirection = 'column',
  wrapperTag: WrapperTag = 'div',
}) => {
  const _skeletonClassName = clsx(
    styles.skeleton,
    {
      [styles.pulseSkeleton]: animation === 'pulse',
      [styles.waveSkeleton]: animation === 'wave',
      [styles.gradientSkeleton]: animation === 'gradient',
    },
    customClassName,
  )

  const _wrapperClassName = clsx(
    styles.skeletonWrapper,
    {
      [styles.skeletonArray]: skeletonSizes && skeletonSizes?.length > 0,
      [styles.skeletonWrapperDirection]: wrapperDirection === 'row',
    },
    wrapperClassName,
  )

  const elements = Array.from({ length: count }).map((_el, i) => {
    const style = composeSkeletonElementStyle(
      width,
      height,
      color,
      highlightColor,
      duration,
      circle,
    )

    // eslint-disable-next-line react/no-array-index-key
    const SkeletonElement = <span key={i} className={_skeletonClassName} style={{ ...style }} />

    const SkeletonSizes = skeletonSizes?.map(
      ({ w, h }): JSX.Element => (
        <span
          key={nanoid(4)}
          className={_skeletonClassName}
          style={{
            ...style,
            width: w,
            height: h,
            borderRadius: w && h && circle ? '50%' : 'none',
          }}
        />
      ),
    )

    return skeletonSizes && skeletonSizes.length > 0 ? SkeletonSizes : SkeletonElement
  })

  return <WrapperTag className={_wrapperClassName}>{elements}</WrapperTag>
}
