import { useEffect } from 'react'
import clsx from 'clsx'
import { useDocContext } from '@/contexts'
import { updateDocumentReadAccess } from '@/db/permissions/sync'
import { useSlr } from '@/store'
import { selectAccount } from '@/store/account'
import { SchemaLayout } from './SchemaLayout'

export const Home = () => {
  const { doc } = useDocContext()
  const account = useSlr(selectAccount)

  useEffect(() => {
    if (account._relationType === 'admin' && doc._read_access !== 'everyone') {
      updateDocumentReadAccess(doc._id, 'everyone')
    }
  }, [doc._id, account.group])

  return (
    <main className={clsx('pt-0 max-lg:px-5')}>
      <SchemaLayout />
    </main>
  )
}
