import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLiveQuery } from 'dexie-react-hooks'
import {
  DocumentPermissionGroupEntry,
  ROLES,
} from '@common/interfaces/documents/permission.interface'
import { queryDocsWithPermissions } from '@/db/docs/queries'
import { usePath } from '@/hooks'
import { keyHash } from '@/utils'
import { Key } from '@react-types/shared'
import { Autocomplete, AutocompleteItem } from '@nextui-org/react'
import { groupArr } from '@/utils/docsSort'
import { Icon, Tooltip } from '@/ui'
import { IconName } from '@/ui/Icon'

interface UserPermissionGroupProps {
  groups: DocumentPermissionGroupEntry[]
}

const roleIcons: Record<ROLES, IconName> = {
  editor: 'pencil',
  contributor: 'user',
  viewer: 'eye',
}

const PermissionsStatus = ({ permissions }: { permissions: string[] }) => {
  const roles = useMemo(
    () =>
      Array.from(
        new Set(permissions.map((permission) => keyHash.parsePermission(permission).role)),
      ),
    [permissions],
  )

  return (
    <Tooltip
      content={
        <div className="p-2 rounded-md bg-neutral200 gap-1">
          {permissions.map((permission) => (
            <p key={permission}>{permission}</p>
          ))}
        </div>
      }
    >
      <div className="float-right flex gap-1 justify-end relative group">
        {roles.map((role) => (
          <Icon key={role} name={roleIcons[role]} size={16} />
        ))}
      </div>
    </Tooltip>
  )
}

export const DocsPermissionGroup = ({ groups }: UserPermissionGroupProps) => {
  const [groupId, setGroupId] = useState<Key>()
  const { getDocPath } = usePath()

  const group = useMemo(() => groups.find((group) => group._id === groupId), [groupId, groups])
  const groupPermissions = useMemo(() => {
    if (!group) return []

    return group.permissions.filter((id) => keyHash.parsePermission(id).documentId)
  }, [group])

  const entries = useLiveQuery(() => queryDocsWithPermissions(groupPermissions), [groupPermissions])
  const entriesGroups = useMemo(
    () =>
      groupArr(entries, (entry) => entry.doc._type, true).sort(([labelA], [labelB]) =>
        labelA.localeCompare(labelB, undefined, { numeric: true, sensitivity: 'base' }),
      ),
    [entries],
  )

  return (
    <div>
      <Autocomplete
        size="sm"
        selectedKey={groupId}
        placeholder="Select a group"
        onSelectionChange={setGroupId}
        className="mb-4 w-1/3"
      >
        {groups.map((option) => (
          <AutocompleteItem key={option._id} value={option._id}>
            {option.name}
          </AutocompleteItem>
        ))}
      </Autocomplete>
      {group && (
        <div className="grid gap-2">
          {entriesGroups?.map(([type, list]) => (
            <section key={type}>
              <h3 className="doc-type mb-1">{type}</h3>
              <div className="grid grid-cols-4 gap-3 py-3 items-end">
                {list.map((entry) => (
                  <Link
                    key={entry.doc._id}
                    to={getDocPath(entry.doc)}
                    className="p-2 rounded-xl h-full bg-blue50"
                  >
                    <PermissionsStatus permissions={entry.permissions} />
                    <p className="text-focus">{entry.doc.title}</p>
                  </Link>
                ))}
              </div>
            </section>
          ))}
        </div>
      )}
    </div>
  )
}
