import { useMemo, useRef } from 'react'
import { $generateNodesFromDOM } from '@lexical/html'
import { $insertNodes, LexicalEditor } from 'lexical'
import { WebsocketProvider } from 'y-websocket'
import * as Y from 'yjs'
import { HtmlFieldType } from '@common/interfaces/fields/html-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { userColors } from '@/ui/theme'
import { random } from '@/utils'
import { useRteContext } from '../../hooks/useRteContext'
import { CollabWrapper } from './CollabWrapper'

export const CollabPlugin = () => {
  const { user } = useDocContext()
  const { containerRef } = useRteContext()
  const { field, value } = useFieldContext<HtmlFieldType, string>()
  const yDoc = useMemo(() => new Y.Doc(), [])
  const providerRef = useRef<WebsocketProvider | null>(null)
  const id = encodeURIComponent(field?.id ?? 'doc')

  const initialEditorState = (ed: LexicalEditor) => {
    if (!value) return

    const parser = new DOMParser()
    const dom = parser.parseFromString(value, 'text/html')
    const nodes = $generateNodesFromDOM(ed, dom)
    $insertNodes(nodes)
  }

  const cursorColor = useMemo(
    () => user?.color ?? userColors[random(0, userColors.length)],
    [user?.color],
  )

  return (
    <CollabWrapper
      collabId={id}
      username={user?.name}
      yDoc={yDoc}
      ref={providerRef}
      cursorColor={cursorColor}
      cursorContainerRef={containerRef}
      initialEditorState={initialEditorState}
    />
  )
}
