import { Doc } from '@/db'
import { createDoc } from '@/db/docs/sync'
import { usePath } from '@/hooks'
import { Button } from '@/ui'
import { MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

interface SidebarLinkAddProps {
  addType: string
  doc: Doc
}

export const SidebarLinkAdd = ({ addType, doc }: SidebarLinkAddProps) => {
  const navigate = useNavigate()
  const { getDocPath } = usePath()

  const onClick = async (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const newDoc = await createDoc({ type: addType, parentDoc: doc })
    if (newDoc) {
      navigate({ pathname: getDocPath(newDoc), search: `?edit` })
    }
  }

  return (
    <Button
      variant="outline"
      icon="plus"
      className="w-5 h-5 rounded-md"
      tooltip={`Add new ${addType}`}
      onClick={onClick}
    />
  )
}
