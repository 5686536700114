import { ImageFieldData } from '@/db'
import { useImage } from '@/features/files'

interface CoverAuthorProps {
  image: ImageFieldData
}

export const CoverAuthor = ({ image }: CoverAuthorProps) => {
  const img = useImage(image)

  if (image.source !== 'unsplash' || !img?.author) return null

  if (img?.authorUrl) {
    return (
      <a
        href={`${img.authorUrl}?utm_source=hypha&utm_medium=referral`}
        target="_blank"
        className="bg-neutral900 text-xs text-white font-bold p-1 opacity-70 rounded"
        rel="noreferrer"
      >
        {img.author}, Unsplash
      </a>
    )
  }

  return (
    <figcaption className="bg-neutral900 text-xs font-bold text-white p-1 opacity-70 rounded">
      {img.author}, Unsplash
    </figcaption>
  )
}
