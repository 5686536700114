import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import { useLiveQuery } from 'dexie-react-hooks'
import { queryDoc } from '@/db/docs/queries'
import { usePath } from '@/hooks'
import { useSlr } from '@/store'
import { selectActiveDocId } from '@/store/docs'
import { Breadcrumb } from './Breadcrumb'
import css from './breadcrumbs.module.css'

export const Breadcrumbs = () => {
  const { workspacePath } = usePath()
  const activeDocId = useSlr(selectActiveDocId)
  const doc = useLiveQuery(() => queryDoc({ _id: activeDocId }), [activeDocId])

  return (
    <ul className={css.breadcrumbs}>
      <NavLink
        to={workspacePath}
        end
        className={({ isActive }) => clsx(`flex items-center`, isActive && 'opacity-0')}
      >
        Home
      </NavLink>
      {doc && <Breadcrumb level={1} doc={doc} activeDocId={activeDocId} />}
    </ul>
  )
}
