import { useMemo, useState } from 'react'
import { Input, Tab, Tabs } from '@nextui-org/react'
import { Button } from '@/ui'
import type { Key } from '@react-types/shared'
import { useLiveQuery } from 'dexie-react-hooks'
import { Files, Users } from 'lucide-react'
import { queryPermissionGroups } from '@/db/permissions/queries'
import { createPermissionGroup } from '@/db/permissions/sync'
import { queryAllUsers } from '@/db/users/queries'
import { DocsPermissionGroup } from './components/DocsPermissionGroup'
import { UserPermissionGroup } from './components/UserPermissionGroup'

export const PermissionsSettings = () => {
  const [selected, setSelected] = useState<Key>('users')
  const groups = useLiveQuery(() => queryPermissionGroups(), [])
  const users = useLiveQuery(() => queryAllUsers(), []) ?? []

  const [loading, setLoading] = useState(false)
  const [newGroupName, setNewGroupName] = useState('')

  const createNewGroup = async () => {
    setLoading(true)
    await createPermissionGroup(newGroupName)
    setNewGroupName('')
    setLoading(false)
  }

  const userGroups = useMemo(
    () =>
      groups?.map((group) => ({
        ...group,
        users: users.filter((user) => user.permissionGroups?.includes(group._id)),
      })),
    [groups, users],
  )

  return (
    <div className="grid gap-5">
      <div className="flex gap-1 items-center w-1/2">
        <Input
          size="sm"
          isDisabled={loading}
          type="text"
          placeholder="New group"
          onChange={(e) => setNewGroupName(e.target.value)}
        />
        <Button
          icon="plus"
          onClick={createNewGroup}
          disabled={newGroupName.length < 2}
          loading={loading}
        >
          Add Group
        </Button>
      </div>

      <Tabs aria-label="tabs" selectedKey={selected} onSelectionChange={setSelected}>
        <Tab
          key="users"
          title={
            <div className="flex items-center space-x-2">
              <Users size={16} />
              <span>Users</span>
            </div>
          }
        />
        <Tab
          key="docs"
          title={
            <div className="flex items-center space-x-2">
              <Files size={16} />
              <span>Documents</span>
            </div>
          }
        />
      </Tabs>

      {selected === 'users'
        ? userGroups?.map((group) => (
            <UserPermissionGroup
              key={group._id}
              group={group}
              users={group.users}
              allUsers={users}
            />
          ))
        : groups && <DocsPermissionGroup groups={groups} />}
    </div>
  )
}
