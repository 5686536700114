import { useState } from 'react'
import { queryAllDocs } from '@/db/docs/queries'
import { scm } from '@/contexts/schema'
import { Button } from '@/ui'
import { sequentially } from '@/utils'
import { getField } from '@/utils/fields'
import { parallel } from '@/utils/promises'
import { saveFieldApi } from '@/api/docs'

export const FixLinkFields = () => {
  const [loading, setLoading] = useState(false)

  const fixLinkFields = async () => {
    const docs = await queryAllDocs()
    if (!docs?.length) return

    setLoading(true)
    await sequentially(docs, async (doc) => {
      const docSchema = scm.getDocSchema(doc._type)
      if (!docSchema) return

      await parallel(docSchema.fields, async (field) => {
        if (!(field.type === 'singlelink' || field.type === 'multilink')) return
        const fieldValue = getField<string[]>(field.name, doc.fields)
        if (!fieldValue?.length) return

        return saveFieldApi(doc._id, field.name, [])
      })
    })
    setLoading(false)
  }

  return (
    <Button
      variant="ghost"
      className="max-[400px]:w-full"
      loading={loading}
      onClick={fixLinkFields}
    >
      Fix link fields
    </Button>
  )
}
