import { useLiveQuery } from 'dexie-react-hooks'
import { querySchema } from '@/db/workspaces/queries'

const data = {
  name: [
    { value: 'home', label: 'home' },
    { value: 'section', label: 'section' },
    { value: 'single', label: 'single' },
  ],
  theme: [{ value: 'rounded', label: 'Rounded' }],
  spans: [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
  ],
  classes: [
    { value: 'full', label: 'Full' },
    { value: 'white', label: 'White' },
    { value: 'title', label: 'Title' },
    { value: 'right', label: 'Right' },
  ],
  card_type: [
    { value: 'default', label: 'default' },
    { value: 'horizontal', label: 'horizontal' },
    { value: 'persona', label: 'persona' },
    { value: 'metric', label: 'metric' },
    { value: 'colorful', label: 'colorful' },
    { value: 'listItem', label: 'listItem' },
    { value: 'listItem2Rows', label: 'listItem2Rows' },
    { value: 'oneFourth', label: 'oneFourth' },
    { value: 'noImage', label: 'noImage' },
    { value: 'goalCard', label: 'goalCard' },
    { value: 'flipCard', label: 'flipCard' },
  ],
  footerElements: [
    { value: 'avatar', label: 'avatar' },
    { value: 'likes', label: 'likes' },
    { value: 'comments', label: 'comments' },
  ],
  actionFields: [
    { value: 'like', label: 'like' },
    { value: 'comment', label: 'comment' },
  ],
  field: [
    { value: 'text', label: 'text' },
    { value: 'number', label: 'number' },
    { value: 'image', label: 'image' },
    { value: 'checkbox', label: 'checkbox' },
    { value: 'color', label: 'color' },
  ],
  scope: [
    { value: 'global', label: 'global' },
    { value: 'context', label: 'context' },
  ],
  resultType: [
    { value: 'count', label: 'count' },
    { value: 'sum', label: 'sum' },
    { value: 'dash', label: 'dash' },
    { value: 'distinctCount', label: 'distinctCount' },
  ],
  source: [{ value: 'aiRecommended', label: 'aiRecommended' }],
  relationFilter_type: [
    { value: 'multilink', label: 'multilink' },
    { value: 'singlelink', label: 'singlelink' },
    { value: 'reverselink', label: 'reverselink' },
    { value: 'children', label: 'children' },
  ],
}

export const useGetOptions = (name) => {
  const schema = useLiveQuery(() => querySchema(), [])

  if ((name === 'allowed' || name === 'types' || name === 'docTypes') && schema) {
    return schema.map((entity) => ({ label: entity.name, value: entity.name }))
  }

  if (name && data[name]) {
    return data[name]
  }

  return []
}
