import React, { useMemo, useState } from 'react'
import { MultiValue } from 'react-select'
import { Button } from '@/ui'
import keys from 'lodash.keys'
import { DocumentImportData, UserData } from '@common/interfaces/clients/import-data.interface'
import { db } from '@/db'
import { queryAllDocs, queryAllowedDocs } from '@/db/docs/queries'
import { queryAllInteractions } from '@/db/interactions/queries'
import { queryAllRelations } from '@/db/relations/queries'
import { queryAllUsers } from '@/db/users/queries'
import { useSchemaState } from '@/contexts/schema'
import { Select } from '@/ui'
import { SelectOption } from '@/ui/Select'
import { toUnderscore } from '@/utils/parseString'

export const ExportDataSettings: React.FC = () => {
  const allSchema = useSchemaState((state) => state.allSchema)
  const [isGenerating, setIsGenerating] = useState(false)
  const [docTypes, setDocTypes] = useState<MultiValue<SelectOption>>([])
  const [resultType, setResultType] = useState<'all' | 'relations' | 'docType' | 'interactions'>(
    'all',
  )

  const typeOptions = useMemo(
    () => allSchema.map((item) => ({ label: item.niceName, value: item.name })),
    [allSchema],
  )

  const donwloadJson = <T extends DocumentImportData | UserData[]>(
    data: T,
    name: string = resultType,
  ) => {
    const json = JSON.stringify(data, null, 4)
    const blob = new Blob([json], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    const fileName = `${toUnderscore(db.activeWorkspace.name)}-${name}-${new Date().toISOString()}`
    a.download = `export-${fileName}.json`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  const exportData = async () => {
    const isALl = resultType === 'all'
    setIsGenerating(true)

    const data: DocumentImportData = {
      docs: [],
      fields: [],
      relations: [],
      interactions: [],
    }

    if (isALl || resultType === 'docType') {
      const allowedTypes = docTypes.map((item) => item.value)

      const docs = await (resultType === 'docType'
        ? queryAllowedDocs(allowedTypes)
        : queryAllDocs())

      docs?.forEach((doc) => {
        data.docs.push({
          workspaceId: doc._partId,
          parentId: doc._parentId,
          secondaryId: doc._id,
          title: doc.title,
          userId: doc._userId,
          type: doc._type,
          createdOn: doc._addedOn,
          modifiedOn: doc._modifiedOn,
        })

        keys(doc.fields).forEach((key) => {
          if (key === 'title') return

          data.fields.push({
            secondaryId: doc._id,
            fieldName: key,
            value: doc.fields[key],
          })
        })
      })
    }

    if (isALl || resultType === 'relations') {
      const relations = await queryAllRelations()

      relations?.forEach((rel) => {
        data.relations.push({
          workspaceId: rel._partId,
          secondaryId: rel._id,
          fromId: rel.fromId,
          toId: rel.toId,
          fromDocType: rel.fromDocType,
          toDocType: rel.toDocType,
          relationType: rel.relationType,
          fieldName: rel.fieldName,
          reverseFieldName: rel.reverseFieldName,
          createdOn: rel._addedOn,
          modifiedOn: rel._modifiedOn,
        })
      })
    }

    if (isALl || resultType === 'interactions') {
      const interactions = await queryAllInteractions()

      interactions?.forEach((interaction) => {
        data.interactions.push({
          secondaryId: interaction._id,
          type: interaction._type,
          value: interaction.value,
          refId: interaction._refId,
          docId: interaction._partId,
          createdOn: interaction._addedOn,
          modifiedOn: interaction._modifiedOn,
        })
      })
    }

    donwloadJson(data)
    setIsGenerating(false)
  }

  const exportUsers = async () => {
    const users = await queryAllUsers()

    if (users) {
      const userData = users.map<UserData>((user) => ({
        secondaryId: user._id,
        email: user.email,
        name: user.name,
      }))

      donwloadJson(userData, 'users')
    }
  }

  return (
    <div className="grid gap-4">
      <div className="flex gap-3">
        <label className="flex gap-1">
          <input
            type="radio"
            name="import"
            checked={resultType === 'all'}
            onChange={() => setResultType('all')}
          />
          <span>All data</span>
        </label>
        <label className="flex gap-1">
          <input
            type="radio"
            name="import"
            checked={resultType === 'relations'}
            onChange={() => setResultType('relations')}
          />
          <span>Only relations</span>
        </label>
        <label className="flex gap-1">
          <input
            type="radio"
            name="import"
            checked={resultType === 'docType'}
            onChange={() => setResultType('docType')}
          />
          <span>By type</span>
        </label>
      </div>
      {resultType === 'docType' && (
        <Select placeholder="document type" options={typeOptions} isMulti onChange={setDocTypes} />
      )}
      <div className="flex gap-2 justify-start">
        <Button onClick={exportData} loading={isGenerating}>
          Export Data
        </Button>
        <Button onClick={exportUsers} loading={isGenerating}>
          Export Users
        </Button>
      </div>
    </div>
  )
}
