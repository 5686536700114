import { BooleanFieldType } from '@common/interfaces/fields/boolean-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { FieldContainer } from '@/schema'
import { FieldComponent } from '../fieldType'
import { Pin } from './components/Pin'

export const BooleanField: FieldComponent = () => {
  const { field, value, saveValue, isEditable } = useFieldContext<BooleanFieldType, boolean>()
  const { doc } = useDocContext()
  const changeHandler = () => {
    if (!isEditable) {
      return
    }
    saveValue(!value)
  }

  if (field.viewComponent === 'pin') {
    return (
      <FieldContainer>
        <Pin doc={doc} isEditMode={isEditable} />
      </FieldContainer>
    )
  }

  return (
    <FieldContainer className="flex items-center gap-2">
      <label htmlFor={field.name} className="grid">
        <input
          aria-label={field.name}
          id={field.name}
          type="checkbox"
          name={field.name}
          disabled={!isEditable}
          checked={value}
          onChange={changeHandler}
        />
      </label>
    </FieldContainer>
  )
}
