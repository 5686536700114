import { MouseEvent, useMemo } from 'react'
import { Chip } from '@nextui-org/react'
import { LinkFieldType } from '@common/interfaces/fields/link-field.interface'
import { QueryEntry } from '@common/interfaces/fields/query-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { Doc } from '@/db'
import { SingleLink } from '@/fields/multilink/components/SingleLink.tsx'
import { usePath, useQueryEntry, useToggle } from '@/hooks'
import { FieldContainer } from '@/schema'
import { DocsList, TinyPersonaCard } from '@/schema/cards'
import { Button } from '@/ui'
import { FieldComponent } from '../fieldType'
import { MultiLinkRecommended } from './components/MultiLinkRecommended'
import { MultiLinkSelect } from './components/MultiLinkSelect'
import { queryDocList } from '@/db/docs/queries'
import { useLiveQuery } from 'dexie-react-hooks'
import { useNavigate } from 'react-router-dom'
import { useSort } from '@/schema/hooks'

export interface MultiLinkChildProps {
  relatedDocs: Doc[]
  allowedDocs: Doc[]
  relatedIdSet: Set<string>
  addRelation: (toDoc: Doc) => void
  addRelationList: (toDocs: Doc[]) => void
  deleteRelation: (toDocId: string, event?: MouseEvent<HTMLButtonElement>) => void
  clearRelations: () => void
}

export const MultiLinkField: FieldComponent = () => {
  const { doc, isCompound } = useDocContext()
  const navigate = useNavigate()
  const { field, value, saveValue, showSkeleton, isEditable } = useFieldContext<
    LinkFieldType,
    string[]
  >()
  const isRightBarArea = isCompound || field.area === 'right-sidebar'
  const { getDocPath } = usePath()

  const { ...chipStyles } = field.styles ?? {}

  const query = useMemo<QueryEntry>(() => {
    if (!isEditable) return { types: [] }
    if (field.query) return field.query

    return { types: field.allowed ?? [], scope: 'global' }
  }, [field.query, isEditable])
  const { docs: allDocs } = useQueryEntry(query)
  const queryDocs = useSort(allDocs, field.sort)

  const valueSet = useMemo(() => new Set(value), [value])

  const allowedDocs = useMemo(
    () => queryDocs.filter((el) => !valueSet.has(el._id)),
    [queryDocs, valueSet],
  )

  const data = useLiveQuery(() => queryDocList(value ?? []), [value]) ?? []
  const docs = useSort(data)

  const [isOpened, { on, off }] = useToggle()

  const updateField = () => {
    saveValue(Array.from(valueSet))
  }

  const addRelation = (toDoc: Doc) => {
    valueSet.add(toDoc._id)
    updateField()
  }

  const addRelationList = (toDocs: Doc[]) => {
    toDocs.forEach((toDoc) => valueSet.add(toDoc._id))
    updateField()
  }

  const deleteRelation = (toDocId: string, event?: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()

    valueSet.delete(toDocId)
    updateField()
  }

  const clearRelations = () => {
    valueSet.clear()
    updateField()
  }

  if (!isEditable && !showSkeleton && !docs.length) return null

  const childProps: MultiLinkChildProps = {
    relatedDocs: docs,
    allowedDocs,
    relatedIdSet: valueSet,
    addRelation,
    addRelationList,
    deleteRelation,
    clearRelations,
  }

  if (field.type === 'singlelink' && isEditable) {
    return <SingleLink docs={queryDocs} relatedDocs={docs} />
  }

  if (!isEditable && !docs.length) {
    return null
  }
  const isSelectView = field.viewComponent === 'select' || isRightBarArea

  return (
    <FieldContainer className={isSelectView ? 'w-full' : 'grid gap-7'}>
      {isSelectView ? (
        <div className="flex flex-wrap gap-1">
          {docs.map((item) => {
            if (item._type === 'Persona') {
              return (
                <TinyPersonaCard
                  key={item._id}
                  isEditMode={isEditable}
                  doc={item}
                  onDelete={(event) => deleteRelation(item._id, event)}
                />
              )
            }

            return (
              <Chip
                key={item._id}
                style={chipStyles}
                classNames={{
                  base: 'line-clamp-1 whitespace-normal h-full py-1 cursor-pointer',
                }}
                onClick={() =>
                  navigate({
                    pathname: getDocPath(item),
                  })
                }
                onClose={isEditable ? () => deleteRelation(item._id) : undefined}
              >
                {item.title}
              </Chip>
            )
          })}
        </div>
      ) : (
        <DocsList
          docs={docs}
          onDelete={(item, event) => deleteRelation(item._id, event)}
          parentDoc={doc}
        />
      )}

      <MultiLinkRecommended {...childProps} />

      {isEditable && (
        <div className="mt-1.5">
          {isOpened ? (
            <MultiLinkSelect hideSelect={off} {...childProps} />
          ) : (
            <Button variant="outline" icon="plus" disabled={!isEditable} onClick={on}>
              Add
            </Button>
          )}
        </div>
      )}
    </FieldContainer>
  )
}
