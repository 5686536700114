import { Link, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { useLiveQuery } from 'dexie-react-hooks'
import CheckIcon from '@/assets/check.svg?react'
import { db, Workspace } from '@/db'
import css from '@/features/sidebar/Sidebar.module.css'
import { useDidUpdateEffect, usePath } from '@/hooks'
import { pathes } from '@/routes'
import { useAppDispatch, useSlr } from '@/store'
import { selectClientWorkspace, selectWorkspaceIds } from '@/store/account'
import { switchWorkspace } from '@/store/session/slice'
import { Avatar } from '@/ui'
import { LS } from '@/utils'
import { reload } from '@/utils/localStorage'

export const ClientsDropdown: React.FC<{ close: () => void }> = ({ close }) => {
  const dispatch = useAppDispatch()
  const { workspacePath } = usePath()
  const { workspace } = useSlr(selectClientWorkspace)
  const workspaceIds = useSlr(selectWorkspaceIds)
  const workspaces = useLiveQuery(
    () => db.workspaces.where('_id').anyOf(workspaceIds).toArray(),
    [workspaceIds],
  )
  const navigate = useNavigate()
  const openWorkspace = (item: Workspace) => {
    if (item._id === workspace._id) {
      close()
      return
    }
    LS.remove('disconnectTime')
    dispatch(switchWorkspace(item))
    reload()
  }

  useDidUpdateEffect(() => {
    close()
  }, [navigate])

  return (
    <div className="absolute z-50 bg-white w-[246px] shadow-l4 mt-2 pt-[17px] border  border-neutral200 rounded-md">
      <div className="text-neutral700 text-[11px] pb-[7px] px-5 uppercase">Workspaces</div>
      {workspaces?.map((item) => (
        <button
          key={item._id}
          onClick={() => openWorkspace(item)}
          className="w-full flex items-center gap-x-3.5 py-[9px] px-6 rounded hover:bg-primary hover:bg-opacity-10"
        >
          <Avatar image={item.image} fit="contain" shape="square" letters={item.name?.[0] ?? 'W'} />
          <div className={clsx('capitalize', css.organizationDropdownTitleClamp)}>{item.name}</div>
          {workspace._id === item._id && <CheckIcon className="ml-auto" />}
        </button>
      ))}

      <div className="divider h-1 m-0" />
      <Link
        aria-label={`link to settings`}
        to={`${workspacePath}/${pathes.WORKSPACE_SETTINGS}`}
        className="block px-5 pt-[13px] pb-3.5 hover:bg-primary hover:bg-opacity-10"
      >
        Settings
      </Link>
    </div>
  )
}
