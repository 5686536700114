import { useState } from 'react'
import { toast } from 'react-toastify'
import { ReadAccess } from '@common/interfaces/documents/document.interface'
import { useDocContext } from '@/contexts'
import { updateDocumentReadAccess } from '@/db/permissions/sync'

export const DocumentReadAccess = () => {
  const { doc } = useDocContext()
  const [readAccess, setReadAccess] = useState<ReadAccess>(doc._read_access ?? 'private')

  const toggleChangeHandler = async (value: typeof readAccess) => {
    setReadAccess(value)
    const toastId = toast.loading("Updating children documents, please don't close the tab")
    await updateDocumentReadAccess(doc._id, value)
    toast.update(toastId, {
      render: 'Successfully finished',
      type: 'success',
      isLoading: false,
      autoClose: 2000,
    })
  }

  return (
    <div className="flex gap-3 justify-center mt-6">
      <label className="flex cursor-pointer justify-start items-center gap-x-3">
        <input
          type="radio"
          className="radio radio-xs"
          checked={readAccess === 'private'}
          onChange={() => toggleChangeHandler('private')}
        />
        <span className="text-caption">Available for the groups</span>
      </label>
      <label className="flex cursor-pointer justify-start items-center gap-x-3">
        <input
          type="radio"
          className="radio radio-xs"
          checked={readAccess === 'everyone'}
          onChange={() => toggleChangeHandler('everyone')}
        />
        <span className="text-caption">Available for everyone</span>
      </label>
    </div>
  )
}
