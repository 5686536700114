import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Input } from '@nextui-org/react'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
} from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createQuoteNode, $isHeadingNode } from '@lexical/rich-text'
import {
  $isAtNodeEnd,
  $isParentElementRTL,
  $patchStyleText,
  $setBlocksType,
} from '@lexical/selection'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import clsx from 'clsx'
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import { db } from '@/db'
import { Button, ColorPicker } from '@/ui'
import { useRteContext } from '../../hooks/useRteContext'

const LowPriority = 1

function positionEditorElement(editor: HTMLDivElement, rect: DOMRect | null) {
  if (rect === null) {
    editor.style.opacity = '0'
    editor.style.top = '-1000px'
    editor.style.left = '-1000px'
  } else {
    editor.style.opacity = '1'
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`
    editor.style.left = `${
      rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
    }px`
  }
}

const FloatingLinkEditor = ({ editor }: { editor: LexicalEditor }) => {
  const editorRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const mouseDownRef = useRef(false)
  const [linkUrl, setLinkUrl] = useState('')
  const [isEditMode, setEditMode] = useState(false)
  const [lastSelection, setLastSelection] = useState<ReturnType<typeof $getSelection>>(null)

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL())
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL())
      } else {
        setLinkUrl('')
      }
    }
    const editorElem = editorRef.current
    const nativeSelection = window.getSelection()
    const { activeElement } = document

    if (editorElem === null) return

    const rootElement = editor.getRootElement()
    if (
      nativeSelection &&
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection?.getRangeAt(0)
      let rect
      if (nativeSelection?.anchorNode === rootElement) {
        const inner = rootElement?.firstElementChild ?? rootElement
        // while (inner.firstElementChild != null) {
        //   inner = inner.firstElementChild;
        // }
        rect = inner.getBoundingClientRect()
      } else {
        rect = domRange.getBoundingClientRect()
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect)
      }
      setLastSelection(selection)
    } else if (!activeElement || activeElement.className !== 'link-input') {
      positionEditorElement(editorElem, null)
      setLastSelection(null)
      setEditMode(false)
      setLinkUrl('')
    }
  }, [editor])

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateLinkEditor()
          })
        }),

        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateLinkEditor()
            return true
          },
          LowPriority,
        ),
      ),
    [editor, updateLinkEditor],
  )

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor()
    })
  }, [editor, updateLinkEditor])

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditMode])

  return (
    <div ref={editorRef} className="link-editor">
      {isEditMode ? (
        <input
          ref={inputRef}
          className="link-input"
          value={linkUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              if (lastSelection !== null) {
                if (linkUrl !== '') {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl)
                }
                setEditMode(false)
              }
            } else if (event.key === 'Escape') {
              event.preventDefault()
              setEditMode(false)
            }
          }}
        />
      ) : (
        <div className="link-input flex justify-between">
          <a href={linkUrl} target="_blank" rel="noopener noreferrer">
            {linkUrl}
          </a>
          <Button className="link-edit" onClick={() => setEditMode(true)} />
        </div>
      )}
    </div>
  )
}

function getSelectedNode(selection: any) {
  const { anchor, focus } = selection
  const anchorNode = selection.anchor.getNode()
  const focusNode = selection.focus.getNode()
  if (anchorNode === focusNode) {
    return anchorNode
  }
  if (selection.isBackward()) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode
  }
  return $isAtNodeEnd(anchor) ? focusNode : anchorNode
}

export const ToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext()
  const toolbarRef = useRef(null)
  const [blockType, setBlockType] = useState('paragraph')
  const [, setSelectedElementKey] = useState<string>()
  const [, setIsRTL] = useState(false)
  const [isLink, setIsLink] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const { withAI, enableAI, toggleAI } = useRteContext()

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      const element =
        anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow()
      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey)
        if ($isListNode(element)) {
          const parentList: any = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList ? parentList.getTag() : element.getTag()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType()
          setBlockType(type)
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setIsStrikethrough(selection.hasFormat('strikethrough'))
      setIsRTL($isParentElementRTL(selection))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }
    }
  }, [editor])

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar()
          })
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateToolbar()
            return false
          },
          LowPriority,
        ),
      ),
    [editor, updateToolbar],
  )

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://')
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink])

  const formatQuote = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection()

      if (!$isRangeSelection(selection)) return
      if (blockType !== 'quote') {
        $setBlocksType(selection, () => $createQuoteNode())
      } else {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }, [editor, blockType])

  const applyStyleText = useCallback(
    (styles: Record<string, string>, skipHistoryStack?: boolean) => {
      editor.update(
        () => {
          const selection = $getSelection()
          if (selection !== null) {
            $patchStyleText(selection, styles)
          }
        },
        skipHistoryStack ? { tag: 'historic' } : {},
      )
    },
    [editor],
  )

  const onFontColorSelect = useCallback(
    (value: string, skipHistoryStack?: boolean) => {
      applyStyleText({ color: value }, skipHistoryStack)
    },
    [applyStyleText],
  )

  const applyFontSize = useCallback(
    (styles: Record<string, string>, skipHistoryStack?: boolean) => {
      editor.update(
        () => {
          const selection = $getSelection()
          if (selection !== null) {
            $patchStyleText(selection, styles)
          }
        },
        skipHistoryStack ? { tag: 'historic' } : {},
      )
    },
    [editor],
  )

  const onFontSizeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      applyFontSize({ 'font-size': `${event.target.value}px` })
    },
    [applyFontSize],
  )

  return (
    <div className="toolbar sticky top-0 bg-white z-10" ref={toolbarRef}>
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
        }}
        className={clsx('toolbar-item spaced', isBold && 'active')}
        variant="ghost"
        aria-label="Format Bold"
        icon="bold"
      />
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
        }}
        variant="ghost"
        className={clsx('toolbar-item spaced', isItalic && 'active')}
        aria-label="Format Italics"
        icon="italic"
      />
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
        }}
        variant="ghost"
        className={clsx('toolbar-item spaced', isUnderline && 'active')}
        aria-label="Format Underline"
        icon="underline"
      />
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
        }}
        variant="ghost"
        className={clsx('toolbar-item spaced', isStrikethrough && 'active')}
        aria-label="Format Strikethrough"
        icon="strikethrough"
      />
      <Button
        onClick={insertLink}
        variant="ghost"
        className={clsx('toolbar-item spaced', isLink && 'active')}
        aria-label="Insert Link"
        icon="link"
      />
      {isLink && <FloatingLinkEditor editor={editor} />}
      <div className="divider" />
      <Button
        onClick={formatQuote}
        variant="ghost"
        className={clsx('toolbar-item spaced', isLink && 'active')}
        aria-label="Insert Link"
        icon="quote"
      />
      <Button
        onClick={() => {
          editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, null as any)
        }}
        variant="ghost"
        className={clsx('toolbar-item spaced')}
        aria-label="Unordered list"
        icon="list"
      />
      <Button
        onClick={() => {
          editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, null as any)
        }}
        variant="ghost"
        className={clsx('toolbar-item spaced')}
        aria-label="Ordered list"
        icon="list-ordered"
      />
      <div className="divider" />
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')
        }}
        variant="ghost"
        className="toolbar-item spaced"
        aria-label="Left Align"
        icon="align-left"
      />
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')
        }}
        variant="ghost"
        className="toolbar-item spaced"
        aria-label="Center Align"
        icon="align-center"
      />
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')
        }}
        variant="ghost"
        className="toolbar-item spaced"
        aria-label="Right Align"
        icon="align-right"
      />
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')
        }}
        variant="ghost"
        className="toolbar-item"
        aria-label="Justify Align"
        icon="align-justify"
      />
      {db.flags.enableAI && withAI && (
        <Button
          aria-label="Open AI Helper"
          variant="ghost"
          icon="wand-sparkles"
          className="absolute right-2 bottom-1"
          disabled={enableAI}
          onClick={() => toggleAI(true)}
        />
      )}
      <ColorPicker showIcon onChange={onFontColorSelect} />

      <Input
        type="number"
        onChange={onFontSizeChange}
        classNames={{
          inputWrapper: 'p-1 w-12 min-h-0 h-full',
        }}
      />
    </div>
  )
}
