import clsx from 'clsx'
import { QueryFieldType } from '@common/interfaces/fields/query-field.interface'
import { useFieldContext } from '@/contexts'
import { FieldContainer } from '@/schema'
import { FieldComponent } from '../fieldType'
import { GeoMap } from './components/GeoMap'
import { QueryDefaultView } from './components/QueryDefaultView'

export const QueryField: FieldComponent = () => {
  const { field } = useFieldContext<QueryFieldType>()
  const { columnFlow } = field.layout ?? {}

  return (
    <FieldContainer
      className={clsx('grid gap-7 relative', columnFlow && 'grid-flow-col overflow-x-auto')}
    >
      {field.viewComponent === 'map' ? <GeoMap /> : <QueryDefaultView />}
    </FieldContainer>
  )
}
