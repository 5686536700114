import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'
import { URLFieldData, URLFieldType } from '@common/interfaces/fields/url-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { FieldContainer } from '@/schema'
import { saveField } from '@/db/docs/sync'
import { db } from '@/db'

export const UrlEdit = () => {
  const { doc } = useDocContext()
  const { value, saveValue } = useFieldContext<URLFieldType, URLFieldData>()
  const [url, setUrl] = useState(value?.url ?? '')

  const sendChangeDebounced = useMemo(
    () =>
      debounce((newValue: URLFieldData) => {
        saveValue(newValue)
      }, 1000),
    [],
  )

  useEffect(() => {
    if (url && value?.url !== url) {
      fetch(`https://api.microlink.io/?url=${encodeURIComponent(url)}`)
        .then((response) => response.json())
        .then((data) => {
          const title = data?.data?.title || 'Not found'
          const description = data?.data.description || 'Not found'
          const image = data?.data.image || 'Not found'
          const newData = {
            title,
            description,
            image: {
              ...image,
              fileName: title + image.type,
              source: 'cdn',
              _clientId: db.activeClient._id,
              _documentId: doc._id,
            },
          }
          Object.keys(newData).map((name) => saveField(doc._id, { name, value: newData[name] }))
        })
        .catch((error) => {
          console.error('Ошибка при запросе данных:', error)
        })
    }
  }, [url])

  const handleUrlChange = (value: string) => {
    setUrl(value)
    sendChangeDebounced({ url: value })
  }

  return (
    <FieldContainer className="grid grid-cols-2 gap-3">
      <input
        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        placeholder="https://example.com"
        value={url}
        onChange={(e) => handleUrlChange(e.target.value)}
      />
    </FieldContainer>
  )
}
