import { useMemo, useState } from 'react'
import clsx from 'clsx'
import { useLiveQuery } from 'dexie-react-hooks'
import { useFieldContext } from '@/contexts'
import { DataListParams } from '@/db'
import { queryDocRelatedDocs } from '@/db/docs/queries'
import { HtmlParser } from '@/features/rte'
import { Sparklines } from '@/features/sparklines'
import { useSlr } from '@/store'
import { selectFilters } from '@/store/filters/selectors'
import { Icon } from '@/ui'
import { showUntitledText } from '@/utils/parseString'
import css from '../card.module.css'
import { CardProps } from '../cardsTypes'
import { getApprovedMetricData } from '@/utils/docsSort'

export const FlipCard = ({ doc, children, headerElements }: CardProps) => {
  const { field } = useFieldContext<DataListParams>()
  const filters = useSlr(selectFilters)

  const [flipStatus, setFlipStatus] = useState(false)

  const { query, sparkline } = field?.layout?.card ?? {}
  const { type = '' } = query ?? {}
  const filter = filters[query?.filterName ?? ''] as string[]

  const relatedDocs = useLiveQuery(
    () => queryDocRelatedDocs(doc._id, type, query?.filterFieldName, filter ?? []),
    [type, filter],
  )
  const docs = useMemo(() => getApprovedMetricData(relatedDocs, true), [relatedDocs])

  const flipHandler = (e) => {
    e.preventDefault()
    setFlipStatus((prev) => !prev)
  }

  return (
    <div className={css.flip_card}>
      <div className={clsx(css.flip_card_inner, { [css.flip_card_inner_rotate]: flipStatus })}>
        <div className={css.flip_card_front}>
          <div className={css.flip_card_front_content}>
            <div className={clsx('pb-1 flex', flipStatus && 'hidden')}>
              {headerElements}
              <Icon name="info" onClick={flipHandler} />
            </div>
            <p title={doc.title} className="text-focus pb-2 break-words">
              {showUntitledText(doc.title)}
            </p>
            <Sparklines currentDoc={doc} docs={docs} sparkline={sparkline} />
          </div>
          {children}
        </div>
        <div className={clsx(css.flip_card_back)}>
          <div className="flex justify-end">
            <Icon name="info" size={20} className={clsx(flipStatus && '')} onClick={flipHandler} />
          </div>

          {doc.fields?.description ? (
            <HtmlParser className={clsx('text-regular text-neutral700 break-words')}>
              {doc.fields.description}
            </HtmlParser>
          ) : (
            <p>No description</p>
          )}
        </div>
      </div>
    </div>
  )
}
