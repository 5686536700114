import { AreaFieldType } from '@common/interfaces/fields/area-fields.interace'
import { useFieldContext } from '@/contexts'
import { AccordionView } from './components/AccordionView'
import { BlocksView } from './components/BlocksView'
import { TabsView } from './components/TabsView'

export const AreaField = () => {
  const { field } = useFieldContext<AreaFieldType>()

  switch (field.viewComponent) {
    case 'tabs':
      return <TabsView />
    case 'accordion':
      return <AccordionView />
    default:
      return <BlocksView />
  }
}
