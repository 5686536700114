import { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { KeyValueData, KeyValueFieldType } from '@common/interfaces/fields/keyvalue-field.interface'
import { useFieldContext } from '@/contexts'
import { FieldContainer } from '@/schema'
import { Button, ColorPicker } from '@/ui'
import { UploadImage } from './UploadImage'

export const KeyValueForm = () => {
  const { field, value = {}, saveValue } = useFieldContext<KeyValueFieldType, KeyValueData>()
  const { fields = [] } = field
  const defaultValues: KeyValueData = {}

  fields.forEach((field) => {
    let defaultValue
    if (field.type === 'text') {
      defaultValue = ''
    }
    if (field.type === 'number') {
      defaultValue = 0
    }
    if (field.type === 'checkbox') {
      defaultValue = false
    }
    if (field.type === 'color') {
      defaultValue = '#ffffff'
    }
    defaultValues[field.name] = value ? value[field.name] : defaultValue
  })
  const { register, handleSubmit, reset, setValue } = useForm<KeyValueData>({
    defaultValues,
  })

  const onSave = (data: typeof defaultValues) => {
    if (value && value.image) {
      data.image = value.image
    } else {
      delete data.image
    }
    if (value) {
      fields.forEach((field) => {
        if (field.type === 'color') {
          value[field.name] = data[field.name]
        }
      })
    }

    saveValue(data)
  }

  return (
    <FieldContainer className="grid grid-cols-[1fr_3fr] gap-x-5 gap-y-3.5 max-w-[550px] w-full max-sm:grid-cols-1 max-sm:p-0">
      {fields.map((field) => {
        if (field.type === 'image') {
          return (
            <Fragment key={field.name}>
              <label key={`${field.name}_name`}>Image</label>
              <UploadImage key={`${field.name}_value`} />
            </Fragment>
          )
        }
        if (field.type === 'checkbox') {
          return (
            <Fragment key={field.type}>
              <label
                htmlFor={`${field.name}_name`}
                className="font-bold"
                key={`${field.name}_name`}
              >
                {field.name}:{' '}
              </label>
              <div className="flex items-center gap-2" key={`${field.name}_value`}>
                <label className="grid">
                  <input id={`${field.name}_name`} type="checkbox" {...register(field.name)} />
                </label>
              </div>
            </Fragment>
          )
        }
        if (field.type === 'color') {
          return (
            <Fragment key={field.name}>
              <label className="font-bold" key={`${field.name}_name`}>
                {field.name}:{' '}
              </label>
              <ColorPicker
                key={`${field.name}_value`}
                value={value[field.name] as string}
                onChange={(val) => {
                  setValue(field.name, val)
                }}
              />
            </Fragment>
          )
        }
        return (
          <Fragment key={field.name}>
            <label className="font-bold" key={`${field.name}_name`}>
              {field.name}:{' '}
            </label>
            <input
              key={`${field.name}_value`}
              type={field.name}
              className="input input-bordered w-[160px]"
              placeholder={field.name}
              {...register(field.name, {
                valueAsNumber: field.type === 'number',
              })}
            />
          </Fragment>
        )
      })}
      <div />
      <div className="flex justify-between">
        <Button variant="ghost" onClick={() => reset(defaultValues)}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSave)}>Save</Button>
      </div>
    </FieldContainer>
  )
}
