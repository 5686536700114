import { lazy, Suspense, useMemo } from 'react'
import { LucideProps } from 'lucide-react'
import dynamicIconImports from 'lucide-react/dynamicIconImports'

const fallback = <div className="w-5 h-5 rounded bg-transparent m-1 shrink-0" />

export type IconName = keyof typeof dynamicIconImports

interface IconProps extends Omit<LucideProps, 'ref'> {
  name: IconName
}

export const Icon = ({ name, ...props }: IconProps) => {
  const LucideIcon = useMemo(() => {
    const icon = dynamicIconImports[name]
    if (icon) {
      return lazy(icon)
    }
    return null
  }, [name])

  if (!LucideIcon) return fallback

  return (
    <Suspense fallback={fallback}>
      <LucideIcon {...props} />
    </Suspense>
  )
}
