import clsx from 'clsx'
import { useDocContext } from '@/contexts'
import { HeadingArea } from '../areas/HeadingArea'
import { AreaRenderer } from '../components/AreaRenderer'
import { selectFieldSchema, useSchemaState } from '@/contexts/schema'
import { CommentSection } from '@/features/comments'

interface SchemaLayoutProps {
  className?: string
}

export const SchemaLayout = ({ className }: SchemaLayoutProps) => {
  const { schema, isEditMode, doc } = useDocContext()
  const { layout } = schema
  const hasBanner = useSchemaState((state) => selectFieldSchema(state, doc._type, 'banner'))

  return (
    <div className={clsx(className ?? 'grid gap-8 pt-5', layout.theme)}>
      {(!hasBanner || isEditMode) && <HeadingArea />}
      <AreaRenderer />
      {schema.interactions?.comment && schema.interactionsArea === 'main' && (
        <CommentSection styles={schema.commentStyles} />
      )}
      <AreaRenderer className="fixed -z-10 hidden" areaName="hidden" />
    </div>
  )
}
