import { useForm } from 'react-hook-form'
import { useToggle } from '@/hooks'
import { Button, Modal } from '@/ui'
import { FIELD_TYPES } from '../../constants.ts'
import css from '../../SchemaEditor.module.css'

export const AddFieldModal = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm()
  const [isOpened, { on, off }] = useToggle()

  const addField = (data) => {
    onSubmit(true, data.type)
    off()
  }

  return (
    <>
      <Button icon="plus" className={css.addBtn} onClick={on} />

      {isOpened && (
        <Modal title="Add new field" onClose={off}>
          <form onSubmit={handleSubmit(addField)}>
            <div className="form-control w-full max-w-full pb-8">
              <label className="cursor-pointer label justify-start flex-col items-start p-0">
                <span className="label-text capitalize font-bold px-1 py-2">Field type</span>

                <select
                  className="select select-bordered w-full min-h-[30px] h-11"
                  {...register('type', { required: true })}
                >
                  <option value="">Select...</option>
                  {FIELD_TYPES?.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <Button type="submit" className="w-full" disabled={!isDirty || !isValid}>
              Add field
            </Button>
          </form>
        </Modal>
      )}
    </>
  )
}
