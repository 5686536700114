import { useMemo } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { Doc } from '@/db'
import { queryDoc } from '@/db/docs/queries.ts'
import { FormatType, formatNumber } from '@/utils/numberFormatter'
import { getField } from '@/utils/fields'

export const ValueWithFormat = ({ doc, value = 0 }: { doc: Doc; value: number }) => {
  const fieldValue = getField<string[]>('metric', doc.fields)
  const relatedDoc = useLiveQuery(() => queryDoc({ _id: fieldValue?.[0] ?? '' }), [fieldValue])

  const formattedValue = useMemo(() => {
    if (!relatedDoc) {
      return formatNumber(value)
    }

    return formatNumber(value, relatedDoc?.fields.type as FormatType)
  }, [relatedDoc?.fields.type, value])

  return <div>{formattedValue}</div>
}
