import { createContext, useContext } from 'react'
import { BranchType } from '../types'

export const SchemaTreeContext = createContext<SchemaTreeContextValue | object>({})

export type SchemaTreeContextValue = {
  updateBranch: (branch: BranchType) => void
  tree: BranchType
}

export function useSchemaTreeContext() {
  return useContext(SchemaTreeContext) as SchemaTreeContextValue
}
