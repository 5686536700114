import { Button } from '@/ui'
import { useWhiteboardMode } from '../hooks'

export const WhiteboardHeader = () => {
  const { toggleWhiteboardMode } = useWhiteboardMode()

  return (
    <div
      className="fixed z-10 bg-white w-full grid grid-flow-col items-center gap-4 px-5 border-b border-neutral200"
      style={{ height: 52 }}
    >
      <Button variant="outline" icon="minimize-2" onClick={() => toggleWhiteboardMode()} />
      {/* {boardRef.current && <Zoombar viewport={boardRef.current.viewport} />} */}
    </div>
  )
}
