import Cookies from 'js-cookie'
import { db } from '@/db'
import { getWSUrl } from '@/utils'
import { kebabToCamel } from '@/utils/parseString'
import { singlePromise } from '@/utils/promises'

type EventResponse = {
  id?: string
  items?: any[]
  status?: boolean
  event: string
}

export type MergeEventResponse<T> = EventResponse & {
  metadata: {
    id: string
    object: T
  }
}

class WebSocketManager {
  socket: WebSocket
  private events: Record<string, { id?: string; cb: any }> = {}
  private listeners: Record<string, { id: string; cb: any; finish?: any }> = {}
  private heartBeatId: NodeJS.Timeout

  async getSocket() {
    if (this.socket?.readyState === 1) {
      return this.socket
    } else {
      return this.connect()
    }
  }

  private connectCb() {
    return new Promise<WebSocket>((resolve) => {
      try {
        this.socket = new WebSocket(
          `${getWSUrl()}?token=${Cookies.get('token')}&tabId=${crypto.randomUUID()}`,
        )

        this.socket.onopen = () => {
          this.onMessage()
          this.heartbeat()
          resolve(this.socket)
        }

        this.socket.onclose = () => {
          this.reconnect()
        }
      } catch (error) {
        window.Rollbar.error('Websocket connection failed', error as Error)
      }
    })
  }

  connect = (() => singlePromise('ws', () => this.connectCb())).bind(this)

  private reconnect() {
    clearInterval(this.heartBeatId)
    window.Rollbar.error('Failed to connect. Reconnecting in 5 sec')
    setTimeout(() => this.getSocket(), 5000)
  }

  private onMessage() {
    this.socket.onmessage = (e: MessageEvent<string>) => {
      try {
        // response to emit
        const data: EventResponse = JSON.parse(e.data)
        const key = `${data.event}${data.id}`
        if (data.id && this.events[key]) {
          this.events[key]?.cb(data)
          delete this.events[key]
        } else {
          this.events[data.event]?.cb(data)
          delete this.events[data.event]
        }

        // listen pagination events
        if (data.id && data.items) {
          this.callListeners(data.id, data)
        }

        // listen merge events
        this.listeners[data.event]?.cb(data)
      } catch (error) {
        window.Rollbar.error('Failed to parse socket message', error as Error)
      }
    }
  }

  private heartbeat() {
    clearInterval(this.heartBeatId)
    this.heartBeatId = setInterval(async () => {
      const socket = await this.getSocket()
      socket.send(JSON.stringify({ action: 'ping' }))
    }, 5000)
  }

  private callListeners(id: string | undefined, data: any) {
    if (!id) return

    for (const key in this.listeners) {
      if (id.includes(key)) {
        this.listeners[key]?.cb(data)
      }
    }
  }

  async emit<R, T extends object = object>(eventName: string, payload?: T | null, id?: string) {
    return new Promise<R>((resolve) => {
      const event = kebabToCamel(eventName)
      this.events[id ? `${event}${id}` : event] = {
        id,
        cb: resolve,
      }

      this.getSocket().then((socket) => {
        socket.send(
          JSON.stringify({
            id,
            action: eventName,
            data: {
              workspaceId: db.activeWorkspace._id,
              ...payload,
            },
          }),
        )
      })
    })
  }

  async on<R>(eventName: string, cb: (data: R) => void) {
    this.listeners[eventName] = {
      id: eventName,
      cb,
    }
  }
}

export const ws = new WebSocketManager()
