import React, { MouseEvent, PropsWithChildren, useEffect, useState } from 'react'
import clsx from 'clsx'
import { ChevronRight } from 'lucide-react'
import css from './Collapse.module.css'

type Props = {
  title: string | JSX.Element | undefined
  arrow?: 'left' | 'right'
  className?: string
  borderClassName?: string
  arrowSize?: number
  arrowColor?: string
  arrowHide?: boolean
  icon?: JSX.Element
  open?: boolean
  updateKey?: string
  keepAlive?: boolean
  styles?: { [key: string]: string }
  secondaryStyles?: { [key: string]: string }
}

export const Collapse: React.FC<PropsWithChildren<Props>> = ({
  title,
  arrow = 'right',
  className,
  borderClassName,
  arrowSize = 14,
  arrowColor,
  arrowHide = false,
  icon,
  open,
  children,
  updateKey,
  keepAlive = false,
  styles,
  secondaryStyles,
}) => {
  const [show, setShow] = useState(open ?? false)

  const handleShow = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setShow(!show)
  }

  useEffect(() => {
    if (open !== undefined) setShow(open)
  }, [updateKey, open])

  return (
    <div className={borderClassName}>
      <div
        className={clsx(css.container, className, {
          [css.open]: borderClassName && show,
          [css.withIcon]: icon,
        })}
        style={styles}
      >
        {icon}
        {title as string}
        {!arrowHide && (
          <button
            onClick={handleShow}
            className={clsx(css.arrow, {
              [css.right]: arrow === 'right',
              [css.left]: arrow === 'left',
            })}
            style={secondaryStyles}
          >
            <ChevronRight
              className={clsx({ [css.arrowRotate]: show })}
              color={arrowColor}
              size={arrowSize}
            />
          </button>
        )}
      </div>

      {keepAlive ? (
        <div style={{ display: show ? 'block' : 'none' }}>{children}</div>
      ) : (
        show && children
      )}
    </div>
  )
}
