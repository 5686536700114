import { useLiveQuery } from 'dexie-react-hooks'
import { DocProvider } from '@/contexts'
import { queryUserDoc } from '@/db/docs/queries'
import { useSchemaState } from '@/contexts/schema'
import { useSlr } from '@/store'
import { selectAccount } from '@/store/account'
import { QuickPanelButtons } from './components/QuickPanelButtons'

export type PanelQueryType = {
  sortBy: string
  viewUserOnly: boolean
}

export const QuickPanel = () => {
  const user = useSlr(selectAccount)
  const userDoc = useLiveQuery(() => queryUserDoc(user._id), [user])
  const schema = useSchemaState((state) => state.docTypes[userDoc?._type ?? ''])

  if (!userDoc || !schema) return null

  return (
    <DocProvider doc={userDoc} schema={schema} isEditMode>
      <QuickPanelButtons />
    </DocProvider>
  )
}
