import { ActionType } from '@common/interfaces/documents/action.interface'
import { AreaCols, AreaOption } from '@common/interfaces/fields/area-fields.interace'
import { useDocContext } from '@/contexts'
import { ActionElement } from '@/features/actionElement'
import { FieldItem } from '@/schema/components'

interface AreaItemProps {
  value: string
  element?: ActionType
  areas?: AreaOption[]
  cols?: AreaCols
  styles?: React.CSSProperties
}

export const AreaItem = ({ value, element, areas: subAreas, cols = 1, styles }: AreaItemProps) => {
  const { areas } = useDocContext()
  const fields = areas.get(value)
  const { gridTemplateColumns = `repeat(${cols}, 1fr)`, ...otherStyles } = styles ?? {}

  if (element) {
    return <ActionElement schema={element} />
  }

  return (
    <div className="grid gap-4" style={{ gridTemplateColumns, ...otherStyles }}>
      {subAreas
        ? subAreas.map((option) => <AreaItem key={option.value} {...option} />)
        : fields?.map((field) => <FieldItem key={field.name} field={field} />)}
    </div>
  )
}
