import { useForm } from 'react-hook-form'
import { useDocContext } from '@/contexts'
import { updateUserPassword } from '@/db/users/sync'
import { Button } from '@/ui'
import { isAdmin, isOwner } from '@/utils/database'
import { Input } from '@nextui-org/react'
import { useState } from 'react'

interface FormData {
  password: string
  confirmPassword: string
}

export const UserSettings = () => {
  const { isEditMode, user, doc } = useDocContext()
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, watch, setValue } = useForm<FormData>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  if (!(isEditMode && (isAdmin(user) || isOwner(doc, user)))) return null

  const onSubmit = async (data: FormData) => {
    setLoading(true)
    await updateUserPassword(data.password.trim(), data.confirmPassword.trim())
    setValue('password', '')
    setValue('confirmPassword', '')
    setLoading(false)
  }

  return (
    <div className="field-theme mt-10">
      <h3 className="text-subtitle mb-1">Change password</h3>
      <form className="flex items-end justify-between gap-2 w-1/2 pb-7">
        <Input
          size="sm"
          type="password"
          placeholder="Password"
          className="input input-bordered w-full"
          {...register('password')}
        />
        <Input
          size="sm"
          type="password"
          placeholder="Confirm Password"
          className="input input-bordered w-full"
          {...register('confirmPassword')}
        />

        <Button
          loading={loading}
          disabled={
            !watch('password').trim() ||
            !watch('confirmPassword').trim() ||
            watch('password') !== watch('confirmPassword')
          }
          onClick={handleSubmit(onSubmit)}
        >
          Update
        </Button>
      </form>
    </div>
  )
}
