import { WorkflowFieldType } from '@common/interfaces/fields/workflow-field.interface.ts'
import { useDocContext, useFieldContext } from '@/contexts'
import { ApproveButton } from '@/fields/workflow/ApproveButton.tsx'

export const WorkflowField = () => {
  const { doc } = useDocContext()
  const { field } = useFieldContext<WorkflowFieldType>()
  const { fieldToChange } = field

  return <ApproveButton doc={doc} fieldToChange={fieldToChange} />
}
