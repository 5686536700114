import { useDrag } from 'react-dnd'
import { Doc } from '@/db'
import { Preview } from '@/ui'

export const DocBarItem = ({ item }: { item: Doc }) => {
  const [, ref] = useDrag(() => ({ type: 'doc', item }))

  return (
    <button ref={ref} draggable className="text-left hover:bg-neutral200 rounded bg-neutral50">
      <article className="grid grid-flow-col justify-start gap-3.5 items-center py-2 px-4 border-neutral300">
        <Preview image={item.fields.image} className="rounded w-10 h-10" />
        <h1 className="text-regular font-bold w-full">{item.title}</h1>
      </article>
    </button>
  )
}
