import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { Modal, ModalBody, ModalContent, useDisclosure } from '@nextui-org/react'
import { Button, Icon, Tooltip } from '@/ui'
import { FileAttachmentFieldType } from '@common/interfaces/fields/file-attachment-field.interface.ts'
import { ImageFieldData } from '@common/interfaces/fields/image-field.interface.ts'
import { io } from '@/api'
import { useDocContext, useFieldContext } from '@/contexts'
import { db } from '@/db'
import { FieldContainer } from '@/schema'

export const FileAttachmentView = () => {
  const { doc } = useDocContext()
  const { value, field } = useFieldContext<FileAttachmentFieldType, ImageFieldData>()
  const {
    width = '100%',
    height = '600px',
    showModalButton,
    showDownloadButton,
    inlineView,
    playing = true,
    loop = true,
    controls = true,
    niceName,
    helpPanelStyles,
  } = field

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (value?.fileName) {
      const payload = {
        _clientId: db.activeClient._id,
        _documentId: doc._id,
        fileName: value.fileName,
        hours: 1,
      }

      io.emit<string>('getSignedUrl', payload)
        .then((res) => {
          setUrl(res)
        })
        .catch((error) => {
          window.Rollbar.error(error as Error)
        })
    }
  }, [])

  if (!url || !value) {
    return null
  }

  if (value?.type?.includes('video')) {
    return (
      <FieldContainer>
        <ReactPlayer
          url={url}
          width={width}
          height={height}
          playing={playing}
          controls={controls}
          loop={loop}
        />
      </FieldContainer>
    )
  }
  const handleDownload = async (cdnUrl, filename) => {
    try {
      const response = await fetch(cdnUrl, {
        method: 'GET',
        headers: {
          'Content-Type': value.type || 'application/octet-stream',
        },
      })

      if (!response.ok) {
        throw new Error('Network response was not ok.')
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error)
    }
  }

  return (
    <FieldContainer>
      {showModalButton && (
        <div className="flex justify-end">
          <Button icon="expand" aria-label="Expand" className="mb-3" onClick={onOpen} />
        </div>
      )}

      {showDownloadButton && (
        <div className="flex justify-between items-center">
          {value?.fileName && (
            <h3
              className={field.area === 'advanced' ? 'text-focus' : 'text-subtitle'}
              style={helpPanelStyles}
            >
              {niceName}
            </h3>
          )}
          <div className="flex items-center justify-center p-1 w-7 h-7 rounded-lg bg-primary shadow hover:bg-primary/80">
            <Tooltip content="Download file">
              <Icon
                name="download"
                size={18}
                color="#FFF"
                onClick={() => handleDownload(url, value.fileName)}
              />
            </Tooltip>
          </div>
        </div>
      )}

      {inlineView && (
        <div>
          <iframe src={url} width={width} height={height} loading="lazy" title="PDF-file" />
        </div>
      )}

      <Modal size="full" radius="none" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalBody className="px-0 py-0">
            <iframe src={url} width="100%" height="100%" loading="lazy" title="PDF-file" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </FieldContainer>
  )
}
