import { useEffect, useMemo, useState } from 'react'
import { ComposableMap, Geographies, ZoomableGroup } from 'react-simple-maps'
import { Geography } from 'react-simple-maps'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import { useLiveQuery } from 'dexie-react-hooks'
import 'node_modules/leaflet-geosearch/dist/geosearch.css'
import { QueryFieldType } from '@common/interfaces/fields/query-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { queryDocsByTypeList } from '@/db/docs/queries'
import { useQueryEntry2 } from '@/hooks'
import { AreaRenderer } from '@/schema'
import { Button } from '@/ui'
import { colors } from '@/ui/theme'
import { toMap } from '@/utils/docsSort'
import geographies from './Geographies.json'
import { GeographyItem } from './GeographyItem'

interface Position {
  center: number[]
  zoom: number
}

const REGION_POSITIONS = {
  'geo-na': {
    center: [-10, 68],
    zoom: 1.5,
  },
  'geo-uki': {
    center: [0, 54],
    zoom: 7.5,
  },
  'geo-latam': {
    center: [0, -10],
    zoom: 1.9,
  },
  'geo-emea': {
    center: [50, 50],
    zoom: 1.2,
  },
  'geo-apjc': {
    center: [100, 10],
    zoom: 1.7,
  },
}

export const GeoMap = () => {
  const { doc } = useDocContext()
  const { field } = useFieldContext<QueryFieldType>()
  const allLocations = useLiveQuery(() => queryDocsByTypeList(['Country', 'State']), [])
  const [content, setContent] = useState('')
  const { filtered } = useQueryEntry2(field.query)
  const [countryPosition, setCountryPosition] = useState<Position | null>(null)
  const [position, setPosition] = useState({ center: [0, 0], zoom: 1 })

  const getCountries = useMemo(
    () => toMap(allLocations ?? [], (item) => item.fields.secondaryId as string),
    [allLocations],
  )

  useEffect(() => {
    const position = REGION_POSITIONS[doc.fields?.secondaryId as string]

    if (countryPosition) {
      setPosition({
        center: countryPosition.center,
        zoom: countryPosition.zoom,
      })
    }

    if (position) {
      setPosition(position)
    }
  }, [doc, countryPosition])

  const handleZoomIn = () => {
    if (position.zoom >= 4) return
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }))
  }

  const handleZoomOut = () => {
    if (position.zoom <= 1) return
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }))
  }

  const handleMoveEnd = (positions: { coordinates: number[]; zoom: number }) => {
    setPosition({ center: positions.coordinates, zoom: position.zoom })
  }

  return (
    <>
      <ComposableMap
        style={{ width: '100%', height: '500px', background: '#c9d2d3', borderRadius: '0.75rem' }}
        projection="geoMercator"
        projectionConfig={{
          rotate: [-10, 0, 0],
          scale: 147,
        }}
      >
        <ZoomableGroup
          center={position.center}
          zoom={position.zoom}
          minZoom={position.zoom}
          maxZoom={position.zoom}
          onMoveEnd={handleMoveEnd}
        >
          <Geographies geography="/geoData.json">
            {() =>
              geographies.map((geo) => {
                const currentDoc = getCountries.get(geo.rsmKey)
                if (currentDoc) {
                  return (
                    <GeographyItem
                      currentDoc={currentDoc}
                      setCountryPosition={setCountryPosition}
                      setContent={setContent}
                      filtered={filtered}
                      key={geo.rsmKey}
                      geo={geo}
                      showDocLength={field.showDocLength}
                    />
                  )
                }

                return (
                  <Geography
                    key={geo.rsmKey}
                    className="outline-none"
                    geography={geo}
                    fill={colors.neutral100}
                    stroke={colors.neutral200}
                  />
                )
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <div className="absolute p-5 flex flex-wrap gap-4 right-0 bottom-0 justify-end">
        <Button variant="outline" icon="plus" onClick={handleZoomIn} />
        <Button variant="outline" icon="minus" onClick={handleZoomOut} />
      </div>
      <AreaRenderer
        areaName={field.name}
        className="absolute p-5 flex flex-wrap gap-4 right-0 top-0 justify-end"
      />
      <MouseTooltip visible={!!content} offsetX={15} offsetY={10}>
        {content}
      </MouseTooltip>
    </>
  )
}
