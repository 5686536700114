import { useState } from 'react'
import { Button } from '@/ui'
import { Doc } from '@/db'
import { Avatar } from '@/ui'

type CollaborateUserProps = {
  isAnotherUser: boolean
  user: Doc
  removeUser: (userId?: string) => Promise<void>
}

export const CollaborateUser = ({ user, isAnotherUser, removeUser }: CollaborateUserProps) => {
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    setLoading(true)
    await removeUser(user.userId)
    setLoading(false)
  }

  return (
    <div className="flex items-center justify-between gap-1 p-1 border border-neutral300 rounded-xl">
      <Avatar image={user.fields?.image} letters={user.title[0]} size={1} />
      <p className="text-caption text-sm">
        {user.title}
        {!isAnotherUser && ' (Me)'}
      </p>
      {isAnotherUser && <Button icon="x" variant="outline" loading={loading} onClick={onClick} />}
    </div>
  )
}
