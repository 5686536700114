import { createContext, useContext } from 'react'
import type { Doc, DocumentType, FieldValue, Roles, SomeField, User } from '@/db'

export interface DocContextValue {
  doc: Doc
  schema: DocumentType
  isEditMode: boolean
  user: User
  areas: Map<string, SomeField[]>
  save: (value: FieldValue) => void
  isCompound?: boolean
  isNew?: boolean
  roles: Roles
}

export const DocContext = createContext<DocContextValue | object>({})

export function useDocContext() {
  return useContext(DocContext) as DocContextValue
}
