import { useEffect } from 'react'
import { FallbackProps } from 'react-error-boundary'
import { useFieldContext } from '@/contexts'

export const FieldError = ({ error }: FallbackProps) => {
  const { field } = useFieldContext()

  useEffect(() => {
    window.Rollbar.error(error as Error)
  }, [])

  return (
    <div className="my-2 text-error">
      <p>
        Something went wrong in the schema of <b>{field.name}</b> field
      </p>
    </div>
  )
}
