import { Link } from 'react-router-dom'
import { useLiveQuery } from 'dexie-react-hooks'
import { queryDoc } from '@/db/docs/queries.ts'
import { usePath } from '@/hooks'
import { Icon } from '@/ui'

export const ParentUrl = ({
  parentId,
  withTitle = false,
}: {
  parentId: string
  withTitle?: boolean
}) => {
  const doc = useLiveQuery(() => queryDoc({ _id: parentId }))
  const { getDocPath } = usePath()

  if (!doc) {
    return <div className="text-center opacity-30">-</div>
  }

  if (withTitle) {
    return (
      <Link to={getDocPath(doc)} className="line-clamp-2 break-words">
        {doc.title}
      </Link>
    )
  }

  return (
    <Link to={getDocPath(doc)} className="line-clamp-2">
      <Icon name="square-arrow-out-up-right" color="#5F6160" />
    </Link>
  )
}
