import { useRef } from 'react'

export const useDiagramWidth = () => {
  const ref = useRef<HTMLDivElement>(null)
  const currentWidth = (ref && ref.current && ref.current.scrollWidth - 96) || 1044 - 96

  return {
    ref,
    currentWidth,
  }
}
