import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useLiveQuery } from 'dexie-react-hooks'
import { produce } from 'immer'
import throttle from 'lodash.throttle'
import { io } from '@/api'
import { queryDoc } from '@/db/docs/queries'
import { useDidUpdateEffect } from '@/hooks'
import { useSlr } from '@/store'
import { selectAccount } from '@/store/account'
import { Collaborator, selectActiveDocId } from '@/store/docs'

export const usePresence = () => {
  const [collaborators, setCollaborators] = useState<Map<string, Collaborator>>(new Map())
  const { slug = 'home' } = useParams()
  const account = useSlr(selectAccount)
  const activeDocId = useSlr(selectActiveDocId)
  const doc = useLiveQuery(() => queryDoc({ _id: activeDocId }), [activeDocId])
  const lastActiveTime = useRef(Date.now())
  const slugHistory = useRef<string[]>(['', slug])

  useEffect(() => {
    const handleCursor = throttle(() => {
      lastActiveTime.current = Date.now()
    }, 2000)

    window.addEventListener('mousemove', handleCursor)

    return () => {
      window.removeEventListener('mousemove', handleCursor)
    }
  }, [])

  const removeCollaborator = (email: string) => {
    setCollaborators(
      produce(collaborators, (draft) => {
        draft.delete(email)
      }),
    )
  }

  useEffect(() => {
    io.on('presenceAware', (member: Collaborator) => {
      if (member.idViewed === slug) {
        setCollaborators(
          produce(collaborators, (draft) => {
            draft.set(member.email, { ...member, lastTime: Date.now() })
          }),
        )
      }
    })

    return () => {
      io.off('presenceAware')
    }
  }, [collaborators, doc])

  useDidUpdateEffect(() => {
    slugHistory.current.shift()
    slugHistory.current.push(slug)
  }, [slug])

  useEffect(() => {
    const id = setInterval(() => {
      io.emit('presence', {
        name: account.name,
        email: account.email,
        image: '',
        cursor: {},
        idViewed: slugHistory.current[1],
        idLeft: slugHistory.current[0],
        color: account.color,
      })
    }, 5000)

    return () => {
      clearInterval(id)
    }
  }, [account, doc])

  useDidUpdateEffect(() => {
    if (collaborators.size) {
      setCollaborators(
        produce(collaborators, (draft) => {
          draft.clear()
        }),
      )
    }
  }, [slug])

  return {
    collaborators: Array.from(collaborators.values()),
    removeCollaborator,
  }
}
