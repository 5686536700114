import { Tab, Tabs as UITabs } from '@nextui-org/react'
import { Key } from '@react-types/shared'

type DefaultTab<Id> = {
  value: Id
  label: JSX.Element | string
  disabled?: boolean
  title?: string
  active?: boolean
  'data-test'?: string
}

type ButtonTab<Id> = DefaultTab<Id>
type LinkTab<Id> = DefaultTab<Id> & {
  to: string
  exact?: boolean
}

type TabsByType<Id> =
  | {
      type: 'button'
      tabs: ButtonTab<Id>[]
    }
  | {
      type: 'link'
      tabs: LinkTab<Id>[]
    }

type TabsProps<TabId> = TabsByType<TabId> & {
  activeTabId?: TabId
  onChange?: (key: TabId) => void
  className?: string
  disableActiveTab?: boolean
}

export const Tabs = <TabId extends string | number>({
  activeTabId,
  tabs,
  onChange,
}: TabsProps<TabId>): JSX.Element => {
  const handleChange = (key: Key) => {
    const value = key as TabId
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <div className="flex w-full flex-col">
      <UITabs
        variant="underlined"
        aria-label="Options"
        items={tabs}
        color="primary"
        selectedKey={activeTabId}
        onSelectionChange={(key) => handleChange(key)}
      >
        {(item) => <Tab key={item.value} title={item.label} />}
      </UITabs>
    </div>
  )
}
