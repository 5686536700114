import { ReactNode } from 'react'
import { components, GroupBase, OptionProps } from 'react-select'
import { Icon, IconName } from '@/ui/Icon'

type Props<T, IsMulti extends boolean, Group extends GroupBase<T>> = {
  children: ReactNode
} & OptionProps<T, IsMulti, Group>

export const CustomSelectIconOption = <T, IsMulti extends boolean, Group extends GroupBase<T>>({
  children,
  ...optionsProps
}: Props<T, IsMulti, Group>): JSX.Element => {
  const innerProps = {
    ...optionsProps.innerProps,
  }

  return (
    <components.Option {...optionsProps} innerProps={innerProps}>
      <Icon name={children as IconName} size={24} />
    </components.Option>
  )
}
