import { ReadAccess } from '@common/interfaces/documents/document.interface'
import { DocumentPermissionGroupEntry } from '@common/interfaces/documents/permission.interface'
import { io, ws } from '@/api'
import { AncestorsUpdatePayload, updateModifiedOnApi } from '@/api/docs'
import { MergeEventResponse } from '@/api/socket/websocket'
import { sequentially } from '@/utils'
import { changeArr, getArrDiff } from '@/utils/docsSort'
import { db } from '../db'
import { BatchData, Data, DataItem, Doc, DocPermissions } from '../dbTypes'
import { queryAncestors, queryDoc, queryDocChildren } from '../docs/queries'
import { fetchDocsDebounced, fetchGroupsDocs } from '../docs/sync'
import { fetchUserById } from '../users/sync'
import { queryDocPermissions } from './queries'
import { store } from '@/store'
import { updateAccountData } from '@/store/account/slice'

export async function createDocPermissions(doc: Doc) {
  const ancestors = await queryAncestors(doc)

  return io.emit<boolean>('createDocumentPermissions', {
    clientId: db.activeClient._id,
    workspaceId: db.activeWorkspace._id,
    documentId: doc._id,
    documentType: doc._type,
    userId: doc._userId,
    ancestors,
  })
}

export async function fetchDocumentPermissions(docId: string) {
  const currentPermissions = await queryDocPermissions(docId)

  if (currentPermissions) {
    return currentPermissions
  }

  const data = await ws.emit<DataItem<string[]>>(
    'find-document-permissions',
    {
      documentId: docId,
    },
    docId,
  )
  const newPermissions: DocPermissions = { _id: docId, permissions: data.items }

  db.ws.transaction('rw', db.ws.permissions, () => {
    db.ws.permissions.put(newPermissions)
  })

  return newPermissions
}

export async function updateAncestors(doc: Doc) {
  const ancestors = await queryAncestors(doc)

  return {
    _id: doc._id,
    _parentId: doc._parentId,
    ancestors,
    oldAncestors: ancestors,
  }
}

export async function fetchPermissionGroups() {
  try {
    const data = await ws.emit<Data<DocumentPermissionGroupEntry>>(
      'find-workspace-permission-groups',
      {
        excludeAuto: true,
      },
    )

    const permissionsSet = new Set<string>()
    data.items.forEach((group) => {
      if (db.activeAccount.permissionGroups.includes(group._id)) {
        group.permissions.forEach((item) => permissionsSet.add(item))
      }
    })
    db.accountPermissions = permissionsSet

    await db.ws.transaction('rw', db.ws.permissionGroups, () =>
      db.ws.permissionGroups.bulkPut(data.items),
    )

    return data.items
  } catch (error) {
    window.Rollbar.error(error as Error)
  }
}

export async function createPermissionGroup(name: string, permissions: string[] = []) {
  const data = await ws.emit<MergeEventResponse<DocumentPermissionGroupEntry>>(
    'create-permission-group',
    {
      name,
      permissions,
    },
  )

  if (!data.metadata.object) return

  db.ws.transaction('rw', db.ws.permissionGroups, () => {
    db.ws.permissionGroups.put(data.metadata.object)
  })

  return data.metadata.object
}

export async function updatePermissionGroup(payload: {
  groupId: string
  name?: string
  permissions?: string[]
  overridePermissions?: boolean
}) {
  try {
    const data = await ws.emit('update-permission-group', payload)
    const { groupId, overridePermissions, ...changes } = payload

    if (!data) return false

    db.ws.transaction('rw', db.ws.permissionGroups, () => {
      db.ws.permissionGroups.update(groupId, changes)
    })

    return true
  } catch (error) {
    window.Rollbar.error(error as Error)
    return false
  }
}

type UserPermissionGroup = {
  _userId: string
  _permissionGroups: string[]
  _clientId: string
  _workspaceId: string
}

export async function updateUserPermissionGroup(
  userId: string,
  groupId: string,
  action: 'add' | 'remove' = 'add',
) {
  const user = await fetchUserById(userId)
  if (!user) return false
  const permissionGroups = changeArr(user.permissionGroups, action, [groupId])

  const status = await io.emit<boolean, UserPermissionGroup>(
    'updateUserWorkspacePermissionGroups',
    {
      _userId: userId,
      _permissionGroups: permissionGroups,
      _clientId: db.activeClient._id,
      _workspaceId: db.activeWorkspace._id,
    },
  )

  if (!status) return false

  await db.ws.users.update(userId, { permissionGroups })

  return true
}

type DocumentReadAccess = {
  id: string
  workspaceId: string
  access: ReadAccess
}

export async function updateDocumentReadAccess(id: string, access: ReadAccess) {
  const updatedAccesses: DocumentReadAccess[] = []

  async function updateTreeReadAccess(id: string) {
    updatedAccesses.push({
      id,
      workspaceId: db.activeWorkspace._id,
      access,
    })

    const children = await queryDocChildren(id)
    if (children) {
      await Promise.all(children.map((child) => updateTreeReadAccess(child._id)))
    }
  }

  await updateTreeReadAccess(id)
  const status = await io.emit<boolean>('batchUpdateDocumentReadAccess', {
    batch: updatedAccesses,
  })

  // eslint-disable-next-line no-console
  console.debug('batchUpdateDocumentReadAccess', updatedAccesses)

  if (status) {
    await db.ws.transaction('rw', db.ws.docs, () =>
      Promise.all(
        updatedAccesses.map((item) => db.ws.docs.update(item.id, { _read_access: item.access })),
      ),
    )
  }
}

export async function updateDocModifiedOn(docId: string) {
  try {
    await updateModifiedOnApi(docId)
    const children = await queryDocChildren(docId)
    if (!children) return

    await sequentially(children, (child) => updateDocModifiedOn(child._id))

    return true
  } catch (error) {
    window.Rollbar.error(error as Error)
    return false
  }
}

interface PropagateParams {
  setViewer?: boolean
  setContributor?: boolean
}

type PropagatePayload = {
  workspaceId: string
  documentId: string
} & PropagateParams

export async function updateDocumentPropagateFlag(
  doc: Doc,
  propagateParams: PropagateParams,
  includeSelf = false,
) {
  const updatedDocs: Doc[] = []
  const updatedAncestors: AncestorsUpdatePayload[] = []

  async function propagatePermissions(item: Doc, withAncestors = true) {
    const updatedDoc = { ...item, ...propagateParams }
    updatedDocs.push(updatedDoc)

    if (withAncestors) {
      const oldAncestors = await queryAncestors(item)
      if (!oldAncestors.length) return

      let startPropagate = false
      const ancestors = oldAncestors.map((ancestor) => {
        if (ancestor.documentId === doc._id) {
          startPropagate = true
        }
        if (ancestor.documentId === item._parentId || startPropagate) {
          return { ...ancestor, ...propagateParams }
        }

        return ancestor
      })

      updatedAncestors.push({
        _id: item._id,
        _partId: item._partId,
        _parentId: item._parentId,
        ancestors,
        oldAncestors,
      })
    }

    const children = await queryDocChildren(item._id)
    if (children) {
      await Promise.all(children.map((child) => propagatePermissions(child)))
    }
  }

  await propagatePermissions(doc, includeSelf)
  await io.emit('batchUpdateDocumentAncestory', {
    batch: updatedAncestors,
  })

  // eslint-disable-next-line no-console
  console.debug('batchUpdateDocumentAncestory', updatedAncestors)

  await io.emit('batchUpdateDocumentPropagateFlag', {
    batch: updatedDocs.map<PropagatePayload>((item) => ({
      workspaceId: item._partId,
      documentId: item._id,
      setContributor: item.setContributor,
      setViewer: item.setViewer,
    })),
  })

  // eslint-disable-next-line no-console
  console.debug('batchUpdateDocumentPropagateFlag', updatedDocs)

  await db.ws.transaction('rw', db.ws.docs, () => {
    db.ws.docs.bulkPut(updatedDocs)
  })
}

export function listenPermissions() {
  ws.on<
    MergeEventResponse<{
      workspaceId: string
      documentId: string
      permissions: string[]
    }>
  >('merge-update-document-permissions', async (res) => {
    if (!res.metadata.object) return
    const { object: newPermissions } = res.metadata

    db.ws.transaction('rw', db.ws.permissions, db.ws.docs, async () => {
      db.ws.permissions.put({
        _id: newPermissions.documentId,
        permissions: newPermissions.permissions,
      })
    })
  })

  io.on('mergeBatchUpdateDocumentPropagateFlag', async (res: BatchData<PropagatePayload>) => {
    await db.ws.transaction('rw', db.ws.docs, () =>
      Promise.all(
        res.batch.map(async (item) => {
          const { workspaceId, documentId, ...propagateParams } = item

          const doc = await queryDoc({ _id: documentId })
          if (!doc) return
          db.ws.docs.update(documentId, propagateParams)
        }),
      ),
    )

    fetchDocsDebounced()
  })

  io.on('mergeUpdateUserWorkspacePermissionGroups', async (res: UserPermissionGroup) => {
    if (res._userId === db.activeAccount._id) {
      await fetchPermissionGroups()
      const { added } = getArrDiff(res._permissionGroups, db.activeAccount.permissionGroups)

      fetchGroupsDocs(added)
      store.dispatch(updateAccountData({ permissionGroups: res._permissionGroups }))
      db.activeAccount = { ...db.activeAccount, permissionGroups: res._permissionGroups }
    }

    db.ws.transaction('rw', db.ws.users, () => {
      db.ws.users.update(res._userId, { permissionGroups: res._permissionGroups })
    })
  })
}
