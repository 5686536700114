import { Fragment, useMemo } from 'react'
import { useDocContext } from '@/contexts'
import { Doc, SomeField } from '@/db'
import { FieldItem } from './Field'

export type Areas = 'default' | 'banner' | 'heading' | 'right-sidebar'

interface AreaRendererProps {
  fields?: SomeField[]
  areaName?: Areas | string
  showSkeleton?: boolean
  className?: string
  style?: { [key: string]: string | undefined }
  doc?: Doc
}

export const AreaRenderer = ({
  areaName = 'default',
  fields,
  showSkeleton,
  className,
  style,
  doc: childDoc,
}: AreaRendererProps) => {
  const { areas, doc, schema } = useDocContext()
  const areaFields = fields ?? areas.get(areaName)

  const content = useMemo(
    () =>
      areaFields?.map((field) => (
        <FieldItem key={field.name} field={field} showSkeleton={showSkeleton} childDoc={childDoc} />
      )),
    [areaFields, showSkeleton, schema, childDoc?._id],
  )

  if (!content?.length) {
    return null
  }

  if (className) {
    return (
      <div className={className} style={style}>
        {content}
      </div>
    )
  }

  return <Fragment key={childDoc?._id ?? doc._id}>{content}</Fragment>
}
