import ReactSelect, {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  Props,
  SelectComponentsConfig,
} from 'react-select'
import clsx from 'clsx'
import { selectComponents } from './components'
import { Control } from './components/Control'
import { MenuList } from './components/MenuList'
import { Option } from './components/Option'
import { SingleValue } from './components/SingleValue'
import { setCustomStyles } from './constants'
import cssDropdown from './select.module.css'

export { MenuList, Option, Control, SingleValue }
export type ChangeHandler<T> = (option: T | null, action: ActionMeta<T>) => void
export type MultiChangeHandler<T> = (
  options: OnChangeValue<T, true> | null,
  action: ActionMeta<T>,
) => void
export type ComponentsConfig<T> = SelectComponentsConfig<T, false, GroupBase<T>>

export type SelectOption = { label: string; value: string }

export const Select = <
  T = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<T> = GroupBase<T>,
>({
  value,
  isSearchable = true,
  closeMenuOnSelect = true,
  components,
  menuPlacement = 'auto',
  className,
  ...props
}: Props<T, IsMulti, Group>): JSX.Element => {
  const customStyles = setCustomStyles<T, IsMulti, Group>()

  return (
    <ReactSelect
      components={{
        ...selectComponents<T, IsMulti, Group>(),
        ...components,
      }}
      classNamePrefix="dropdown"
      className={clsx(className, cssDropdown.dropdown)}
      closeMenuOnSelect={closeMenuOnSelect}
      styles={customStyles}
      isSearchable={isSearchable}
      menuPlacement={menuPlacement}
      value={value}
      {...props}
    />
  )
}
