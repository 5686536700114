import { formatDistance, subDays } from 'date-fns'
import { useLiveQuery } from 'dexie-react-hooks'
import { Interaction } from '@/db'
import { Avatar } from '@/ui'
import { HtmlParser } from '../rte'
import { queryUserDoc } from '@/db/docs/queries'
import { useMemo } from 'react'

export const Comment = ({ comment }: { comment: Interaction<string> }) => {
  const user = useLiveQuery(() => queryUserDoc(comment._userId), [])
  const date = useMemo(
    () =>
      formatDistance(subDays(new Date(comment._addedOn), 0), new Date(), {
        addSuffix: true,
      }),
    [comment._addedOn],
  )

  return (
    <div key={comment._id}>
      <div className="flex items-center justify-between gap-2 mb-3">
        <div className="flex items-center gap-2">
          <Avatar user={user} size={3} />
          <p className="text-regular font-bold text-base-content">{user?.title}</p>
        </div>
        <span className="text-xs text-neutral700">{date}</span>
      </div>
      <div className="pl-2">
        <HtmlParser>{comment?.value}</HtmlParser>
      </div>
    </div>
  )
}
