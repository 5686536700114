import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button } from '@/ui'
import clsx from 'clsx'
import Cookies from 'js-cookie'
import { createTelemetryApi } from '@/api/workspaces.ts'
import { DocProvider } from '@/contexts'
import { db, Doc, DocumentType } from '@/db'
import { Header } from '@/features/header'
import { useActiveDoc } from '@/hooks/useActiveDoc'
import { useBackground } from '@/hooks/useBackground'
import { Renderer } from '@/schema'
import { selectDocSchema, selectFieldSchema, useSchemaState } from '@/contexts/schema'
import { CoockieBanner } from '@/schema/components/CoockieBanner'
import { useAppDispatch, useSlr } from '@/store'
import { embeddingStatus, getOpenAIRecommendation } from '@/store/ai-recommendations'
import { selectIsEditMode } from '@/store/docs'
import { clearSession } from '@/store/session/slice'
import { CompoundDoc } from '../MainLayout/CompoundDoc'
import { usePath } from '@/hooks'
import { AddNewButton } from '@/features/header/components/AddNewButton/AddNewButton'
import { WORKSPACE_DOCUMENT_TYPE_NAME } from '@common/interfaces/clients/document-type.interface'

type DocumentContentProps = {
  doc: Doc
  schema: DocumentType
  isCompound?: boolean
}

const DocumentContent = ({ doc, schema }: DocumentContentProps) => {
  const [hasCookie, setHasCookie] = useState(!!Cookies.get('acceptCookies'))
  const dispatch = useAppDispatch()
  const style = useBackground(doc)
  const cookieBanner = useSchemaState((state) =>
    selectFieldSchema(state, doc._type, 'cookieBanner'),
  )
  const isEditMode = useSlr(selectIsEditMode)
  const [searchParams] = useSearchParams()
  const compoundSlug = searchParams.get('row')
  const pageRef = useRef<HTMLDivElement>(null)
  const workspaceSchema = useSchemaState((state) =>
    selectDocSchema(state, WORKSPACE_DOCUMENT_TYPE_NAME),
  )
  const backgroundColorMain = workspaceSchema?.layout?.bgColors?.main
  const backgroundColorHome = workspaceSchema?.layout?.bgColors?.content

  useEffect(() => {
    window.document.title = doc.title
  }, [doc.title])

  useLayoutEffect(() => {
    pageRef.current?.scrollTo(0, 0)
  }, [doc._id])

  useEffect(() => {
    if (doc?.aiContext && db.flags.enableAI) {
      dispatch(
        embeddingStatus({ workspaceId: doc._partId, documentId: doc._id, text: doc.aiContext }),
      ).then((status) => {
        if (status.payload) {
          dispatch(
            getOpenAIRecommendation({ workspaceId: doc._partId, documentId: doc._id, count: 15 }),
          )
        }
      })
    }
  }, [doc?.aiContext, db.flags.enableAI])

  return (
    <>
      <DocProvider doc={doc} schema={schema} isEditMode={isEditMode}>
        <Header />
        <div className="overflow-hidden" style={style}>
          {cookieBanner && !hasCookie && (
            <CoockieBanner setHasCoockie={setHasCookie} context={doc.fields.cookieBanner} />
          )}
          <AddNewButton />
          <div
            ref={pageRef}
            className={clsx('overflow-y-scroll', 'grid', 'grid-rows-[1fr_auto]', 'h-content')}
            style={{
              backgroundColor:
                doc._type === WORKSPACE_DOCUMENT_TYPE_NAME
                  ? backgroundColorMain
                  : backgroundColorHome,
            }}
          >
            <Renderer />
          </div>
        </div>
      </DocProvider>
      {compoundSlug && compoundSlug !== 'newDoc' && <CompoundDoc slug={compoundSlug} />}
    </>
  )
}

export const DocumentPage: React.FC = () => {
  const { activeDoc, schema } = useActiveDoc()
  const { setModal } = usePath()
  const activityLabel = useSlr((state) => state.activityLabel.label)

  useEffect(() => {
    if (activeDoc) {
      const { _id, _type, title } = activeDoc

      createTelemetryApi({
        documentId: _id,
        documentType: _type,
        documentTitle: title,
        action: 'read',
        label: activityLabel,
      })
    }
  }, [activeDoc?._id, activityLabel])

  useEffect(() => {
    if (activeDoc && !schema) {
      setModal({ type: 'delete', doc: activeDoc })
    }
  }, [activeDoc, schema])

  if (!activeDoc || !schema) {
    return (
      <Button variant="ghost" className="ml-auto mt-2 mr-2" onClick={clearSession}>
        Reset
      </Button>
    )
  }

  return <DocumentContent doc={activeDoc} schema={schema} />
}
