import { Dispatch, Key, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Selection } from '@nextui-org/react'
import { compareArr, getSetDiff, toMap } from '@/utils/docsSort'

export function useSelectState<T>(
  options: T[] = [],
  defaultValue?: string[] | null,
  keyAccessor: (item: T) => string = (item) => item as string,
) {
  const prevDefaultValue = useRef<typeof defaultValue>()
  const [selectedKeys, setSelectedKeys] = useState(new Set(defaultValue))

  useEffect(() => {
    if (
      prevDefaultValue.current &&
      defaultValue &&
      !compareArr(prevDefaultValue.current, defaultValue)
    ) {
      setSelectedKeys(new Set(defaultValue))
    }

    prevDefaultValue.current = defaultValue ?? []
  }, [defaultValue])

  const optionsMap = useMemo(() => toMap(options, keyAccessor) as Map<Key, T>, [options])
  const selectedItems = useMemo(() => {
    const selectedList: T[] = []
    selectedKeys.forEach((key) => {
      const item = optionsMap.get(key)
      if (item) selectedList.push(item)
    })

    return { selected: selectedList[0] ?? null, selectedList }
  }, [selectedKeys, optionsMap])

  const selectDiff = useCallback(
    (newKeys: Selection) => getSetDiff(newKeys as Set<Key>, selectedKeys as Set<Key>),
    [selectedKeys],
  )

  const addSelect = useCallback((id: string) => {
    setSelectedKeys((prev) => {
      const newState = new Set(prev)
      newState.add(id)
      return newState
    })
  }, [])

  const removeSelect = useCallback((id: string) => {
    setSelectedKeys((prev) => {
      const newState = new Set(prev)
      newState.delete(id)
      return newState
    })
  }, [])

  const clearSelect = useCallback(() => {
    setSelectedKeys(new Set())
  }, [])

  return {
    ...selectedItems,
    addSelect,
    removeSelect,
    clearSelect,
    options,
    optionsMap,
    selectedKeys: selectedKeys as Selection,
    setSelectedKeys: setSelectedKeys as Dispatch<Selection>,
    selectDiff,
  }
}
