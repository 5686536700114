import { useDocContext } from '@/contexts'
import { EmptyBarChart } from '@/fields/stack-bar-chart/EmptyBarChart.tsx'
import { StackBarChart } from '@/fields/stack-bar-chart/StackBarChart.tsx'

export const StackBarChartField = () => {
  const { doc } = useDocContext()

  if (!doc.fields?.ACTIVITY) {
    return <EmptyBarChart />
  }

  return <StackBarChart activities={(doc.fields?.ACTIVITY as []) ?? []} />
}
