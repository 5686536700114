import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Geography, Marker } from 'react-simple-maps'
import clsx from 'clsx'
import 'node_modules/leaflet-geosearch/dist/geosearch.css'
import { QueryFieldType } from '@common/interfaces/fields/query-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { Doc } from '@/db'
import { ColumnItem } from '@/db/common/QueryManager2'
import { usePath } from '@/hooks'
import { colors } from '@/ui/theme'
import { sumArr } from '@/utils/docsSort'
import { toUnderscore } from '@/utils/parseString'
import { formatNumber } from '@/utils/numberFormatter'

interface Geography {
  type: string
  id: string
  properties: {
    name: string
    'Alpha-2'?: string | null | undefined
  }
  geometry: {
    type: string
    coordinates: number | number[] | number[][] | number[][][] | number[][][][]
  }
  rsmKey: string
  svgPath: string
}

interface GeographyItemProps {
  geo: Geography
  setContent: (content: string) => void
  filtered: ColumnItem[]
  currentDoc: Doc
  setCountryPosition: (val: { center: number[]; zoom: number }) => void
  showDocLength?: boolean
}

export const GeographyItem = ({
  geo,
  setContent,
  filtered,
  currentDoc,
  setCountryPosition,
  showDocLength,
}: GeographyItemProps) => {
  const { doc } = useDocContext()
  const { field } = useFieldContext<QueryFieldType>()
  const { getDocPath } = usePath()
  const [isHover, setHover] = useState(false)
  const fieldName = field.fieldName || ''

  const value = useMemo(() => {
    const isChildOrItself = currentDoc._id === doc._id || currentDoc._parentId === doc._id
    if (field.childrenHighlighted && !isChildOrItself) {
      return 0
    }

    const docs = filtered.filter((row) => row.columns.countries.has(currentDoc._id))

    if (showDocLength) {
      return docs.length
    }

    return sumArr(docs, (row) => +(row.target.fields[fieldName] ?? 0))
  }, [filtered, currentDoc._id, doc._id])

  const getCoord = (geo) => {
    if (Array.isArray(geo[0])) {
      getCoord(geo[0])
      return
    }

    const mapPositon = {
      center: [Math.floor(geo[0]), Math.floor(geo[1] + 10)],
      zoom: 2.5,
    }

    setCountryPosition(mapPositon)
  }

  useEffect(() => {
    if ('geo-' + toUnderscore(doc.title ?? '') === geo.rsmKey) {
      getCoord(geo.geometry.coordinates)
    }
  }, [])

  if (!value && currentDoc._type === 'State') {
    return null
  }

  const onHoverProps = {
    onMouseEnter: () => {
      setContent(`${currentDoc.title} - ${formatNumber(value)}`)
      setHover(true)
    },
    onMouseLeave: () => {
      setContent('')
      setHover(false)
    },
  }
  const isCheckChild = field.childrenHighlighted && currentDoc._parentId === doc._id
  const childColor = isCheckChild ? colors.blue50 : colors.neutral100

  const fillColor = value ? colors.primary : childColor
  const strokeColor = value ? colors.primary : colors.neutral200

  if (geo.rsmKey === 'geo-us-dc') {
    return (
      <Link key={geo.rsmKey} to={getDocPath(currentDoc)}>
        <Marker {...onHoverProps} key={currentDoc.title} coordinates={[-77.0193, 39.2897]}>
          <rect
            transform={'rotate(45)'}
            width={1}
            height={1}
            r={10}
            fill={fillColor}
            strokeWidth={1}
            stroke={strokeColor}
          />
        </Marker>
      </Link>
    )
  }

  return (
    <Link key={geo.rsmKey} to={getDocPath(currentDoc)}>
      <Geography
        {...onHoverProps}
        className={clsx(
          'outline-none',
          isHover && 'stroke-neutral400 fill-neutral400 drop-shadow-md',
        )}
        geography={geo}
        fill={fillColor}
        stroke={strokeColor}
      />
    </Link>
  )
}
