import { useDiagramContext } from './useDiagramContext'

export const useDiagramPosition = (x: number, y: number) => {
  const { deltaX, deltaY, settings } = useDiagramContext()
  const { axisX, axisY } = settings

  const xPos = x - axisX.min
  const yPos = y - axisY.min

  const xRelativePos = xPos * deltaX
  const yRelativePos = yPos * deltaY

  return [xRelativePos, yRelativePos]
}
