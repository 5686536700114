import { createAsyncThunk } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { checkToken } from '@/api/auth'

type Payload = {
  tokenId: string
  tokenType: string
  newClientId: string | null
}

export const checkSession = createAsyncThunk(
  'session/check',
  async ({ tokenId, tokenType, newClientId }: Payload, { rejectWithValue }) => {
    try {
      const { status, userId, token, tokenExpiry } = await checkToken(tokenId, tokenType)

      if (status) {
        console.log('token checked successfuly', token, tokenExpiry)
        Cookies.set('token', token)
        Cookies.set('exp', tokenExpiry)
      }
      return { status, userId, newClientId }
    } catch {
      return rejectWithValue(false)
    }
  },
)
