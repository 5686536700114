import { DocumentType } from '@/db'
import { scm } from '@/contexts/schema'
import { BranchType } from './types'

export function convertSchemaToTree(schema: DocumentType[]): Record<string, BranchType> {
  const obj: Record<string, BranchType> = {}

  schema.forEach((docType) => {
    const branch = getBranch(obj, docType.name)

    docType.fields.forEach((field) => {
      if (!branch.children) {
        branch.children = {}
      }

      branch.children[field.name] = {
        name: field.name,
        isField: true,
      }

      if (field.type === 'children') {
        branch.children[field.name].children = []

        field.allowed?.forEach((docType) => {
          if (docType === branch.name || !scm.getDocSchema(docType)) return
          const childBranch = getBranch(obj, docType)
          branch.children[field.name].children.push(childBranch)
        })
      }
    })

    if (branch.children) {
      branch.children = Object.values(branch.children)
    }
  })

  return obj
}

function getBranch(obj: any, name: string) {
  if (!obj[name]) {
    obj[name] = { name }
  }

  return obj[name]
}
