import { useCallback, useEffect, useState } from 'react'
import { StatusFieldType, StatusOption } from '@common/interfaces/fields/status-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { FieldContainer } from '@/schema'
import { ChangeHandler, Select } from '@/ui'
import { FieldComponent } from '../fieldType'
import css from './status.module.css'
import { StylesConfig } from 'react-select'
import { toSentence } from '@/utils/parseString'

export const StatusField: FieldComponent = () => {
  const [status, setStatus] = useState<StatusOption | null>(null)

  const { value, saveValue, field } = useFieldContext<StatusFieldType, string>()
  const { isEditMode } = useDocContext()

  const { options, disabledByDefault, styles, secondaryStyles } = field

  useEffect(() => {
    if (value && options) {
      const selectedOption = options.find((option) => option.value === value)

      if (selectedOption) setStatus(selectedOption)
    }
  }, [value, options])

  const handleStatusChange: ChangeHandler<StatusOption> = useCallback((option, actionMeta) => {
    const { action } = actionMeta
    if (action === 'clear' || option === null) {
      setStatus(null)
      saveValue('')
    }

    if (!option || !('value' in option)) return

    const { value: selectedStatus } = option

    setStatus(option)
    saveValue(selectedStatus)
  }, [])

  if (!(value || isEditMode)) return null

  if (field.withoutBage) return <div style={secondaryStyles}>{toSentence(value)}</div>

  return (
    <FieldContainer>
      <Select<StatusOption, false>
        value={status}
        options={options}
        className={!isEditMode ? css.disabledDropdown : ''}
        isDisabled={disabledByDefault || !isEditMode}
        onChange={handleStatusChange}
        styles={styles as StylesConfig<StatusOption, false>}
        isClearable
      />
    </FieldContainer>
  )
}
