import { ReactNode } from 'react'
import { components, GroupBase, SingleValueProps } from 'react-select'

type Props<T, IsMulti extends boolean, Group extends GroupBase<T>> = {
  children: ReactNode
} & SingleValueProps<T, IsMulti, Group>

export const SingleValue = <T, IsMulti extends boolean, Group extends GroupBase<T>>({
  children,
  ...singleValueProps
}: Props<T, IsMulti, Group>): JSX.Element => {
  const innerProps = {
    ...singleValueProps.innerProps,
  }

  return (
    <components.SingleValue {...singleValueProps} innerProps={innerProps}>
      {children}
    </components.SingleValue>
  )
}
