import { keyHash } from '@/utils'
import { db } from '../db'
import { Doc } from '../dbTypes'
import { getField } from '@/utils/fields'

export function queryPermissionGroups() {
  return db.ws.permissionGroups
    .filter((group) => !group.name.startsWith('DOCUMENT#'))
    .toArray()
    .catch<undefined>(console.error)
}

export function queryDocPermissions(docId) {
  return db.ws.permissions.get(docId).catch<undefined>(console.error)
}

export function queryPermissionGroup(groupId: string) {
  return db.ws.permissionGroups.get(groupId).catch<undefined>(console.error)
}

export function queryPermissionGroupByName(name: string) {
  return db.ws.permissionGroups.get({ name }).catch<undefined>(console.error)
}

export function queryPermissionsGroupsByIds(ids: string[]) {
  return db.ws.permissionGroups.where('_id').anyOf(ids).toArray().catch<undefined>(console.error)
}

export async function queryAccountRoles(doc: Doc, withOwner = true) {
  if (
    db.activeAccount._relationType === 'admin' ||
    (withOwner && db.activeAccount._id === doc._userId) ||
    getField<string[]>('collaborate', doc.fields)?.includes(db.activeAccount._id)
  ) {
    return {
      editor: true,
      contributor: true,
      viewer: true,
    }
  }

  const docPermissions = await queryDocPermissions(doc._id)
  if (!docPermissions)
    return {
      editor: false,
      contributor: false,
      viewer: false,
    }

  let isEditor = false
  let isViewer = false
  let isContributor = false

  docPermissions.permissions.forEach((permission) => {
    if (db.accountPermissions.has(permission)) {
      if (permission.endsWith('editor')) isEditor = true
      else if (permission.endsWith('contributor')) isContributor = true
      else if (permission.endsWith('viewer')) isViewer = true
    }
  })

  return {
    editor: isEditor,
    contributor: isEditor || isContributor,
    viewer: isEditor || isContributor || isViewer,
  }
}

export async function queryDocIdsByGroups(groupIds: string[]) {
  const groups = await queryPermissionsGroupsByIds(groupIds)
  const docIds =
    (groups
      ?.map((group) => group.permissions)
      .flat()
      .map((id) => keyHash.parsePermission(id).documentId)
      .filter(Boolean) as string[]) ?? []

  return docIds
}
