import { getUnixTime } from 'date-fns'
import { Ancestor, ReadAccess } from '@common/interfaces/documents/document.interface'
import { BaseDoc, Data, DataDoc, db, Doc, FieldValueType } from '@/db'
import { keyHash } from '@/utils'
import { io } from './socket'
import { ws } from './socket/websocket'

export function getDocument(documentId: string) {
  return ws.emit<Data<Doc> | false>(
    'get-document',
    {
      workspaceId: db.activeWorkspace._id,
      clientId: db.activeClient._id,
      documentId,
    },
    documentId,
  )
}

export async function createDocApi(
  payload: {
    title: string
    _type: string
    _parentId: string
    _ancestors: Ancestor[]
    _userId?: string
    _addedOn?: string
    _modifiedOn?: string
    disableEditPropagation?: boolean
    _read_access?: ReadAccess
  },
  readAccess?: ReadAccess,
) {
  if (readAccess) {
    if (readAccess === 'parent') {
      const parentDoc = await db.ws.docs.get(payload._parentId)

      if (parentDoc) {
        payload._read_access = parentDoc._read_access
      }
    } else {
      payload._read_access = readAccess
    }
  }

  const { status } = await io.emit<{ status: false | Doc }>('createDocument', {
    _workspaceId: db.activeWorkspace._id,
    clientId: db.activeWorkspace._partId,
    ...payload,
  })
  if (!status) throw new Error('Failed to create document')

  return status
}

export async function deleteDocApi(payload: { _id: string; fieldName: string }) {
  const res = await io.emit<{ status?: boolean; message?: string } | false>('deleteDocument', {
    _clientId: db.activeWorkspace._partId,
    _workspaceId: db.activeWorkspace._id,
    ...payload,
  })
  if (!res || !res.status) throw new Error('Failed to delete document')

  return res
}

export async function saveFieldApi(docId: string, fieldName: string, value: FieldValueType) {
  const fieldId = keyHash.genDocumentField(docId, fieldName)
  const status = await io.emit<boolean>('fieldChange', {
    _workspaceId: db.activeWorkspace._id,
    _id: fieldId,
    logEntry: {
      value,
      timestamp: getUnixTime(new Date()),
    },
  })
  if (!status) throw new Error('Failed to change field')

  const data = await io.emit<{ status?: boolean }>('fieldSave', {
    _workspaceId: db.activeWorkspace._id,
    _clientId: db.activeClient._id,
    _documentId: docId,
    _id: fieldId,
  })
  if (!data?.status) throw new Error('Failed to save field')

  return true
}

export type AncestorsUpdatePayload = {
  _id: string
  _parentId: string
  _partId?: string
  ancestors?: Ancestor[]
  oldAncestors?: Ancestor[]
  disableEditPropagation?: boolean
}

export async function updateAncestorsApi(payload: AncestorsUpdatePayload) {
  const res = await io.emit('updateDocumentAncestory', {
    ...payload,
    _partId: db.activeWorkspace._id,
  })
  if (!res) throw new Error('Failed to update ancestors')

  return res
}

export async function updateModifiedOnApi(documentId: string) {
  const res = await io.emit('updateDocumentModifiedTS', {
    workspaceId: db.activeWorkspace._id,
    documentId,
  })
  if (!res) throw new Error('Failed to update modifiedOn')

  return res
}

export async function getCachedDocument(documentId: string) {
  const res = await io.emit<DataDoc | false>('getCachedDocument', {
    workspaceId: db.activeWorkspace._id,
    documentId,
  })

  return res
}

export async function getCacheGroupBundleUrl(bundleKey: string) {
  const res = await io.emit<string>('getCacheGroupBundleUrl', {
    workspaceId: db.activeWorkspace._id,
    bundleId: bundleKey,
  })
  if (!res) throw new Error('Failed to get cache bundle url')

  return res
}

export async function createDocumentCacheEntry(documentId: string) {
  const res = await io.emit<boolean>('createDocumentCacheEntry', {
    workspaceId: db.activeWorkspace._id,
    documentId,
  })
  if (!res) throw new Error('Failed to create cache entry')

  return res
}

type DeltaSyncDocs = {
  permission?: BaseDoc[]
  everyone?: BaseDoc[]
  private?: BaseDoc[]
  public?: BaseDoc[]
}

export async function findDocumentIdsDelta(isoModifiedOn: string | null, isDeleted?: boolean) {
  const res = await io.emit<DeltaSyncDocs[]>('findDocumentIdsDelta', {
    clientId: db.activeClient._id,
    workspaceId: db.activeWorkspace._id,
    isoModifiedOn,
    isDeleted,
  })

  return {
    everyoneDocs: res[0]?.everyone ?? [],
    privateDocs: res[0]?.private ?? [],
    permissionsDocs: res[0]?.permission ?? [],
  }
}

type NotificationEmailPayload = {
  email: string
  subject: string
  htmlBody: string
}

export async function inviteUserToDocument(payload: NotificationEmailPayload) {
  const res = await io.emit<boolean>('notificationEmail', payload)
  if (!res) throw new Error('Failed to invite user')

  return res
}

export function findGroupDocumentIds(groupId: string) {
  return io.emit<BaseDoc[]>('findGroupDocumentIds', {
    workspaceId: db.activeWorkspace._id,
    groupId,
  })
}
