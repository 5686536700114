import { createContext } from 'react'
import { DiagramFieldData } from '@common/interfaces/fields/diagram-field.interface'

export type DiagramContextValue = {
  outerWidth: number
  outerHeight: number
  innerWidth: number
  innerHeight: number
  margin: number
  deltaX: number
  deltaY: number
  outerDeltaX: number
  outerDeltaY: number
  xTotal: number
  yTotal: number
  settings: DiagramFieldData
}

export const DiagramContext = createContext<DiagramContextValue | null>(null)
