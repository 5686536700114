import { FC, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react'
import { Button } from '@/ui'
import { DocProvider } from '@/contexts'
import { Doc, FieldValue } from '@/db'
import { createDoc, CreateDocPL, saveField } from '@/db/docs/sync'
import { DocumentStatus } from '@/features/DocumentStatus/DocumentStatus'
import { Renderer } from '@/schema'
import { selectDocSchema, useSchemaState } from '@/contexts/schema'
import { useAppDispatch, useSlr } from '@/store'
import { resetNewDoc, setNewDocFields } from '@/store/docs'
import { selectNewDoc } from '@/store/docs/selectors'

export type CompaundDocProps = {
  slug: string | null
}

export const CompoundDoc: FC<CompaundDocProps> = ({ slug }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const newDoc = useSlr(selectNewDoc)
  const docSchema = useSchemaState((state) => selectDocSchema(state, newDoc?._type))

  const handleChange = useCallback(
    (fieldValue: FieldValue) => dispatch(setNewDocFields(fieldValue)),
    [dispatch],
  )

  if (!newDoc || !docSchema) {
    return null
  }

  const close = () => {
    searchParams.delete('row')
    setSearchParams(searchParams)
    dispatch(resetNewDoc())
  }

  const onSubmit = async () => {
    if (!newDoc || !Object.keys(newDoc.updatedFields).length || !newDoc.parentDoc) return

    if (newDoc.isNew) {
      const payload: CreateDocPL = {
        title: newDoc.title.trim(),
        type: newDoc._type,
        parentDoc: newDoc.parentDoc as Doc,
        fieldName: 'children',
        fields: newDoc.fields,
      }

      await createDoc(payload)
    } else {
      Object.keys(newDoc.updatedFields).map((name) =>
        saveField(newDoc._id, { name, value: newDoc.updatedFields[name] }),
      )
    }

    dispatch(resetNewDoc())
    close()
  }

  return (
    <Modal isOpen={!!slug} onOpenChange={close}>
      <ModalContent className="w-[600px] max-w-2xl">
        <ModalHeader className="flex items-center justify-between gap-1 px-12 text-regular h-16 bg-neutral200">
          <h3 className="text-heading2 break-words whitespace-pre-wrap">{newDoc._type}</h3>
          <DocumentStatus />
          <div>
            <Button variant="ghost" onClick={() => close()}>
              Cancel
            </Button>
            <Button className="ml-2" onClick={() => onSubmit()}>
              Save
            </Button>
          </div>
        </ModalHeader>
        <ModalBody className="p-0 max-h-[600px] overflow-y-auto overflow-x-hidden">
          <DocProvider
            onChange={handleChange}
            doc={newDoc}
            schema={docSchema}
            isEditMode
            isCompound
            isNew={newDoc.isNew}
          >
            <Renderer />
          </DocProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
