import { EmailEntry } from '@common/interfaces/users/user.interface'
import { io } from '@/api'
import { db, ImageFieldData, User } from '@/db'
import { BaseWorkspace } from './workspaces'

interface UserInfo {
  user: User
  email: EmailEntry
  clientIds: string[]
  workspaceIds: BaseWorkspace[]
  status?: boolean
}

export type UpdateOtherUserPasswordType = {
  password: string
  confirmPassword: string
  userId: string
  workspaceId: string
}

export async function getUserInfoApi() {
  const res = await io.emit<UserInfo>('getUserInfo')
  if (res.status) throw new Error('Failed to get user info')

  return res
}

interface UserData {
  user: User
  email: EmailEntry
  permissionGroups: string[]
  primaryPermissionGroup: string
}

export async function getUserApi(id: string) {
  const res = await io.emit<UserData>('getUser', {
    userId: id,
    _id: id,
    workspaceId: db.activeWorkspace._id,
  })
  if (!res) throw new Error('Failed to get user')

  return res
}

interface UserStatus {
  status: boolean
  error?: number
  user: User
}

export async function addEmailToWorkspaceApi(payload: {
  email: string
  name?: string
  _sourceId?: string
  _disableEmail?: boolean
  _allowedOperation?: 'view' | 'edit'
}) {
  const res = await io.emit<UserStatus>('addEmailToWorkspace', {
    _workspaceId: db.activeWorkspace._id,
    _clientId: db.activeClient._id,
    _allowedOperation: 'view',
    name: '',
    ...payload,
  })
  if (!res?.user || res?.error === 409) throw new Error('Failed to add email to workspace')

  return res
}

export async function updateUserApi(payload: {
  _id: string
  _workspaceId?: string
  email?: string
  name?: string
  color?: string
  nickName?: string
  profileImage?: ImageFieldData
}) {
  const res = await io.emit<UserStatus>('updateUser', payload)
  if (!res?.status || res?.error === 409) throw new Error('Failed to update user')

  return res.status
}

export async function updateUserEmailApi(payload: { _id: string; email: string }) {
  const { status } = await io.emit<UserStatus>('updateUserEmail', payload)
  if (!status) throw new Error('Failed to update user email')

  return status
}

export async function deleteUserApi(id: string) {
  const res = await io.emit<boolean>('removeUserWorkspaceRelation', {
    _userId: id,
    _workspaceId: db.activeWorkspace._id,
    _clientId: db.activeClient._id,
  })
  if (!res) throw new Error('Failed to delete user')

  return res
}

export async function makeUserAdminApi(id: string, isAdmin = true) {
  const res = io.emit('makeUserAdmin', {
    _userId: id,
    _workspaceId: db.activeWorkspace._id,
    _clientId: db.activeClient._id,
    isAdmin,
  })
  if (!res) throw new Error('Failed to make user admin')

  return res
}

export async function updateOtherUserPasswordApi(payload: UpdateOtherUserPasswordType) {
  const response = io.emit<boolean>('updateOtherUserPassword', payload)

  if (!response) throw new Error('Failed to update other user password')

  return response
}
