import * as React from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { queryDocList } from '@/db/docs/queries'

export const LinkedDocuments: React.FC<{ value: string[] }> = ({ value }) => {
  const docs = useLiveQuery(() => queryDocList(value), [value]) ?? []

  if (!docs.length) {
    return <div className="bg-neutral50 p-4 rounded-md" />
  }

  return (
    <div className="flex flex-col p-1 gap-y-3">
      {docs.map((item) => (
        <p key={item._id} className="line-clamp-2" title={item.title}>
          {item.title}
        </p>
      ))}
    </div>
  )
}
