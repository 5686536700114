import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import get from 'lodash.get'
import { TableDefaultType } from '@common/interfaces/fields/table-field.interface'
import { useDocContext, useFieldContext } from '@/contexts'
import { Doc } from '@/db'
import { HtmlParser } from '@/features/rte'
import { AncestorsList } from '@/fields/table/components/TableList/AncestorsList.tsx'
import { ApproveButton } from '@/fields/workflow/ApproveButton.tsx'
import { usePath, useQueryEntry } from '@/hooks'
import { FieldContainer } from '@/schema'
import { scm } from '@/contexts/schema'
import { useAppDispatch } from '@/store'
import { setNewDoc } from '@/store/docs'
import { Button, Preview } from '@/ui'
import { ListLinkedDocuments } from './ListLinkedDocuments'
import { ValueWithFormat } from './ValueWithFormat'
import { useGlobalFilter } from '@/hooks/useGlobalFilter.ts'
import { QueryEntry2 } from '@common/interfaces/fields/query-field.interface.ts'
import { toSentence } from '@/utils/parseString'
import { FormatType, formatNumber } from '@/utils/numberFormatter'
import { TableActionElements } from './TableActionElements'
import { useSort } from '@/schema/hooks'
import { useFilterDocs } from '@/features/filter'
import { ParentUrl } from './ParentUrl.tsx'

export const TableDefault = ({ parentDoc }: { parentDoc: Doc }) => {
  const { doc, roles } = useDocContext()
  const { setModal } = usePath()
  const { field } = useFieldContext<TableDefaultType>()
  const fieldToChange = field?.fieldToChange
  const { columns = [] } = field
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { docs: data } = useQueryEntry(field.query)
  const filtererDocs = useFilterDocs(data)
  const docs = useSort(filtererDocs, field.sort)
  const filteredDocs = useGlobalFilter(docs, field.query as QueryEntry2, field.enableGlobalFilter)

  const handleDelete = (doc: Doc) => {
    setModal({
      type: 'delete',
      doc: doc,
    })
  }

  const getFieldValue = useCallback(
    (item: Doc, name: string) => {
      if (name === 'title') {
        return <p>{item.title}</p>
      }

      switch (name) {
        case 'url': {
          return <ParentUrl parentId={item._parentId} />
        }
        case 'ancestors': {
          return <AncestorsList parentId={item._parentId} />
        }
        case 'approve': {
          return (
            <div className="flex items-center gap-2">
              <ApproveButton
                doc={item}
                hideRejectButton={field.hideRejectButton}
                fieldToChange={fieldToChange}
              />
            </div>
          )
        }
        case 'action-elements': {
          return (
            <TableActionElements
              doc={item}
              editDoc={() => onEdit(item, 'edit')}
              deleteDoc={() => handleDelete(item)}
            />
          )
        }
      }

      if (name.includes('.')) {
        return <ListLinkedDocuments doc={item} fieldName={name} />
      }

      const fieldValue: any = get(item.fields, name)
      const fieldSchema = scm.getFieldSchema(item._type, name)

      switch (fieldSchema?.type) {
        case 'html': {
          return item.fields[fieldSchema.name] ? (
            <HtmlParser className="text-break">{fieldValue}</HtmlParser>
          ) : (
            <div>No description</div>
          )
        }
        case 'image': {
          return fieldValue ? (
            <Preview image={fieldValue} className="w-full bg-neutral50 p-4" />
          ) : (
            <div>No cover image</div>
          )
        }
        case 'multilink':
        case 'children':
        case 'singlelink': {
          return <ListLinkedDocuments doc={item} fieldName={name} />
        }

        case 'status': {
          const option = fieldSchema.options.find((option) => option.value === fieldValue)
          const pendingOption = fieldSchema.options.find((option) => option.value === 'pending')
          const text = option?.label ?? pendingOption?.label

          if (!text) {
            return <div className="text-center opacity-30">-</div>
          }

          return (
            <div className="capitalize text-caption rounded-full bg-neutral200 text-center p-0.5 text-nowrap">
              {option?.label ?? pendingOption?.label}
            </div>
          )
        }

        case 'number': {
          if (parentDoc.fields?.type) {
            return formatNumber(fieldValue, parentDoc.fields?.type as FormatType)
          }

          return <ValueWithFormat doc={item} value={fieldValue} />
        }

        default:
          return <div>{fieldValue}</div>
      }
    },
    [columns, filteredDocs],
  )

  const createDocument = useCallback(async () => {
    const type = field?.query.types?.[0] ?? ''
    const docSchema = scm.getDocSchema(type)

    const payload: Partial<Doc> = {
      _type: type,
      _parentId: doc._id,
      _ancestors: [...doc._ancestors, doc._id],
    }

    if (docSchema?.defaultFieldValues) {
      payload.fields = docSchema.defaultFieldValues
    }

    navigate({
      pathname: location.pathname,
      search: `?row=newDocument`,
    })
    dispatch(setNewDoc({ ...payload, parentDoc: doc, isNew: true }))
  }, [field.query, field.name, doc])

  const onEdit = (doc: Doc, slug: string) => {
    navigate({
      pathname: location.pathname,
      search: `?row=${slug}`,
    })
    dispatch(setNewDoc(doc))
  }

  return (
    <FieldContainer>
      <table
        className={clsx(
          'rounded-xl w-full border border-neutral300 overflow-hidden',
          field.fixedLayout && 'table-fixed',
        )}
      >
        {field.fixedLayout && (
          <colgroup>
            {columns.map(({ name, widthPercent }) => (
              <col key={name} style={{ width: widthPercent && `${widthPercent}%` }} />
            ))}
          </colgroup>
        )}
        <thead>
          <tr>
            {columns.map(({ name, label, widthPercent }) => (
              <th
                key={name}
                style={{ width: widthPercent ? `${widthPercent}%` : 'auto' }}
                className={clsx('bg-primary/20 text-left p-3 border border-neutral300')}
              >
                {label ?? toSentence(name)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredDocs.map((item) => (
            <tr key={item._id}>
              {columns.map(({ name }) => (
                <td key={name} className="p-2 border border-neutral300">
                  {getFieldValue(item, name)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {field.showAddButton && roles.contributor && (
        <Button className="mt-2" onClick={createDocument}>
          Add new
        </Button>
      )}
    </FieldContainer>
  )
}
