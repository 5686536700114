import { createCachedSelector } from 're-reselect'
import { RootState } from '@/store'

export const selectEmbeddingStatus = (state: RootState) => state.aiRecommendations.embeddingStatus
export const selectRecommendationDocIds = (state: RootState, docId: string) =>
  state.aiRecommendations.recommendedDocIds[docId]

export const selectAIRecommendedDocIds = createCachedSelector(
  selectRecommendationDocIds,
  (recommendedDocIds) => recommendedDocIds,
)((_, docId) => docId)
