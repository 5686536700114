import { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { BLUR_COMMAND, COMMAND_PRIORITY_NORMAL } from 'lexical'

export const OnBlurPlugin = ({ onBlur }: { onBlur: () => void }) => {
  const [editor] = useLexicalComposerContext()
  useEffect(
    () =>
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          onBlur()
          return true
        },
        COMMAND_PRIORITY_NORMAL,
      ),
    [editor, onBlur],
  )
  return null
}
