import { ChangeEvent, FC, useCallback, useState } from 'react'
import { Input, Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react'
import { getUnixTime } from 'date-fns'
import { ImageFieldData, ImageFieldType } from '@common/interfaces/fields/image-field.interface'
import { fetchUnsplashDownload, UnsplashEntity } from '@/api/files'
import { useDocContext, useFieldContext } from '@/contexts'
import { db } from '@/db'
import { FileUpload, saveNewFile } from '@/features/files'
import { Icon, Preview, Tabs } from '@/ui'
import { saveUrlCache } from '@/db/common/sync'
import { Unsplash } from './Unsplash'
import { checkImage } from '@/utils/fields'

interface Props {
  onClose: () => void
}

export const ImageUpdateModal: FC<Props> = ({ onClose }) => {
  const { doc } = useDocContext()
  const { value, saveValue } = useFieldContext<ImageFieldType, ImageFieldData>()

  const cdnImg = value?.source === 'cdn' && value
  const [tab, setTab] = useState(cdnImg ? 'upload' : 'unsplash')

  const saveCdnFile = async (file: File) => {
    if (!file) return

    const fileName = `${getUnixTime(new Date())}-${file.name}`
    const url = await saveNewFile({
      fileName,
      file,
      docId: doc._id,
    })
    if (!url) return

    const img = await checkImage(url)
    if (!img) {
      window.Rollbar.error('Failed to load cdn image')
      return
    }

    await saveUrlCache(fileName, url)
    saveValue({
      source: 'cdn',
      fileName,
      _clientId: db.activeClient._id,
      _documentId: doc._id,
    })
  }

  const saveUnsplashImage = useCallback(
    async (image: UnsplashEntity) => {
      const img = await checkImage(image.urls.regular)
      if (!img) {
        window.Rollbar.error('Failed to load unsplash image')
        return
      }

      saveValue({
        source: 'unsplash',
        fileName: image.id,
        _clientId: db.activeClient._id,
        _documentId: doc?._id,
        author: image.user.name,
        description: image.alt_description,
        url: image.urls.regular,
        authorUrl: `https://unsplash.com/@${image.user.username}`,
      })
      fetchUnsplashDownload(image.id)
      onClose()
    },
    [doc?._id],
  )

  const handleAltChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!value || !event.target.value) return
    if (value.description === event.target.value) return

    saveValue({
      ...value,
      description: event.target.value,
    })
  }

  return (
    <Modal isOpen={true} onOpenChange={onClose}>
      <ModalContent className="w-[600px] max-w-2xl px-4">
        {() => (
          <>
            <ModalHeader className="flex flex-col gap-1 px-0 text-regular h-12">
              Choose from Unsplash or upload
            </ModalHeader>
            <ModalBody className="p-0 pb-5">
              <Tabs
                type="button"
                activeTabId={tab}
                onChange={setTab}
                tabs={[
                  { value: 'upload', label: 'Upload' },
                  { value: 'unsplash', label: 'Unsplash' },
                ]}
              />
              {tab === 'unsplash' ? (
                <Unsplash onSelect={saveUnsplashImage} />
              ) : (
                <>
                  {cdnImg && (
                    <Input
                      type="text"
                      className="py-2"
                      defaultValue={value?.description}
                      onChange={handleAltChange}
                      label="Description"
                      placeholder="Add the image description"
                    />
                  )}
                  <FileUpload
                    onUpload={saveCdnFile}
                    className="rounded w-full border border-neutral400"
                  >
                    {cdnImg ? (
                      <Preview image={cdnImg} className="rounded w-full" iconSize={36} />
                    ) : (
                      <div className="w-full aspect-cover flex justify-center items-center">
                        <Icon name="image" size={32} className="text-neutral500" />
                      </div>
                    )}
                  </FileUpload>
                </>
              )}
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
