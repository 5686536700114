import { Doc, FieldValueType, SomeField } from '@/db'
import { getHtmlString } from './parseString'
import { LinkFieldType } from '@common/interfaces/fields/link-field.interface'

export function getField<T = FieldValueType>(
  fieldName: string,
  fields?: Partial<Record<string, FieldValueType>>,
) {
  return fields?.[fieldName] as T | undefined
}

export function hasFieldValue(type: SomeField['type'], value: FieldValueType) {
  switch (type) {
    case 'multilink':
    case 'singlelink': {
      return Array.isArray(value) && value.length
    }

    case 'text':
      return (value as string | undefined)?.length
    case 'html': {
      const text = getHtmlString(value as string)
      return text.length > 0
    }
    default:
      return true
  }
}

export function hasSomeFieldValue(schema: SomeField[], fields: Doc['fields']) {
  return schema.some((fieldSchema) => {
    const { name, type } = fieldSchema
    const fieldValue = fields[name]
    if (!fieldValue) {
      return false
    }

    return hasFieldValue(type, fieldValue)
  })
}

export function checkImage(url: string) {
  const img = document.createElement('img')
  img.src = url

  return new Promise<boolean>((resolve) => {
    img.onload = () => {
      resolve(true)
      img.remove()
    }

    img.onerror = () => {
      resolve(false)
      img.remove()
    }
  })
}

export function isLinkField(field?: SomeField): field is LinkFieldType {
  return field?.type === 'singlelink' || field?.type === 'multilink'
}
