export const pathes = {
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  SUCCESS: '/success',
  HOME: '/',
  SET_UP_NAME: '/set-up-name',
  PRIVACY_POLICY: '/privacy-policy',
  WORKSPACE: '/:client/:workspace/',
  DOCUMENT: ':titleSlug/:slug',
  SEARCH: 'search',
  WHITEBOARD: ':titleSlug/:slug/wb/:whiteboardName',
  CLIENT_SETTINGS: 'organization-settings',
  WORKSPACE_SETTINGS: 'workspace-settings',
  SCHEMA: 'schema',
}
