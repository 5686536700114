import { HtmlParser } from '@/features/rte'
import { CardProps } from '../cardsTypes'
import { Icon, IconName } from '@/ui/Icon'
import { useFieldContext } from '@/contexts'
import { DataListParams } from '@/db'
import { hexToRgb } from '@/utils/colors'
import { useSlr } from '@/store'
import { selectClientWorkspace } from '@/store/account'

export const CategoryCard = ({ doc, children, headerElements }: CardProps) => {
  const { workspace } = useSlr(selectClientWorkspace)
  const { field } = useFieldContext<DataListParams>()
  const mainIcon = doc.fields.icon || 'smile'
  const icon = field.layout?.card?.icon || 'arrow-right'
  const iconColor = doc.fields.color || field.layout?.card?.iconColor || workspace.color
  const rgb = hexToRgb(iconColor as string) ?? { r: 255, g: 255, b: 255 }

  return (
    <div className="h-full p-4 border border-neutral300 rounded-lg relative bg-white">
      {headerElements}
      <div
        className="rounded-full w-20"
        style={{ background: `rgba(${rgb.r},${rgb.g},${rgb.b},${0.2})` as string }}
      >
        <div
          className={
            'bg-base-200 grid place-items-center shrink-0 w-20 h-20 mb-4 rounded-full object-top'
          }
        >
          <Icon
            color={iconColor as string}
            name={mainIcon as IconName}
            size={26}
            className="shrink-0 opacity-100"
          />
        </div>
      </div>

      <p title={doc.title} className="font-bold mb-2 break-words whitespace-pre-wrap">
        {doc.title}
      </p>
      {doc.fields.description && (
        <HtmlParser className="text-regular text-neutral700 [&>ul]:list-disc break-words line-clamp-3 mb-2">
          {doc.fields.description}
        </HtmlParser>
      )}
      {children}
      <div className="flex justify-end mt-1">
        {icon && (
          <Icon
            name={icon as IconName}
            color={iconColor as string}
            size={18}
            className="shrink-0"
          />
        )}
      </div>
    </div>
  )
}
