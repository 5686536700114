import { getUnixTime } from 'date-fns'
import { WORKSPACE_DOCUMENT_TYPE_NAME } from '@common/interfaces/clients/document-type.interface'
import { DocumentImportData, UserData } from '@common/interfaces/clients/import-data.interface'
import { LinkFieldType } from '@common/interfaces/fields/link-field.interface'
import { uploadCdnApi } from '@/api/files'
import { createInteractionApi } from '@/api/interactions'
import { addEmailToWorkspaceApi, updateUserApi } from '@/api/users'
import { db, ImageFieldData, InteractionType, User } from '@/db'
import { queryDoc } from '@/db/docs/queries'
import { createDoc, deleteDoc, saveField } from '@/db/docs/sync'
import { createOneRelation, deleteOneRelation } from '@/db/relations/sync'
import { scm } from '@/contexts/schema'
import { keyHash, sequentially } from '@/utils'
import { useImporterState } from './importerState'
import { validateEmail } from '@/utils/regexp'

const { addMessage } = useImporterState.getState()

export async function importData(data: DocumentImportData) {
  const { docs, relations, fields, interactions, assets } = data

  if (docs?.length) {
    await createDocs(docs)
  }
  if (relations?.length) {
    await createRelations(relations)
  }
  if (fields?.length) {
    await createFields(fields)
  }
  if (assets?.length) {
    await uploadAssets(assets)
  }
  if (interactions?.length) {
    await createInteractions(interactions)
  }
}

async function createDocs(docs: DocumentImportData['docs']) {
  await sequentially(docs, async (doc, i) => {
    const key = `${i + 1}: ${doc.secondaryId} ${doc.title}`

    try {
      const existingDoc = await queryDoc({ secondaryId: doc.secondaryId })

      if (existingDoc) {
        if (!doc.delete && !doc.rewrite) {
          addMessage('docs', {
            type: 'success',
            message: `${key} - already exists`,
          })
          return Promise.resolve()
        }

        await deleteDoc(existingDoc)
      }

      if (doc.delete) {
        addMessage('docs', {
          type: 'success',
          message: `${key} - deleted`,
        })
        return Promise.resolve()
      }

      if (!doc.type.trim()) {
        addMessage('docs', {
          type: 'error',
          message: `${key} - type is empty`,
        })
      }

      if (!scm.getDocSchema(doc.type)) {
        addMessage('docs', {
          type: 'error',
          message: `${key} - ${doc.type} type doesn't exist`,
        })
        return Promise.resolve()
      }

      const parentDoc = await queryDoc({ secondaryId: doc.parentId })
      if (!parentDoc) {
        addMessage('docs', {
          type: 'error',
          message: `${key} - parent ${doc.parentId} not found`,
        })
        return Promise.resolve()
      }

      const user = await getUser(doc.userId)

      if (!user) {
        addMessage('docs', {
          type: 'error',
          message: `${key} - user ${doc.userId} not found`,
        })
        return Promise.resolve()
      }

      const res = await createDoc({
        title: doc.title,
        type: doc.type,
        userId: user._id,
        parentDoc,
        addedOn: doc.createdOn,
        modifiedOn: doc.modifiedOn,
        fields: {
          ...doc.fields,
          secondaryId: doc.secondaryId,
        },
      })

      if (res) {
        addMessage('docs', {
          type: 'success',
          message: `${key} - created`,
        })
      } else {
        addMessage('docs', {
          type: 'error',
          message: `${key} - not created on server`,
        })
      }

      return Promise.resolve()
    } catch (error) {
      addMessage('docs', {
        type: 'error',
        message: `${key} - WRONG DATA`,
      })
      window.Rollbar.error(key, doc, error as Error)
    }
  })
}

async function createRelations(relations: DocumentImportData['relations']) {
  await sequentially(relations, async (relation, i) => {
    const key = `${i + 1}: ${relation.fromId}(${relation.fromDocType})->${relation.toId}(${
      relation.toDocType
    })-${relation.fieldName}`

    try {
      if (!scm.getDocSchema(relation.fromDocType)) {
        addMessage('relations', {
          type: 'error',
          message: `${key} - fromDocType doesn't exist in the schema`,
        })
        return Promise.resolve()
      }
      if (!scm.getDocSchema(relation.toDocType)) {
        addMessage('relations', {
          type: 'error',
          message: `${key} - toDocType doesn't exist in the schema`,
        })
        return Promise.resolve()
      }

      const fromDoc = await queryDoc({ secondaryId: relation.fromId })
      if (!fromDoc) {
        addMessage('relations', {
          type: 'error',
          message: `${key} - fromId not found`,
        })
        return Promise.resolve()
      }

      const toDoc = await queryDoc({ secondaryId: relation.toId })
      if (!toDoc) {
        addMessage('relations', {
          type: 'error',
          message: `${key} - toId not found`,
        })
        return Promise.resolve()
      }

      const fromField = scm.getFieldSchema<LinkFieldType>(relation.fromDocType, relation.fieldName)

      if (!fromField) {
        addMessage('relations', {
          type: 'error',
          message: `${key} - ${relation.fieldName} doens't exist in the schema of ${relation.fromDocType}`,
        })
        return Promise.resolve()
      }

      const keys = keyHash.getDocumentRelation(relation.fieldName, fromDoc._id, toDoc._id)
      const existedRelation = await db.ws.relations.get(keys.id)

      if (existedRelation) {
        if (!relation.delete && !relation.rewrite) {
          addMessage('relations', {
            type: 'success',
            message: `${key} - already exists`,
          })

          return Promise.resolve()
        }

        await deleteOneRelation(existedRelation)
      }

      if (relation.delete) {
        addMessage('relations', {
          type: 'success',
          message: `${key} - deleted`,
        })
        return Promise.resolve()
      }

      const response = await createOneRelation({
        fieldName: relation.fieldName,
        fromId: fromDoc._id,
        fromDocType:
          relation.fromDocType === '__home__' ? WORKSPACE_DOCUMENT_TYPE_NAME : relation.fromDocType,
        toId: toDoc._id,
        toDocType: relation.toDocType,
        relationType: relation.relationType,
        reverseFieldName: relation.reverseFieldName ?? fromField.reverseName ?? '',
      })

      if (response) {
        addMessage('relations', {
          type: 'success',
          message: `${key} - created`,
        })
      } else {
        addMessage('relations', {
          type: 'error',
          message: `${key} - not created on server`,
        })
      }
      return Promise.resolve()
    } catch (error) {
      addMessage('relations', {
        type: 'error',
        message: `${key} - WRONG DATA`,
      })
      window.Rollbar.error(key, relation, error as Error)
    }
  })
}

async function createFields(fields: DocumentImportData['fields']) {
  await sequentially(fields, async (field, i) => {
    const key = `${i + 1}: ${field.secondaryId}-${field.fieldName}`

    try {
      const doc = await queryDoc({ secondaryId: field.secondaryId })
      if (!doc) {
        addMessage('fields', {
          type: 'error',
          message: `${key} - docId not found`,
        })
        return Promise.resolve()
      }

      await saveField(doc._id, { name: field.fieldName, value: field.value }, { saveLog: false })

      addMessage('fields', {
        type: 'success',
        message: `${key} - saved`,
      })
      return Promise.resolve()
    } catch (error) {
      addMessage('fields', {
        type: 'error',
        message: `${key} - not created on server`,
      })

      window.Rollbar.error(key, field, error as Error)
      return Promise.resolve()
    }
  })
}

async function uploadAssets(assets: DocumentImportData['assets'] = []) {
  const updatedProfiles: { userId: string; profileImage: ImageFieldData }[] = []

  await sequentially(assets, async (asset, i) => {
    const key = `${i + 1}: ${asset.docId}-${asset.field}-${asset.filename}`

    try {
      const user = await getUser(asset.docId)
      const doc = await queryDoc({ secondaryId: asset.docId })
      const timestamp = getUnixTime(new Date())
      const fileName = `${timestamp}-${asset.filename}`

      if (doc) {
        try {
          await uploadCdnApi({
            _documentId: doc._id,
            fileName,
            url: asset.url,
          })

          const img: ImageFieldData = {
            source: 'cdn',
            _clientId: db.activeClient._id,
            _documentId: doc._id,
            fileName,
          }

          await saveField(doc._id, { name: asset.field, value: img }, { saveLog: false })

          addMessage('assets', {
            type: 'success',
            message: `Image ${key} - saved`,
          })
          return Promise.resolve()
        } catch (error) {
          addMessage('assets', {
            type: 'error',
            message: `Image ${key} - not created on server`,
          })
          return Promise.resolve()
        }
      }

      if (user) {
        try {
          if (user.profileImage?.fileName) {
            addMessage('assets', {
              type: 'success',
              message: `Profile image ${key} - already exists`,
            })
            return Promise.resolve()
          }

          await uploadCdnApi({
            _documentId: user._id,
            fileName,
            url: asset.url,
          })
          const profileImage: ImageFieldData = {
            source: 'cdn',
            _clientId: db.activeClient._id,
            _documentId: user._id,
            fileName,
          }

          await updateUserApi({
            _id: user._id,
            profileImage,
          })

          updatedProfiles.push({ userId: user._id, profileImage })

          addMessage('assets', {
            type: 'success',
            message: `Profile image ${key} - saved`,
          })
          return Promise.resolve()
        } catch (error) {
          addMessage('assets', {
            type: 'error',
            message: `${key} - not created on server`,
          })
          return Promise.resolve()
        }
      }

      addMessage('assets', {
        type: 'error',
        message: `${key} - docId not found`,
      })
      return Promise.resolve()
    } catch (error) {
      window.Rollbar.error(key, asset, error as Error)
    }
  })

  if (updatedProfiles.length) {
    await Promise.all(
      updatedProfiles.map(async ({ userId, profileImage }) =>
        db.ws.users.update(userId, { profileImage }),
      ),
    )
  }
}

async function createInteractions(interactions: DocumentImportData['interactions']) {
  await sequentially(interactions, async (interaction, i) => {
    const key = `${i + 1}: ${interaction.secondaryId}-${interaction.type}`

    const doc = await queryDoc({ secondaryId: interaction.secondaryId })
    if (!doc) {
      addMessage('interactions', {
        type: 'error',
        message: `${key} - docId not found`,
      })
      return Promise.resolve()
    }

    const user = await await getUser(interaction.userId)
    if (!user) {
      addMessage('interactions', {
        type: 'error',
        message: `${key} - user not found`,
      })
      return Promise.resolve()
    }

    const existedInteraction = await db.ws.interactions.get({
      _sourceId: interaction.secondaryId,
    })
    if (existedInteraction) {
      addMessage('interactions', {
        type: 'error',
        message: `${key} - already exists`,
      })
      return Promise.resolve()
    }

    try {
      const response = await createInteractionApi({
        _partId: doc._id,
        _type: interaction.type as InteractionType,
        value: interaction.value,
        _userId: user._id,
        _sourceId: interaction.secondaryId,
        _addedOn: interaction.createdOn,
        _modifiedOn: interaction.modifiedOn,
      })

      await db.ws.interactions.put(response)

      addMessage('interactions', {
        type: 'success',
        message: `${key} - created`,
      })
      return Promise.resolve()
    } catch (error) {
      addMessage('interactions', {
        type: 'error',
        message: `${key} - not created on server`,
      })
      return Promise.resolve()
    }
  })
}

async function getUser(userId = '') {
  let user = await db.ws.users.get({ _sourceId: userId })
  if (user) return user

  user = await db.ws.users.get({ _id: userId || db.activeAccount._id })
  return user
}

export async function inviteUsers(users: UserData[]) {
  const newUsers: User[] = []

  await sequentially(users, async (user, i) => {
    const key = `${i + 1}: ${user.secondaryId}-${user.name}`

    if (!user.email || !validateEmail(user.email)) {
      addMessage('users', {
        type: 'error',
        message: `${key} - email is not valid`,
      })

      return Promise.resolve()
    }

    try {
      const existedUser = await db.ws.users.get({ _sourceId: user.secondaryId })
      if (existedUser) return Promise.resolve()

      const { user: newUser } = await addEmailToWorkspaceApi({
        name: user.name,
        email: user.email,
        _sourceId: user.secondaryId,
        _disableEmail: true,
      })

      newUsers.push(newUser)

      addMessage('users', {
        type: 'success',
        message: `${key} - invited`,
      })
      return Promise.resolve()
    } catch (error) {
      addMessage('users', {
        type: 'error',
        message: `${key} - not created on server`,
      })

      window.Rollbar.error(key, user, error as Error)
      return Promise.resolve()
    }
  })

  if (newUsers.length) {
    db.ws.transaction('rw', db.ws.users, () => {
      db.ws.users.bulkPut(newUsers)
    })
  }
}
