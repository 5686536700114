import { useFieldContext } from '@/contexts'
import { useFilterState } from '@/contexts/filter'
import { scm } from '@/contexts/schema'
import { DataListParams, Doc } from '@/db'
import { useSlr } from '@/store'
import { selectFilters } from '@/store/filters/selectors'
import { getField } from '@/utils/fields'
import { useMemo } from 'react'

export function useFilterDocs(docs?: Doc[]) {
  const { field } = useFieldContext<DataListParams>()
  const globalfilters = useSlr(selectFilters)
  const { filters: filtersState } = useFilterState()

  let filters = filtersState

  if (field.useDiagramFilter) {
    filters = globalfilters
  }

  return useMemo(() => {
    if (!docs) return []
    if (!filters) return docs
    const { title, ...fields } = filters
    const fieldNames = Object.keys(fields)

    const searchValue = (title as string)?.toLowerCase()

    return docs.filter((docItem) => {
      if (searchValue?.length) {
        return docItem.title.toLowerCase().includes(searchValue)
      }

      return fieldNames.every((fieldName) => {
        const fieldSchema = scm.getFieldSchema(docItem._type, fieldName)

        switch (fieldSchema?.type) {
          case 'multilink':
          case 'singlelink': {
            const filterValue = filters[fieldName] as string[]
            const fieldValue = getField<string[]>(fieldName, docItem.fields)

            return filterValue.some((value) => fieldValue?.includes(value))
          }
          case 'boolean': {
            const filterValue = filters[fieldName]
            const fieldValue = getField(fieldName, docItem.fields)

            if (!filterValue) {
              return !fieldValue
            }

            return filterValue === fieldValue
          }
          case 'number': {
            const filterValue = filters[fieldName]
            const fieldValue = getField(fieldName, docItem.fields) ?? 0

            return filterValue && fieldValue >= filterValue[0] && fieldValue <= filterValue[1]
          }
          default: {
            const filterValue = filters[fieldName]
            const fieldValue = getField(fieldName, docItem.fields)

            return filterValue === fieldValue
          }
        }
      })
    })
  }, [docs, field.filters, filters, globalfilters])
}
