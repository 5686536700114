import { Icon, Tooltip } from '@/ui'
import { CardElementsProps } from './CardElements/cardElements'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface'
import { IconName } from '@/ui/Icon'
import { DocumentType } from '@/db'

const getData = (type: string, docSchema: DocumentType | undefined) => {
  if (!docSchema) return
  const options = docSchema?.iconsData
  const option = options?.find(
    (item) => item.value.toLocaleLowerCase() === type.toLocaleLowerCase(),
  )
  if (option) {
    return option
  }
}

export const TypeIcon = ({ docItem, docSchema }: CardElementsProps) => {
  const { field } = useFieldContext<ChildrenFieldType>()
  if (!docItem) return
  const type = (docItem?._type as string) ?? ''
  if (!type) return

  const data = getData(type, docSchema)
  if (!data) {
    return null
  }
  const backgroundColor = field.layout?.card?.iconColor ?? 'white'

  return (
    <div className="flex gap-1 items-center relative z-50">
      {
        <div
          className="rounded-full aspect-square grid place-items-center items-center justify-center w-10 h-10"
          style={{ background: backgroundColor }}
        >
          <Tooltip content={type as string}>
            <Icon name={data.icon as IconName} />
          </Tooltip>
        </div>
      }
    </div>
  )
}
